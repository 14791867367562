import ApiRequest from 'services/api'
import { apiUrls } from 'configs/apis'
import { DispatchType } from 'types'
import { UPDATE_APP } from '../actionTypes'

/**
 * Get menu, chứa danh sách departments
 */
export const getMenuApi = () => (dispatch: DispatchType) => {
  return ApiRequest.get(apiUrls.menu(), {}, ({ status, data }) => {
    if (status) dispatch({ type: UPDATE_APP, payload: data })
  })
}
