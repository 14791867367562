import { Container } from '@mui/material'
import React from 'react'
import { useIsMobile } from 'store/mobile'
import { OverviewType } from 'types'
import Overview from '../overview/Overview'
import Title from '../title/Title'

interface Props {
  overview: OverviewType
}

const IntroductionsOverview: React.FC<Props> = ({ overview }) => {
  // const { apiGet } = useApis()
  const isMobile = useIsMobile()

  const { department, research_group, scientific_paper, research_information, reward } = overview

  if (!department && !research_group && !scientific_paper && !research_information && !reward)
    return null

  return (
    <>
      {isMobile ? (
        <Container>
          <Title>Thành tựu</Title>
        </Container>
      ) : (
        <div style={{ height: 24, width: '100%' }} />
      )}
      <Overview {...overview} noBackground={isMobile} />
    </>
  )
}

export default IntroductionsOverview
