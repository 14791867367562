import { Container } from '@mui/material'
import EditorViewer from '../../editorViewer/EditorViewer'
import React from 'react'
import Title from '../title/Title'

import imgGeneral from 'assets/images/departments/general.jpg'
import { useIsMobile } from 'store/mobile'

interface Props {
  introduction?: string
}

const DepartmentGeneral: React.FC<Props> = ({ introduction }) => {
  const isMobile = useIsMobile()

  if (!introduction) return null

  const img = (
    <div className="img">
      <img alt="" src={imgGeneral} />
    </div>
  )

  return (
    <div className="DepartmentGeneral mt-80 mb-80">
      <Container className="content d-f fw-w py-80 py-80-24 jc-sb">
        <div className="left">
          <Title noPadding>Giới thiệu chung</Title>
          {isMobile && img}
          <EditorViewer content={introduction} />
        </div>
        {!isMobile && img}
      </Container>
    </div>
  )
}

export default DepartmentGeneral
