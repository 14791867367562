import Title from '../title/Title'
import React from 'react'
import { useHome } from 'store/home'
import NewsItem from '../newsItem/NewsItem'
import Link from '../links/Link'
import { Container } from '@mui/material'
import { ROUTES } from 'configs/constants'
import { useIsMobile } from 'store/mobile'

// Quy định cách hiển thị giao diện như design, 1 bài viết, 2 bài ...
const mappingBlockTypes: any = {
  1: [1],
  2: [2, 2],
  3: [3, 4, 4],
  4: [2, 4, 4, 4],
}

const HomeNews: React.FC = () => {
  const { news } = useHome()
  const isMobile = useIsMobile()

  if (!news.length) return null

  const viewMore = news.length > 4
  const length = news.length

  // Nhiều hơn 4 cũng show như 4, chỉ khác có nút xem thêm
  const blockTypes = mappingBlockTypes[isMobile ? 2 : length > 4 ? 4 : length]

  const firstItem = (
    <NewsItem {...(news?.[0] || {})} blockType={blockTypes?.[0]} isFirstItem={true} />
  )

  return (
    <Container>
      <div className="HomeNews">
        <Title>Tin tức &amp; Sự kiện</Title>
        <div className="HomeNews-row">
          {length > 0 && (
            <>
              {isMobile ? (
                news.slice(0, 4).map((item, index) => (
                  <div className="col" key={item.id}>
                    <NewsItem {...item} blockType={2} isFirstItem={!index} />
                  </div>
                ))
              ) : length === 1 ? (
                firstItem
              ) : (
                <>
                  <div className="col">{firstItem}</div>
                  <div className="col">
                    {news.slice(1, 4).map((item, index) => (
                      <NewsItem
                        key={item.id}
                        {...item}
                        blockType={blockTypes[index + 1]}
                        isFirstItem={!index && length === 2}
                      />
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {viewMore && (
          <div className={`text-center${isMobile ? '' : ' mt-2'}`}>
            <Link className="typo-18-6 underline" to={`/${ROUTES.ROUTE_NEWS}`} color="neutral-2">
              Xem thêm
            </Link>
          </div>
        )}
      </div>
    </Container>
  )
}

export default HomeNews
