/* eslint-disable */
import { apiUrls } from 'configs/apis'
import { MAX_ATTACHMENT_SIZE } from 'configs/constants'
import React, { useRef, useState } from 'react'
import { useApis } from 'services/api'
import { useModalConfirm } from 'store/modalConfirm'
import { bibtexToJson } from 'utils'
import Button from '../button/Button'

interface Props {
  onSuccess: any
}

const ImportScientificPapers: React.FC<Props> = ({ onSuccess }) => {
  const inputRef = useRef<any>()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const { apiPost, apiPut } = useApis()

  const [loading, setLoading] = useState(false)

  const handleUploadFile = async (e: any) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }

    if (file.size > MAX_ATTACHMENT_SIZE) {
      const inputSelector: any = document.getElementById('file-input')
      if (inputSelector.value) {
        inputSelector.value = ''
      }
      showModalConfirm({
        content: 'Không thể upload file trên 50 MB.',
        confirm: { action: hideModalConfirm },
        close: hideModalConfirm,
      })

      return
    }

    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = e.target?.result

      try {
        bibtexToJson(text as any)
      } catch {
        showModalConfirm({
          content: 'Tập tin không đúng định dạng. Vui lòng kiểm tra lại.',
          confirm: { action: hideModalConfirm },
          close: hideModalConfirm,
        })
        return
      }

      setLoading(true)

      apiPost(
        apiUrls.importScientificPapers(),
        {
          upload: {
            notHasPrefix: true,
            file,
          },
        },
        ({ status, text, id, data }) => {
          setLoading(false)
          if (status) {
            onSuccess()
          } else {
            const needConfirm = id === '191'
            const textErr = text
              .replaceAll(
                '<ul>',
                '<ul style="list-style: inside; margin-left: 12px;text-indent: -1.5rem;margin-left: 2.5rem;">'
              )
              .replaceAll('{', '')
              .replaceAll('}', '')
            showModalConfirm({
              content: needConfirm ? textErr : text,
              cancel: needConfirm ? { text: 'Hủy', action: hideModalConfirm } : undefined,
              confirm: needConfirm
                ? {
                    action: () => {
                      hideModalConfirm()
                      apiPut(
                        apiUrls.confirmJoinScientificPapers(),
                        { ...data, is_import: true },
                        () => {
                          onSuccess()
                        }
                      )
                    },
                    text: 'Đồng ý',
                  }
                : { action: hideModalConfirm },
              close: hideModalConfirm,
              center: !needConfirm,
            })
          }
        }
      )
    }
    reader.readAsText(file)

    e.target.value = null
  }

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        id="file-input"
        accept=".bib"
        style={{ display: 'none' }}
        onChange={handleUploadFile}
      />
      <Button
        colorType="default secondary"
        icon="upload"
        size="small"
        onClick={() => inputRef.current?.click()}
        loading={loading}
      >
        <span className="text">Import công bố khoa học</span>
      </Button>
    </>
  )
}

export default ImportScientificPapers
