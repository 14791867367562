import React from 'react'
import Slider, { Settings } from 'react-slick'
import './index.scss'

interface Props extends Settings {
  dark?: boolean
}

const MySlider: React.FC<Props> = ({ className = '', dark, ...rest }) => {
  return <Slider className={`Slider${dark ? ' dark' : ''} ${className}`} {...rest} />
}

export default MySlider
