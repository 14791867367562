/* eslint-disable */
import {
  Card,
  TableContainer,
  Table as TableWrapper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Box,
  Select,
  MenuItem,
  Stack,
} from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Row from './Row'
import './index.scss'
import { useState } from 'react'
import { RowType, TableFieldsType, TableSortType } from 'types'
import Button from '../button/Button'
import { addNumber, formatCompare } from 'utils'
import { LS_SHOWN_COLUMNS, MAX_PAGE_SIZE } from 'configs/constants'
import ShowHideColumns from './ShowHideColumns'
import Pagination from './Pagination'
import { isNumber, isString } from 'lodash'
import '../../scrollbar/index.scss'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import Checkbox from '../checkbox/Checkbox'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const getAllTablesShownColumns = () => {
  const allTablesShownColumns = localStorage.getItem(LS_SHOWN_COLUMNS)
  if (!allTablesShownColumns) return

  try {
    const data = JSON.parse(allTablesShownColumns)
    return data
  } catch {}
}

interface Props {
  items: RowType[]
  fields: TableFieldsType
  loading?: boolean
  headerLeft?: React.ReactChild
  headerRight?: React.ReactChild
  refreshButton?: { disabled?: boolean; action?: () => void } | any
  createButton?: { text?: string; action?: () => void; disabled?: boolean } | any
  removeButton?: { text?: string; disabled?: boolean; action?: () => void } | any
  importButton?: { action?: () => void; disabled?: boolean } | any
  minWidth?: any
  onChangeCheckbox?: (selected: string[]) => void
  sort?: {
    isBackend?: boolean
    field: string
    order: 'asc' | 'desc'
    onChange: (params: TableSortType) => void
  }
  pagination?: {
    total: number
    pageSize: number
    page: number
    onPageChange?: (page: number) => void
    onPageSizeChange?: (pageSize: number) => void
    hidePage?: boolean
    itemsLabel?: string
    itemsLabelPage?: string
  }
  isFiltered?: boolean
  hiddenColumns?: string[]
  onClickItem?: (item: RowType) => void
  onMiddleClickItem?: (item: RowType) => void
  name?: string
  showHideColumnsWidth?: number
  onDragDrop?: (positionDrag: number, positionDrop: number) => void
  noIndex?: boolean
  hideButtonShowColumns?: boolean
  modifyLabelShowHideCol?: (label: string) => string
}

const DataTable: React.FC<Props> = ({
  items: oldItems,
  fields: oldFields,
  sort: oldSort,
  loading,
  headerLeft,
  headerRight,
  refreshButton,
  createButton,
  removeButton,
  importButton,
  minWidth,
  onChangeCheckbox,
  pagination = {
    total: 0,
    pageSize: 0,
    page: 0,
    onPageChange: () => {},
    onPageSizeChange: () => {},
    hidePage: false,
  },
  isFiltered,
  hiddenColumns = [],
  name,
  onClickItem,
  onMiddleClickItem,
  showHideColumnsWidth,
  onDragDrop,
  noIndex,
  hideButtonShowColumns,
  modifyLabelShowHideCol,
}) => {
  const { t } = useTranslation()
  const { apiPut } = useApis()
  const {
    total,
    page = 1,
    onPageChange,
    pageSize = MAX_PAGE_SIZE,
    onPageSizeChange,
    hidePage,
    itemsLabel,
  } = pagination

  const dnd = !!onDragDrop // Check drag drop

  const itemsLabelPage = pagination.itemsLabelPage || itemsLabel

  const [sort, setSort] = useState({
    field: oldSort?.field || oldSort?.field || '',
    order: oldSort?.order || oldSort?.order || 'asc',
  })
  const [selected, setSelected] = useState<string[]>([])

  // Update selected khi data bị thay đổi
  useEffect(() => {
    if (selected.length) {
      setSelected(selected.filter((item) => oldItems.some((i) => i.id === item)))
    }
  }, [oldItems])

  // Add column no.
  const fields: TableFieldsType = {
    ...(noIndex ? {} : { number: { style: { width: '6%', textAlign: 'center' } } }),
    ...oldFields,
  }

  const allFieldsObj = { ...fields }
  // delete allFieldsObj.actions
  const allFields = dnd ? ['drag_drop', ...Object.keys(allFieldsObj)] : Object.keys(allFieldsObj)
  const saveShownColumns: string[] = useMemo(() => {
    const data = getAllTablesShownColumns()
    return data?.[name || '']
  }, [])

  const [shownColumns, setShownColumns] = useState(
    saveShownColumns || allFields.filter((item) => !hiddenColumns.includes(item))
  )

  const colsLocal = getAllTablesShownColumns()?.[name || '']
  useEffect(() => {
    if (colsLocal?.length) setShownColumns(colsLocal)
  }, [JSON.stringify(colsLocal)])

  const showDragColumn = shownColumns.includes('drag_drop')

  // Add data for column no.
  const items = oldItems.map((item: any, index: number) => addNumber(item, index, page, pageSize))

  const hiddenFields = allFields.filter((item) => !shownColumns.includes(item))
  const shownFields: TableFieldsType = fields
  hiddenFields.forEach((item) => {
    delete shownFields[item]
  })

  const columns = Object.keys(shownFields).map((item) => ({
    key: item,
    label: fields[item].label,
    sort: fields[item].sort,
  }))

  const renderLoading = (
    <TableRow className={`DataTable-loading${items.length > 0 ? ' has-items' : ' no-items'}`}>
      <TableCell align="center" colSpan={columns.length}>
        <CircularProgress size={28} color="inherit" />
      </TableCell>
    </TableRow>
  )

  const renderNoResults = (
    <TableRow>
      <TableCell align="center" colSpan={columns.length} sx={{ py: 5 }}>
        Không có kết quả
      </TableCell>
    </TableRow>
  )

  const rows = [15, 25, 50, 100]

  const totalPages = Math.ceil(total / pageSize)
  const totalRows = items.length

  let newItems = items
  if (!oldSort?.isBackend && sort.field) {
    // Sort data
    newItems = newItems
      .sort((a, b) => {
        const key = sort.field
        let valueA = a[key]
        let valueB = b[key]
        if (isString(valueA)) valueA = formatCompare(valueA)
        if (isString(valueB)) valueB = formatCompare(valueB)
        const multiple = sort.order === 'asc' ? 1 : -1
        if (valueA === valueB) {
          valueA = a['number']
          valueB = b['number']
        }
        return (valueA > valueB ? 1 : -1) * multiple
      })
      .map((item: any, index: number) => addNumber(item, index, page, pageSize))
  }

  const header = !!(headerLeft || headerRight || refreshButton || createButton)

  const checkbox = !!onChangeCheckbox

  // Is selected all if all checkbox is checked
  const isCheckedAll = !!(items.length && items.length === selected.length)

  // Click checkbox at the top of table => selected all or deselect all
  const handleCheckAll = () => {
    if (isCheckedAll) setSelected([])
    else setSelected(items.map((item) => item.id))
  }

  // Click at a checkbox => select or deselect it
  const handleChangeCheckbox = (id: string) => {
    if (selected.includes(id)) setSelected(selected.filter((item) => item !== id))
    else setSelected([...selected, id])
  }

  useEffect(() => {
    onChangeCheckbox?.(selected)
  }, [selected])

  // Drag Drop table

  const handleDragEnd = (result: any) => {
    const { source, destination } = result
    if (!destination) return
    if (source.index === destination.index) return
    onDragDrop?.(source.index, destination.index)
  }

  const renderTable = showDragColumn ? (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="table-body">
        {(dropProvided) => (
          <TableWrapper className="DataTable-table">
            <TableBody {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
              {items.length > 0 ? (
                <>
                  <TableRow style={{ height: 0, padding: 0 }}>
                    <TableCell
                      style={{
                        width: '5%',
                        height: 0,
                        padding: 0,
                        border: 'none',
                      }}
                    ></TableCell>
                    {columns.map(({ key }) => (
                      <TableCell
                        key={key}
                        style={{
                          ...fields[key].style,
                          height: 0,
                          padding: 0,
                          border: 'none',
                        }}
                      />
                    ))}
                  </TableRow>
                  {newItems.map((item, index) => (
                    <Draggable draggableId={item.id} key={item.id} index={index}>
                      {(provided, snapshot) => {
                        let newDnd: any
                        if (dnd)
                          newDnd = {
                            innerRef: isFiltered ? undefined : provided.innerRef,
                            dragHandleProps: isFiltered ? undefined : provided.dragHandleProps,
                            draggableProps: isFiltered ? undefined : provided.draggableProps,
                            isDragging: isFiltered ? undefined : snapshot.isDragging,
                          }

                        return (
                          <Row
                            dnd={newDnd}
                            dndDisable={isFiltered}
                            key={item.id || index}
                            fields={shownFields}
                            row={item}
                            onClick={onClickItem}
                            onMiddleClick={onMiddleClickItem}
                            checkbox={
                              checkbox
                                ? {
                                    checked: selected.includes(item.id),
                                    onClick: () => handleChangeCheckbox(item.id),
                                  }
                                : undefined
                            }
                          />
                        )
                      }}
                    </Draggable>
                  ))}
                  {dropProvided.placeholder}
                  {loading && renderLoading}
                </>
              ) : !loading ? (
                renderNoResults
              ) : (
                renderLoading
              )}
            </TableBody>
          </TableWrapper>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <TableWrapper className="DataTable-table">
      <TableBody>
        {items.length > 0 ? (
          <>
            {newItems.map((item, index) => (
              <Row
                key={item.id || index}
                fields={shownFields}
                row={item}
                onClick={onClickItem}
                onMiddleClick={onMiddleClickItem}
                checkbox={
                  checkbox
                    ? {
                        checked: selected.includes(item.id),
                        onClick: () => handleChangeCheckbox(item.id),
                      }
                    : undefined
                }
              />
            ))}
            {loading && renderLoading}
          </>
        ) : !loading ? (
          renderNoResults
        ) : (
          renderLoading
        )}
      </TableBody>
    </TableWrapper>
  )

  if (!isFiltered && items.length === 0)
    return (
      <div className="d-f ai-c jc-c" style={{ width: '100%', height: '60vh' }}>
        <div style={{ textAlign: 'center' }}>
          {loading ? (
            <CircularProgress size={28} color="inherit" />
          ) : (
            <>
              <div style={{ fontSize: 18, marginBottom: 18, textAlign: 'center', fontWeight: 600 }}>
                Chưa có {itemsLabel}
              </div>
              <div className="d-f jc-c">
                {!!importButton && (
                  <div className="mr-2">
                    {
                      <Button
                        variant="contained"
                        size="medium"
                        disabled={loading}
                        onClick={importButton.action}
                        style={{ height: 40 }}
                      >
                        <i className="fas fa-upload mr-1" />
                        Import
                      </Button>
                    }
                  </div>
                )}
                {!!createButton && (
                  <Button
                    variant="contained"
                    size="medium"
                    disabled={createButton.disabled}
                    onClick={createButton.action}
                    style={{ height: 40 }}
                  >
                    <i className="fas fa-plus mr-1" />
                    {createButton.text}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    )

  const shownColumnsLength = Object.keys(shownFields).length
  const extraMinWidth = shownColumnsLength > 12 ? (shownColumnsLength - 12) * 110 : 0

  if (isNumber(minWidth)) minWidth += extraMinWidth

  return (
    <Card className="DataTable" style={{ padding: '20px 0 24px' }}>
      {header && (
        <Stack className="DataTable-header" flexDirection="row" gap={3}>
          <div className="d-f jc-sb w-100">
            <div className="d-f ai-c space-2" style={{ flexWrap: 'wrap' }}>
              {headerLeft}
            </div>
            <div className="d-f ai-c space-2-left">
              {importButton && (
                <Button
                  className="btn-refresh"
                  style={{ marginRight: -8 }}
                  size="small"
                  disabled={loading}
                  title="Import dữ liệu"
                  onClick={importButton.action}
                >
                  <i className="fas fa-upload" />
                </Button>
              )}
              {refreshButton && (
                <Button
                  className="btn-refresh"
                  size="small"
                  disabled={refreshButton.disabled}
                  onClick={refreshButton.action}
                  title="Tải lại dữ liệu"
                >
                  <i className="fas fa-sync-alt" />
                </Button>
              )}
              {headerRight}
              {createButton && (
                <Button
                  variant="contained"
                  size="small"
                  disabled={createButton.disabled}
                  onClick={createButton.action}
                >
                  <i className="fas fa-plus" />
                  {createButton.text}
                </Button>
              )}
            </div>
          </div>
        </Stack>
      )}
      {!hideButtonShowColumns && (
        <div className="d-f jc-sb">
          <Button
            variant="contained"
            color="error"
            style={{
              padding: '0 10px',
              height: 32,
              marginLeft: 20,
              visibility: removeButton ? 'visible' : 'hidden',
            }}
            disabled={removeButton?.disabled || selected.length === 0}
            onClick={removeButton?.action}
          >
            {removeButton?.text}
          </Button>
          <ShowHideColumns
            columns={allFields}
            defaultShownColumns={shownColumns}
            save={(shown) => {
              const data = getAllTablesShownColumns() || {}
              if (shown) data[name || ''] = shown
              else delete data[name || '']
              localStorage.setItem(LS_SHOWN_COLUMNS, JSON.stringify(data))
              setShownColumns(shown || allFields.filter((item) => !hiddenColumns.includes(item)))
              apiPut(apiUrls.adminSetting(), { display_column: data })
            }}
            width={showHideColumnsWidth}
            modifyLabel={modifyLabelShowHideCol}
          />
        </div>
      )}

      <div className="Scrollbar bold">
        <TableContainer sx={{ minWidth: minWidth || 1000 }}>
          <TableWrapper className="DataTable-table">
            <TableHead>
              <TableRow>
                {showDragColumn && (
                  <TableCell style={{ width: '5%', textAlign: 'center' }}>
                    {t('drag_drop')}
                  </TableCell>
                )}
                {checkbox && (
                  <TableCell style={{ width: '4%' }}>
                    <Checkbox checked={isCheckedAll} onClick={handleCheckAll} />
                  </TableCell>
                )}
                {columns.map(({ key, label, sort: s }) => {
                  const sortKey = fields[key].key || key
                  label = t(label || key)
                  const active = sort.field === sortKey

                  return (
                    <TableCell key={key} style={fields[key].style}>
                      {!s ? (
                        label
                      ) : (
                        <div
                          className={`DataTable-table__sort-cell ${active ? sort.order : s}${
                            active ? ' active' : ''
                          }`}
                          onClick={() => {
                            const newSort = {
                              field: sortKey,
                              order: active ? (sort.order === 'asc' ? 'desc' : 'asc') : s,
                            }
                            setSort(newSort)
                            oldSort?.onChange(newSort)
                          }}
                        >
                          {label}
                          <i className="fas fa-arrow-down" />
                        </div>
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
          </TableWrapper>
          {renderTable}
        </TableContainer>
      </div>

      {!hidePage && total > 0 && (
        <Box className="Pagination" display="flex" justifyContent="center" pt={2}>
          {total > 0 && (
            <>
              <Pagination
                count={totalPages}
                page={page}
                onChange={(page) => onPageChange?.(page)}
                disabled={loading}
              />
              <Stack
                className={`Pagination-right${loading ? ' disabled' : ''}`}
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={2}
              >
                {!!onPageSizeChange && (
                  <Select
                    className="ChangePageSize"
                    size="small"
                    value={pageSize}
                    onChange={(p) => onPageSizeChange?.(Number(p.target.value))}
                    disabled={loading}
                  >
                    {rows.map((item) => (
                      <MenuItem
                        key={item}
                        className="ChangePageSize-item"
                        style={{ display: item === pageSize ? 'none' : undefined }}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                <div>
                  {totalPages === 1 ? (
                    totalRows
                  ) : (
                    <>
                      {totalRows}/{total}
                    </>
                  )}{' '}
                  {itemsLabelPage}
                </div>
              </Stack>
            </>
          )}
        </Box>
      )}
    </Card>
  )
}

export default DataTable
