import React from 'react'
import Slider from 'react-slick'
import { MissionType } from 'types'
import { ButtonArrow } from '../button'
import MissionItem from '../missionItem/MissionItem'
import { useIsMobile } from 'store/mobile'

import './index.scss'

interface Props {
  data: MissionType[]
  light?: boolean
}

const IntroductionsMissions: React.FC<Props> = ({ data, light }) => {
  const isMobile = useIsMobile()

  const settings = {
    dots: true,
    infinite: isMobile,
    speed: 800,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: isMobile ? 1 : 3,
    arrows: isMobile ? false : true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const Arrow = light ? (
    <ButtonArrow extraClass="DepartmentSubjects-arrow" big />
  ) : (
    <ButtonArrow extraClass="DepartmentSubjects-arrow" big outline />
  )

  return (
    <Slider
      className={`IntroductionsMissionsSlider${light ? '' : ' dark'}`}
      pauseOnHover={false}
      prevArrow={isMobile ? undefined : Arrow}
      nextArrow={isMobile ? undefined : Arrow}
      {...settings}
    >
      {data.map((item, index) => (
        <div className="col" key={index}>
          <MissionItem {...item} dark={light} />
        </div>
      ))}
    </Slider>
  )
}

export default IntroductionsMissions
