import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'store/confirmRedirect'
import './index.scss'
import Button from '../button/Button'
import { useModalConfirm } from 'store/modalConfirm'
import { useAuth } from 'store/auth'

interface Props {
  title: string
  back?: boolean
}

const Title: React.FC<Props> = ({ title, back }) => {
  const history = useHistory()
  const ref = useRef<any>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const { signOut } = useAuth()

  const { showModalConfirm, hideModalConfirm } = useModalConfirm()

  useEffect(() => {
    setShowTooltip(ref?.current?.scrollHeight > ref?.current?.clientHeight)
  }, [ref, title])

  useEffect(() => {
    const resizeEvent = () => {
      setShowTooltip(ref?.current?.scrollHeight > ref?.current?.clientHeight)
    }
    window.addEventListener('resize', resizeEvent, true)

    return window.removeEventListener('resize', resizeEvent)
  }, [])

  const handleRiderect = () => {
    showModalConfirm({
      title: 'Thông báo',
      content: 'Bạn có chắc chắn muốn chuyển hướng sang trang Người dùng không?',
      cancel: { text: 'Hủy', action: hideModalConfirm },
      confirm: {
        text: 'Chuyển hướng',
        action: () => {
          hideModalConfirm()
          signOut()
          history.push('/')
        },
      },
      close: hideModalConfirm,
    })
  }

  return (
    <Box ml={back ? 0 : 4} mb={2.5} className="d-f ai-c">
      {back && (
        <div
          className="cursor-pointer a"
          style={{ padding: '5px 5px 2px', margin: '0 10px 0 6px', fontSize: 19 }}
          onClick={() => history.goBack()}
        >
          <i className="fas fa-chevron-left" />
        </div>
      )}
      <div
        style={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div ref={ref} className="AdminTitle" title={showTooltip ? title : undefined}>
          {title}
        </div>
        <Button
          className="button-rederect"
          variant="outlined"
          size="medium"
          onClick={handleRiderect}
        >
          Trang Người dùng <i style={{ marginLeft: 8 }} className="icon-arrow-ios-right" />
        </Button>
      </div>
    </Box>
  )
}

export default Title
