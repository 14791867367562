import { mappingTrainingInfoRoutes, ROUTES } from 'configs/constants'
import React from 'react'
import { useIsMobile } from 'store/mobile'
import { formatDate } from 'utils'
import Link from '../links/Link'
import './index.scss'

interface Props {
  id?: string
  created_date?: any
  title: string
  content_to_summary?: string
  image_url?: string
  type_training?: string
  number_of_comment?: number
  view?: number
}

const TrainingInfoItem: React.FC<Props> = ({
  id,
  created_date,
  title,
  content_to_summary,
  image_url,
  type_training = '',
  number_of_comment,
  view,
}) => {
  const isMobile = useIsMobile()

  return (
    <Link
      to={`/${ROUTES.ROUTE_TRAINING_INFO}/${mappingTrainingInfoRoutes[type_training]}/${id}`}
      className="TrainingInfoItem"
    >
      <div className="banner background-image" style={{ backgroundImage: `url("${image_url}")` }} />
      <div className="body">
        <div className="label-icon-wrapper neutral-2">
          <div className="date">
            <i className="icon-calendar1" />
            {formatDate(created_date)}
          </div>
          <div className="d-f">
            <div className="view">
              <i className="icon-eye"></i>
              {view}
            </div>
            <div className="comment">
              <i className="icon-comment-r"></i>
              {number_of_comment}
            </div>
          </div>
        </div>
        <p className="title typo-20-6 max-line">{title}</p>
        <p className={`content typo-16-4 typo-16-14 max-line${isMobile ? '-1' : ''}`}>
          {content_to_summary}
        </p>
      </div>
    </Link>
  )
}

export default TrainingInfoItem
