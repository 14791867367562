import React from 'react'
import { Link as LinkBase, LinkProps, useHistory as useHistoryBase } from 'react-router-dom'
import { useHistory } from 'store/confirmRedirect'

const Link: React.FC<LinkProps> = ({ to, onClick, ...rest }: any) => {
  const { allow, goTo } = useHistory()
  const history = useHistoryBase()

  const handleClick = (e: any) => {
    if (allow) {
      onClick?.(e)
      return
    }

    e.preventDefault()

    goTo(() => {
      onClick?.(e)
      history.push(to)
    })
  }

  return <LinkBase to={to} onClick={handleClick} {...rest} />
}

export default Link
