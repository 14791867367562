import { Button, CircularProgress, FormHelperText } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import './index.scss'
import Image from '../../image/Image'
import { useUploadFile } from 'utils/uploadFile'

const initialValues = { url: '', loading: false }

interface Props {
  prefix?: string
  image?: string
  onSuccess?: (url: string, fileSize?: number) => void
  onRemove?: () => void
  className?: string
  disabled?: boolean
  error?: string
  errorEmpty?: boolean
  center?: boolean
  isUserSide?: boolean
}

const UploadImage: React.FC<Props> = ({
  prefix = '',
  image: imageDefault = '',
  onSuccess,
  onRemove,
  className = '',
  disabled,
  error,
  errorEmpty,
  center,
  isUserSide = false,
}) => {
  const { upload, canUpload } = useUploadFile(isUserSide)
  const touched = useRef(false)

  const [image, setImage] = useState({ url: imageDefault, loading: false })
  const inputRef = useRef<any>()

  useEffect(() => {
    if (image.url !== imageDefault) setImage({ url: imageDefault, loading: false })
  }, [imageDefault])

  const handleSuccess = useCallback(
    (url: string, _?: any, fileSize?: number) => {
      setImage({ url, loading: false })
      onSuccess?.(url, fileSize)
    },
    [onSuccess]
  )

  const handleRemove = (e: any) => {
    e.stopPropagation()
    setImage(initialValues)
    onRemove?.()
  }

  const handleError = (err: string) => {
    setImage(initialValues)
    console.log(err)
  }

  const handleUploadImage = useCallback((e: any) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }

    if (canUpload(file.size)) {
      setImage({ url: URL.createObjectURL(file), loading: true })

      upload(file, prefix, handleSuccess, handleError)
    }

    e.target.value = null
  }, [])

  useEffect(() => {
    if (errorEmpty) touched.current = true
  }, [errorEmpty])

  const { url, loading } = image

  const errorText = !image.url && touched.current ? error : undefined

  return (
    <div>
      <div className={`UploadImage ${className}`} style={{ margin: center ? '0 auto' : undefined }}>
        <Button
          onClick={() => inputRef.current?.click()}
          className={`UploadImage-btn${url ? ' has-image' : ''}`}
          variant="contained"
          color="primary"
          disabled={loading || disabled}
        >
          {url ? (
            <div className="upload-image-wrapper">
              <div className="overlay" style={{ display: disabled ? 'none' : undefined }}>
                {loading ? (
                  <CircularProgress thickness={5} size={32} color="inherit" />
                ) : (
                  !disabled && (
                    <div
                      className="remove"
                      onClick={handleRemove}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      {isUserSide ? (
                        <i className="icon-x-circle"></i>
                      ) : (
                        <i className="fas fa-times-circle" />
                      )}
                    </div>
                  )
                )}
              </div>
              <Image className={disabled ? 'image-thumbnail-background' : ''} src={url} />
            </div>
          ) : isUserSide ? (
            <i className="icon-camera" />
          ) : (
            <i className="fas fa-image" />
          )}
        </Button>
        <input
          ref={inputRef}
          type="file"
          accept="image/x-png,image/jpeg"
          style={{ display: 'none' }}
          onChange={handleUploadImage}
        />
      </div>
      {!!errorText && (
        <FormHelperText
          error
          style={{ margin: '-10px 0 10px', textAlign: center ? 'center' : undefined }}
        >
          {errorText}
        </FormHelperText>
      )}
    </div>
  )
}

export default UploadImage
