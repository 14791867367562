import { Popover } from '@mui/material'
// import Scrollbar from '../../scrollbar/Scrollbar'
import React, { useEffect, useRef, useState } from 'react'
import MaxLine from 'components/maxLine/MaxLine'
import { Link } from 'components/user'
import { createFileName } from 'utils'
import './index.scss'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'

interface Props {
  attached_files: string[]
}

const PopupAttachedFile: React.FC<Props> = ({ attached_files }) => {
  const ref = useRef<HTMLDivElement>(null)

  const { apiGet } = useApis()

  const [files, setFile] = useState([])

  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const count = attached_files.length

  const getFileName = () => {
    apiGet(
      apiUrls.upload(),
      {
        attached_files: attached_files,
      },
      ({ data, status }) => {
        if (status) {
          const output: any = []

          attached_files.forEach((element) => {
            const elementFind = data.find((item: any) => item.attached_file === element)

            if (elementFind) {
              const { attached_file, file_name } = elementFind

              output.push({ attached_file, file_name, trimFile: false })
            } else {
              output.push({ attached_file: element, file_name: element, trimFile: true })
            }
          })

          setFile(output)
        }
      }
    )
  }
  useEffect(() => {
    if (!open) return
    getFileName()
  }, [open])

  return (
    <div ref={ref}>
      <b style={{ marginLeft: 6 }}>
        {count ? (
          <>
            <Popover
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{
                sx: {
                  mt: 1,
                  maxWidth: 380,
                  // minWidth: 250,
                  borderRadius: '4px',
                  padding: 2,
                },
              }}
              open={open}
              onClose={handleClose}
              anchorEl={ref.current}
            >
              {/* <Scrollbar className="mt-1" style={{ maxHeight: 440 }}> */}
              {files?.map((item: any, index) => (
                <div key={index} className="PopupAttachedFile-item d-f">
                  <div className="mr-1 fw-5">{index + 1}.</div>
                  <div className="PopupAttachedFile-item__block">
                    <div className="fw-5">
                      <Link
                        key={item}
                        className="title typo-16-5 typo-16-14"
                        color="system-link"
                        hoverUnderline
                        to={{ pathname: item.attached_file || '' }}
                        target="_blank"
                        style={{ display: 'block' }}
                      >
                        <MaxLine
                          text={item.trimFile ? createFileName(item.file_name) : item.file_name}
                          numberOfLines={10}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
              {/* </Scrollbar> */}
            </Popover>
            <span className="a" onClick={handleOpen}>
              {count}
            </span>
          </>
        ) : (
          '-'
        )}
      </b>
    </div>
  )
}

export default PopupAttachedFile
