import { DEFAULT_AVATAR, ROUTES } from 'configs/constants'
import { mappingRegency } from 'pages/admin/users/constants'
import React from 'react'
import { useHistory } from 'store/confirmRedirect'
import { UserType } from 'types'
import { createAcademicRankUserWithoutName } from 'utils'
import './index.scss'

const UserItem: React.FC<UserType> = (user) => {
  const {
    avatar_url,
    full_name,
    regency,
    department,
    email,
    id,
    hideDepartment,
    status,
    hideEmail,
  } = user
  const history = useHistory()

  const hideBr = hideDepartment && hideEmail

  return (
    <div
      className={`UserType c-p${hideDepartment ? ' hideDepartment' : ''}`}
      onClick={
        status === undefined || status
          ? () => history.push(`${ROUTES.ROUTE_USERS}/${id}`)
          : undefined
      }
    >
      <div
        className="background-image cover"
        style={{ backgroundImage: `url("${avatar_url || DEFAULT_AVATAR}")` }}
      />
      <div className="body">
        <div className="typo-14-6 rank">{createAcademicRankUserWithoutName(user)}&nbsp;</div>
        <div className="typo-24-6 fullName max-line-1">{full_name}</div>
        <div className="typo-14-6 i regency">{mappingRegency[regency]}</div>
        {!hideBr && <div className="hr" />}
        {!hideDepartment && (
          <div className="iconWrapper d-f ai-c">
            <i className="icon-book1" />
            <span className="typo-16-5">{department?.name}</span>
          </div>
        )}
        {!hideEmail && (
          <div className="iconWrapper d-f ai-c">
            <i className="icon-email" />
            <span className="typo-16-5">{email}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default UserItem
