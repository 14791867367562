/* eslint-disable */
import { Chip, CircularProgress, FormHelperText } from '@mui/material'
import Loading from 'components/loading/Loading'
import { apiUrls } from 'configs/apis'
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useApis } from 'services/api'
import { createFileName, trimFileName } from 'utils'
import { useUploadFile } from 'utils/uploadFile'
import Button from '../button/Button'
import './index.scss'

const initialValues = { url: '', loading: false }

const renderFileName = (fileName: string, maxLength?: number) => {
  if (!maxLength) return fileName
  else if (fileName.length > maxLength) {
    return fileName.slice(0, maxLength)
  } else return fileName
}

interface Props {
  prefix: string
  fileUrl?: string
  onSuccess?: (url: string) => void
  onRemove?: () => void
  onClick?: () => void
  disabled?: boolean
  error?: string
  errorEmpty?: boolean
  label?: string
  accept?: any
  style?: CSSProperties
  isUpdate?: boolean
  maxSize?: number
  maxSizeLabel?: string
  maxLength?: number
}

const UploadImage: React.FC<Props> = ({
  prefix,
  fileUrl: fileDefault = '',
  onSuccess,
  onRemove,
  onClick,
  disabled,
  error,
  errorEmpty,
  label,
  accept,
  style,
  isUpdate,
  maxSize,
  maxSizeLabel,
  maxLength,
}) => {
  const { upload, canUpload } = useUploadFile()
  const touched = useRef(false)

  const { apiGet } = useApis()

  const [file, setFile] = useState({ url: fileDefault, loading: false })
  const inputRef = useRef<any>()

  const [fileName, setFileName] = useState('')

  const [loadingFilename, setLoadingFilename] = useState(true)

  useEffect(() => {
    if (file.url !== fileDefault) setFile({ url: fileDefault, loading: false })
  }, [fileDefault])

  const handleSuccess = useCallback(
    (url: string, _?: any) => {
      setFile({ url, loading: false })
      onSuccess?.(url)
    },
    [onSuccess]
  )

  const handleRemove = (e: any) => {
    e.stopPropagation()
    setFile(initialValues)
    onRemove?.()
  }

  const handleError = (err: string) => {
    setFile(initialValues)
    console.log(err)
  }

  const handleUploadFile = useCallback((e: any) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }

    if (canUpload(file.size, maxSize, maxSizeLabel)) {
      setFile({ url: URL.createObjectURL(file), loading: true })
      setFileName(file.name)

      upload(file, prefix, handleSuccess, handleError)
    }

    e.target.value = null
  }, [])

  useEffect(() => {
    if (errorEmpty) touched.current = true
  }, [errorEmpty])

  const { url, loading } = file

  const errorText = !file.url && touched.current ? error : undefined

  const showFile = !!url && !loading

  const getFileName = () => {
    apiGet(
      apiUrls.upload(),
      {
        attached_files: [url],
      },
      ({ data, status }) => {
        if (status) {
          if (data.length) {
            setFileName(data[0].file_name)
          } else {
            setFileName(createFileName(url))
          }
        }

        setLoadingFilename(false)
      }
    )
  }

  useLayoutEffect(() => {
    if (isUpdate) {
      if (url?.length == 0) {
        setLoadingFilename(false)
      } else if (!url?.includes('blob')) {
        getFileName()
      }
    } else {
      setLoadingFilename(false)
    }
  }, [url?.length, isUpdate])

  return (
    <div style={style}>
      {loadingFilename ? (
        <CircularProgress size={16} color="inherit" style={{ marginLeft: 8 }} />
      ) : (
        <div>
          {showFile ? (
            <Chip
              style={{
                height: 36,
                fontWeight: 500,
                maxWidth: '100%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
              label={renderFileName(fileName, maxLength)}
              onDelete={disabled ? undefined : handleRemove}
              onClick={onClick}
            />
          ) : (
            <Button
              onClick={() => inputRef.current?.click()}
              variant="contained"
              disabled={file.loading}
              size="small"
              style={{ width: 120 }}
            >
              {label || 'Thêm file'}
              {loading && <CircularProgress size={16} color="inherit" style={{ marginLeft: 8 }} />}
            </Button>
          )}

          <input
            ref={inputRef}
            type="file"
            accept={accept || '.xlsx, .xls, .doc, .pdf, .zip, .png, .jpg'}
            style={{ display: 'none' }}
            onChange={handleUploadFile}
          />
        </div>
      )}

      {!!errorText && (
        <FormHelperText error style={{ margin: '8px 0 10px' }}>
          {errorText}
        </FormHelperText>
      )}
    </div>
  )
}

export default UploadImage
