/* eslint-disable */
import MaxLine from 'components/maxLine/MaxLine'
import React from 'react'
import './index.scss'

interface Props {
  author: string
  author_info: string
  avatar_url: string
  content: string
}

const quotes = (
  <div className="quotes">
    <div>
      <i className="icon-quote" />
      <i className="icon-quote" />
    </div>
  </div>
)

const AboutUsItem: React.FC<Props> = ({ author, author_info, avatar_url, content }) => {
  return (
    <div className="AboutUsItem">
      <img className="AboutUsItem-avatar" alt="" src={avatar_url} />
      <div className="AboutUsItem-body">
        <MaxLine
          className="AboutUsItem-body__author text-center typo-24-6"
          text={author}
          numberOfLines={1}
        />
        {!!author_info && (
          <MaxLine
            className="AboutUsItem-body__authorInfo text-center typo-14-5"
            text={author_info}
            numberOfLines={1}
          />
        )}
        <div className="AboutUsItem-body__contentWrapper">
          <div className="AboutUsItem-body__content typo-16-5">
            {quotes}
            <span>{content}</span>
            {quotes}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUsItem
