/* eslint-disable */
import { Modal, ModalContentScroll } from 'components'
import { Button } from 'components/admin'
import moment from 'moment'
import React from 'react'
import { useModalConfirm } from 'store/modalConfirm'

interface Props {
  show: boolean
  onClose: any
  title: string
  data?: { content: string; id: string; created_user: any; created_date: any }[]
  deleteComment?: (id: string) => void
}

const ModalComment: React.FC<Props> = ({ show, onClose, title, data, deleteComment }) => {
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()

  return (
    <Modal title={title} show={show} size="sm" close={onClose}>
      <ModalContentScroll style={{ paddingTop: 12 }}>
        {data?.map(({ created_user, content, id, created_date }, index) => (
          <div key={id} className="mb-3">
            <div className="mb-1 d-f jc-sb ai-c" style={{ color: 'var(--cl-primary)' }}>
              <div className="d-f ai-c">
                <b>
                  {index + 1}. {created_user ? created_user.full_name : 'Người dùng ẩn danh'}
                </b>
                <p className="ml-2" style={{ fontSize: 14, fontWeight: 700, color: '#666' }}>
                  {moment(created_date * 1000).format('DD/MM/YYYY HH:mm')}
                </p>
              </div>

              <Button
                className="btn-remove"
                onClick={() =>
                  showModalConfirm({
                    title: 'Xóa bình luận',
                    content: 'Bạn có chắc muốn xóa bình luận này?',
                    confirm: {
                      action: () => {
                        deleteComment?.(id)
                        hideModalConfirm()
                      },
                      text: 'Xóa',
                    },
                    cancel: { action: hideModalConfirm, text: 'Hủy bỏ' },
                    error: true,
                  })
                }
              >
                <i className="fas fa-trash"></i>
              </Button>
            </div>
            <div className="b-w" style={{ marginLeft: 18 }}>
              {content}
            </div>
          </div>
        ))}
      </ModalContentScroll>
    </Modal>
  )
}

export default ModalComment
