import React from 'react'
import { ResearchActivityType } from 'types'
// import ScientificPapers from '../scientificPapers/ScientificPapers'
// import banner from 'assets/images/common/scientific_research_activities.jpg'
import { formatDate } from 'utils'
import Scrollbar from 'components/scrollbar/Scrollbar'
import { useIsMobile } from 'store/mobile'
import ScientificPaperItem from '../scientificPaperItem/ScientificPaperItem'
import AttachedFile from '../attachedFile/AttachedFile'
import Title from '../title/Title'
import { Container } from '@mui/material'

interface Props {
  research_activities?: ResearchActivityType[]
}

const UserDetailResearchActivities: React.FC<Props> = ({ research_activities }) => {
  const isMobile = useIsMobile()

  if (!research_activities?.length) return null
  return (
    <Container>
      <div className="MyScientificPapersComponent">
        <Title>Hoạt động nghiên cứu khoa học</Title>
        {/* <div
          className="banner d-f ai-c jc-c typo-40-7 ta-c"
          style={banner ? { backgroundImage: `url("${banner}")` } : undefined}
        >
          Hoạt động nghiên cứu khoa học
        </div> */}
        <Scrollbar style={{ maxHeight: isMobile ? undefined : 640 }}>
          {research_activities?.map((item) => {
            const { id = '', time, title, description, attached_files } = item

            const params = { date: formatDate(time), title, description }

            return (
              <div key={id} className="MyScientificPapersComponent-item body">
                <div className="MyScientificPapersComponent-row">
                  <div className="col-left">
                    <ScientificPaperItem {...params} />
                  </div>
                </div>
                <AttachedFile
                  attached_files={attached_files}
                  style={{ marginRight: isMobile ? 30 : 54 }}
                />
              </div>
            )
          })}
        </Scrollbar>
      </div>
    </Container>
  )
}

export default UserDetailResearchActivities
