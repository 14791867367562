import React from 'react'
import { LinkProps } from 'react-router-dom'
import LinkBase from '../../links/Link'

interface Props extends LinkProps {
  color?: 'neutral-1' | 'neutral-2' | 'neutral-3' | 'semantic-1' | 'system-link'
  hoverSemantic?: boolean
  hoverAccent?: boolean
  semantic?: boolean
  hoverUnderline?: boolean
}

const Link: React.FC<Props> = ({
  className = '',
  color = 'neutral-1',
  hoverSemantic,
  hoverAccent,
  semantic,
  hoverUnderline,
  ...rest
}: any) => {
  return (
    <LinkBase
      className={`${className} ${color}${semantic ? ' semantic-1 hover-semantic' : ''}${
        hoverSemantic ? ' hover-semantic' : hoverAccent ? ' hover-accent' : ''
      }${hoverUnderline ? ' hover-underline' : ''}`}
      {...rest}
    />
  )
}

export default Link
