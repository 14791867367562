import { matchPath } from 'react-router-dom'
import { SubMenuType } from 'types'

export const isActive = (to: string, subs?: SubMenuType[]) => {
  // Active khi có ít nhất một sub location giống với location hiện tại của web (window.locaiton)
  const pathname = window.location.pathname
  if (!!to && subs) {
    return (
      matchPath(pathname, { path: to, exact: false }) ||
      subs.some((item) => item.to && matchPath(pathname, { path: item.to, exact: false }))
    )
  }
  const active = subs
    ? subs.some((item) => item.to && matchPath(pathname, { path: item.to, exact: false }))
    : matchPath(pathname, { path: to, exact: false })

  return active
}
