import React from 'react'

interface Props {
  data: any[]
  getFieldFunc?: (item: any) => string
  isEllipsis?: boolean
}

const List: React.FC<Props> = ({ data, getFieldFunc, isEllipsis }) => {
  let render: any = '-'
  data = data || []

  if (getFieldFunc) data = data.map(getFieldFunc)

  if (data.length) {
    let newData = data
    let needEllipsis = false
    if (isEllipsis && data.length > 3) {
      newData = [...newData.slice(0, 3), '...']
      needEllipsis = true
    }
    const strData = newData.join(', ')
    const titleData = needEllipsis ? data.join(', ') : undefined

    render = <div title={titleData}>{strData}</div>
  }

  return render
}

export default List
