/* eslint-disable */
import Modal from '../../modal/Modal'
import React, { useEffect, useRef, useState } from 'react'
import { useModalAuth } from 'store/modalAuth'
import { MODAL_AUTH_ROUTES } from 'configs/constants'
import Input from '../input/Input'
import Button from '../button/Button'
import { useTranslation } from 'react-i18next'
import { randomId, validatePassword, validateTypingEmail } from 'utils'
import * as Yup from 'yup'
import { confirmPasswordSchema, emailSchema, passwordSchema } from 'configs/schemas'
import { Form, FormikProvider, useFormik } from 'formik'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { useAuth } from 'store/auth'
import { useModalConfirm } from 'store/modalConfirm'
import { useHistory } from 'react-router-dom'

const { SIGN_IN, FORGOT_PASSWORD, RESET_PASSWORD, CHANGE_PASSWORD } = MODAL_AUTH_ROUTES

const mappingTitle = {
  [SIGN_IN]: 'Đăng nhập',
  [FORGOT_PASSWORD]: 'Quên mật khẩu',
  [RESET_PASSWORD]: 'Thiết lập mật khẩu',
  [CHANGE_PASSWORD]: 'Đổi mật khẩu',
}

const mappingButtonText = { ...mappingTitle }
mappingButtonText[RESET_PASSWORD] = 'Đặt mật khẩu'
mappingButtonText[CHANGE_PASSWORD] = 'Lưu'

const mappingApis = {
  [SIGN_IN]: apiUrls.signIn(),
  [FORGOT_PASSWORD]: apiUrls.forgotPassword(),
  [RESET_PASSWORD]: apiUrls.resetPassword(),
}

const ModalAuth: React.FC = () => {
  const { t } = useTranslation()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()

  const { apiPost, apiPut } = useApis()
  const { updateAuth, signOut } = useAuth()
  const history = useHistory()

  const { show, route, hideModalAuth, showModalForgotPassword, showModalSignIn } = useModalAuth()

  const clearDataRef = useRef(route !== SIGN_IN)
  const clearData = clearDataRef.current

  const emailName = useRef(`email${clearData ? randomId() : ''}`)

  const [wrong, setWrong] = useState({ password: '', text: '' })
  const [duplicate, setDuplicate] = useState({ password: '', text: '' })

  let shape: any = {
    [emailName.current]: emailSchema,
    password: passwordSchema,
  }

  if (route === RESET_PASSWORD) {
    shape[emailName.current] = undefined
    shape.confirmPassword = confirmPasswordSchema
  } else if (route === FORGOT_PASSWORD) shape.password = undefined
  else if (route === CHANGE_PASSWORD) {
    shape = {
      currentPassword: Yup.string()
        .required('current_password_is_required')
        .notOneOf([wrong.password], wrong.text)
        .min(8, 'password_format_is_incorrect')
        .matches(validatePassword(), 'password_format_is_incorrect'),
      password: Yup.string()
        .required('new_password_is_required')
        .notOneOf([duplicate.password], duplicate.text)
        .min(8, 'password_format_is_incorrect')
        .matches(validatePassword(), 'password_format_is_incorrect'),
      confirmPassword: Yup.string()
        .required('confirm_password_is_required')
        .oneOf([Yup.ref('password'), null], 'confirm_password_not_match'),
    }
  }

  const validationSchema = Yup.object().shape(shape)

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [resetSuccessfully, setResetSuccessfully] = useState(false)

  const title = mappingTitle[route]

  const formik = useFormik({
    initialValues: {
      [emailName.current]: '',
      password: '',
      confirmPassword: '',
      currentPassword: '',
    },
    validationSchema,
    onSubmit: (values, { resetForm, validateForm }) => {
      if (error) return

      const email = values[emailName.current]
      const password = values.password
      setLoading(true)
      route === CHANGE_PASSWORD
        ? apiPut(
            apiUrls.changePassword(),
            {
              current_password: values.currentPassword,
              new_password: password,
            },
            ({ id, status, text }) => {
              setLoading(false)

              if (status) {
                showModalConfirm({ content: text, confirm: { action: hideModalConfirm } })
                hideModalAuth()
                signOut()
                history.push('/')
              } else if (id === '151') setWrong({ password: values.currentPassword, text })
              else if (id === '152') setDuplicate({ password, text })
              validateForm()
            }
          )
        : apiPost(
            mappingApis[route],
            {
              email: route === RESET_PASSWORD ? undefined : email,
              password: route === FORGOT_PASSWORD ? undefined : password,
              is_admin: false,
            },
            ({ id, status, data, text }) => {
              setLoading(false)

              switch (route) {
                case SIGN_IN:
                  if (status) {
                    updateAuth({ ...data, isAdmin: false })
                    hideModalAuth()
                  } else {
                    if (id !== '002') {
                      showModalConfirm({
                        content: text,
                        confirm: { action: hideModalConfirm },
                        close: hideModalConfirm,
                      })
                    } else setError(text)
                  }
                  break

                case FORGOT_PASSWORD:
                  if (status) {
                    const hide = () => {
                      hideModalConfirm()
                      hideModalAuth()
                    }

                    showModalConfirm({
                      title: t('forgot_password'),
                      content: 'Vui lòng kiểm tra email của bạn để thiết lập lại mật khẩu.',
                      confirm: {
                        action: hide,
                      },
                      close: hide,
                    })
                  } else {
                    if (id === '007' || id === '009') {
                      setError(text)
                    } else {
                      showModalConfirm({
                        content: text,
                        confirm: { action: hideModalConfirm },
                        close: hideModalConfirm,
                      })
                    }
                  }
                  break

                case RESET_PASSWORD:
                  if (status) {
                    setResetSuccessfully(true)
                    resetForm()
                  } else
                    showModalConfirm({
                      content: text,
                      confirm: { action: hideModalConfirm },
                      close: hideModalConfirm,
                    })
                  break
              }
            }
          )
    },
  })

  const { errors, handleSubmit, getFieldProps, handleChange, touched, dirty, resetForm } = formik

  useEffect(() => {
    if (show) {
      if (route !== SIGN_IN || resetSuccessfully) {
        emailName.current = randomId()
        clearDataRef.current = true

        if (route === CHANGE_PASSWORD) {
          setWrong({ password: '', text: '' })
          setDuplicate({ password: '', text: '' })
        }
      } else {
        clearDataRef.current = false
      }

      setError('')
      resetForm()
      setIsSubmitted(false)
      setResetSuccessfully(false)
    }
  }, [show, route])

  return (
    <Modal
      show={show}
      size="xs"
      close={resetSuccessfully ? undefined : hideModalAuth}
      title={resetSuccessfully ? 'Thông báo' : title}
      dirty={dirty}
    >
      {resetSuccessfully ? (
        <>
          <div className="typo-16-4 typo-16-14 neutral-2 mb-3 ta-c">
            Thiết lập mật khẩu thành công.
          </div>
          <Button fullWidth onClick={showModalSignIn}>
            Đăng nhập ngay
          </Button>
        </>
      ) : (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            {route === FORGOT_PASSWORD && (
              <div className="typo-16-4 typo-16-14 neutral-2 mb-3 ta-c">
                Vui lòng nhập email để nhận link thiết lập lại mật khẩu
              </div>
            )}
            {route === CHANGE_PASSWORD && (
              <Input
                className="mb-3 mt-20"
                autoComplete="new-password"
                type="password"
                placeholder={t('current_password')}
                {...getFieldProps('currentPassword')}
                error={t(errors.currentPassword?.toString() || '')}
                disabled={loading}
                errorEmpty={isSubmitted || touched.currentPassword}
              />
            )}
            {route !== RESET_PASSWORD && route !== CHANGE_PASSWORD && (
              <Input
                key={emailName.current}
                className={route === SIGN_IN ? 'mb-3 mb-3-2 mt-20' : 'mb-3'}
                autoComplete={clearData ? 'new-password' : 'email'}
                placeholder={t('email_address')}
                {...getFieldProps(emailName.current)}
                onChange={(e) => {
                  if (error) setError('')
                  if (e.target.value === '' || validateTypingEmail(e)) handleChange(e)
                }}
                error={
                  error && route === SIGN_IN
                    ? true
                    : t(errors[emailName.current]?.toString() || error || '')
                }
                disabled={loading}
                errorFocused={!!error}
                maxLength={50}
                errorEmpty={isSubmitted || touched[emailName.current]}
              />
            )}
            {route !== FORGOT_PASSWORD && (
              <>
                <Input
                  className={route === RESET_PASSWORD ? 'mb-3 mb-3-2 mt-20' : 'mb-3'}
                  autoComplete={clearData ? 'new-password' : 'password'}
                  type="password"
                  placeholder={t(route === SIGN_IN ? 'password' : 'new_password')}
                  {...getFieldProps('password')}
                  onChange={(e) => {
                    if (error) setError('')
                    handleChange(e)
                  }}
                  error={error || t(errors.password?.toString() || '')}
                  disabled={loading}
                  errorFocused={!!error}
                  errorEmpty={isSubmitted || touched.password}
                />
                {(route === RESET_PASSWORD || route === CHANGE_PASSWORD) && (
                  <Input
                    className="mb-3"
                    autoComplete="new-password"
                    type="password"
                    placeholder={t('confirm_password')}
                    {...getFieldProps('confirmPassword')}
                    onChange={(e) => {
                      if (error) setError('')
                      handleChange(e)
                    }}
                    error={error || t(errors.confirmPassword?.toString() || '')}
                    disabled={loading}
                    errorFocused={!!error}
                    errorEmpty={isSubmitted || touched.confirmPassword}
                  />
                )}
              </>
            )}
            <Button fullWidth loading={loading} type="submit" onClick={() => setIsSubmitted(true)}>
              {mappingButtonText[route]}
            </Button>
            {route === SIGN_IN && (
              <div
                className="a system-link underline ta-c mt-3 typo-16-5"
                onClick={showModalForgotPassword}
              >
                Quên mật khẩu
              </div>
            )}
          </Form>
        </FormikProvider>
      )}
    </Modal>
  )
}

export default ModalAuth
