import { validateEmail, validatePassword } from 'utils'
import * as Yup from 'yup'

export const emailSchema = Yup.string()
  .trim()
  .required('email_format_is_incorrect')
  .matches(validateEmail(), 'email_format_is_incorrect')

export const passwordSchema = Yup.string()
  .trim()
  .required('password_is_required')
  .min(8, 'password_format_is_incorrect')
  .matches(validatePassword(), 'password_format_is_incorrect')

export const newPasswordSchema = Yup.string()
  .trim()
  .required('new_password_is_required')
  .min(8, 'password_format_is_incorrect')
  .matches(validatePassword(), 'password_format_is_incorrect')

export const confirmPasswordSchema = Yup.string()
  .trim()
  .required('confirm_password_is_required')
  .oneOf([Yup.ref('password'), null], 'confirm_password_not_match')
