import React, { useEffect } from 'react'
import { useHistory } from 'store/confirmRedirect'
import { useBreadcrumb } from 'store/breadcrumb'

interface Props {
  title?: string
  className?: string
  clearStateOnRefresh?: boolean
  isHideBreadcrumb?: boolean
}

const Page: React.FC<Props> = ({
  title = '',
  children,
  className = '',
  clearStateOnRefresh,
  isHideBreadcrumb,
}) => {
  const history = useHistory()
  const { hideBreadcrumb } = useBreadcrumb()

  useEffect(() => {
    document.title = `Viện CNTT&TT - HVKTQS${title ? ` - ${title}` : ''}`

    if (clearStateOnRefresh) {
      const push = () => history.push({ state: undefined })
      window.addEventListener('beforeunload', push)
      return () => {
        window.removeEventListener('beforeunload', push)
      }
    }

    if (isHideBreadcrumb) {
      hideBreadcrumb()
    }
  }, [hideBreadcrumb])

  return <div className={className}>{children}</div>
}

export default Page
