/* eslint-disable */
import { mappingNewsColor, mappingNewsTypeUser as mappingNewsType, ROUTES } from 'configs/constants'
import React from 'react'
import { useIsMobile } from 'store/mobile'
import { formatDate, YouTubeGetID } from 'utils'
import Link from '../links/Link'
import './index.scss'

interface Props {
  id?: string
  type: any
  start_date?: any
  title: string
  content_to_summary?: string
  image_url: string
  blockType: number
  isPartner?: boolean
  goToPartner?: boolean
  is_hot_news?: boolean
  link_video?: string
  isFirstItem?: boolean
}

const NewsItem: React.FC<Props> = ({
  id,
  type,
  start_date,
  title,
  content_to_summary,
  image_url,
  blockType,
  isPartner: isPartnerOld,
  goToPartner,
  is_hot_news,
  link_video,
  isFirstItem,
}) => {
  const youtubeId = YouTubeGetID(link_video || '')

  const shouldShowVideo = is_hot_news && isFirstItem && !!youtubeId

  const isMobile = useIsMobile()

  // Nhãn loại của tin tức (Tin nổi bật, Tin hoạt động, Tin khoa học)
  const newsTypeLabel = (
    <div className="d-f">
      <div style={mappingNewsColor[type]} className="newsTypeLabel">
        {mappingNewsType[type]}
      </div>
    </div>
  )

  // 1, 4 là text nằm ngang bên phải ảnh banner, còn lại là nằm dưới
  const horizontal = blockType === 1 || blockType === 4
  // 2, 4, 6 content dài 3 line, còn lại là 2 line

  const maxLength3 =
    !isMobile && (blockType === 1 || blockType === 2 || blockType === 4 || blockType === 6)

  const date = (
    <div className="d-f ai-c date-wrapper">
      {horizontal && newsTypeLabel}
      <div className="date d-f ai-c">
        <i className="icon-calendar1 neutral-icon" />
        {formatDate(start_date)}
      </div>
    </div>
  )

  // Bằng 5 overlay tối từ dưới lên, 4, 6 ko có overlay, còn lại là overlay từ trái sang
  const backgroundImage =
    blockType === 5
      ? // eslint-disable-next-line
        `linear-gradient(180deg, rgba(26, 32, 44, 0) 0%, rgba(26, 32, 44, 0.3) 24.48%, rgba(26, 32, 44, 0.7) 100%), url("${image_url}")`
      : blockType !== 4 && blockType !== 6
      ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("${image_url}")`
      : `url("${image_url}")`

  const isPartner = blockType === 4 && isPartnerOld

  return (
    <Link
      to={`/${goToPartner ? ROUTES.ROUTE_PARTNER_ACTIVITIES : ROUTES.ROUTE_NEWS}/${id}`}
      className={`NewsItem${horizontal ? ' d-f' : ''} blockType-${blockType}${
        isPartner ? ' isPartner' : ''
      }`}
    >
      {shouldShowVideo ? (
        <div className="video">
          <iframe
            width="100%"
            height="408"
            src={`https://www.youtube.com/embed/${youtubeId}`}
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            className="body"
            style={{
              marginTop: '0',
              width: '100%',
            }}
            frameBorder="0"
          ></iframe>
          {!horizontal && newsTypeLabel}
        </div>
      ) : (
        <div className="banner background-image" style={{ backgroundImage }}>
          {!horizontal && newsTypeLabel}
          {blockType === 5 && (
            <div className="body">
              <p className={`title typo-20-6 max-line${isMobile ? '' : '-1'}`}>{title}</p>
              <p className="content max-line">{content_to_summary}</p>
            </div>
          )}
        </div>
      )}
      {blockType !== 5 && (
        <div className="body">
          {blockType !== 6 && date}
          <p
            className={`title ${blockType < 4 ? 'typo-32-6' : 'typo-20-6'} max-line${
              isPartner ? (isMobile ? '' : '-1') : maxLength3 ? '-3' : ''
            }`}
          >
            {title}
          </p>
          <p
            className={`content ${blockType < 4 ? 'typo-16-5' : 'typo-14-5'}  max-line${
              blockType === 4 && isMobile ? '-1' : isPartner ? '-3' : ''
            }`}
          >
            {content_to_summary}
          </p>
          {blockType === 6 && date}
        </div>
      )}
    </Link>
  )
}

export default NewsItem
