import { Chip, Typography } from '@mui/material'
import React from 'react'

const SearchKeyword: React.FC<{ keyword: string }> = ({ keyword }) => {
  const lisKeyword = keyword.split(',')

  if (!keyword) return null
  return (
    <div className="d-f" style={{ boxSizing: 'border-box' }}>
      <Typography
        className="typo-16-4"
        sx={{
          // fontSize: '16px',
          fontWeight: '500',
          // lineHeight: '24px',
          marginRight: '32px',
          minWidth: '70px',
        }}
      >
        Từ khóa
      </Typography>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          maxWidth: 'calc(100% - 102px)',
          marginTop: '-5px',
        }}
      >
        {lisKeyword.map((keyword, index) => (
          <Chip
            key={index}
            label={keyword}
            className="typo-16-4"
            sx={{
              backgroundColor: '#fff',
              color: '#242056',
              border: '1px solid #242056',
              borderRadius: '4px',
              marginRight: '20px',
              marginBottom: '10px',
              // fontSize: '16px',
              fontWeight: '500',
              // lineHeight: '24px',
              maxWidth: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default SearchKeyword
