import { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { HomeReducer, RootState } from 'types'

import { getHomePageApi } from './actions'

export default function useHome() {
  const dispatch = useDispatch()
  const homeReducer: HomeReducer = useSelector((state: RootState) => state.home, shallowEqual)

  return useMemo(
    () => ({
      ...homeReducer,
      getHomePageApi: () => dispatch(getHomePageApi()),
    }),
    [dispatch, homeReducer]
  )
}
