/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'ckeditor5-custom-build/ckeditor'
import ApiRequest from 'services/api'
import './index.scss'
import { FormHelperText } from '@mui/material'
import { apiUrls } from 'configs/apis'
import EditorViewer from '../../editorViewer/EditorViewer'

class UploadAdapter {
  loader: any

  constructor(loader: any) {
    this.loader = loader
  }

  upload() {
    return new Promise((resolve, reject) => {
      const loader = this.loader
      loader.file.then((file: any) => {
        ApiRequest.post(
          apiUrls.upload(),
          { upload: { prefix: 'article', file } },
          ({ status, data }) => {
            if (status) {
              resolve({
                default: data.file_url,
              })
            }
          }
        )
      })
    })
  }
}

const uploadAdapterPlugin = (editor: any) => {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return new UploadAdapter(loader)
  }
}

type Cell = {
  width: number
  node: any
}

const OFFSET = 8

const table: { leftCell: Cell | null; rightCell: Cell | null } = {
  leftCell: null,
  rightCell: null,
}

let x: any,
  newX: number = 0
let dragging: boolean = false
let td: any
let pillar: any

interface Props {
  value?: string
  onChange?: (value: string) => void
  minLength?: number
  maxLength?: number
  small?: boolean
  errorEmpty?: boolean
  view?: boolean
  disableMinLength?: boolean
  hideColor?: boolean
  itemShow?: string[]
  noCheckOnchange?: boolean
}

const Editor: React.FC<Props> = ({
  value,
  onChange,
  minLength,
  maxLength = 100000,
  small,
  errorEmpty,
  view,
  disableMinLength,
  hideColor,
  itemShow,
  noCheckOnchange,
}) => {
  const touched = useRef(false)
  const first = useRef(true)

  const [show, setShow] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [focus, setFocus] = useState(false)
  const [error, setError] = useState({ show: false, content: '' })

  const showError = (show: boolean, isMax?: boolean) =>
    setError({
      show,
      content: isMax ? 'Quá giới hạn kí tự cho phép.' : 'Nội dung không được để trống.',
    })

  const check = (value: any) => {
    const length = value.length
    const isMax = maxLength && length > maxLength
    const isMin = minLength && length < minLength

    if (isMax || isMin) {
      if (!error?.show) showError(true, !!isMax)
    } else {
      if (error?.show) showError(false)
    }
  }

  useEffect(() => {
    if (errorEmpty) touched.current = true
  }, [errorEmpty])

  const isShowError = !focus && (!!error?.show || (!value && !disableMinLength && touched.current))

  useEffect(() => {
    setTimeout(() => setShow(true), 10)
  }, [])

  if (view) return <EditorViewer content={value} />

  return (
    <div className={`AdminEditor${isShowError ? ' error' : ''}${small ? ' small' : ''}`}>
      {!loaded && (
        <div
          className="w-100 d-f ai-c jc-c"
          style={{ height: '42vh', color: 'var(--cl-primary)', fontSize: 32 }}
        >
          <i className="fa-spin icon-loader" />
        </div>
      )}
      {show && (
        <div style={{ display: loaded ? 'block' : 'none' }}>
          <CKEditor
            config={
              hideColor
                ? {
                    toolbar: {
                      items: [
                        'heading',
                        'bold',
                        'italic',
                        // 'fontColor',
                        // 'fontBackgroundColor',
                        'alignment',
                        'link',
                        'imageUpload',
                        'bulletedList',
                        'numberedList',
                        'blockQuote',
                        'insertTable',
                        'mediaEmbed',
                        'undo',
                        'redo',
                      ],
                    },
                  }
                : itemShow && {
                    toolbar: {
                      items: itemShow,
                    },
                  }
            }
            onReady={(editor: any) => {
              setLoaded(true)
              editor.editing.view.document.on('mousemove', (evt: any, data: any) => {
                newX = data.domEvent.pageX
                const target = data.target

                if (dragging) {
                  if (pillar) pillar.style.left = `${newX + 1}px`
                  return
                }

                if (target.name !== 'td' && target.name !== 'th') {
                  if (td) {
                    document.body.style.cursor = 'default'
                    document.body.onmousedown = null
                    document.body.onmouseup = null
                    td = null
                  }
                } else {
                  td = data.domTarget

                  // const domConverter = editor.editing.view.domConverter
                  // const mapper = editor.editing.mapper

                  // console.log(td, target)
                  // console.log(mapper.toModelElement(target))
                  // console.log(domConverter.domToView(td))
                  // console.log(domConverter.viewToDom(target))
                  // console.log(mapper.toViewElement(mapper.toModelElement(target)))

                  const { left, right } = td.getBoundingClientRect()
                  const offsetLeft = newX - left
                  const offsetRight = right - newX

                  if (
                    (offsetLeft > offsetRight && offsetRight < OFFSET) ||
                    (offsetLeft <= offsetRight && offsetLeft < OFFSET)
                  ) {
                    document.body.style.cursor = 'col-resize'

                    document.body.onmousedown = () => {
                      x = newX
                      const { top, height } = td.parentElement.parentElement.getBoundingClientRect()

                      const root = editor.model.document.getRoot()
                      pillar = document.createElement('div')
                      pillar.style.left = `${x + 1}px`
                      pillar.style.top = `${top}px`
                      pillar.style.width = '3px'
                      pillar.style.height = `${height}px`
                      pillar.style.position = 'fixed'
                      pillar.style.backgroundColor = '#75757540'
                      pillar.style.userSelect = 'none'
                      document.body.appendChild(pillar)

                      if (offsetLeft > offsetRight) {
                        if (offsetRight > OFFSET) return
                        const path = editor.editing.mapper.toModelPosition(target).path

                        path[path.length - 1] = target.index
                        path[path.length - 2] = 0

                        table.leftCell = {
                          width: td.offsetWidth,
                          node: root.getNodeByPath(path),
                        }
                        td = td.nextSibling
                        if (td) {
                          path[path.length - 1] += 1
                          table.rightCell = {
                            width: td.offsetWidth,
                            node: root.getNodeByPath(path),
                          }
                        } else table.rightCell = null
                      } else {
                        if (offsetLeft > OFFSET) return
                        const path = editor.editing.mapper.toModelPosition(target).path
                        path[path.length - 1] = target.index
                        path[path.length - 2] = 0

                        table.rightCell = {
                          width: td.offsetWidth,
                          node: root.getNodeByPath(path),
                        }
                        td = td.prevSibling
                        if (td) {
                          path[path.length - 1] -= 1
                          table.leftCell = {
                            width: td.offsetWidth,
                            node: root.getNodeByPath(path),
                          }
                        } else table.leftCell = null
                      }

                      dragging = true
                    }

                    document.body.onmouseup = () => {
                      if (pillar) {
                        document.body.removeChild(pillar)
                        pillar = null
                      }
                      const model = editor.model
                      model.change((writer: any) => {
                        if (table.leftCell)
                          writer.setAttribute(
                            'tableCellWidth',
                            `${table.leftCell.width + newX - x}px`,
                            table.leftCell.node
                          )
                        if (table.rightCell)
                          writer.setAttribute(
                            'tableCellWidth',
                            `${table.rightCell.width - newX + x}px`,
                            table.rightCell.node
                          )
                      })

                      dragging = false
                    }
                  }
                }
              })

              // editor.ui.view.editable.element.style.height = '200px';
              uploadAdapterPlugin(editor)
            }}
            data={value || ''}
            editor={ClassicEditor}
            onBlur={(_: any, editor: any) => {
              touched.current = true
              setFocus(false)
              const data = editor.getData()
              check(data)
              onChange?.(data)
            }}
            onChange={
              noCheckOnchange
                ? undefined
                : (_: any, editor: any) => {
                    if (first.current) {
                      first.current = false
                      return
                    }
                    if (!editor.editing.view.document.isFocused) editor.editing.view.focus()
                    const data = editor.getData()

                    !noCheckOnchange && check(data)
                  }
            }
            onFocus={() => setFocus(true)}
          />
        </div>
      )}
      {isShowError && (
        <FormHelperText error style={{ marginTop: 8, marginRight: 0 }}>
          {!value ? 'Nội dung không được để trống.' : error?.content}
        </FormHelperText>
      )}
    </div>
  )
}

export default Editor
