"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.remove = exports.register = exports.list = exports.has = exports.format = exports.add = void 0;

var _register = _interopRequireDefault(require("../util/register.js"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

const validate = (name, formatter) => {
  if (typeof name !== 'string') {
    throw new TypeError(`Invalid output format name, expected string, got ${typeof name}`);
  } else if (typeof formatter !== 'function') {
    throw new TypeError(`Invalid formatter, expected function, got ${typeof formatter}`);
  }
};

const register = new _register.default();
exports.register = register;

const add = (name, formatter) => {
  validate(name, formatter);
  register.set(name, formatter);
};

exports.add = add;

const remove = name => {
  register.remove(name);
};

exports.remove = remove;

const has = name => {
  return register.has(name);
};

exports.has = has;

const list = () => {
  return register.list();
};

exports.list = list;

const format = (name, data, ...options) => {
  if (!register.has(name)) {
    throw new Error(`Output format "${name}" unavailable`);
  }

  return register.get(name)(data, ...options);
};

exports.format = format;