import { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MenuItem, RootState } from 'types'

import { updateBreadcrumb } from './actions'

export default function useBreadcrumb() {
  const dispatch = useDispatch()
  const breadcrumbReducer: { breadcrumb: MenuItem[]; banner: any; noMargin: boolean } = useSelector(
    (state: RootState) => state.breadcrumb,
    shallowEqual
  )

  return useMemo(
    () => ({
      ...breadcrumbReducer,
      showBreadcrumb: (breadcrumb: MenuItem[], banner?: any, noMargin?: boolean) =>
        dispatch(updateBreadcrumb(breadcrumb, banner, noMargin)),
      hideBreadcrumb: () => dispatch(updateBreadcrumb()),
    }),
    [dispatch, breadcrumbReducer]
  )
}
