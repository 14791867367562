import React from 'react'
import { ScientificPaperType } from 'types'
import ScientificPapers from '../scientificPapers/ScientificPapers'

interface Props {
  scientific_papers?: ScientificPaperType[]
  isFull?: boolean
}

const UserDetailScientificPapers: React.FC<Props> = ({ scientific_papers, isFull = false }) => {
  return <ScientificPapers isFull={isFull} scientificPapers={scientific_papers} />
}

export default UserDetailScientificPapers
