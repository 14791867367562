/* eslint-disable */
import React from 'react'
import noItems from 'assets/images/no_items.png'
import noItems404 from 'assets/images/404.png'
import './index.scss'
import Button from '../button/Button'
import { useHistory } from 'store/confirmRedirect'

interface Props {
  filtered?: boolean
  title?: string
  content?: string
  contentFiltered?: string
  hideBanner?: boolean
  button?: {
    text: string
    onClick: any
  }
  is404?: boolean
  style?: any
}

const NoItems: React.FC<Props> = ({
  filtered,
  title,
  content,
  contentFiltered,
  hideBanner,
  button,
  is404,
  style,
}) => {
  const history = useHistory()

  return (
    <div className="NoItems d-f fd-c ai-c" style={style}>
      {!hideBanner && (
        <div
          className="img background-image cover"
          style={{ backgroundImage: `url("${is404 ? noItems404 : noItems}")` }}
        />
      )}
      <div className="title ta-c">{title}</div>
      {!!(content || contentFiltered) && (
        <div className="content ta-c">{filtered ? contentFiltered : content}</div>
      )}
      {(!filtered || button) && (
        <Button onClick={button?.onClick || (() => history.push('/'))}>
          {button?.text || 'Quay về trang chủ'}
        </Button>
      )}
    </div>
  )
}

export default NoItems
