/* eslint-disable */
import { Collapse } from '@mui/material'
import { NavLink } from 'components/links'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'store/confirmRedirect'
import { SubMenuType } from 'types'
import './index.scss'
import { isActive } from './utils'
import { createTrainingLink } from 'utils'
import { TRAINING_INFO_TYPES } from 'configs/constants'
const { ALL } = TRAINING_INFO_TYPES

const MenuItemCollapse: React.FC<SubMenuType> = ({ title, to, subs, level, onClose }) => {
  const history = useHistory()

  const [show, setShow] = useState(false)

  const active = isActive(to, subs)

  const Wrapper = useCallback(
    (props) => {
      if (level)
        return (
          <div
            {...props}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              if (props.to) {
                onClose?.()
                history.push(props.to)
              }
            }}
          />
        )

      return (
        <NavLink
          {...props}
          onClick={(e) => {
            if (!props.to) e.preventDefault()
            else onClose?.()
          }}
        />
      )
    },
    [level]
  )

  const hasSub = !!subs && !!subs.length

  return (
    <>
      <Wrapper
        to={to === createTrainingLink(ALL) ? '' : to || ''}
        className={`MenuItemCollapse d-b${
          level
            ? ` isSubMenu${level >= 2 ? ' ml-15' : ''}`
            : active
            ? ' MenuItemCollapse-active py-12'
            : ' py-12'
        }`}
      >
        <div
          className={`title d-f ai-c jc-sb${show && !level ? ' active' : ''}`}
          onClick={() => setShow(!show)}
        >
          {title}
          {hasSub && (
            <div
              className={`rotate-animation ml-1${show ? ' rotate-180' : ''}`}
              style={{ height: 24 }}
            >
              <i className="icon-chevron-down" />
            </div>
          )}
        </div>
      </Wrapper>
      {hasSub && (
        <Collapse in={show}>
          <ul className={`MenuItemCollapse-item${level && level >= 1 ? ' isSubMenu' : ''}`}>
            {subs?.map((item, index) => (
              <MenuItemCollapse key={index} {...item} level={(level || 0) + 1} onClose={onClose} />
            ))}
          </ul>
        </Collapse>
      )}
    </>
  )
}

export default MenuItemCollapse
