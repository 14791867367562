/* eslint-disable */
import { apiUrls } from 'configs/apis'
import React, { useEffect, useState } from 'react'
import { useApis } from 'services/api'
import { useIsMobile } from 'store/mobile'
import { NewsType } from 'types'
import NewsItem from '../newsItem/NewsItem'
import Title from '../title/Title'
import './index.scss'

interface Props {
  toDate?: number | null
}

const MoreNews: React.FC<Props> = ({ toDate }) => {
  const { apiGet } = useApis()
  const isMobile = useIsMobile()

  const [items, setItems] = useState<NewsType[]>([])

  useEffect(() => {
    if (toDate === null) return

    apiGet(apiUrls.news(), { page_size: 3, to_date: toDate }, ({ status, data }) => {
      if (status) setItems(data.items)
    })
  }, [toDate])

  if (items.length === 0) return null

  return (
    <div className="MoreNews">
      <Title>Tin tức &amp; Sự kiện</Title>
      <div className="MoreNews-row">
        {items.map((item: any) => (
          <div key={item.id} className="col">
            <NewsItem {...item} blockType={isMobile ? 2 : 6} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default MoreNews
