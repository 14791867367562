import Title from '../title/Title'
import React from 'react'
import { Container } from '@mui/material'
import { useHome } from 'store/home'
import ProductItem from '../productItem/ProductItem'
import { useIsMobile } from 'store/mobile'

const HomeProducts: React.FC = () => {
  const { products } = useHome()
  const isMobile = useIsMobile()

  if (!products.length) return null

  return (
    <div className="HomeProducts">
      <Container>
        <Title paddingSmall>Sản phẩm tiêu biểu</Title>
      </Container>
      <div className="HomeProducts-background">
        <Container
          className="HomeProducts-container"
          style={
            products.length > 3
              ? { marginBottom: isMobile ? 38 : 93, paddingBottom: isMobile ? 172 : 95 }
              : { marginBottom: 0 }
          }
        >
          <div className="HomeProducts-row">
            {products.slice(0, 3).map((item) => (
              <div key={item.id} className="col">
                <ProductItem {...item} />
              </div>
            ))}
            {products.length >= 4 && <ProductItem {...products[3]} fullWidth />}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default HomeProducts
