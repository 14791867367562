import { apiUrls } from 'configs/apis'
import { MAX_PAGE_SIZE, ROUTES } from 'configs/constants'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useApis } from 'services/api'
import { useAuth } from 'store/auth'
import { useHistory } from 'store/confirmRedirect'
import { addHistorySearch, fetchHistorySearch, getHistorySearch, removeHistorySearch } from 'utils'

const useHistorySearch = () => {
  const { apiGet, apiPost, apiDelete } = useApis()
  const { isAuth } = useAuth()
  const history = useHistory()

  const [historySearch, setHistorySearch] = useState(getHistorySearch())

  /**
   * Handle search
   *
   * @param keyword
   */
  const handleSearch = (keyword: string) => {
    setHistorySearch(addHistorySearch(keyword))

    // Đăng nhập rồi thì add từ khóa vào server
    isAuth && apiPost(apiUrls.history(), { keyword })

    // Use qs to handle special character
    const queryString = qs.stringify({
      keyword: keyword.trim(),
    })
    history.push(`/${ROUTES.ROUTE_SEARCH}?${queryString}`)
  }

  /**
   * Remove a keyword from list
   * @param e
   * @param keyword
   */
  const handleRemoveHistory = (e: React.MouseEvent<HTMLElement>, keyword: string) => {
    e.stopPropagation()
    setHistorySearch(removeHistorySearch(keyword))
    if (isAuth) {
      const queryString = qs.stringify({ keyword })
      apiDelete(`${apiUrls.history()}?${queryString}`)
    }
  }

  // Sync history search when signed in
  useEffect(() => {
    if (!isAuth) return

    apiGet(apiUrls.history(), { page_size: MAX_PAGE_SIZE }, ({ status, data }) => {
      if (status) {
        setHistorySearch(fetchHistorySearch(data.items))
      }
    })
  }, [isAuth])

  return { historySearch: historySearch.slice(0, 10), handleSearch, handleRemoveHistory }
}

export default useHistorySearch
