import MaxLine from '../../maxLine/MaxLine'
import React from 'react'
import { ScientificPaperItemType } from 'types'
import './index.scss'

import Link from '../links/Link'
import { useIsMobile } from 'store/mobile'

const ScientificPaperItem: React.FC<ScientificPaperItemType> = ({
  date,
  title,
  author,
  description,
  maxLineMobile = 2,
  cited_by,
  link,
}) => {
  const isMobile = useIsMobile()

  return (
    <div className="ScientificPaperItem d-f">
      <div className="left d-f fd-c ai-c">
        <div className="circle" />
        <div className="dash" />
      </div>
      <div className="right">
        <div className="date primary typo-14-6 mb-1">{date || '-'}</div>
        {link ? (
          <Link
            className="title typo-16-5 typo-16-14 mb-1"
            color="system-link"
            hoverUnderline
            to={{ pathname: link || '' }}
            target="_blank"
            style={{ marginBottom: 12, display: 'block' }}
          >
            <MaxLine text={title || ''} numberOfLines={isMobile ? maxLineMobile : 1} />
          </Link>
        ) : (
          <div className="title typo-16-5 typo-16-14 mb-1">
            <MaxLine text={title || ''} numberOfLines={isMobile ? maxLineMobile : 1} />
          </div>
        )}
        {!!author && <div className="author typo-14-5 neutral-2 max-line-1">{author}</div>}
        <MaxLine
          className="description typo-14-5 neutral-2"
          text={description}
          numberOfLines={isMobile ? maxLineMobile : 1}
        />
        {isMobile && cited_by && (
          <div className="date primary typo-14-6 mt-1 mb-1 ">{`Tổng trích dẫn: ${cited_by}`}</div>
        )}
      </div>
    </div>
  )
}

export default ScientificPaperItem
