import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Input from '../admin/input/Input'
import Button from '../admin/button/Button'
import { validatePassword } from 'utils'
import { useTranslation } from 'react-i18next'
import { formatContent } from './extensions'
import { useEffect } from 'react'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'

interface Props {
  show: boolean
  title: string
  content: string
  errorColor?: boolean
  btnConfirmText?: string
  onSuccess?: () => void
  onClose?: () => void
}

const ModalConfirmPassword: React.FC<Props> = ({
  show,
  content,
  title,
  onSuccess,
  onClose,
  errorColor = true,
  btnConfirmText,
}) => {
  const { apiPost } = useApis()
  const { t } = useTranslation()
  content = formatContent(content)

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [wrongPassword, setWrongPassword] = useState({ value: '', text: '' })
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    setPassword('')
    setTouched(false)
  }, [show])

  let invalid = t('password_is_required')
  if (password.trim())
    invalid = validatePassword().test(password) ? '' : t('password_format_is_incorrect')

  if (wrongPassword.value && wrongPassword.value === password) invalid = wrongPassword.text
  const error = password || touched ? invalid : ''

  const handleConfirm = () => {
    setTouched(true)
    if (invalid) return

    setLoading(true)
    apiPost(apiUrls.verifyPassword(), { current_password: password }, ({ id, text, status }) => {
      setLoading(false)
      if (status) {
        onClose?.()
        onSuccess?.()
      } else if (id === '030') {
        setWrongPassword({ value: password, text })
      }
    })
  }

  return (
    <Dialog className="Modal" open={show} fullWidth maxWidth="xs">
      <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>
      <DialogContent style={{ fontWeight: 500, textAlign: 'center' }}>
        {content}
        <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
          <input autoComplete="password" />
        </div>

        <Input
          value={password}
          autoComplete="new-password"
          type="password"
          label="Mật khẩu"
          onKeyDown={(e) => {
            if (e.key === 'Enter' && password && !invalid) handleConfirm()
          }}
          blurWhenEnter
          onChange={(e) => setPassword(e.target.value)}
          onBlur={() => setTouched(true)}
          error={error}
          style={{ marginTop: 24 }}
          disabled={loading}
          errorEmpty={touched}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading} variant={errorColor ? 'contained' : 'text'}>
          Hủy bỏ
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          loading={loading}
          color={errorColor ? 'error' : undefined}
        >
          {btnConfirmText || 'Xóa'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalConfirmPassword
