import React, { useCallback, useContext } from 'react'
import { ButtonProps } from '@mui/material'
import AdminButton from '../admin/button/Button'
import UserButton from '../user/button/Button'
import { ModalContext } from './constants'

interface Props extends ButtonProps {
  loading?: boolean
}

const ModalButtonCancel: React.FC<Props> = ({ children, onClick, ...rest }) => {
  const { dirty, setShowAttention } = useContext(ModalContext)

  const isAdmin = window.location.pathname.includes('/admin')

  const Button = useCallback(
    (props) =>
      isAdmin ? (
        <AdminButton className="AdminButton small" {...props} />
      ) : (
        <UserButton colorType="default secondary" {...props} />
      ),
    []
  )

  return (
    <Button
      {...rest}
      onClick={(e: any) => {
        if (dirty) setShowAttention(true)
        else onClick?.(e)
      }}
    >
      {children}
    </Button>
  )
}

export default ModalButtonCancel
