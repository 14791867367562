import React from 'react'
import { LinkProps } from 'react-router-dom'
import Link from './Link'

interface Props extends LinkProps {
  count: number
  enabled?: boolean
  zero?: string
  canClick?: boolean
}

const CountLink: React.FC<Props> = ({ count, enabled, zero, canClick, ...rest }) => {
  return (
    <b style={{ marginLeft: 6 }}>
      {canClick || (count && enabled !== false) ? (
        <Link {...rest}>{count}</Link>
      ) : (
        count || zero || 0
      )}
    </b>
  )
}

export default CountLink
