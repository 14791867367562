import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ModalConfirmType } from 'types'

import { showModalConfirm, hideModalConfirm } from './actions'

export default function useModalConfirm() {
  const history = useHistory()
  const dispatch = useDispatch()

  return useMemo(
    () => ({
      showModalConfirm: (payload: ModalConfirmType) => dispatch(showModalConfirm(payload)),
      hideModalConfirm: () => dispatch(hideModalConfirm()),
      showModalConfirmRedirect: (confirm: () => void) => {
        const close = () => dispatch(hideModalConfirm())
        const excute = () => {
          dispatch(hideModalConfirm())
          confirm()
        }
        dispatch(
          showModalConfirm({
            title: 'Thông báo',
            content: 'Nếu nhấn **Thoát**, mọi thông tin bạn đã nhập sẽ không được lưu lại.',
            confirm: {
              text: 'Tiếp tục',
              action: close,
            },
            cancel: {
              text: 'Thoát',
              action: excute,
            },
            onBackdropClick: close,
          })
        )
      },
      showModalNotFound: (msg: string) => {
        const newClose = () => {
          history.goBack()
          dispatch(hideModalConfirm())
        }
        dispatch(
          showModalConfirm({
            content: msg,
            confirm: {
              action: newClose,
            },
            close: newClose,
          })
        )
      },
    }),
    [dispatch]
  )
}
