import { EditorViewer, Loading } from 'components'
import { MoreNews, OfferCooperate } from 'components/user'
import { apiUrls } from 'configs/apis'
import { BREADCRUMBS, mappingNewsColor, mappingNewsType, NEWS_TYPES } from 'configs/constants'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useApis } from 'services/api'
import { useBreadcrumb } from 'store/breadcrumb'
import { useModalConfirm } from 'store/modalConfirm'
import { ItemType, NewsType } from 'types'
import { formatDate, toStr, useRefresh, YouTubeGetID } from 'utils'
import SearchKeyword from '../searchKeyword/SearchKeyword'
import { useIsMobile } from 'store/mobile'

const { COOPERATIVE_NEWS, COOPERATIVE_ACTIVITY } = NEWS_TYPES
const { BREADCRUMB_NEWS, BREADCRUMB_PARTNER_ACTIVITIES } = BREADCRUMBS

interface Props {
  isPartner?: boolean
}

const isCooperate = (type: any) => {
  const strType = toStr(type)
  return strType === COOPERATIVE_ACTIVITY || strType === COOPERATIVE_NEWS
}

const NewsDetailWrapper: React.FC<Props> = ({ isPartner }) => {
  const refresh = useRefresh()
  const { showBreadcrumb, hideBreadcrumb } = useBreadcrumb()
  const { showModalNotFound } = useModalConfirm()
  const { apiGet } = useApis()
  const { id } = useParams() as any
  const isMobile = useIsMobile()

  const [data, setData] = useState<ItemType<NewsType>>({ loading: true })

  const { item, loading, error } = data

  const breadcrumb = isPartner ? BREADCRUMB_PARTNER_ACTIVITIES : BREADCRUMB_NEWS

  useLayoutEffect(() => {
    if (error) hideBreadcrumb()
    else showBreadcrumb([breadcrumb])
  }, [error, isPartner])

  useEffect(() => {
    showBreadcrumb([
      breadcrumb,
      {
        name: data.item?.title || '',
        path: '/',
      },
    ])
  }, [data.item?.title, breadcrumb])

  useEffect(() => {
    apiGet(apiUrls.news(id), {}, ({ status, data, text }) => {
      if (status) setData({ loading: false, item: data })
      else {
        setData((prev) => ({ ...prev, loading: false, error: true }))
        showModalNotFound(text)
      }
    })
  }, [id, refresh, isPartner])

  const {
    title,
    type,
    start_date,
    content,
    created_user,
    partner_name,
    keyword,
    // is_hot_news,
    link_video,
  } = item || {}

  const youtubeId = YouTubeGetID(link_video || '')

  const shouldShowVideo = !!youtubeId

  if (loading) return <Loading />
  if (error) return <div className="block-error" />

  return (
    <div className="NewsDetailWrapper">
      <p className="large-title neutral-1">{title}</p>
      <div className="ScreenDetail-info d-f ai-c">
        <div style={mappingNewsColor[type]} className="newsTypeLabel">
          {mappingNewsType[type]}
        </div>
        <div className="d-f ai-c">
          <div className="label-icon-wrapper big">
            <i className="icon-calendar1" />
            {formatDate(start_date)}
          </div>
          <div className="label-icon-wrapper big">
            <i className="icon-edit-2" />
            {created_user?.full_name}
          </div>
        </div>
      </div>
      {shouldShowVideo && (
        <iframe
          width="100%"
          height={isMobile ? 400 : 800}
          src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&mute=1`}
          title="YouTube video player"
          allow="autoplay"
          allowFullScreen
          className="youtube_video_iframe"
          style={{ marginBottom: 25 }}
          frameBorder="0"
        ></iframe>
      )}
      {isCooperate(type) && (
        <div className="typo-16-6 neutral-1 mb-40 mb-40-24">
          <span className="fw-6">Đơn vị hợp tác:</span> {partner_name}
        </div>
      )}
      <div>
        <EditorViewer content={content} />
      </div>
      <SearchKeyword keyword={keyword || ''} />
      <OfferCooperate />
      <MoreNews toDate={isPartner ? undefined : start_date || null} />
    </div>
  )
}

export default NewsDetailWrapper
