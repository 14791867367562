/*eslint-disable*/
import { Button, Container } from '@mui/material'
import React from 'react'
import ScrollMenu from './ScrollMenu'
import './index.scss'

export const setting = {
  alignCenter: false,
  clickWhenDrag: false,
  dragging: true,
  hideArrows: true,
  hideSingleArrow: true,
  inertiaScrolling: false,
  inertiaScrollingSlowdown: 0.25,
  rtl: false,
  scrollBy: 0,
  scrollToSelected: true,
  transition: 0.5,
  useButtonRole: true,
  wheel: false,
  menuClass: 'scroll-menu-wrapper',
}

interface Props {
  value: any
  data: { value: any; label: string }[]
  onChange: (newValue: any) => void
  hideArrows?: boolean
}

const ScrollableTabs: React.FC<Props> = ({ data, value, onChange, hideArrows }) => {
  return (
    <div className="Tabs">
      <Container>
        <ScrollMenu
          {...setting}
          selected={value}
          wrapperStyle={{ margin: '0 auto' }}
          onSelect={(key) => onChange(key)}
          alignOnResize
          data={data.map(({ value, label }) => (
            <Button key={value} variant="text">
              {label}
            </Button>
          ))}
          arrowLeft={
            !hideArrows
              ? ({ firstItemVisible }) => (
                  <Button
                    key={value}
                    disabled={firstItemVisible}
                    variant="outlined"
                    className="ButtonArrow-menu left"
                  >
                    <span className="icon-chevron-right" />
                  </Button>
                )
              : null
          }
          arrowRight={
            !hideArrows
              ? ({ lastItemVisible }) => (
                  <Button
                    key={value}
                    disabled={lastItemVisible}
                    variant="outlined"
                    className={'ButtonArrow-menu right'}
                  >
                    <span className="icon-chevron-right" />
                  </Button>
                )
              : null
          }
        />
      </Container>
    </div>
  )
}

export default ScrollableTabs
