import { Container } from '@mui/material'
import React from 'react'
import Title from '../title/Title'
import { UserType } from 'types'
import UserItem from '../userItem/UserItem'

interface Props {
  head_of_departments?: UserType[]
  members: UserType[]
  isResearchGroups?: boolean
}

const DepartmentMembers: React.FC<Props> = ({ head_of_departments, isResearchGroups }) => {
  const data = [
    { items: head_of_departments, title: isResearchGroups ? 'Trưởng nhóm' : 'Ban chủ nhiệm' },
  ].filter((i) => !!i.items?.length)

  return (
    <div className="DepartmentMembers">
      {data.map(({ title, items }, index) => (
        <div key={index} className="DepartmentMembers-block">
          <Container>
            <Title paddingHalf={index === 0}>{title}</Title>
            <div className="row row-20">
              {items?.map((item) => (
                <div key={item.id} className="col-4">
                  <UserItem {...item} hideDepartment={!isResearchGroups} hideEmail />
                </div>
              ))}
            </div>
          </Container>
        </div>
      ))}
    </div>
  )
}

export default DepartmentMembers
