import React, { useEffect, useState } from 'react'
import { useAuth } from 'store/auth'
import Button from '../button/Button'
import Modal from '../../modal/Modal'
import * as Yup from 'yup'
import { Form, FormikProvider, useFormik } from 'formik'
import TopLabel from '../topLabel/TopLabel'
import Input from '../input/Input'
import { toStr, validateTypingName } from 'utils'
import Select from '../select/Select'
import { listAcademicRanks, listDegrees } from 'pages/admin/users/constants'
import { DialogActions } from '@mui/material'
import ModalButtonCancel from '../../modal/ModalButtonCancel'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'

const schema = (error: any) =>
  Yup.object().shape({
    full_name: Yup.string()
      .trim()
      .required('Họ và tên không được để trống.')
      .notOneOf([error.value], error.text),
  })

const UpdateInfo: React.FC = () => {
  const {
    avatar_url,
    full_name,
    degree,
    academic_rank,
    biography,
    updateAuth,
    // main_research_directions,
  } = useAuth()
  const { apiPut } = useApis()

  const [modal, setModal] = useState({
    show: false,
    initialValues: { full_name, degree, academic_rank, biography },
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ value: '', text: '' })

  const { show, initialValues } = modal

  const handleShowModal = () => {
    setModal({
      show: true,
      initialValues: {
        full_name,
        degree: toStr(degree),
        academic_rank: toStr(academic_rank),
        biography,
      },
    })
  }

  const handleCloseModal = () => setModal({ ...modal, show: false })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema(error),
    validateOnChange: false,
    onSubmit: ({ full_name, biography, academic_rank, degree }) => {
      full_name = (full_name || '').trim()
      academic_rank = academic_rank ? Number(academic_rank) : undefined
      degree = degree ? Number(degree) : undefined
      biography = (biography || '').trim()

      setLoading(true)

      apiPut(
        apiUrls.profile(),
        {
          full_name,
          biography,
          academic_rank,
          degree,
          // main_research_ids: main_research_directions,
          avatar_url: avatar_url || undefined,
          key: 2,
        },
        ({ status, id: resId, text }) => {
          setLoading(false)
          if (status) {
            updateAuth({ full_name, biography, academic_rank, degree })
            handleCloseModal()
          } else if (resId === '256') {
            setError({ value: full_name || '', text })
            validateForm()
          }
        }
      )
    },
  })

  const {
    getFieldProps,
    handleChange,
    values,
    dirty,
    resetForm,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    validateForm,
  } = formik

  useEffect(() => {
    if (modal.show) resetForm()
  }, [modal.show])

  return (
    <div className="Profile-editButton">
      <Button colorType="default secondary" icon="edit-2" size="small" onClick={handleShowModal}>
        <span className="text">Chỉnh sửa thông tin</span>
      </Button>

      <Modal
        className="ModalCreateUpdate marginTitleBig"
        title="Chỉnh sửa thông tin cá nhân"
        show={show}
        size="sm"
        close={handleCloseModal}
        dirty={dirty}
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <div className="mb-3 mb-3-2">
              <TopLabel label="Họ và tên" required />
              <Input
                placeholder="Họ và tên"
                maxLength={50}
                {...getFieldProps('full_name')}
                error={errors.full_name}
                onChange={(e) => {
                  if (validateTypingName(e)) handleChange(e)
                }}
                errorEmpty={touched.full_name}
              />
            </div>
            <div className="ModalCreateUpdate-row">
              <div className="ModalCreateUpdate-col-6 mb-3 mb-3-2">
                <TopLabel label="Học hàm" />
                <Select
                  fullWidth
                  border
                  data={listAcademicRanks}
                  selected={values.academic_rank}
                  setSelected={(s) => setFieldValue('academic_rank', s)}
                  placeholder="Chọn học hàm"
                  // sort
                  removable
                />
              </div>
              <div className="ModalCreateUpdate-col-6 mb-3 mb-3-2">
                <TopLabel label="Học vị" />
                <Select
                  fullWidth
                  border
                  data={listDegrees}
                  selected={values.degree}
                  setSelected={(s) => setFieldValue('degree', s)}
                  placeholder="Chọn học vị"
                  sort
                  removable
                />
              </div>
            </div>
            <div className="mb-3">
              <TopLabel label="Tiểu sử ngắn" />
              <Input
                placeholder="Tiểu sử ngắn"
                maxLength={5000}
                {...getFieldProps('biography')}
                rows={5}
                multiline
              />
            </div>
            <DialogActions>
              <ModalButtonCancel onClick={handleCloseModal}>Hủy</ModalButtonCancel>
              <Button type="submit" variant="contained" loading={loading}>
                Lưu
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Modal>
    </div>
  )
}

export default UpdateInfo
