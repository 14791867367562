import { FormHelperText } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { randomId, validateTypingNameWithNumber } from 'utils'

interface Props {
  value: { id: string; name: string }[]
  onChangeValue: (value: { id: string; name: string }[]) => void
  submitted?: boolean
  resetTouched?: boolean
  isProceedings?: boolean
  placeholder?: string
}

const titleCase = (str: string) => {
  const convertToArray = str.toLowerCase().split(' ')
  const result = convertToArray.map(function (val) {
    return val.replace(val.charAt(0), val.charAt(0).toUpperCase())
  })

  return result.join(' ')
}

const InputAuthors: React.FC<Props> = ({
  value,
  onChangeValue,
  submitted,
  resetTouched,
  isProceedings,
  placeholder,
}) => {
  const touchedRef = useRef(false)
  const inputRef = useRef('')

  const [input, setInput] = useState('')
  const [focus, setFocus] = useState(false)

  useEffect(() => {
    inputRef.current = input
  }, [input])

  useEffect(() => {
    touchedRef.current = false
  }, [resetTouched])

  const touched = submitted || touchedRef.current
  const error = touched && !focus && !value.length

  const update = (input: string) => {
    const trimmedInput = input.trim()

    if (trimmedInput) onChangeValue([...value, { id: randomId(), name: titleCase(trimmedInput) }])

    setInput('')
  }

  return (
    <>
      <div className={`InputAuthor${focus ? ' focus' : ''}${error ? ' error' : ''}`}>
        <div className="border" />
        {value.map(({ id, name }) => (
          <div className="InputAuthor-authorName" key={id}>
            <span className="neutral-1" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {name}
            </span>

            <i
              className="icon-close"
              onClick={() => onChangeValue(value.filter((i) => i.id !== id))}
            />
          </div>
        ))}
        <input
          placeholder={placeholder}
          maxLength={50}
          value={input}
          onFocus={() => {
            setFocus(true)
            touchedRef.current = true
          }}
          onBlur={() => {
            setFocus(false)
            update(inputRef.current)
          }}
          onChange={(e) => {
            if (validateTypingNameWithNumber(e)) setInput(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              update(input)
            }
          }}
        />
      </div>
      {error && (
        <FormHelperText error>
          {isProceedings ? 'Người chỉnh sửa' : 'Tác giả'} không được để trống.
        </FormHelperText>
      )}
    </>
  )
}

export default InputAuthors
