import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { firebaseConfig } from './config'

const app = initializeApp(firebaseConfig)
getAnalytics(app)

const AnalysticWrapper = () => {
  return <></>
}

export default AnalysticWrapper
