import { UPDATE_HOME } from 'store/actionTypes'
import { ActionType, HomeReducer } from 'types'

const initialState: HomeReducer = {
  loading: true,
  about: [],
  banners: [],
  news: [],
  overview: {
    department: 0,
    research_group: 0,
    research_information: 0,
    reward: 0,
    scientific_paper: 0,
  },
  partners: [],
  products: [],
  trainings: [],
  researches: [],
  seminar: '',
}

const homeReducer = (state = initialState, action: ActionType) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_HOME:
      return { ...state, ...(payload || {}) }

    default:
      return state
  }
}

export default homeReducer
