/* eslint-disable */
import { ROUTES } from 'configs/constants'
import React from 'react'
import { useIsMobile } from 'store/mobile'
import { formatDate } from 'utils'
import Link from '../links/Link'
import './index.scss'

interface Props {
  id?: string
  object_id: string
  object_type: any
  created_date?: any
  title: string
  summary?: string
  image_url: string
}

const {
  ROUTE_NEWS,
  ROUTE_STUDENTS,
  ROUTE_PRODUCTS,
  ROUTE_TRAINING_INFO,
  ROUTE_ENROLLMENTS,
  ROUTE_RESEARCH_INFO,
  ROUTE_PARTNER_ACTIVITIES,
} = ROUTES

const mappingRoute: any = {
  articles: ROUTE_NEWS,
  students: ROUTE_STUDENTS,
  products: ROUTE_PRODUCTS,
  trainings: ROUTE_TRAINING_INFO,
  enrollments: ROUTE_ENROLLMENTS,
  researches: ROUTE_RESEARCH_INFO,
  cooperation: ROUTE_PARTNER_ACTIVITIES,
}

const SearchItem: React.FC<Props> = ({
  object_id,
  object_type,
  created_date,
  title,
  summary,
  image_url,
}) => {
  const isMobile = useIsMobile()

  return (
    <Link to={`/${mappingRoute[object_type]}/${object_id}`} className="SearchItem">
      <div className="banner background-image" style={{ backgroundImage: `url("${image_url}")` }} />
      <div className="body">
        <div className="label-icon-wrapper neutral-2">
          <i className="icon-calendar1" />
          {formatDate(created_date)}
        </div>
        <p className={`title typo-20-6 max-line${isMobile ? '' : '-1'}`}>{title}</p>
        <p className={`content typo-16-4 typo-16-14 max-line-${isMobile ? '1' : '3'}`}>{summary}</p>
      </div>
    </Link>
  )
}

export default SearchItem
