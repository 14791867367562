import { CircularProgress } from '@mui/material'
import { apiUrls } from 'configs/apis'
import { DEFAULT_AVATAR } from 'configs/constants'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useApis } from 'services/api'
import { useAuth } from 'store/auth'
import { useUploadFile } from 'utils/uploadFile'

const UploadAvatar: React.FC = () => {
  const ref = useRef<HTMLInputElement>(null)

  const { avatar_url, updateAuth, full_name, biography, academic_rank, degree } = useAuth()
  const { upload, canUpload } = useUploadFile(true)
  const { apiPut } = useApis()

  const [file, setFile] = useState({ url: avatar_url, loading: false })

  useEffect(() => {
    setFile({ url: avatar_url, loading: false })
  }, [avatar_url])

  const handleUploadFile = useCallback((e: any) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }

    if (canUpload(file.size)) {
      setFile({ url: URL.createObjectURL(file), loading: true })

      upload(file, 'avatars', (url) => {
        apiPut(
          apiUrls.profile(),
          {
            full_name,
            biography: biography || '',
            academic_rank: academic_rank || undefined,
            degree: degree || undefined,
            avatar_url: url,
            key: 1,
          },
          () => {
            updateAuth({ avatar_url: url })
          }
        )
      })
    }

    e.target.value = null
  }, [])

  const { url, loading } = file

  return (
    <div
      className={`background-image UserDetailInfo-avatar avatar${loading ? ' loading' : ''}`}
      style={{ backgroundImage: `url("${url || DEFAULT_AVATAR}")` }}
    >
      <div
        className="UserDetailInfo-uploadAvatar d-f ai-c jc-c c-p"
        onClick={loading ? undefined : () => ref.current?.click()}
      >
        {loading ? <CircularProgress size={20} /> : <i className="icon-camera primary" />}
      </div>
      <input
        ref={ref}
        type="file"
        accept=".png, .jpg, .jpeg"
        style={{ display: 'none' }}
        onChange={handleUploadFile}
      />
      <div className="overlay" />
    </div>
  )
}

export default UploadAvatar
