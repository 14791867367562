import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'store/auth'
import { useHistory } from 'store/confirmRedirect'
import { useModalConfirm } from 'store/modalConfirm'

const excepts = ['sign-in', 'reset-password']

const SessionContainer: React.FC = () => {
  const { isAuth, msg, signOut } = useAuth()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const { pathname } = useLocation()
  const history = useHistory()

  const isAdmin = window.location.pathname.includes('/admin')

  useEffect(() => {
    if (!isAuth && msg && !excepts.some((except) => pathname.includes(except))) {
      showModalConfirm({
        content: msg,
        confirm: {
          action: () => {
            hideModalConfirm()
            signOut()
            !isAdmin && history.push('/')
          },
        },
      })
    }
  }, [isAuth])

  return null
}

export default SessionContainer
