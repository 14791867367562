import { combineReducers } from 'redux'
import { appReducer } from './app'
import { authReducer } from './auth'
import { breadcrumbReducer } from './breadcrumb'
import { confirmRedirectReducer } from './confirmRedirect'
import { homeReducer } from './home'
import { mobileReducer } from './mobile'
import { modalConfirmReducer } from './modalConfirm'
import { modalAuthReducer } from './modalAuth'
import { toastsReducer } from './toasts'

const rootReducer = combineReducers({
  modalConfirm: modalConfirmReducer,
  auth: authReducer,
  toasts: toastsReducer,
  confirmRedirect: confirmRedirectReducer,
  breadcrumb: breadcrumbReducer,
  mobile: mobileReducer,
  app: appReducer,
  home: homeReducer,
  modalAuth: modalAuthReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
