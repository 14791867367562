/* eslint-disable */
import { mappingTrainingInfoRoutes, ROUTES } from 'configs/constants'
import React from 'react'
import { useIsMobile } from 'store/mobile'
import Link from '../links/Link'
import './index.scss'

interface Props {
  id?: string
  image_url: string
  title: string
  content_to_summary?: string
  type_training?: string
}

const TrainingItem: React.FC<Props> = ({
  id,
  image_url,
  title,
  content_to_summary,
  type_training = '',
}) => {
  const isMobile = useIsMobile()

  const to = `/${ROUTES.ROUTE_TRAINING_INFO}/${mappingTrainingInfoRoutes[type_training]}/${id}`

  return (
    <div className="TrainingItem">
      <Link
        to={to}
        className="TrainingItem-banner background-image"
        style={{
          backgroundImage: `url("${image_url}")`,
        }}
      />
      <div className="TrainingItem-body">
        <div>
          <Link to={to} className="TrainingItem-body__title max-line-1 typo-20-7" hoverSemantic>
            {title}
          </Link>
          <p className={`TrainingItem-body__content max-line${isMobile ? '' : '-7'} typo-16-4`}>
            {content_to_summary}
          </p>
        </div>
        <Link className="TrainingItem-body__more" to={to} color="semantic-1">
          <span className="typo-16-6">Tìm hiểu thêm</span>
          <i className="icon-chevron-right" />
        </Link>
      </div>
    </div>
  )
}

export default TrainingItem
