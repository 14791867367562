import React, { CSSProperties } from 'react'
import { convertToLinks } from 'utils'

interface Props {
  className?: string
  content?: string
  style?: CSSProperties
}

const EditorViewer: React.FC<Props> = ({ className = '', content = '', ...rest }) => {
  const htmlContent = content ? (
    <span
      className="label"
      dangerouslySetInnerHTML={{
        __html: convertToLinks(content),
      }}
    />
  ) : (
    'Đang cập nhật…'
  )

  return (
    <div className={`EditorViewer ck-content ${className}`} {...rest}>
      {htmlContent}
    </div>
  )
}

export default EditorViewer
