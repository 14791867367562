import { RESOLUTION_MOBILE } from 'store/actionTypes'
import { ActionType } from 'types'

const initialState = window.innerWidth <= 860

const mobileReducer = (state = initialState, action: ActionType) => {
  const { type, payload } = action

  switch (type) {
    case RESOLUTION_MOBILE:
      return payload

    default:
      return state
  }
}

export default mobileReducer
