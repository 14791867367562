import React, { CSSProperties } from 'react'
import './index.scss'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

interface Props {
  className?: string
  style?: CSSProperties
  [key: string]: any
}

const Scrollbar: React.FC<Props> = ({ children, className = '', ...rest }) => {
  return (
    <SimpleBar {...rest} className={`Scrollbar ${className}`} timeout={500}>
      {children}
    </SimpleBar>
  )
}

export default Scrollbar
