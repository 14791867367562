/* eslint-disable */
import { DialogActions } from '@mui/material'
import SelectMainResearch from 'components/user/selectMainResearch'
import { apiUrls } from 'configs/apis'
import {
  entryFieldsSubmit,
  listScientificPaperGroupSort,
  listTypesLessUser,
  mappingFieldUser,
} from 'configs/scientificPaperConstants'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApis } from 'services/api'
import { useModalConfirm } from 'store/modalConfirm'
import { ScientificPaperType } from 'types'
import { authorToBibtex, jsonToBibtex, validateTypingScientificPaper } from 'utils'
import * as Yup from 'yup'
import Modal from '../../modal/Modal'
import ModalButtonCancel from '../../modal/ModalButtonCancel'
import Scrollbar from '../../scrollbar/Scrollbar'
import Button from '../button/Button'
import Input from '../input/Input'
import Select from '../select/Select'
import TopLabel from '../topLabel/TopLabel'
import InputAuthors from './InputAuthors'

const schema = (error: any) =>
  Yup.object().shape({
    title: Yup.string()
      .trim()
      .required('Tiêu đề không được để trống.')
      .notOneOf([error.value], error.text),
    authors: Yup.array().min(1),
  })

interface Props {
  show: boolean
  type: 'create' | 'update' | string
  initialValues: ScientificPaperType
  onClose: any
  onSuccess: any
}

const ModalScientificPaper: React.FC<Props> = ({
  show,
  onClose,
  onSuccess,
  type,
  initialValues,
}) => {
  const { t } = useTranslation()
  const [reloadMainResearch, setReloadMainResearch] = useState(false)
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const isUpdate = type === 'update'
  const { requestApi, apiPut } = useApis()

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState({ value: '', text: '' })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema(error),
    // validateOnChange: false,
    onSubmit: (values, { validateForm }) => {
      setLoading(true)
      const newValues = {
        ENTRYTYPE: values.ENTRYTYPE,
        address: values.address,
        author: values.author,
        bibtex: values.bibtex,
        bibtex_url: values.bibtex_url,
        booktitle: values.booktitle,
        chapter: values.chapter,
        edition: values.edition,
        editor: values.ENTRYTYPE === 'proceedings' ? values.author : values.editor,
        howpublished: values.howpublished,
        institution: !isUpdate ? values.institution || values.organization : values.institution,
        journal: values.journal,
        language: values.language,
        month: values.month,
        note: values.note,
        number: values.number,
        organization: values.organization,
        publisher: values.publisher,
        school: values.school,
        series: values.series,
        title: values.title,
        type: values.type,
        volume: values.volume,
        year: values.year,
        newAuthors: values.newAuthors,
        authors: values.authors,
        book_type: values.book_type,
        page: values.page,
        book_number: values.book_number,
        group: values.group && Number(values.group),
        main_research_ids:
          values?.main_researches?.map((item: { id: string; name: string }, index: number) => ({
            id: item.id,
            index,
          })) || [],
      } as any

      const dataMapEntryType: any = {}

      mappingFieldUser[values.ENTRYTYPE || ''].forEach((i) => {
        dataMapEntryType[i.id] = newValues[i.id] || undefined
      })

      const bibtexJson = {
        ...dataMapEntryType,
        ENTRYTYPE: values.ENTRYTYPE,
        author: values.author,
        bibtex: values.bibtex,
        bibtex_url: values.bibtex_url,
        title: values.title,
      }

      // const bibtexJson = { ...newValues }
      delete bibtexJson.authors
      bibtexJson.type = bibtexJson.ENTRYTYPE
      bibtexJson.author = values.authors?.map((i) => authorToBibtex(i.name)) as any
      bibtexJson.issue = bibtexJson.book_number
      bibtexJson['container-title'] = bibtexJson.journal ? bibtexJson.journal : bibtexJson.booktitle
      bibtexJson['publisher-place'] = bibtexJson.address
      bibtexJson['collection-title'] = bibtexJson.series
      bibtexJson.organization = bibtexJson.institution
      bibtexJson.publisher = bibtexJson.school ? bibtexJson.school : bibtexJson.publisher
      bibtexJson.genre = bibtexJson.book_type

      bibtexJson.issued = {
        'date-parts': [[bibtexJson.year, bibtexJson.month]],
      }
      if (values.ENTRYTYPE === 'proceedings') {
        bibtexJson.editor = values.authors?.map((i) => authorToBibtex(i.name)) as any
        delete bibtexJson.author
      }

      if (values.ENTRYTYPE === 'conference' || values.ENTRYTYPE === 'inproceedings') {
        bibtexJson.editor = values.editor ? [authorToBibtex(values.editor)] : null
        newValues.editor = values.editor ? values.editor : ''
      }
      if (values.ENTRYTYPE === 'techreport') {
        delete bibtexJson.organization
        newValues.institution = values.school
        delete newValues.organizations
      }

      let bibtex = jsonToBibtex(bibtexJson)
      bibtex = bibtex.replace(/\\{DJ}{}/g, '\\DJ{}') // Xử lý lỗi chữ Đ viết hoa
      if (values.ENTRYTYPE === 'mastersthesis') {
        bibtex = bibtex.replace('publisher', 'school')
      }
      if (values.ENTRYTYPE === 'techreport') {
        bibtex = bibtex.replace('publisher', 'institution')
      }

      if (
        (values.ENTRYTYPE === 'inproceedings' ||
          values.ENTRYTYPE === 'conference' ||
          values.ENTRYTYPE === 'proceedings') &&
        isUpdate
      ) {
        newValues.organization = ''
      }
      if (values.ENTRYTYPE === 'phdthesis') {
        bibtex = bibtex.replace('publisher', 'school')
        delete newValues.publisher
      }

      newValues.author = newValues.authors?.map(({ name }: any) => name).join(', ')
      newValues.type = newValues.book_type
      newValues.number = newValues.book_number
      newValues.page = newValues.pages
      delete newValues.authors
      delete newValues.book_type
      delete newValues.book_number
      delete newValues.pages
      delete newValues.newAuthors
      newValues.bibtex = bibtex
      // newValues.editor = newValues.author

      const filterValue: { [key: string]: unknown } = {}

      for (const key in newValues) {
        const newValue = newValues[key]
        if (newValue === null || newValue === undefined) {
          delete newValues[key]
        } else if (entryFieldsSubmit[ENTRYTYPE || ''].includes(key)) {
          if (key === 'group' || key === 'main_research_ids') {
            filterValue[key] = newValue
          } else filterValue[key] = String(newValue)
        } else {
          filterValue[key] = ''
        }
      }

      requestApi(
        isUpdate ? 'put' : 'post',
        apiUrls.scientificPapers(values.id),
        filterValue,
        ({ status, text, id: resId, data }) => {
          setLoading(false)

          const close = () => {
            hideModalConfirm()
            onClose()
          }

          if (status) {
            onClose()
            onSuccess()
          } else {
            if (resId === '160') {
              // Trùng title
              setError({ value: values.title || '', text })
              validateForm()
            } else if (resId === '190') {
              showModalConfirm({
                content: text,
                cancel: { text: 'Hủy', action: close },
                confirm: {
                  action: () => {
                    close()
                    apiPut(apiUrls.confirmJoinScientificPapers(), data, () => {
                      onSuccess()
                    })
                  },
                  text: 'Đồng ý',
                },
                close: close,
              })
            } else if (resId === '155') {
              showModalConfirm({
                content: text,
                confirm: {
                  action: close,
                },
                close: close,
              })
            } else if (resId === '229') {
              showModalConfirm({
                content: text,
                confirm: {
                  action: () => {
                    confirmMainResearchNotfound(data)
                  },
                },
                close: () => {
                  confirmMainResearchNotfound(data)
                },
              })
            }
          }
        }
      )
    },
  })

  const {
    getFieldProps,
    handleChange,
    values,
    validateForm,
    dirty,
    resetForm,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik

  const confirmMainResearchNotfound = (idsNotFound: string[]) => {
    hideModalConfirm()
    setReloadMainResearch((prev) => !prev)
    setFieldValue(
      'main_researches',
      values.main_researches.filter(
        (item: { id: string; name: string }) => !idsNotFound.includes(item.id)
      )
    )
  }

  useEffect(() => {
    if (!show) return

    setSubmitted(false)
    setError({ value: '', text: '' })
    resetForm()
    setTimeout(validateForm, 10)
  }, [show])

  const { ENTRYTYPE, authors = [], group, main_researches } = values

  const isProceedings = ENTRYTYPE === 'proceedings'

  return (
    <Modal
      className="ModalCreateUpdate"
      title={`${isUpdate ? 'Sửa' : 'Tạo'} công bố khoa học`}
      show={show}
      size="lg"
      close={onClose}
      dirty={dirty}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Scrollbar
            style={{
              maxHeight: 'calc(97vh - 230px)',
              paddingRight: 12,
              marginRight: -12,
            }}
          >
            <div className="ModalCreateUpdate-row">
              <div className="ModalCreateUpdate-col-6 mb-2">
                <TopLabel label="Loại" required />
                <Select
                  fullWidth
                  border
                  data={listTypesLessUser}
                  selected={ENTRYTYPE}
                  setSelected={(s) => setFieldValue('ENTRYTYPE', s)}
                />
              </div>
              <div className="ModalCreateUpdate-col-6 mb-2">
                <TopLabel label="Tiêu đề" required />
                <Input
                  placeholder="Tiêu đề"
                  maxLength={200}
                  {...getFieldProps('title')}
                  error={errors.title}
                  onChange={(e) => {
                    if (validateTypingScientificPaper(e)) handleChange(e)
                  }}
                  errorEmpty={submitted || touched.title}
                />
              </div>
            </div>
            <div className="mb-2">
              <TopLabel label={isProceedings ? 'Người chỉnh sửa' : 'Tác giả'} required />
              <InputAuthors
                value={authors}
                onChangeValue={(s) => setFieldValue('authors', s)}
                submitted={submitted}
                resetTouched={show}
                isProceedings={isProceedings}
                placeholder={isProceedings ? 'Người chỉnh sửa' : 'Tác giả'}
              />
            </div>
            <div className="ModalCreateUpdate-row">
              <div className="ModalCreateUpdate-col-6 mb-2">
                <TopLabel label="Nhóm công bố khoa học" />
                <Select
                  placeholder="Nhóm công bố khoa học"
                  fullWidth
                  border
                  data={listScientificPaperGroupSort}
                  selected={group}
                  setSelected={(s) => setFieldValue('group', s)}
                  removable
                />
              </div>
              <div className="ModalCreateUpdate-col-6 mb-2">
                <SelectMainResearch
                  reload={reloadMainResearch}
                  noWhireSpace
                  checkbox={{
                    name: 'name',
                    cutText: 40,
                    selected: main_researches || [],
                    onChange: (value) => {
                      setFieldValue('main_researches', value)
                    },
                    areaStyle: { marginLeft: -110 },
                  }}
                  maxHeight={220}
                />
              </div>
            </div>
            <div className="ModalCreateUpdate-row">
              {mappingFieldUser[ENTRYTYPE || '']?.map(
                ({ id, fullWidth, maxLength, rows, halfWidthMobile }) => (
                  <div
                    key={id}
                    className={`ModalCreateUpdate-col-${fullWidth ? 12 : 6}${
                      halfWidthMobile ? ' halfWidthMobile' : ''
                    } mb-2`}
                  >
                    <TopLabel label={t(id)} />
                    <Input
                      placeholder={t(id)}
                      maxLength={maxLength || 50}
                      multiline={!!rows}
                      rows={rows}
                      name={id}
                      value={values[id] || ''}
                      onChange={(e) => {
                        if (validateTypingScientificPaper(e)) handleChange(e)
                      }}
                    />
                  </div>
                )
              )}
            </div>
          </Scrollbar>
          <DialogActions>
            <ModalButtonCancel onClick={onClose}>Hủy</ModalButtonCancel>
            <Button
              type="submit"
              variant="contained"
              onClick={() => setSubmitted(true)}
              loading={loading}
            >
              {isUpdate ? 'Lưu' : 'Tạo mới'}
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Modal>
  )
}

export default ModalScientificPaper
