/* eslint-disable */
import { Button, Fade, MenuItem, Paper, Popper } from '@mui/material'
import { DEFAULT_AVATAR } from 'configs/constants'
import React, { useRef, useState } from 'react'
import { useAuth } from 'store/auth'
import { useHistory } from 'store/confirmRedirect'

interface Props {
  menuUserItems: any[]
}

const Menu: React.FC<Props> = ({ menuUserItems }) => {
  const { avatar_url } = useAuth()

  const ref = useRef<HTMLButtonElement>(null)

  const history = useHistory()

  const [hover, setHover] = useState(false)

  const open = hover

  return (
    <>
      <Button
        className={`Header-avatar${open ? ' open' : ''}`}
        ref={ref}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="Header-avatar__border" />
        <img alt="" src={avatar_url || DEFAULT_AVATAR} />

        <Popper
          className="Header-menu"
          placement="bottom-end"
          modifiers={[
            {
              name: 'flip',
              enabled: false,
            },
            {
              name: 'preventOverflow',
              enabled: false,
            },
            {
              name: 'offset',
              options: {
                offset: [0, 0],
              },
            },
          ]}
          disablePortal
          transition
          style={{ zIndex: 3, width: 316 }}
          open={open}
          anchorEl={ref.current}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={300}>
              <Paper className="Chart-popper__paper">
                {menuUserItems.map(({ title, to, onClick }) => (
                  <MenuItem
                    className="typo-16-5 neutral-1"
                    key={title}
                    onClick={() => {
                      setHover(false)
                      onClick?.()
                      if (to) history.push(to)
                    }}
                  >
                    {title}
                  </MenuItem>
                ))}
              </Paper>
            </Fade>
          )}
        </Popper>
      </Button>
    </>
  )
}

export default Menu
