import Title from '../title/Title'
import React from 'react'
import Slider from 'react-slick'
import AboutUsItem from '../aboutUsItem/AboutUsItem'
import { Container } from '@mui/material'
import { useHome } from 'store/home'
import { ButtonArrow } from '../button'

const Arrow = <ButtonArrow extraClass="HomeAboutUs-arrow" />

const settings = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: false,
  swipeToSlide: true,
}

const HomeAboutUs: React.FC = () => {
  const { about } = useHome()

  if (!about.length) return null

  return (
    <Container>
      <div className="HomeAboutUs">
        <Title>Nói về chúng tôi</Title>

        <Slider {...settings} infinite={true} prevArrow={Arrow} nextArrow={Arrow}>
          {about.slice(0, 5).map((item, index) => (
            <div key={index}>
              <Container className="HomeAboutUs-itemContainer">
                <AboutUsItem {...item} />
              </Container>
            </div>
          ))}
        </Slider>
      </div>
    </Container>
  )
}

export default HomeAboutUs
