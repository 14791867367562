import { ROUTES } from 'configs/constants'
import React from 'react'
import { useHistory } from 'store/confirmRedirect'
import { IntroductionType } from 'types'
import './index.scss'

const IntroductionItem: React.FC<IntroductionType> = ({ title, id, image_url }) => {
  const history = useHistory()

  return (
    <div
      onClick={() => history.push(`${ROUTES.ROUTE_INTRODUCTIONS}/${id}`)}
      className="IntroductionItem background-image"
      style={{
        backgroundImage: `url("${image_url}")`,
      }}
    >
      <div className="IntroductionItem-overlay" />
      <div className="IntroductionItem-content d-f fd-c jc-c ai-c ta-c c-p">
        <i className="icon-open-book" />
        <span className="typo-24-6 max-line">{title}</span>
      </div>
    </div>
  )
}

export default IntroductionItem
