"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.remove = exports.output = exports.list = exports.input = exports.has = exports.dict = exports.config = exports.add = void 0;

var input = _interopRequireWildcard(require("./input/index.js"));

exports.input = input;

var output = _interopRequireWildcard(require("./output.js"));

exports.output = output;

var dict = _interopRequireWildcard(require("./dict.js"));

exports.dict = dict;

var config = _interopRequireWildcard(require("./config.js"));

exports.config = config;

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const registers = {
  input,
  output,
  dict,
  config
};
const indices = {};

const add = (ref, plugins = {}) => {
  const mainIndex = indices[ref] = {};

  for (const type in plugins) {
    if (type === 'config') {
      mainIndex.config = {
        [ref]: plugins.config
      };
      registers.config.add(ref, plugins.config);
      continue;
    }

    const typeIndex = mainIndex[type] = {};
    const typePlugins = plugins[type];

    for (const name in typePlugins) {
      const typePlugin = typePlugins[name];
      typeIndex[name] = true;
      registers[type].add(name, typePlugin);
    }
  }
};

exports.add = add;

const remove = ref => {
  const mainIndex = indices[ref];

  for (const type in mainIndex) {
    const typeIndex = mainIndex[type];

    for (const name in typeIndex) {
      registers[type].remove(name);
    }
  }

  delete indices[ref];
};

exports.remove = remove;

const has = ref => ref in indices;

exports.has = has;

const list = () => Object.keys(indices);

exports.list = list;