import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useCallback } from 'react'
import { formatContent } from './extensions'
import AdminButton from '../admin/button/Button'
import UserButton from '../user/button/Button'
import { useTranslation } from 'react-i18next'

interface Props {
  show: boolean
  title: string
  content: any
  confirm?: { text: string; action: any }
  cancel?: { text: string; action: any }
  close?: any
  center?: boolean
  small?: boolean
  error?: boolean
  onBackdropClick?: any
}

const ModalConfirm: React.FC<Props> = ({
  show,
  title,
  confirm,
  cancel,
  center = true,
  small = true,
  error,
  content,
  onBackdropClick,
  close,
}) => {
  const { t } = useTranslation()
  content = formatContent(content)

  const isAdmin = window.location.pathname.includes('/admin')

  const Button = useCallback(
    (props) => (isAdmin ? <AdminButton {...props} /> : <UserButton {...props} />),
    [isAdmin]
  )

  return (
    <Dialog
      className="Modal"
      open={show}
      fullWidth
      maxWidth={small ? 'xs' : 'sm'}
      onBackdropClick={onBackdropClick}
    >
      <DialogTitle style={{ textAlign: center ? 'center' : undefined }}>
        {title || t('information')}
        {!!close && <i className="icon-close Modal-close" onClick={close} />}
      </DialogTitle>
      <DialogContent
        style={{ fontWeight: isAdmin ? 500 : undefined, textAlign: center ? 'center' : undefined }}
      >
        {content}
      </DialogContent>
      <DialogActions>
        {cancel && (
          <Button
            onClick={cancel.action}
            colorType={isAdmin ? undefined : 'default secondary'}
            color="primary"
            variant={error ? 'contained' : undefined}
            fullWidth
          >
            {cancel.text}
          </Button>
        )}
        {confirm && (
          <Button
            fullWidth
            onClick={confirm.action}
            variant="contained"
            color={error && cancel ? 'error' : 'primary'}
          >
            {confirm.text || t('understand')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ModalConfirm
