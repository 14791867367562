/* eslint-disable */
import { Container } from '@mui/material'
import { DEFAULT_AVATAR, mappingAcademicRank, mappingDegree } from 'configs/constants'
import { mappingRegency, mappingRolePartyCommittee } from 'pages/admin/users/constants'
import React from 'react'
import { useIsMobile } from 'store/mobile'
import { UserType } from 'types'
import Title from '../title/Title'
import ExportInfo from './ExportInfo'
import UpdateInfo from './UpdateInfo'
import UploadAvatar from './UploadAvatar'

const UserDetailInfo: React.FC<UserType> = ({
  avatar_url,
  full_name,
  regency,
  department,
  academic_rank,
  degree,
  email,
  role_party_committee,
  biography,
  isProfile,
}) => {
  const isMobile = useIsMobile()

  const renderTitle = (
    <div className="d-f jc-sb">
      <Title noPadding>Thông tin cá nhân</Title>
      {isProfile && (
        <div className="d-f ai-c">
          <UpdateInfo />
          <ExportInfo />
        </div>
      )}
    </div>
  )

  const renderAvatar = isProfile ? (
    <UploadAvatar />
  ) : (
    <div
      className="background-image avatar UserDetailInfo-avatar"
      style={{ backgroundImage: `url("${avatar_url || DEFAULT_AVATAR}")` }}
    />
  )

  const renderBiography = (
    <div
      className="biography typo-16-4 neutral-1"
      dangerouslySetInnerHTML={{ __html: biography?.replace(/\n/g, '<br />') || '' }}
    />
  )

  const renderMainInfo = (
    <>
      <div>
        <span>Đơn vị: </span>
        <span>{department?.name}</span>
      </div>
      {!!academic_rank && (
        <div>
          <span>Học hàm: </span>
          <span>{mappingAcademicRank[academic_rank]}</span>
        </div>
      )}
      {!!degree && (
        <div>
          <span>Học vị: </span>
          <span>{mappingDegree[degree]}</span>
        </div>
      )}
      {isProfile ? (
        <div>
          <span>Email: </span>
          <span>
            {isProfile ? (
              email
            ) : (
              <a className="system-link hover-underline" href={`mailto:${email}`}>
                {email}
              </a>
            )}
          </span>
        </div>
      ) : null}
      {/* {!!role_party_committee && (
        <div>
          <span>Chức vụ Đảng: </span>
          <span>{mappingRolePartyCommittee[role_party_committee]}</span>
        </div>
      )} */}
    </>
  )

  return isMobile ? (
    <>
      <Container>{renderTitle}</Container>
      <div className="UserDetailInfo-mobile d-f fd-c ai-c">
        <Container className="d-f fd-c ai-c">
          {renderAvatar}
          <div className="fullName neutral-1 mt-2 ta-c">{full_name}</div>
          <div className="regency primary mb-2 ta-c">{mappingRegency[regency]}</div>
          <div className="mainInfo p-2 w-100">{renderMainInfo}</div>
        </Container>
        {!!biography && (
          <div className="biographyWrapper py-3 w-100">
            <Container>
              <div className="title mb-2">Tiểu sử ngắn</div>
              {renderBiography}
            </Container>
          </div>
        )}
      </div>
    </>
  ) : (
    <>
      <Container className="UserDetailInfo">
        {renderTitle}
        <div className="d-f jc-sb">
          {renderAvatar}
          <div className="info neutral-1">
            <div>
              <span>Họ và tên:</span>
              <span>{full_name}</span>
            </div>
            <div>
              <span>Chức vụ:</span>
              <span>{mappingRegency[regency]}</span>
            </div>
            {renderMainInfo}
          </div>
        </div>
        {!!biography && renderBiography}
      </Container>
    </>
  )
}

export default UserDetailInfo
