import React, { useEffect, useRef, useState } from 'react'
import './index.scss'

interface Props {
  title?: string
  numberOfLines?: number
  text: any
  hideTooltip?: boolean
  className?: string
  style?: any
}

const MaxLine: React.FC<Props> = ({
  title,
  numberOfLines = 2,
  text,
  hideTooltip,
  className = '',
  style,
}) => {
  const ref = useRef<any>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    setShowTooltip(ref?.current?.scrollHeight > ref?.current?.clientHeight)
  }, [ref, text])

  return (
    <div className={className} title={showTooltip && !hideTooltip ? title || text : undefined}>
      <div
        style={{ WebkitLineClamp: numberOfLines, whiteSpace: 'normal', ...style }}
        ref={ref}
        className="MaxLine"
      >
        {text}
      </div>
    </div>
  )
}

export default MaxLine
