import MaxLine from '../../maxLine/MaxLine'
import React from 'react'
import { ResearchInfoType } from 'types'
import './index.scss'
import { formatDate } from 'utils'
import Link from '../links/Link'
import { ROUTES, mappingResearchType, mappingRoleReseach } from 'configs/constants'

const ResearchItem: React.FC<ResearchInfoType> = ({
  id,
  start_time,
  end_time,
  title,
  isMobile,
  link,
  execution_status,
  topic_research,
  role_research,
  isProfile,
  showMember,
  manager_user,
  secretary_user,
  members,
  hideInfor,
}) => {
  const manager = ['Chủ nhiệm đề tài', manager_user?.full_name]
  const secretary = ['Thư ký', secretary_user?.full_name]
  const listMembers = ['Thành viên', members?.map((item) => item.full_name).join(', ')]

  const renderInfo = [manager, secretary, listMembers]
    .filter((i) => i[1])
    .map((i) => (
      <div key={i[0]} className="mt-1 typo-14-5 neutral-2 d-f">
        <span>
          {i[0]}: {i[1]}
        </span>
      </div>
    ))

  const date = `${formatDate(start_time)}${end_time ? ` - ${formatDate(end_time)}` : ''}`

  const reviewedStatusElement = link ? (
    <a href={link} target="_blank" rel="noreferrer" className="link_review">
      Đã hoàn thành
    </a>
  ) : (
    'Đã hoàn thành'
  )
  const executionStatus = [execution_status ? reviewedStatusElement : 'Đang thực hiện']

  return (
    <div className="ResearchItem d-f">
      <div className="left d-f fd-c ai-c">
        <div className="circle" />
        <div className="dash" />
      </div>
      <div className="right">
        <div className="date primary typo-14-6 mb-1">{date}</div>
        <MaxLine
          className="title typo-16-5 typo-16-14"
          text={
            <Link color="system-link" hoverUnderline to={`/${ROUTES.ROUTE_RESEARCH_INFO}/${id}`}>
              {title}
            </Link>
          }
          title={title}
          numberOfLines={isMobile ? 4 : 2}
        />
        {isMobile && isProfile && (
          <div className="typo-14-6 mt-1 mb-1 neutral-2">
            {`Loại đề tài: ${mappingResearchType[topic_research]}`}
          </div>
        )}
        {!hideInfor && (
          <div className="mt-1 typo-14-5 neutral-2">Trạng thái thực hiện: {executionStatus}</div>
        )}
        {showMember ? (
          <div>{renderInfo}</div>
        ) : (
          <div className="mt-1 typo-14-5 neutral-2">
            {!hideInfor && `Vai trò: ${mappingRoleReseach[role_research || '']}`}
          </div>
        )}
      </div>
    </div>
  )
}

export default ResearchItem
