/* eslint-disable */
import { Chip, CircularProgress, FormHelperText } from '@mui/material'
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { createFileName, trimFileName } from 'utils'
import './index.scss'
import { useUploadFile } from 'utils/uploadFile'
import Button from '../../user/button/Button'
import { useModalConfirm } from 'store/modalConfirm'
import { MAX_ATTACHMENT_SIZE } from 'configs/constants'
import { apiUrls } from 'configs/apis'
import { useApis } from 'services/api'

const initialValues = { url: [], loading: false }

interface Props {
  prefix: string
  fileUrl?: string[]
  onSuccess?: (url: string[]) => void
  onRemove?: (url: string[]) => void
  onClick?: (url: string) => void
  disabled?: boolean
  error?: string
  errorEmpty?: boolean
  label?: string
  accept?: any
  style?: CSSProperties
  adminButton?: boolean
  limit?: number
  showIndex?: boolean
  trimFile?: boolean
  isUpdate?: boolean
}

const UploadImage: React.FC<Props> = ({
  prefix,
  fileUrl: fileDefault = [],
  onSuccess,
  onRemove,
  onClick,
  disabled,
  error,
  errorEmpty,
  label,
  accept,
  style,
  adminButton,
  limit = 5,
  showIndex,
  trimFile,
  isUpdate,
}) => {
  const { upload } = useUploadFile()
  const touched = useRef(false)
  const firstLoad = useRef(true)

  const { apiGet } = useApis()

  const [file, setFile] = useState<{ url: string[]; loading: boolean }>({
    url: [],
    loading: false,
  })

  const inputRef = useRef<any>()

  const { showModalConfirm, hideModalConfirm } = useModalConfirm()

  useEffect(() => {
    if (JSON.stringify(file.url) !== JSON.stringify(fileDefault))
      setFile({
        url: fileDefault,
        loading: false,
      })
  }, [fileDefault])

  const showPopupCantUpload = () =>
    showModalConfirm({
      title: 'Thông báo',
      content: 'Không thể upload file trên 50 MB.',
      confirm: { action: hideModalConfirm, text: 'Đã hiểu' },
    })

  const handleSuccess = (url: any, identify?: string, _?: any) => {
    let payloadUrl: any[] = []
    setFile((prev: any) => {
      const newUrls = prev.url.map((item: any) =>
        item?.name && item?.name === identify ? { file: url, name: identify } : item
      )
      const newData = {
        url: newUrls,
        loading: newUrls.some((i: any) => i.file.includes('blob')),
      }
      payloadUrl = newUrls
      return newData
    })
    onSuccess?.(payloadUrl)
  }

  const handleRemove = (e: any, item: any) => {
    setFile((prev: any) => {
      const newData = { url: prev.url.filter((i: any) => i.name !== item), loading: false }
      onRemove?.(newData.url)
      return newData
    })
  }

  const handleError = (err: string) => {
    setFile(initialValues)
    console.log(err)
  }

  const handleUploadFile = (e: any) => {
    e.persist()
    const files = e.target.files
    const canUpload = Object.values(files).some((file: any) => file.size > MAX_ATTACHMENT_SIZE)

    const newFile: any[] = []

    if (!canUpload) {
      Object.values(files)
        .splice(0, limit - file.url.length)
        ?.reverse()
        .forEach((item: any, index: number) => {
          if (!item) {
            return
          }
          newFile.push({
            name: item.name + (file.url.length + index),
            file: URL.createObjectURL(item),
          })

          upload(
            item,
            prefix,
            handleSuccess,
            handleError,
            `${item.name + (file.url.length + index)}`
          )

          e.target.value = null
        })
      setFile({ url: [...newFile, ...file.url], loading: true })
    } else {
      showPopupCantUpload()
    }
  }

  useEffect(() => {
    if (errorEmpty) touched.current = true
  }, [errorEmpty])

  const { url, loading } = file

  const errorText = !file.url && touched.current ? error : undefined

  const [loadingFilename, setLoadingFilename] = useState(true)

  const getFileName = () => {
    setLoadingFilename(true)
    apiGet(
      apiUrls.upload(),
      {
        attached_files: url.map((item: any) => item.file),
      },
      ({ data, status }) => {
        if (status) {
          firstLoad.current = false
          const output: any = []

          url?.forEach((element: any, index: number) => {
            const elementFind = data.find((item: any) => item.attached_file === element.file)

            if (elementFind) {
              const { attached_file, file_name } = elementFind

              output.push({ file: attached_file, name: file_name + index })
            } else {
              output.push({
                file: element.file,
                name: element.name,
              })
            }
          })

          setFile({ ...file, url: output })
          setLoadingFilename(false)
        }
      }
    )
  }
  useLayoutEffect(() => {
    if (isUpdate) {
      if (url.length == 0) {
        setLoadingFilename(false)
      } else if (firstLoad.current) {
        getFileName()
      } else if (!firstLoad.current) {
        setLoadingFilename(false)
      }
    } else {
      setLoadingFilename(false)
    }
  }, [url.length, firstLoad.current])

  const loadingLimit =
    url.length === limit && url.some((i: any) => i.file.includes('blob')) && firstLoad.current

  return (
    <div style={style}>
      <div>
        {loadingFilename || loadingLimit ? (
          <CircularProgress size={16} color="inherit" style={{ marginLeft: 8 }} />
        ) : (
          <>
            {url.length > 0 &&
              url?.map((item: any, index: number) => {
                const fileName = item.name.slice(0, item.name.length - 1)
                return (
                  <div key={item.file}>
                    <Chip
                      style={{
                        margin: '0 10px 10px 0',
                        height: 36,
                        fontWeight: 500,
                        maxWidth: '100%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                      label={`${showIndex ? index + 1 + '. ' : ''}${fileName}`}
                      onDelete={disabled ? undefined : (e) => handleRemove(e, item.name)}
                      onClick={() => onClick?.(item.file)}
                    />
                  </div>
                )
              })}
            {url?.length < limit && !disabled && (
              <Button
                adminButton={adminButton}
                onClick={() => inputRef.current?.click()}
                variant="contained"
                disabled={file.loading}
                style={{
                  fontSize: 12,
                  width: 106,
                  height: 40,
                }}
              >
                {label || 'Thêm file'}
                {loading && (
                  <CircularProgress size={16} color="inherit" style={{ marginLeft: 8 }} />
                )}
              </Button>
            )}

            <input
              multiple
              ref={inputRef}
              type="file"
              accept={
                accept || '.png, .jpg, .jpeg, .doc, .docx, .pdf, .mp4, .avi, .mov, .flv, .wmv'
              }
              style={{ display: 'none' }}
              onChange={handleUploadFile}
            />
          </>
        )}
      </div>
      {!!errorText && (
        <FormHelperText error style={{ margin: '8px 0 10px' }}>
          {errorText}
        </FormHelperText>
      )}
    </div>
  )
}

export default UploadImage
