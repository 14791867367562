/* eslint-disable */
import { Cite } from '@citation-js/core'
import '@citation-js/plugin-bibtex'
import { isArray } from 'lodash'
import { ScientificPaperType } from 'types'

const bibtexToAuthors = (authors: any[]) => {
  if (!authors) return ''
  return authors
    .map((author) => ((author.family || '') + ' ' + (author.given || '')).trim())
    .join(', ')
}

export const authorToBibtex = (author: string) => {
  const authorComps = author.split(' ')
  const family = authorComps[0]
  const given = authorComps.slice(1).join(' ')
  return { family, given }
}

// `@article{ly1997intestinal,
//   title={Intestinal Ơ fish parasites as heavy {Metal} {Bioindicators}: A {Comparison} {Between} {Acanthocephalus} lucii ({Palaeacanthocephala}) and the {Zebra} {Mussel}, {Dreissena} polymorpha-{Ngu} nghiep},
//   author={L{\\\'y}, Kinh Tế-Quản and Tin, C{\\\^o}ng Nghệ Th{\\\^o}ng and H{\\\`a}ng, T{\\\`a}i Ch{\\\'\\\i}nh-Ng{\\\^a}n and Marketing, Kinh Doanh and Bản, Biểu Mẫu-V{\\u{a}}n and C{\\\'a}o, Luận V{\\u{a}}n-B{\\\'a}o and Hội, Khoa Học X{\\\~a} and Mềm, Kỹ N{\\u{a}}ng},
//   journal={Bull. Environ. Contam. Toxicol},
//   volume={59},
//   pages={14--21},
//   year={1997}
// }`
export const bibtexToJson = (bibtex: string) => {
  if (!bibtex) return {}

  const cite = new Cite(bibtex).data[0]

  cite.book_type = cite.genre
  cite.book_number = cite.number
  cite.newAuthors = bibtexToAuthors(cite.author)
  cite.pages = cite.page
  cite.series = cite['collection-title']

  if (cite.editor && isArray(cite.editor)) cite.editor = bibtexToAuthors(cite.editor)

  const time = cite.issued?.['date-parts']?.[0]
  if (time) {
    cite.year = time[0]
    cite.month = time[1]
    cite.day = time[2]
  }

  return cite
}

// {
//   author: [
//     {
//       family: 'Ly',
//       given: 'Franco',
//     },
//     {
//       family: 'Nguyễn',
//       given: 'Khánh',
//     },
//     {
//       family: 'Moretti',
//       given: 'Franco',
//     },
//   ],
//   title: `Intestinal khánh Á fish parasites as heavy Metal Bioindicators: A Comparison Between Acanthocephalus lucii (Palaeacanthocephala) and the Zebra Mussel, Dreissena polymorpha-Ngu nghiep`,
//   type: 'article-journal',
//   'container-title': 'Bull. Environ. Contam. Toxicol',
//   volume: '59',
//   page: '14-21',
//   issued: {
//     'date-parts': [[2019]],
//   },
// }
export const jsonToBibtex = (obj: { [key: string]: any }) => {
  const cite = new Cite(obj)
  return cite.format('bibtex')
}

export const selectPublishedByType = (type: string, data: ScientificPaperType) => {
  const { school, journal, booktitle, howpublished, organization, publisher, institution } = data
  switch (type) {
    case 'article':
      return journal

    case 'inproceedings':
      return booktitle

    case 'book':
      return publisher

    case 'mastersthesis':
      return school

    case 'phdthesis':
      return school

    case 'techreport':
      return institution

    case 'conference':
      return booktitle

    case 'proceedings':
      return publisher
  }
}
