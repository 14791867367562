/* eslint-disable */
import { FormHelperText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Select from '../select/Select'
import TopLabel from '../topLabel/TopLabel'

const days = Array.from({ length: 31 }).map((_, index) => {
  const value = ('' + (index + 1)).substr(-2)
  return {
    value,
    label: value,
  }
})

const months = Array.from({ length: 12 }).map((_, index) => {
  const value = ('' + (index + 1)).substr(-2)
  return {
    value,
    label: value,
  }
})

const currentYear = new Date().getFullYear()

const years = Array.from({ length: currentYear - 1970 + 1 }).map((_, index) => {
  const value = (currentYear - index).toString()
  return {
    value,
    label: value,
  }
})

interface Props {
  day: any
  month: any
  year: any
  setFieldValue: any
  dateMessageError: string
  submitted: number
}

const TimePicker: React.FC<Props> = ({
  day,
  month,
  year,
  setFieldValue,
  dateMessageError,
  submitted,
}) => {
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    // Mỗi khi bấm nút submit sẽ show error
    if (submitted) setShowError(true)
  }, [submitted])

  const emptyError = showError && (!day || !month || !year) ? 'Thời gian không được để trống.' : ''

  const messageError = emptyError || dateMessageError

  const error = showError && !!messageError

  return (
    <div className="mb-2">
      <TopLabel label="Thời gian" required />
      <div className="ModalCreateUpdate-row">
        <div className="ModalCreateUpdate-col-4">
          <Select
            data={days}
            border
            selected={day}
            setSelected={(s) => setFieldValue('day', s)}
            forceError={error}
            placeholder="Ngày"
            fullWidth
            maxHeight={200}
            onOpen={() => setShowError(false)}
            onClose={(hasValue) => setShowError(!hasValue || !!(month && year))}
          />
        </div>
        <div className="ModalCreateUpdate-col-4">
          <Select
            data={months}
            border
            selected={month}
            setSelected={(s) => setFieldValue('month', s)}
            forceError={error}
            placeholder="Tháng"
            fullWidth
            maxHeight={200}
            onOpen={() => setShowError(false)}
            onClose={(hasValue) => setShowError(!hasValue || !!(day && year))}
          />
        </div>
        <div className="ModalCreateUpdate-col-4">
          <Select
            data={years}
            border
            selected={year}
            setSelected={(s) => setFieldValue('year', s)}
            forceError={error}
            placeholder="Năm"
            fullWidth
            maxHeight={200}
            onOpen={() => setShowError(false)}
            onClose={(hasValue) => setShowError(!hasValue || !!(day && month))}
          />
        </div>
      </div>
      {error && <FormHelperText error>{messageError}</FormHelperText>}
    </div>
  )
}

export default TimePicker
