import { Container } from '@mui/material'
import React from 'react'
import { LaboratoryType } from 'types'
import ButtonArrow from '../button/ButtonArrow'
import LaboratoryItem from '../laboratoryItem/LaboratoryItem'
import Title from '../title/Title'
import Slider from '../slider/Slider'

const Arrow = <ButtonArrow extraClass="DepartmentLaboratories-arrow disabled" outline hoverAccent />

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: false,
  rows: 2,
  slidesPerRow: 1,
  responsive: [
    {
      breakpoint: 860,
      settings: { dots: true, rows: 1, infinite: true },
    },
  ],
}

interface Props {
  laboratory_systems?: LaboratoryType[]
}

const DepartmentLaboratories: React.FC<Props> = ({ laboratory_systems }) => {
  if (!laboratory_systems?.length) return null

  return (
    <div className="DepartmentLaboratories">
      <Container>
        <Title>Hệ thống phòng thí nghiệm</Title>
      </Container>
      <div className="DepartmentLaboratories-content">
        <div className="background">
          <Container className="DepartmentLaboratories-container d-f">
            <div className="left" />
            <div className="right py-80 py-80-24">
              <Slider
                dark
                className="DepartmentLaboratories-slider"
                prevArrow={Arrow}
                nextArrow={Arrow}
                {...settings}
              >
                {laboratory_systems?.map((item, index) => (
                  <LaboratoryItem key={index} {...item} />
                ))}
              </Slider>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default DepartmentLaboratories
