/* eslint-disable */
import { Modal } from 'components'
import React, { useState } from 'react'
import { randomId } from 'utils'
import Button from '../button/Button'
import { Input } from '../input'
import NoItems from '../noItems/NoItems'
import Title from '../title/Title'
import Footer from './Footer'
import useHistorySearch from './useHistorySearch'

interface Props {
  show: boolean
  onClose: () => void
}

const ModalSearch: React.FC<Props> = ({ show, onClose }) => {
  const [value, setValue] = useState('')

  const { historySearch, handleSearch, handleRemoveHistory } = useHistorySearch()

  /**
   * Handle search
   *
   * @param keyword
   */
  const handleSearchInput = (keyword: string) => {
    onClose()
    handleSearch(keyword)
  }

  const handleSubmitSearch = (e: React.FormEvent) => {
    e.preventDefault()
    if (!value.trim()) return
    handleSearchInput(value)
  }

  return (
    <Modal show={show} size="sm" onBackdropClick={onClose} fullScreen>
      <div className="ModalSearch-header d-f ai-c jc-sb">
        <form className="w-100" onSubmit={handleSubmitSearch}>
          <Input
            autoFocus
            name={randomId()}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Tìm kiếm"
            onRemove={() => setValue('')}
            maxLength={200}
            size="small"
            fullWidth
            blurWhenEnter
            required
          />
        </form>
        <div className="ml-1">
          <Button colorType="none" onClick={onClose}>
            Thoát
          </Button>
        </div>
      </div>
      <div className="ModalSearch-body">
        <Title noPadding>Lịch sử tìm kiếm</Title>
        {historySearch.length === 0 ? (
          <>
            <NoItems contentFiltered="Chưa có lịch sử tìm kiếm" filtered={true} />
            <div style={{ margin: '0 -16px' }}>
              <Footer />
            </div>
          </>
        ) : (
          <div style={{ paddingBottom: 48 }}>
            {historySearch.map((item) => (
              <div
                key={item}
                className="HistorySearch-item mobile"
                onClick={() => handleSearchInput(item)}
              >
                <div className="d-f ai-c">
                  <i className="icon-history" />
                  <span>{item}</span>
                </div>
                <i className="icon-close" onClick={(e) => handleRemoveHistory(e, item)} />
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ModalSearch
