/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import Modal from '../../modal/Modal'
import Button from '../button/Button'
import Input from '../input/Input'
import ModalButtonCancel from '../../modal/ModalButtonCancel'
import { ResearchActivityType } from 'types'
import { Form, FormikProvider, useFormik } from 'formik'
import { DialogActions } from '@mui/material'
import TopLabel from '../topLabel/TopLabel'
import { validateTypingPreventDoubleSpace } from 'utils'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { useModalConfirm } from 'store/modalConfirm'
import moment from 'moment'
import { FORMAT_DATE } from 'configs/constants'
import TimePicker from './TimePicker'
import { UploadMultipleFiles } from 'components/admin'

const convertDateOfBirth = (day: string, month: string, year: string) =>
  day ? `${day}/${month}/${year}` : undefined

const isOk = (value: any) => {
  if (!value) return false
  value = Number(value)
  if (isNaN(value)) return false
  return true
}

const schema = Yup.object().shape({
  title: Yup.string().trim().required('Tên hoạt động không được để trống.'),
  description: Yup.string().trim().required('Mô tả không được để trống.'),
})

interface Props {
  show: boolean
  type: 'create' | 'update' | string
  initialValues: ResearchActivityType
  onClose: any
  onSuccess: any
}

const ModalResearchActivity: React.FC<Props> = ({
  show,
  onClose,
  onSuccess,
  type,
  initialValues,
}) => {
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const isUpdate = type === 'update'
  const { requestApi } = useApis()

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(0)

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: ({
      id,
      day,
      month,
      year,
      title,
      description,
      attached_files: old_attached_files,
    }) => {
      setLoading(true)

      title = title.trim()
      description = description.trim()
      const attached_files = old_attached_files?.map((i) => i.file)

      requestApi(
        isUpdate ? 'put' : 'post',
        apiUrls.researchActivities(id),
        {
          title,
          description,
          time: moment(convertDateOfBirth(day, month, year), FORMAT_DATE).unix(),
          attached_files,
        },
        ({ status, text, id: resId }) => {
          setLoading(false)
          if (status) {
            onClose()
            onSuccess()
          } else {
            const notExists = resId === '186'

            const close = notExists
              ? () => {
                  hideModalConfirm()
                  onClose()
                }
              : hideModalConfirm

            showModalConfirm({
              content: text,
              confirm: { action: close },
              close: close,
            })
          }
        }
      )
    },
  })

  const {
    getFieldProps,
    handleChange,
    values,
    validateForm,
    dirty,
    resetForm,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
  } = formik

  useEffect(() => {
    if (!show) return

    setSubmitted(0)
    resetForm()
    setTimeout(validateForm, 10)
  }, [show])

  const { day, month, year, attached_files } = values

  const { dateError, dateMessageError } = useMemo(() => {
    if (isOk(day) && isOk(month) && isOk(year)) {
      const date = moment(convertDateOfBirth(day, month, year), FORMAT_DATE)
      const isValid = date.isValid()
      if (isValid) {
        const tomorrow = moment().endOf('day')
        const pastInvalid = date >= tomorrow

        return {
          dateError: pastInvalid,
          dateMessageError: pastInvalid ? 'Thời gian phải ở trong quá khứ.' : '',
        }
      }
      return {
        dateError: true,
        dateMessageError: 'Thời gian không hợp lệ, vui lòng chọn ngày chính xác.',
      }
    }
    return { dateError: true, dateMessageError: '' }
  }, [day, month, year])

  return (
    <Modal
      className="ModalCreateUpdate marginTitleBig"
      title={`${isUpdate ? 'Sửa' : 'Tạo'} hoạt động nghiên cứu khoa học`}
      show={show}
      size="sm"
      close={onClose}
      dirty={dirty}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="mb-2">
            <TopLabel label="Tên hoạt động" required />
            <Input
              placeholder="Tên hoạt động"
              maxLength={200}
              {...getFieldProps('title')}
              error={errors.title}
              onChange={(e) => {
                if (validateTypingPreventDoubleSpace(e)) handleChange(e)
              }}
              errorEmpty={!!submitted || touched.title}
            />
          </div>
          <TimePicker
            day={day}
            month={month}
            year={year}
            setFieldValue={setFieldValue}
            dateMessageError={dateMessageError}
            submitted={submitted}
          />
          <TopLabel label={'File đính kèm'} />
          <UploadMultipleFiles
            prefix="research_activity"
            fileUrl={attached_files}
            onSuccess={(url) => setFieldValue('attached_files', url)}
            onRemove={(url) => setFieldValue('attached_files', url)}
            onClick={(url) => window.open(url)}
            errorEmpty={isSubmitting}
            style={{ paddingBottom: 20 }}
            isUpdate={isUpdate}
          />
          <div className="mb-3">
            <TopLabel label="Mô tả" required />
            <Input
              placeholder="Mô tả"
              maxLength={5000}
              {...getFieldProps('description')}
              error={errors.description}
              errorEmpty={!!submitted || touched.description}
              rows={5}
              multiline
            />
          </div>
          <DialogActions>
            <ModalButtonCancel onClick={onClose}>Hủy</ModalButtonCancel>
            <Button
              type="submit"
              variant="contained"
              onClick={(e) => {
                if (dateError) e.preventDefault()
                setSubmitted(submitted + 1)
              }}
              loading={loading}
            >
              {isUpdate ? 'Lưu' : 'Tạo mới'}
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Modal>
  )
}

export default ModalResearchActivity
