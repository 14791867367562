/* eslint-disable */
import { Container, Typography } from '@mui/material'
import { Checkbox } from 'components/admin'
import { apiUrls } from 'configs/apis'
import React, { useCallback, useEffect, useState } from 'react'
import { useApis } from 'services/api'
import { useAuth } from 'store/auth'
import { useModalConfirm } from 'store/modalConfirm'
import { ItemType, MainResearchType } from 'types'
import Title from '../title/Title'

interface Props {
  main_researches: MainResearchType[]
  isProfile?: boolean
}

const UserDetailResearchDirections: React.FC<Props> = ({ main_researches, isProfile }) => {
  const { apiPut, apiGet } = useApis()
  const { avatar_url, full_name, degree, academic_rank, biography, updateAuth } = useAuth()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const [data, setData] = useState<
    ItemType<
      {
        id: string
        name: string
      }[]
    >
  >({ loading: true, item: [] })

  const setLoading = (loading: boolean) => setData((prev) => ({ ...prev, loading }))

  const getMainResearchs = useCallback(() => {
    setLoading(true)
    apiGet(apiUrls.mainResearch(), {}, ({ status, data, text }) => {
      if (status) {
        setData({ loading: false, item: data.items })
      }
    })
  }, [])

  const handleUpdate = (new_main_researches: MainResearchType[]) => {
    apiPut(
      apiUrls.profile(),
      {
        full_name,
        biography: biography || '',
        academic_rank: academic_rank || undefined,
        degree: degree || undefined,
        main_research_ids: new_main_researches.map((item, index) => ({
          id: item.main_research.id,
          index,
        })),
        avatar_url: avatar_url || undefined,
        key: 3,
        main_research_old_ids: main_researches.map((i) => i.main_research.id),
      },
      ({ status, id: resId, data, text }) => {
        setLoading(false)
        if (status) {
          updateAuth({ main_researches: new_main_researches })
        } else if (resId === '229') {
          showModalConfirm({
            content: text,
            confirm: {
              action: () => {
                confirmMainResearchNotfound(data)
              },
            },
            close: () => {
              confirmMainResearchNotfound(data)
            },
          })
        }
      }
    )
  }
  const confirmMainResearchNotfound = (idsNotFound: string[]) => {
    const new_main_researches = main_researches.filter((item) =>
      idsNotFound.some((i) => i !== item.main_research.id)
    )
    updateAuth({ main_researches: new_main_researches })
    hideModalConfirm()
    getMainResearchs()
  }

  const handleCheckItem = ({ name, id }: { name: string; id: string }) => {
    if (!!main_researches.find((item) => id == item.main_research.id)) {
      const newMain_researches = main_researches.filter((item) => item.main_research.id != id)
      handleUpdate(newMain_researches)
    } else {
      const newMain_researches = [
        ...main_researches,
        {
          main_research: {
            name,
            id,
          },
        },
      ]
      handleUpdate(newMain_researches)
    }
  }

  useEffect(() => {
    getMainResearchs()
  }, [])

  // an component khi admin chua tao item nao
  if ((!main_researches?.length && !isProfile) || data?.item?.length == 0) return null

  return (
    <div className="UserDetailResearchDirections">
      <Container>
        <div className="d-f jc-sb">
          <Title noPadding>Các hướng nghiên cứu chính</Title>
        </div>
        {isProfile ? (
          <div className="row row-20">
            {data?.item?.map(({ name, id }) => (
              <div className="d-f ai-s col-4" key={id} style={{ marginBottom: 0 }}>
                <Checkbox
                  // label={name}
                  checked={!!main_researches.find((item) => id == item.main_research.id)}
                  onChange={() => handleCheckItem({ name, id })}
                />
                <Typography
                  className="typo-16-4 typo-16-14"
                  sx={{
                    padding: '8px 0',
                  }}
                >
                  {name}
                </Typography>
              </div>
            ))}
          </div>
        ) : (
          <div className="typo-16-4 typo-16-14">
            {main_researches?.map((item) => item.main_research.name)?.join(', ')}
          </div>
        )}
      </Container>
    </div>
  )
}

export default UserDetailResearchDirections
