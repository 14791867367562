/* eslint-disable */
import { mappingNewsColor, RESEARCH_TYPES, ROUTES } from 'configs/constants'
import React, { useMemo } from 'react'
import { useIsMobile } from 'store/mobile'
import { ResearchInfoType } from 'types'
import { formatDate } from 'utils'
import Link from '../links/Link'
import './index.scss'

const { NATIONAL_LEVEL, MINISTRY_LEVEL, COOPERATION, COOPERATION_INTERNATIONAL, NAFOSTED } =
  RESEARCH_TYPES

const mappingTypeDesktop = {
  [NATIONAL_LEVEL]: 'Đề tài cấp quốc gia',
  [NAFOSTED]: 'NVNCCB Quỹ Nafosted',
  [COOPERATION]: 'ĐTHTNC theo nghị định thư',
  [MINISTRY_LEVEL]: 'Đề tài cấp bộ',
  [COOPERATION_INTERNATIONAL]: 'Đề tài hợp tác quốc tế',
}

const mappingTypeMobile = {
  [NATIONAL_LEVEL]: 'ĐT cấp quốc gia',
  [NAFOSTED]: 'NVNCCB Quỹ Nafosted',
  [COOPERATION]: 'ĐTHTNCT nghị định thư',
  [MINISTRY_LEVEL]: 'ĐT cấp bộ',
  [COOPERATION_INTERNATIONAL]: 'ĐTHT quốc tế',
}

const NewsItem: React.FC<ResearchInfoType> = ({
  id,
  topic_research,
  avatar_url,
  content_to_summary,
  title,
  created_date,
}) => {
  const isMobile = useIsMobile()

  const mappingType = useMemo(() => (isMobile ? mappingTypeMobile : mappingTypeDesktop), [isMobile])

  const newsTypeLabel = (
    <div className="d-f">
      <div style={mappingNewsColor[1]} className="newsTypeLabel">
        {mappingType[topic_research]}
      </div>
    </div>
  )

  const date = (
    <div className="d-f ai-c date-wrapper">
      {newsTypeLabel}
      <div className="date d-f ai-c">
        <i className="icon-calendar1 neutral-icon" />
        {formatDate(created_date)}
      </div>
    </div>
  )

  return (
    <Link to={`/${ROUTES.ROUTE_RESEARCH_INFO}/${id}`} className="NewsItem d-f blockType-4 research">
      <div
        className="banner background-image"
        style={{ backgroundImage: `url("${avatar_url}")` }}
      ></div>
      <div className="body">
        {date}
        <p className={`title typo-20-6 max-line${isMobile ? '' : '-1'}`}>{title}</p>
        <p className={`content max-line${isMobile ? '-1' : ''}`}>{content_to_summary}</p>
      </div>
    </Link>
  )
}

export default NewsItem
