/* eslint-disable */
import {
  Checkbox,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Popover,
  TextField,
} from '@mui/material'
import { ALL } from 'configs/constants'
import { isEqual } from 'lodash'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import SimpleBar from 'simplebar-react'
import { SelectType } from 'types'
import { formatCompare2 } from 'utils'
import Input from '../input/Input'
import './index.scss'
import 'simplebar/dist/simplebar.min.css'
import { Waypoint } from 'react-waypoint'

interface Props {
  className?: string
  data?: SelectType[]
  selected?: any
  setSelected?: (selectedId: any, selectedItem?: any) => void
  label?: string
  style?: CSSProperties
  disabled?: boolean
  hideSelected?: boolean
  placeholder?: string
  checkbox?: {
    name: string
    selected: any[]
    onChange: (value: any[]) => void
    areaStyle?: CSSProperties
  }
  maxHeight?: any
  fullWidth?: boolean
  popupSearch?: {
    maxLength?: number
    timeout?: number
    searchByLabel?: boolean
    labelNoItems?: string
    labelTotallyNoItems?: string
    placeholder?: string
    onSearch?: (keyword: string) => void
    onChange?: (keyword: string) => void
    searchFrontend?: boolean
    margin?: boolean
  }
  sort?: boolean
  noSortFirst?: boolean
  error?: any
  errorEmpty?: boolean
  validateForm?: any
  removable?: boolean
  border?: boolean
  noWhireSpace?: boolean
  onOpen?: () => void
  onClose?: (hasValue: boolean) => void
  forceError?: boolean
  onIncreasePage?: () => void
  showLoadmore?: boolean
  [key: string]: any
}

const Select: React.FC<Props> = ({
  className = '',
  data = [],
  selected,
  setSelected,
  style = {},
  disabled,
  hideSelected = true,
  maxHeight,
  fullWidth,
  popupSearch,
  placeholder,
  sort,
  noSortFirst,
  error,
  errorEmpty,
  validateForm,
  removable,
  checkbox,
  border,
  noWhireSpace,
  onOpen,
  onClose,
  forceError,
  onIncreasePage,
  showLoadmore,
  ...rest
}) => {
  const anchorRef = useRef<HTMLInputElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const touched = useRef(false)
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')

  const timeoutSearch = useRef<any>()
  const readOnly = true

  const {
    name: checkboxName,
    selected: checkboxSelected,
    onChange: checkboxOnChange,
    areaStyle: checkboxAreaStyle = {},
  } = checkbox || { name: '', onChange: () => {}, selected: [] }
  const checkboxSelectedIds = checkboxSelected?.map((item) => item.id)

  const {
    timeout,
    labelNoItems = 'Không có kết quả',
    labelTotallyNoItems = 'Không có kết quả',
    placeholder: searchPlaceholder = 'Nhập từ khoá',
    onSearch,
    onChange,
    searchFrontend,
    margin,
    maxLength: searchMaxLength = 200,
  } = popupSearch || {}

  const totallyNoItems = data.length === 0

  let newData = data
  const value = data.find((item) => isEqual(item.value, selected))?.label || ''
  let noItems = true

  if (searchFrontend && search) {
    newData = data.map((item) => {
      const value1 = formatCompare2(item.label)
      const value2 = formatCompare2(search)

      const hide = !value1.includes(value2) || item.hide
      if (!hide) noItems = false
      return { ...item, hide }
    })
  } else noItems = false

  if (sort) {
    let first: any[] = []
    if (noSortFirst) {
      first = newData.slice(0, 1)
      newData = newData.slice(1)
    }
    newData = newData.sort((a, b) => a.label.localeCompare(b.label))
    newData = [...first, ...newData]
  }

  // Open the menu
  const handleOpen = () => {
    setOpen(true)
    onOpen?.()
  }

  // Close the menu
  const handleClose = (hasValue?: boolean) => {
    touched.current = true
    setOpen(false)
    if (!hasValue) validateForm?.()

    setTimeout(() => inputRef.current?.blur(), 0)
  }

  // Search when click item, enter or after 1 second
  const handleSearch = (newValue?: string) => {
    if (!newValue) newValue = value || ''
    onSearch?.(newValue)
  }

  // When click item, set selected item, set value in textbox and close menu
  const handleClickItem = (item?: SelectType) => {
    setSelected?.(item?.value, item)
    handleSearch(item?.value)
    handleClose(true)
    onClose?.(true)
  }

  useEffect(() => {
    if (open && search) setSearch('')
  }, [open])

  useEffect(() => {
    onChange?.(search)

    if (!popupSearch) return

    if (timeout === 0) {
      handleSearch?.(value)
      return
    }

    // If user not typing after 700ms => search
    timeoutSearch.current = setTimeout(handleSearch, timeout || 700)

    return () => clearTimeout(timeoutSearch.current)
  }, [search])

  const renderInputSearch = () => {
    if (!popupSearch || totallyNoItems) return null

    return (
      <div className="SelectPaper-search">
        <Input
          autoFocus
          placeholder={searchPlaceholder}
          icon="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          maxLength={searchMaxLength}
        />
      </div>
    )
  }

  useEffect(() => {
    if (errorEmpty) touched.current = true
  }, [errorEmpty])

  const errorText =
    (checkbox ? checkboxSelected.length === 0 : !value) && !open && touched.current
      ? error
      : undefined

  const errorTextField = errorText || forceError

  const isCheckAll = data.length === checkboxSelected.length + 1

  // When check all => all item will be selected
  const handleCheckAll = () => {
    if (isCheckAll) checkboxOnChange([])
    else
      checkboxOnChange(
        data
          .filter((item) => item.value !== ALL)
          .map(({ value, label }) => ({ id: value, [checkboxName]: label }))
      )
  }

  const selectItemClassname = `Select-item${border ? ' border' : ''}${
    noWhireSpace ? ' noWhireSpace' : ''
  } `

  return (
    <div
      className={`Select ${className}${border ? ' Select-border' : ''} ${
        readOnly ? 'Select-readOnly' : ''
      }${fullWidth ? ' w-100' : ''}`}
      style={style}
    >
      <TextField
        {...rest}
        ref={anchorRef}
        inputRef={inputRef}
        onClick={disabled ? undefined : handleOpen}
        value={value}
        disabled={disabled}
        size="small"
        fullWidth={fullWidth}
        placeholder={placeholder}
        helperText={errorTextField}
        error={!!errorTextField}
        InputProps={{
          className: open ? 'Mui-focused' : undefined,
          readOnly,
          endAdornment: (
            <InputAdornment position="end" style={{ opacity: disabled ? 0.5 : 1 }}>
              <i className={`icon-arrow-ios-down${open ? ' rotate-180' : ''}`} />
            </InputAdornment>
          ),
        }}
      />

      <Popover
        anchorOrigin={{
          vertical: popupSearch && !totallyNoItems ? 'top' : 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: anchorRef.current?.getBoundingClientRect().width || 0,
            marginTop: margin ? 7 : undefined,
          },
        }}
        disableAutoFocus
        open={open}
        onClose={() => {
          handleClose()
          onClose?.(!!selected)
        }}
        anchorEl={anchorRef.current}
      >
        {renderInputSearch()}
        {!!(removable && selected?.toString()) && (
          <MenuItem className={`${selectItemClassname} remove`} onClick={() => handleClickItem()}>
            <div>Bỏ chọn</div>
          </MenuItem>
        )}
        {(noItems || totallyNoItems) && (
          <div
            className={selectItemClassname}
            style={{
              padding: totallyNoItems ? '8px 16px' : '0 16px',
              height: totallyNoItems ? 36 : 28,
              color: 'var(--cl-gray)',
            }}
          >
            {totallyNoItems ? labelTotallyNoItems : labelNoItems}
          </div>
        )}
        <SimpleBar className="SelectPaper-paper" style={{ maxHeight }} autoHide={false}>
          {!!checkbox
            ? newData.map(({ label, value, hide }) => (
                <div
                  className={selectItemClassname}
                  key={value}
                  style={{
                    display:
                      (hideSelected && value === selected) || hide
                        ? 'none'
                        : noWhireSpace
                        ? 'flex'
                        : undefined,
                    alignItems: noWhireSpace ? 'center' : undefined,
                    height: '100%',
                  }}
                >
                  <Checkbox
                    onChange={() =>
                      value === ALL
                        ? handleCheckAll()
                        : checkboxOnChange(
                            checkboxSelected.some((item1) => item1.id === value)
                              ? checkboxSelected.filter((i) => i.id !== value)
                              : [...(checkboxSelected || []), { id: value, [checkboxName]: label }]
                          )
                    }
                    checked={value === ALL ? isCheckAll : checkboxSelectedIds.includes(value)}
                    style={{ height: 36, width: 36, marginLeft: 8 }}
                  />
                  {label}
                </div>
              ))
            : newData.map((item) => (
                <MenuItem
                  className={`${selectItemClassname}${selected === item.value ? ' active' : ''}`}
                  key={item.value}
                  disabled={item.disabled}
                  onClick={() => handleClickItem(item)}
                  style={{
                    display:
                      (hideSelected && item.value === selected) || item.hide ? 'none' : undefined,
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {item.label}
                  </div>
                </MenuItem>
              ))}

          {showLoadmore && !!newData.length && (
            <MenuItem>
              <div className="d-f jc-c w-100">
                <Waypoint
                  onEnter={() => {
                    onIncreasePage?.()
                  }}
                >
                  <CircularProgress size={20} color="inherit" />
                </Waypoint>
              </div>
            </MenuItem>
          )}
        </SimpleBar>
      </Popover>
    </div>
  )
}

export default Select
