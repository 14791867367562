import React from 'react'
import {
  NavLink as NavLinkBase,
  NavLinkProps,
  useHistory as useHistoryBase,
} from 'react-router-dom'
import { useHistory } from 'store/confirmRedirect'

const NavLink: React.FC<NavLinkProps> = ({ to, onClick, ...rest }: any) => {
  const { allow, goTo } = useHistory()
  const history = useHistoryBase()

  const handleClick = (e: any) => {
    if (allow) {
      onClick?.(e)
      return
    }
    e.preventDefault()

    if (!to) return

    goTo(() => {
      onClick?.(e)
      history.push(to)
    })
  }

  return <NavLinkBase to={to} onClick={handleClick} {...rest} />
}

export default NavLink
