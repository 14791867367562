import React from 'react'
import { PartnerType } from 'types'
import Link from '../links/Link'

import './index.scss'

interface Props {
  partners: PartnerType[]
}

const Partners: React.FC<Props> = ({ partners }) => {
  return (
    <div className="Partners">
      {partners.map(({ id, logo_url, link }) => (
        <div key={id} className="col">
          <Link
            to={{ pathname: link }}
            onClick={(e) => {
              if (!link) e.preventDefault()
            }}
            target="_blank"
          >
            <img alt="" src={logo_url} />
          </Link>
        </div>
      ))}
    </div>
  )
}

export default Partners
