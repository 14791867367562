import { UPDATE_BREADCRUMB } from 'store/actionTypes'
import { ActionType } from 'types'

const initialState = {
  breadcrumb: [],
  banner: null,
  noMargin: false,
}

const breadcrumbReducer = (state = initialState, action: ActionType) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_BREADCRUMB:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}

export default breadcrumbReducer
