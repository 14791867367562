"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.undo = exports.save = exports.retrieveVersion = exports.retrieveLastVersion = exports.currentVersion = void 0;

var _index = _interopRequireDefault(require("./index.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const currentVersion = function () {
  return this.log.length;
};

exports.currentVersion = currentVersion;

const retrieveVersion = function (versnum = 1) {
  if (versnum <= 0 || versnum > this.currentVersion()) {
    return null;
  } else {
    const [data, options] = this.log[versnum - 1];
    const image = new _index.default(JSON.parse(data), JSON.parse(options));
    image.log = this.log.slice(0, versnum);
    return image;
  }
};

exports.retrieveVersion = retrieveVersion;

const undo = function (number = 1) {
  return this.retrieveVersion(this.currentVersion() - number);
};

exports.undo = undo;

const retrieveLastVersion = function () {
  return this.retrieveVersion(this.currentVersion());
};

exports.retrieveLastVersion = retrieveLastVersion;

const save = function () {
  this.log.push([JSON.stringify(this.data), JSON.stringify(this._options)]);
  return this;
};

exports.save = save;