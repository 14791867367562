/* eslint-disable */
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useBreadcrumb } from 'store/breadcrumb'
import { useRefresh } from 'utils'
import { listNewsTypes, NEWS_TYPES, USER_PAGE_SIZE } from 'configs/constants'
import { useApis } from 'services/api'
import NewsItem from '../newsItem/NewsItem'
import MoreNews from '../moreNews/MoreNews'
import NoItems from '../noItems/NoItems'
import OfferCooperate from '../offerCooperate/OfferCooperate'
import Select from '../select/Select'
import Title from '../title/Title'
import Loading from '../../loading/Loading'
import { Waypoint } from 'react-waypoint'
import { ItemsType, NewsType } from 'types'
import { useIsMobile } from 'store/mobile'

import './index.scss'

// Quy định cách hiển thị giao diện như design, 1 bài viết, 2 bài ...
const mappingBlockDesktopTypes: any = {
  1: [1],
  2: [2, 2],
  3: [5, 4, 4],
}

const mappingBlockMobileTypes: any = {
  1: [5],
  2: [5, 4],
  3: [5, 4, 4],
}

const { ALL_TYPES } = NEWS_TYPES

interface Props {
  title: string
  titleNoItems: string
  breadcrumb: any
  banner: any
  apiUrl: string
  isPartner?: boolean
}

const NewsWrapper: React.FC<Props> = ({
  title,
  breadcrumb,
  banner,
  apiUrl,
  isPartner,
  titleNoItems,
}) => {
  const refresh = useRefresh()
  const isMobile = useIsMobile()
  const { showBreadcrumb } = useBreadcrumb()
  const { apiGet } = useApis()

  const page = useRef(1)
  const [data, setData] = useState<ItemsType<NewsType>>({ loading: true, items: [], total: 0 })
  const [type, setType] = useState(ALL_TYPES)
  const typeRef = useRef(type)

  useLayoutEffect(() => {
    showBreadcrumb([breadcrumb], banner)
  }, [])

  // Get tất cả items từ server
  const getData = useCallback((type: any) => {
    const currentPage = page.current
    setData((prev) => ({ ...prev, loading: true, items: currentPage === 1 ? [] : prev.items }))

    apiGet(
      apiUrl,
      {
        page: page.current,
        page_size: USER_PAGE_SIZE,
        type: type === ALL_TYPES ? undefined : type,
      },
      ({ status, data }) => {
        if (status)
          setData((prev) => ({
            ...data,
            loading: false,
            items: currentPage === 1 ? data.items : [...prev.items, ...data.items],
          }))
      }
    )
  }, [])

  const { loading, items, total } = data
  const length = items.length
  const full = length >= (total || 0)

  // Loadmore 12 phần tử khi kéo đến cuối trang
  const handleLoadmore = () => {
    if (loading || full) return
    page.current += 1
    getData(type)
  }

  // Mỗi khi filter (thay đổi type dropdown) sẽ đưa page về 1 và get lại danh sách items
  useEffect(() => {
    page.current = 1
    typeRef.current = type
    getData(type)
  }, [type])

  // Reload type khi reload trang
  useEffect(() => {
    if (typeRef.current === ALL_TYPES) {
      page.current = 1
      getData(type)
    } else setType(ALL_TYPES)
  }, [refresh])

  const mappingBlockTypes = isMobile ? mappingBlockMobileTypes : mappingBlockDesktopTypes

  const blockTypes = mappingBlockTypes[length > 3 ? 3 : length]

  let firstItem = (
    <NewsItem {...(items?.[0] || {})} blockType={blockTypes?.[0]} goToPartner={isPartner} />
  )

  if (length === 1 && isMobile) firstItem = <div className="col">{firstItem}</div>

  return (
    <div className="ScreenItems">
      <Title noPadding>{title}</Title>
      {!isPartner && (length !== 0 || type !== ALL_TYPES) && (
        <Select
          className="mb-40 mb-40-24"
          data={listNewsTypes}
          selected={type}
          setSelected={setType}
          style={{ width: 194 }}
          sx={{
            '& fieldset': { border: 'none' },
          }}
        />
      )}
      {items.length === 0 ? (
        loading ? (
          <Loading />
        ) : (
          <NoItems
            filtered={type !== ALL_TYPES}
            title={titleNoItems}
            content="Bạn có thể truy cập vào Trang chủ hoặc sử dụng chức năng tìm kiếm để tìm kiếm"
            contentFiltered="Không có bài viết nào thuộc bộ lọc bạn đã chọn"
          />
        )
      ) : (
        <>
          <div className="ScreenItems-row">
            {length > 0 && (
              <>
                {length === 1 ? (
                  firstItem
                ) : (
                  <>
                    <div className="col">{firstItem}</div>
                    <div className="col">
                      {items.slice(1, 3).map((item: any, index: any) => (
                        <NewsItem
                          key={item.id}
                          {...item}
                          blockType={blockTypes[index + 1]}
                          goToPartner={isPartner}
                        />
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          {length > 3 && (
            <>
              <div className="ScreenItems-row">
                {items.slice(3).map((item: any) => (
                  <div key={item.id} className={isPartner ? 'col col-12' : 'col col-4'}>
                    <NewsItem
                      {...item}
                      blockType={isPartner ? 4 : isMobile ? 2 : 6}
                      isPartner={isPartner}
                      goToPartner={isPartner}
                    />
                  </div>
                ))}
              </div>
              {!full && (
                <div>
                  {loading && <Loading small />}
                  <Waypoint onEnter={handleLoadmore} />
                  <div style={{ height: 6 }}></div>
                </div>
              )}
            </>
          )}
          {isPartner && (
            <>
              <OfferCooperate />
              <MoreNews />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default NewsWrapper
