import { useState } from 'react'
import Slider from 'react-slick'
import { isEmpty } from 'lodash'
import { useHome } from 'store/home'
import { useHistory } from 'store/confirmRedirect'
import Button from '../button/Button'
import { Container } from '@mui/material'
import { BANNER_COLORS, NEWS_TYPES, ROUTES, textColors } from 'configs/constants'
import { getBannerPosition, toStr } from 'utils'
import { useIsMobile } from 'store/mobile'

const { PRIMARY, SEMANTIC, WHITE } = BANNER_COLORS
const { ROUTE_NEWS, ROUTE_PARTNER_ACTIVITIES } = ROUTES
const { COOPERATIVE_ACTIVITY, COOPERATIVE_NEWS } = NEWS_TYPES

const mappingButtonColor: any = {
  [PRIMARY]: ' primary',
  [SEMANTIC]: '',
  [WHITE]: ' white',
}

const settings = {
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
}

const HomeSlider: React.FC = () => {
  const { banners = [] } = useHome()
  const { push } = useHistory()
  const isMobile = useIsMobile()

  const [isDragging, setIsDragging] = useState(false)

  /**
   * Handle view banner detail
   *
   * @param bannerUrl Banner url
   */
  const handleViewDetail = (e: any, id: string, type: any) => {
    if (isDragging) {
      e.stopPropagation()
      return
    }
    // baseRoute dựa theo type, nếu type là hợp tác => hợp tác,
    // còn lại là tin tức sự kiện
    const baseRoute =
      type === COOPERATIVE_ACTIVITY || type === COOPERATIVE_NEWS
        ? ROUTE_PARTNER_ACTIVITIES
        : ROUTE_NEWS

    push(`/${baseRoute}/${id}`)
  }

  if (isEmpty(banners)) return null

  return (
    <Slider
      beforeChange={() => setIsDragging(true)}
      afterChange={() => setIsDragging(false)}
      className="HomeSlider-container"
      pauseOnHover={false}
      {...settings}
    >
      {banners.map(({ id, banner, title, content_to_summary, type }: any, index: number) => {
        const { width, right, textColor, buttonColor, textOffset, buttonOffset } =
          getBannerPosition(banner.position)

        const widthStyle = { maxWidth: width }
        const titleContentColor = textColors[textColor].text
        const titleStyle = {
          style: {
            ...widthStyle,
            color: titleContentColor.title,
            paddingRight: right ? 50 : 0,
            marginRight: right ? -50 : 0,
          },
        }
        const contentStyle = {
          style: {
            ...widthStyle,
            color: titleContentColor.content,
            paddingRight: right ? 32 : 0,
            marginRight: right ? -32 : 0,
          },
        }

        return (
          <div
            key={index}
            className="HomeSliderItem-container"
            onClick={(e) => (isMobile ? handleViewDetail(e, id, toStr(type)) : {})}
          >
            <div
              className="HomeSliderItem-mainContainer"
              style={{
                backgroundImage: `${
                  isMobile
                    ? // eslint-disable-next-line
                      'linear-gradient(180deg, rgba(26, 32, 44, 0) 0%, rgba(26, 32, 44, 0.3) 24.48%, rgba(26, 32, 44, 0.7) 100%), '
                    : ''
                  // eslint-disable-next-line
                }url("${banner?.image_url}")`, // linear-gradient(262.09deg, rgba(0, 0, 0, 0) 42.74%, #000000 94.38%),
              }}
            >
              {isMobile ? (
                <div className="HomeSliderItem-mobileContainer">
                  <div className="title max-line-3">{title}</div>
                  <div className="content max-line">{content_to_summary}</div>
                </div>
              ) : (
                <Container
                  className={`HomeSliderItem-mainContainer__inside d-f fd-c ${
                    right ? 'ai-e ta-r' : 'ai-s ta-l'
                  }`}
                  style={{ paddingTop: textOffset }}
                >
                  <div className="title max-line-4" {...titleStyle}>
                    {title}
                  </div>
                  <div className="content max-line-3" {...contentStyle}>
                    {content_to_summary}
                  </div>
                  <div style={{ marginTop: 32, paddingTop: buttonOffset }}>
                    <Button
                      className="viewDetail"
                      colorType={`cta${mappingButtonColor[buttonColor]}` as any}
                      onClick={(e) => handleViewDetail(e, id, toStr(type))}
                      style={{ width: 199 }}
                    >
                      XEM CHI TIẾT
                    </Button>
                  </div>
                </Container>
              )}
            </div>
          </div>
        )
      })}
    </Slider>
  )
}

export default HomeSlider
