import { mappingRegencyForExport } from 'pages/admin/users/constants'
import React from 'react'
import { useAuth } from 'store/auth'
import Button from '../button/Button'

import { mappingAcademicRank, mappingDegree } from 'configs/constants'
import Docxtemplater from 'docxtemplater'
import { saveAs } from 'file-saver'
import PizZip from 'pizzip'
import PizZipUtils from 'pizzip/utils/index.js'
import { Research } from 'types'
import { formatDate } from 'utils'

function loadFile(url: string, callback: (err: Error, data: string) => void) {
  PizZipUtils.getBinaryContent(url, callback)
}

const ExportInfo: React.FC = () => {
  const { full_name, degree, academic_rank, gender, regency, scientific_papers, researches, id } =
    useAuth()

  const getResearchsRenderItem = (researchArray: Research[]) => {
    return (
      researchArray?.map(({ title, start_time, end_time, execution_status }) => ({
        title,
        time: `${formatDate(start_time)}${end_time ? ` - ${formatDate(end_time)}` : ''}`,
        status: execution_status ? 'đã nghiệm thu' : 'chưa nghiệm thu',
      })) || []
    )
  }

  const generateDocumentProfile = () => {
    const researchesManager = researches?.filter(
      (item) => item.manager_user && item.manager_user.id === id
    )
    const researchesSecretary = researches?.filter(
      (item) =>
        (item.secretary_user && item.secretary_user.id === id) ||
        item.members.map((item) => item.id).includes(id || '')
    )

    loadFile('/doc.docx', function (error, content) {
      if (error) {
        throw error
      }
      const zip = new PizZip(content)
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      })
      doc.setData({
        name: full_name,
        sex: gender ? 'Nữ' : 'Nam',
        academic_rank: mappingAcademicRank[academic_rank] || '',
        degree: mappingDegree[degree] || '',
        rank: mappingRegencyForExport[regency] || '',
        paper:
          scientific_papers?.map(({ title, year, journal }, index) => ({
            index: (index += 1),
            title: title || '',
            year: year || '',
            journal: journal || '',
          })) || [],
        researchManager: getResearchsRenderItem(researchesManager || []),
        researchSecretary: getResearchsRenderItem(researchesSecretary || []),
      })
      try {
        doc.render()
      } catch (error) {
        console.log('error')
      }
      const out = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      }) //Output the document using Data-URI
      saveAs(out, 'phu-luc-ly-lich-khoa-hoc.docx')
    })
  }

  return (
    <div className="Profile-editButton">
      <Button
        colorType="default"
        size="small"
        icon="file-upload"
        onClick={generateDocumentProfile}
        style={{ marginLeft: 10 }}
      >
        <span className="text">Export lý lịch khoa học</span>
      </Button>
    </div>
  )
}

export default ExportInfo
