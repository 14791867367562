import {
  defaultBannerPosition,
  LS_HISTORY_SEARCH,
  mappingAcademicRankShort,
  mappingDegreeShort,
  mappingTrainingInfoRoutes,
  MAX_FILENAME_LENGTH,
  ROUTES,
} from 'configs/constants'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthType, BannerPositionType, UserType } from 'types'
import * as Yup from 'yup'

/**
 * Detect user agent is mobile or not
 *
 * @return Boolean
 */
export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

/**
 * Capitalize first letter in word
 * @param string
 * @returns
 */
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Capitalize each first letter of word in sentence
 * @param string
 * @returns
 */
export const capitalizeWords = (string: string) => {
  if (!string) return ''
  return string
    .split(' ')
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .reduce((x, y) => x + ' ' + y)
}

/**
 * Get user from local storage
 */
export const getUserLS = () => {
  const oldUser = localStorage.getItem('user') || '{}'
  let parsedUser
  try {
    parsedUser = JSON.parse(oldUser)
  } catch (e) {}
  return (parsedUser || {}) as AuthType
}

/**
 * Update user to local storage
 * @param user
 */
export const updateUserLS = (user: AuthType) => {
  const oldUser = getUserLS()
  localStorage.setItem('user', JSON.stringify({ ...oldUser, ...user }))
}

/**
 * Remove user from local storage
 * @param user
 */
export const removeUserLS = () => {
  localStorage.removeItem('user')
}

export const formatCompare = (name: string) => {
  if (!name) return ''

  return (
    name
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/đ/g, 'd~')
      // eslint-disable-next-line no-useless-escape
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z `~!@#$%^&*(),.?'":;{}+=|<>_\-\\\/\[\]])/g, '')
  )
}

export const formatCompare2 = (name: string) => {
  if (!name) return ''

  return (
    name
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/đ/g, 'd')
      // eslint-disable-next-line no-useless-escape
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z `~!@#$%^&*(),.?'":;{}+=|<>_\-\\\/\[\]])/g, '')
  )
}

export const formatFilename = (name: string) => {
  return (
    name
      .trim()
      .normalize('NFD')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
      // eslint-disable-next-line no-useless-escape
      .replace(/([\u0300-\u036f]|[^ 0-9a-zA-Z`~!@#$%^&*(),.?'":;{}+=|<>_\-\\\/\[\]])/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/([`~!@#$%^&*()?'":;{}+=|<>_\-\\\/\[\]])/g, ' ')
      .replace(/ +(?= )/g, '')
      .replace(/ /g, '_')
  )
}

export const trimFileName = (fileName: any = '', maxLength?: number) => {
  const maxFileNameLength = maxLength ? maxLength : MAX_FILENAME_LENGTH
  // Normalize NFC on safari => fix bug germany character
  fileName = formatFilename(fileName)
  if (fileName.length > maxFileNameLength) {
    const indexExtension = fileName.lastIndexOf('.')

    // if extension length > 5 => only take 4 characters
    const ext = indexExtension > -1 ? fileName.substr(indexExtension).substr(0, 5) : ''
    const newName = fileName.substr(0, maxFileNameLength - ext.length)
    fileName = newName + ext
  }
  return fileName
}

// Random id with 6 characters
export const randomId = () => Math.random().toString(36).substr(2, 6)

// Check url is a blob
export const isBlob = (url: any = '') => url.includes('blob:')

export const sortBy = (field: string) => {
  return (a: any, b: any) => {
    a = formatCompare(a[field])
    b = formatCompare(b[field])

    return a > b ? 1 : -1
  }
}

// Sinh tên kèm theo học hàm, học vị VD: PGS.TS Nguyễn Khánh
export const createAcademicRankUserWithoutName = (user?: UserType) => {
  if (!user) return ''
  const { academic_rank, degree } = user
  const textAcademicRank = mappingAcademicRankShort[academic_rank] || ''
  const textDegree = mappingDegreeShort[degree] || ''
  return textAcademicRank + (textAcademicRank && textDegree ? '.' : '') + textDegree
}

// Sinh tên kèm theo học hàm, học vị VD: PGS.TS Nguyễn Khánh
export const createAcademicRankUser = (user?: UserType) => {
  if (!user) return ''
  const { full_name } = user
  return createAcademicRankUserWithoutName(user) + ' ' + full_name
}

export const isString = Yup.string().trim().required()
export const isObject = Yup.object().defined()
export const maxLengthEditor = Yup.string().max(100000)
export const maxLengthEditorRequired = Yup.string().required().max(100000)

export const toStr = (str?: any) => (str || str === 0 ? '' + str : '')

// Check page is refreshing or not: location is not changed
export const useRefresh = () => {
  const location = useLocation()
  const oldLocation = useRef(location)
  const [first, setFirst] = useState(true)
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    if (first) {
      setFirst(false)
      return
    }

    const { pathname, search, state } = location
    // Nếu giống hết các tham số, chỉ khác hash nghĩa là reload tại trang
    const { pathname: oldPathname, search: oldSearch, state: oldState } = oldLocation.current
    if (pathname === oldPathname && search === oldSearch && state === oldState)
      setRefresh((refresh) => refresh + 1)
    oldLocation.current = location
  }, [location])

  return refresh
}

// Check page is refreshing or not: location is not changed
export const useCallOnRefresh = (callback: any) => {
  const location = useLocation()
  const oldLocation = useRef(location)

  const first = useRef(true)

  useEffect(() => {
    if (first.current) {
      first.current = false
      return
    }

    const { pathname, search, state } = location
    // Nếu giống hết các tham số, chỉ khác hash nghĩa là reload tại trang
    const { pathname: oldPathname, search: oldSearch, state: oldState } = oldLocation.current
    if (pathname === oldPathname && search === oldSearch && state === oldState) callback()
    oldLocation.current = location
  }, [location])

  return null
}

/**
 * Get history search from local storage
 * @returns
 */
export const getHistorySearch = () => {
  const historySearch = localStorage.getItem(LS_HISTORY_SEARCH) || '[]'
  let parsedHistorySearch
  try {
    parsedHistorySearch = JSON.parse(historySearch)
  } catch (e) {}
  return (parsedHistorySearch || []) as string[]
}

/**
 * Fetch keywords to local storage
 * @param keyword
 * @returns
 */
export const fetchHistorySearch = (data: any[]) => {
  data = data.map(({ keyword }) => keyword)
  localStorage.setItem(LS_HISTORY_SEARCH, JSON.stringify(data))
  return data
}

/**
 * Add keyword to local storage if it does not exist, or swap to head if it exists
 * @param keyword
 * @returns
 */
export const addHistorySearch = (keyword: string) => {
  let historySearch = getHistorySearch()
  const index = historySearch.findIndex((item) => item === keyword)
  if (index !== -1) historySearch.splice(index, 1)
  historySearch = [keyword, ...historySearch]
  localStorage.setItem(LS_HISTORY_SEARCH, JSON.stringify(historySearch))
  return historySearch
}

/**
 * Remove keyword from local storage if it exists
 * @param keyword
 * @returns
 */
export const removeHistorySearch = (keyword: string) => {
  const historySearch = getHistorySearch()
  const index = historySearch.findIndex((item) => item === keyword)
  if (index !== -1) {
    historySearch.splice(index, 1)
    localStorage.setItem(LS_HISTORY_SEARCH, JSON.stringify(historySearch))
    return [...historySearch]
  }
  return historySearch
}

/**
 * Lấy ra các giá trị position, nếu lỗi sẽ trả về giá trị mặc định
 * @param oldPosition position của banner ở dạng string
 * @returns
 */
export const getBannerPosition = (oldPosition: any) => {
  let position: any = {}

  try {
    position = JSON.parse(oldPosition as any)
  } catch {}

  position.width = position.width || defaultBannerPosition.width
  position.right = position.right || defaultBannerPosition.right
  position.textColor = position.textColor || defaultBannerPosition.textColor
  position.buttonColor = position.buttonColor || defaultBannerPosition.buttonColor
  position.textOffset = position.textOffset || defaultBannerPosition.textOffset
  position.buttonOffset = position.buttonOffset || defaultBannerPosition.buttonOffset

  return position as BannerPositionType
}

export const createTrainingLink = (type: string) =>
  `/${ROUTES.ROUTE_TRAINING_INFO}/${mappingTrainingInfoRoutes[type]}`

/**
 * Kiểm tra content có dạng http... thì chuyển thành link
 * @param content content ở dạng string
 * @returns
 */
export const convertToLinks = (content: string = '') => {
  /* eslint-disable */
  let urlRegex = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/

  let newContent = content.replace(urlRegex, function (url) {
    return '<a target="_blank" href="' + url + '">' + url + '</a>'
  })

  return newContent.replace('<a href', '<a target="_blank" href')
}

/**
 *
 * @param fileUrl
 * @returns fileName from url
 */
export const createFileName = (file: string) => {
  let newName: any = file.split('/')
  newName = newName[newName.length - 1]

  newName = trimFileName(newName)

  return newName
}

export const convertToObj = (data: any, convertBy: string) => {
  const obj: { [key: string]: any } = {}
  data.forEach((item: any) => {
    obj[`${item.major[convertBy]}`] = item.score
  })
  return obj
}

export const convertToAlphabet = (e: any) => {
  let str = e.target.value
  str = str
    .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, '') // xóa các ký tự dấu sau khi tách tổ hợp

  str = str.replace(/[đĐ]/g, 'd')

  str = str.replace(/([^0-9`~!@#$%^&*(),.?'":;{}+=|<>_\\\/\[\]A-Za-z-\s])/g, '')

  return str
}

export const convertTimeToEndDay = (time: any) => {
  const newTime = new Date(time?.getFullYear(), time?.getMonth(), time?.getDate(), 23, 59, 59)

  return newTime.getTime() / 1000
}

export function YouTubeGetID(url: string) {
  const newUrl = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  return newUrl[2] !== undefined ? newUrl[2].split(/[^0-9a-z_\-]/i)[0] : ''
}

export const removeVietnameseTones = (str: string) => {
  str = str.replace(/à|á|ạ|ả|ã|â|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ê|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  str = str.replace(/ệ|ê/g, 'e')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, '') // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ')
  str = str.replace('·', ' ')
  str = str.trim()
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  )
  return str
}
