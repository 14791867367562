import { SubMenuType } from 'types'
import {
  mappingStudentInfoRoutes,
  mappingStudentInfoType,
  ROUTES,
  STUDENT_INFO,
  TRAINING_INFO_TYPES,
} from 'configs/constants'
import { createTrainingLink } from 'utils'

const {
  ROUTE_INTRODUCTIONS,
  ROUTE_DEPARTMENTS,
  ROUTE_NEWS,
  ROUTE_ENROLLMENTS,
  ROUTE_QUESTIONS,
  ROUTE_ENTERPRISE_PARTNERS,
  ROUTE_ACADEMIC_PARTNERS,
  ROUTE_PARTNER_ACTIVITIES,
  ROUTE_BENCHMARKS,
  ROUTE_PRODUCTS,
  ROUTE_RESEARCH_INFO,
  ROUTE_RESEARCH_GROUPS,
  ROUTE_STUDENTS,
  ROUTE_ALL_SCIENTIFIC_PAPERS,
} = ROUTES

const { ALL, UNIVERSITY, MASTER, DOCTOR, SHORT_TERM_IT, SHORT_TERM_CN, SHORT_TERM_SI } =
  TRAINING_INFO_TYPES

export const routes = [
  { path: `/${ROUTE_INTRODUCTIONS}`, title: 'Giới thiệu' },
  { path: `/${ROUTE_DEPARTMENTS}`, title: 'Đơn vị trực thuộc' },
  { path: `/${ROUTE_NEWS}`, title: 'Tin tức - Sự kiện' },
]

export const menu: SubMenuType[] = [
  {
    title: 'Đào tạo',
    to: createTrainingLink(ALL),
    subs: [
      { title: 'Đào tạo Đại học', to: createTrainingLink(UNIVERSITY) },
      { title: 'Đào tạo Thạc sĩ', to: createTrainingLink(MASTER) },
      { title: 'Đào tạo Tiến sĩ', to: createTrainingLink(DOCTOR) },
      {
        title: 'Đào tạo ngắn hạn',
        to: '',
        subs: [
          { title: 'Chứng chỉ CNTT', to: createTrainingLink(SHORT_TERM_IT) },
          { title: 'Chứng chỉ mạng máy tính', to: createTrainingLink(SHORT_TERM_CN) },
          { title: 'Chứng chỉ an toàn thông tin', to: createTrainingLink(SHORT_TERM_SI) },
        ],
      },
    ],
  },
  {
    title: 'Nghiên cứu',
    to: '',
    subs: [
      { title: 'Đề tài - Dự án', to: `/${ROUTE_RESEARCH_INFO}` },
      { title: 'Nhóm nghiên cứu', to: `/${ROUTE_RESEARCH_GROUPS}` },
      { title: 'Công bố khoa học', to: `/${ROUTE_ALL_SCIENTIFIC_PAPERS}` },
    ],
  },
  {
    title: 'Tuyển sinh',
    to: '',
    subs: [
      { title: 'Thông tin tuyển sinh', to: `/${ROUTE_ENROLLMENTS}` },
      { title: 'Điểm chuẩn qua từng năm', to: `/${ROUTE_BENCHMARKS}` },
      { title: 'Hỏi đáp về tuyển sinh', to: `/${ROUTE_QUESTIONS}` },
    ],
  },
  {
    title: 'Học viên - Sinh viên',
    to: '',
    subs: STUDENT_INFO.map((i) => ({
      title: mappingStudentInfoType[i],
      to: `/${ROUTE_STUDENTS}/${mappingStudentInfoRoutes[i]}`,
    })),
  },
  {
    title: 'Hợp tác đối ngoại',
    to: '',
    subs: [
      { title: 'Hoạt động hợp tác', to: `/${ROUTE_PARTNER_ACTIVITIES}` },
      { title: 'Hợp tác khối hàn lâm', to: `/${ROUTE_ACADEMIC_PARTNERS}` },
      { title: 'Hợp tác khối doanh nghiệp', to: `/${ROUTE_ENTERPRISE_PARTNERS}` },
    ],
  },
  { title: 'Sản phẩm tiêu biểu', to: `/${ROUTE_PRODUCTS}` },
]
