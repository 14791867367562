/* eslint-disable */
import Title from '../title/Title'
import React from 'react'
import { ResearchInfoType } from 'types'
import { Container } from '@mui/material'
import Scrollbar from 'components/scrollbar/Scrollbar'
import ResearchItem from '../researchItem/ResearchItem'
import { mappingResearchType } from 'configs/constants'
import { useIsMobile } from 'store/mobile'

interface Props {
  researches?: ResearchInfoType[]
  isFull?: boolean
  showMember?: boolean
  hideInfor?: boolean
}

const UserDetailResearches: React.FC<Props> = ({
  researches,
  isFull = false,
  showMember,
  hideInfor,
}) => {
  const isMobile = useIsMobile()

  if (!researches?.length) return null
  const rederContent = (
    <div className="UserDetailResearches">
      <Title>Đề tài nghiên cứu</Title>
      {/* <div className="banner background-image d-f ai-c jc-c typo-40-7 ta-c">Đề tài nghiên cứu</div> */}
      <div className="UserDetailResearches-row header typo-16-6 ta-c">
        <div className="col-left">Tên đề tài</div>
        <div className="col-right">Loại {isMobile ? 'ĐT' : 'đề tài'}</div>
      </div>
      <Scrollbar style={{ maxHeight: isMobile ? undefined : 640 }}>
        {researches.map((item) => (
          <div key={item.id} className="UserDetailResearches-row body">
            <div className="col-left">
              <ResearchItem
                showMember={showMember}
                {...item}
                isMobile={isMobile}
                hideInfor={hideInfor}
              />
            </div>
            <div className="col-right d-f ai-c jc-c neutral-1 ta-c typo-16-5">
              {mappingResearchType[item.topic_research]}
            </div>
          </div>
        ))}
      </Scrollbar>
    </div>
  )

  return <>{isFull ? rederContent : <Container>{rederContent}</Container>}</>
}

export default UserDetailResearches
