// MODAL CONFIRM
export const SHOW_MODAL_CONFIRM = 'SHOW_MODAL_CONFIRM'
export const HIDE_MODAL_CONFIRM = 'HIDE_MODAL_CONFIRM'

// AUTH
export const UPDATE_AUTH = 'UPDATE_AUTH'
export const CLEAR_AUTH = 'CLEAR_AUTH'

// TOASTS
export const CREATE_TOAST = 'CREATE_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'

// CONFIRM REDIRECT
export const PREVENT_REDIRECT = 'PREVENT_REDIRECT'
export const ALLOW_REDIRECT = 'ALLOW_REDIRECT'

// BREADCRUMB
export const UPDATE_BREADCRUMB = 'UPDATE_BREADCRUMB'

// MOBILE
export const RESOLUTION_MOBILE = 'RESOLUTION_MOBILE'

// APP
export const UPDATE_APP = 'UPDATE_APP'

// APP
export const UPDATE_HOME = 'UPDATE_HOME'

// MODAL_AUTH
export const SHOW_MODAL_AUTH = 'SHOW_MODAL_AUTH'
export const HIDE_MODAL_AUTH = 'HIDE_MODAL_AUTH'
