import { Breakpoint, Dialog, DialogProps, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { ModalContext } from './constants'
import ModalAttention from './ModalAttention'

interface Props extends Omit<DialogProps, 'open'> {
  show: boolean
  className?: string
  title?: string
  close?: () => void
  size?: Breakpoint
  onBackdropClick?: () => void
  dirty?: boolean
  fullScreen?: boolean
}

const Modal: React.FC<Props> = ({
  show,
  className = '',
  title,
  close,
  children,
  size = 'xs',
  dirty,
  fullScreen,
  ...rest
}) => {
  const [showAttention, setShowAttention] = useState(false)

  const closeAttention = () => setShowAttention(false)

  return (
    <ModalContext.Provider value={{ dirty: dirty || false, setShowAttention }}>
      <Dialog
        {...rest}
        className={`Modal${fullScreen ? ' Modal-fullScreen' : ''} ${className}`}
        open={show}
        fullWidth
        maxWidth={size}
      >
        {dirty && (
          <ModalAttention onClose={close} onCloseAttention={closeAttention} show={showAttention} />
        )}
        {!!title && (
          <DialogTitle style={{ marginRight: 20, textAlign: 'center' }}>
            {title}
            {!!close && (
              <i
                className="icon-close Modal-close"
                onClick={() => {
                  if (dirty) setShowAttention(true)
                  else close()
                }}
              />
            )}
          </DialogTitle>
        )}
        {children}
      </Dialog>
    </ModalContext.Provider>
  )
}

export default Modal
