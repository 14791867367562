import { Button } from '@mui/material'
import React from 'react'
import './index.scss'

interface Props {
  extraClass?: string
  onClick?: () => void
  outline?: boolean
  big?: boolean
  hoverAccent?: boolean
}

const ButtonArrow: React.FC<Props> = ({
  extraClass = '',
  className,
  onClick,
  outline,
  big,
  hoverAccent,
}: any) => {
  return (
    <Button
      variant="outlined"
      className={`ButtonArrow ${extraClass} ${className}${outline ? ' outline' : ''}${
        big ? ' big' : ''
      }${hoverAccent ? ' hoverAccent' : ''}`}
      onClick={onClick}
    >
      <span className="icon-chevron-right" />
    </Button>
  )
}

export default ButtonArrow
