import React, { MutableRefObject } from 'react'
import { Button as MuiButton, ButtonProps, CircularProgress } from '@mui/material'

interface Props extends ButtonProps {
  innerRef?: MutableRefObject<any>
  loading?: boolean
}

const Button: React.FC<Props> = ({
  innerRef,
  loading,
  children,
  disabled,
  size = 'small',
  ...rest
}) => {
  const isSmall = size === 'small'

  return (
    <MuiButton ref={innerRef} disabled={loading || disabled} {...rest} size={size}>
      {loading ? (
        <div className="d-f jc-c ai-c" style={{ position: 'relative' }}>
          <div style={{ visibility: 'hidden' }}>{children}</div>
          <CircularProgress
            style={{ position: 'absolute' }}
            size={isSmall ? 20 : 24}
            color="inherit"
          />
        </div>
      ) : (
        children
      )}
    </MuiButton>
  )
}

export default Button
