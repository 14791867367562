/* eslint-disable */
import { Container } from '@mui/material'
import UserLogo from 'assets/images/user_logo.png'
import { Button, Link } from 'components/user'
import { apiUrls } from 'configs/apis'
import { MAX_PAGE_SIZE, ROUTES } from 'configs/constants'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { useApis } from 'services/api'
import { useAuth } from 'store/auth'
import { useModalAuth } from 'store/modalAuth'
import { useModalConfirm } from 'store/modalConfirm'
import { PermissionType } from 'types'
import { useRefresh } from 'utils'
import MenuItem from '../menuItem/MenuItem'
import Menu from './Menu'
import ModalSearch from './ModalSearch'
import Search from './Search'
import Sidebar from './Sidebar'
import { menu, routes } from './constants'

const { ROUTE_PROFILE, ROUTE_SCIENTIFIC_PAPERS, ROUTE_MY_RESEARCH_INFO } = ROUTES

const ROLE_MANAGEMENT_SCIENTIFIC_PAPERS = 'quan-ly-cong-bo-khoa-hoc'
const ROLE_MANAGEMENT_MY_RESEARCH_INFO = 'quan-ly-thong-tin-nghien-cuu'
const ROLE_RESEARCH_ACTIVITIES = 'hoat-dong-nghien-cuu-khoa-hoc'

const Header: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { pathname } = location
  const { showModalSignIn, showModalChangePassword } = useModalAuth()
  const { avatar_url, isAuth, signOut } = useAuth()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()
  const { apiGet } = useApis()

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [showModalSearch, setShowModalSearch] = useState(false)
  const [menuTab, setMenuTab] = useState<PermissionType[]>([])
  const refresh = useRefresh()
  const [seminar, setSeminar] = useState('')

  const history = useHistory()

  useEffect(() => {
    if (isAuth)
      apiGet(
        apiUrls.menuTab(),
        {
          page: 1,
          page_size: MAX_PAGE_SIZE,
        },
        ({ status, data }) => {
          if (status)
            setMenuTab(
              data.roles.map((item: PermissionType) => ({
                ...item,
                key: item.permission_object?.key,
              }))
            )
        }
      )
  }, [isAuth])

  useEffect(() => {
    apiGet(apiUrls.seminar(), {}, ({ status, data }) => {
      if (status) setSeminar(data?.seminar)
    })
  }, [refresh])

  const menuUserItems = [
    { title: 'Thông tin cá nhân', to: `/${ROUTE_PROFILE}` },
    {
      title: 'Công bố khoa học',
      to: `/${ROUTE_SCIENTIFIC_PAPERS}`,
      hidden: menuTab.every((i) => i.key !== ROLE_MANAGEMENT_SCIENTIFIC_PAPERS),
    },
    {
      title: 'Đề tài nghiên cứu',
      to: `/${ROUTE_MY_RESEARCH_INFO}`,
      hidden: menuTab.every((i) => i.key !== ROLE_MANAGEMENT_MY_RESEARCH_INFO),
    },
    { title: 'Đổi mật khẩu', onClick: showModalChangePassword },
    {
      title: 'Đăng xuất',
      onClick: () =>
        showModalConfirm({
          title: 'Đăng xuất',
          content: t('sure_sign_out'),
          cancel: { text: 'Hủy', action: hideModalConfirm },
          confirm: {
            text: 'Đăng xuất',
            action: () => {
              hideModalConfirm()
              signOut()
            },
          },
          close: hideModalConfirm,
        }),
    },
  ].filter((i) => !i.hidden)

  const handleRiderect = () => {
    if (isAuth) {
      showModalConfirm({
        title: 'Thông báo',
        content: 'Bạn có chắc chắn muốn chuyển hướng sang trang Quản trị không?',
        cancel: { text: 'Hủy', action: hideModalConfirm },
        confirm: {
          text: 'Chuyển hướng',
          action: () => {
            hideModalConfirm()
            signOut()
            history.push('/admin/sign-in')
          },
        },
        close: hideModalConfirm,
      })
    } else {
      history.push('/admin/sign-in')
    }
  }

  return (
    <>
      <div className="MobileHeader">
        <ModalSearch show={showModalSearch} onClose={() => setShowModalSearch(false)} />
        <Sidebar
          avatar_url={avatar_url}
          isAuth={isAuth}
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          menuUserItems={menuUserItems}
          seminar={seminar}
          handleRiderect={handleRiderect}
        />
        <Link className="logo d-f ai-c" to="/">
          <img alt="" src={UserLogo} width={'70px'} />
          Viện Công nghệ thông tin và truyền thông
        </Link>
        <div className="right d-f ai-c">
          <i className="icon-search" onClick={() => setShowModalSearch(true)} />
          <i className="icon-menu" onClick={() => setSidebarOpen(true)} />
        </div>
      </div>

      <div className="Header">
        <div className="Header-top d-f ai-c">
          <Container className="d-f jc-sb ai-c">
            <div>
              <span className="name">HỌC VIỆN KỸ THUẬT QUÂN SỰ</span>
              {seminar && (
                <span className="animate-charcter" onClick={() => window.open(seminar)}>
                  Hội thảo
                </span>
              )}
            </div>
            <div className="d-f ai-c">
              <div className="links">
                {routes.map(({ path, title }) => (
                  <Link
                    className={matchPath(pathname, { path, exact: false }) ? 'active' : ''}
                    key={path}
                    to={path}
                  >
                    {title}
                  </Link>
                ))}
              </div>
              <Search />
              {isAuth ? (
                <Menu menuUserItems={menuUserItems} />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <Button className="Header-signIn" colorType="top" onClick={showModalSignIn}>
                    Đăng nhập
                  </Button>
                </div>
              )}

              <Button
                style={{ marginLeft: 16 }}
                className="Header-changePage"
                colorType="default secondary"
                onClick={handleRiderect}
              >
                Trang Quản trị <i style={{ margin: 0 }} className="icon-arrow-ios-right" />
              </Button>
            </div>
          </Container>
        </div>
        <div className="Header-bottom d-f ai-c">
          <Container className="d-f jc-sb ai-c">
            <Link className="logo d-f ai-c" to="/">
              <img alt="" src={UserLogo} width={'70px'} />
              <div>
                Viện Công nghệ thông tin <br /> và truyền thông
              </div>
            </Link>
            <div className="menu">
              {menu.map((item, index) => (
                <MenuItem key={index} {...item} />
              ))}
            </div>
          </Container>
        </div>
      </div>
    </>
  )
}

export default Header
