import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom'
import { AuthType, RootState } from 'types'

interface Props extends RouteProps {
  isAdmin?: boolean
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}

const PrivateRoute: React.FC<Props> = ({ component: Component, isAdmin, ...rest }) => {
  const auth: AuthType = useSelector((state: RootState) => state.auth)
  let isAuth = auth.access_token && !auth.force_change_password
  if (isAdmin) isAuth = isAuth && auth.isAdmin

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: `${isAdmin ? '/admin' : ''}/sign-in`, state: props.location }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
