import { Container } from '@mui/material'
import React from 'react'
import { useHome } from 'store/home'
import Partners from '../partners/Partners'
import Title from '../title/Title'

const HomePartners: React.FC = () => {
  const { partners } = useHome()

  if (!partners.length) return null

  return (
    <Container>
      <Title>Đối tác</Title>
      <Partners partners={partners} />
    </Container>
  )
}

export default HomePartners
