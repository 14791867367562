"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typeMatcher = exports.type = exports.treeTypeParser = exports.removeTypeParser = exports.listTypeParser = exports.hasTypeParser = exports.addTypeParser = void 0;

var _logger = _interopRequireDefault(require("../../logger.js"));

var _dataType = require("./dataType.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const types = {};
const dataTypes = {};
const unregExts = {};

const parseNativeTypes = (input, dataType) => {
  switch (dataType) {
    case 'Array':
      if (input.length === 0 || input.every(entry => type(entry) === '@csl/object')) {
        return '@csl/list+object';
      } else {
        return '@else/list+object';
      }

    case 'SimpleObject':
    case 'ComplexObject':
      return '@csl/object';

    default:
      return '@invalid';
  }
};

const matchType = (typeList = [], data) => {
  for (const type of typeList) {
    if (types[type].predicate(data)) {
      return matchType(types[type].extensions, data) || type;
    }
  }
};

const type = input => {
  const dataType = (0, _dataType.dataTypeOf)(input);

  if (dataType === 'Array' && input.length === 0) {
    return parseNativeTypes(input, dataType);
  }

  const match = matchType(dataTypes[dataType], input);
  return match || parseNativeTypes(input, dataType);
};

exports.type = type;

const addTypeParser = (format, {
  dataType,
  predicate,
  extends: extend
}) => {
  let extensions = [];

  if (format in unregExts) {
    extensions = unregExts[format];
    delete unregExts[format];

    _logger.default.debug('[core]', `Subclasses "${extensions}" finally registered to parent type "${format}"`);
  }

  const object = {
    predicate,
    extensions
  };
  types[format] = object;

  if (extend) {
    const parentTypeParser = types[extend];

    if (parentTypeParser) {
      parentTypeParser.extensions.push(format);
    } else {
      if (!unregExts[extend]) {
        unregExts[extend] = [];
      }

      unregExts[extend].push(format);

      _logger.default.debug('[core]', `Subclass "${format}" is waiting on parent type "${extend}"`);
    }
  } else {
    const typeList = dataTypes[dataType] || (dataTypes[dataType] = []);
    typeList.push(format);
  }
};

exports.addTypeParser = addTypeParser;

const hasTypeParser = type => Object.prototype.hasOwnProperty.call(types, type);

exports.hasTypeParser = hasTypeParser;

const removeTypeParser = type => {
  delete types[type];
  const typeLists = [...Object.keys(dataTypes).map(key => dataTypes[key]), ...Object.keys(types).map(type => types[type].extensions).filter(list => list.length > 0)];
  typeLists.forEach(typeList => {
    const index = typeList.indexOf(type);

    if (index > -1) {
      typeList.splice(index, 1);
    }
  });
};

exports.removeTypeParser = removeTypeParser;

const listTypeParser = () => Object.keys(types);

exports.listTypeParser = listTypeParser;

const treeTypeParser = () => {
  const attachNode = name => ({
    name,
    children: types[name].extensions.map(attachNode)
  });

  return {
    name: 'Type tree',
    children: Object.keys(dataTypes).map(name => ({
      name,
      children: dataTypes[name].map(attachNode)
    }))
  };
};

exports.treeTypeParser = treeTypeParser;
const typeMatcher = /^(?:@(.+?))(?:\/(?:(.+?)\+)?(?:(.+)))?$/;
exports.typeMatcher = typeMatcher;