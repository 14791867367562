import React from 'react'
import './index.scss'

interface Props {
  paddingSmall?: boolean
  noPadding?: boolean
  paddingHalf?: boolean
  primaryColor?: boolean
}

const Title: React.FC<Props> = ({
  paddingSmall,
  noPadding,
  paddingHalf,
  children,
  primaryColor,
}) => {
  return (
    <div
      className={`Title${paddingSmall ? ' paddingSmall' : ''}${noPadding ? ' noPadding' : ''}${
        paddingHalf ? ' paddingHalf' : ''
      }`}
    >
      <div className={`Title-text typo-24-6 ${primaryColor ? 'primary' : ''}`}>{children}</div>
      <div className="line-below" />
    </div>
  )
}

export default Title
