/* eslint-disable */
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Popover,
  TextField,
} from '@mui/material'
import Loading from 'components/loading/Loading'
import { ALL } from 'configs/constants'
import { isEqual } from 'lodash'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { SelectType } from 'types'
import { formatCompare, formatCompare2 } from 'utils'
import Input from '../input/Input'
import './index.scss'

export interface Props {
  data?: SelectType[]
  selected?: any
  setSelected?: (selectedId: any, selectedItem?: any) => void
  label?: string
  style?: CSSProperties
  disabled?: boolean
  hideSelected?: boolean
  placeholder?: string
  checkbox?: {
    name: string
    selected: any[]
    onChange: (value: any[]) => void
    areaStyle?: CSSProperties
    cutText?: number
    minSelected?: number
  }
  maxHeight?: any
  fullWidth?: boolean
  popupSearch?: {
    maxLength?: number
    timeout?: number
    searchByLabel?: boolean
    labelNoItems?: string
    labelTotallyNoItems?: string
    placeholder?: string
    onSearch?: (keyword: string) => void
    onChange?: (keyword: string) => void
    searchFrontend?: boolean
    margin?: boolean
    loadingSearch?: boolean
  }
  sort?: boolean
  noSortFirst?: boolean
  error?: any
  errorEmpty?: boolean
  validateForm?: any
  view?: boolean
  removable?: boolean
  showLabelOneItemSelected?: boolean
  onIncreasePage?: () => void
  showLoadmore?: boolean
  noWhireSpace?: boolean
  validateHasValue?: boolean
  highlight?: boolean
  [key: string]: any
}

const Select: React.FC<Props> = ({
  data = [],
  selected,
  setSelected,
  style = {},
  disabled,
  hideSelected = true,
  maxHeight = 220,
  fullWidth,
  popupSearch,
  placeholder,
  sort,
  noSortFirst,
  error,
  errorEmpty,
  validateForm,
  view,
  removable,
  checkbox,
  showLabelOneItemSelected,
  onIncreasePage,
  showLoadmore,
  noWhireSpace,
  validateHasValue,
  highlight,
  ...rest
}) => {
  const anchorRef = useRef<HTMLInputElement>(null)
  const touched = useRef(false)
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')

  const timeoutSearch = useRef<any>()
  const readOnly = true

  const {
    name: checkboxName,
    selected: checkboxSelected,
    onChange: checkboxOnChange,
    areaStyle: checkboxAreaStyle = {},
    cutText = 50,
    minSelected = 0,
  } = checkbox || { name: '', onChange: () => {}, selected: [] }
  const checkboxSelectedIds = checkboxSelected?.map((item) => item.id)

  const {
    timeout,
    labelNoItems = 'Không có kết quả',
    labelTotallyNoItems = 'Không có kết quả',
    placeholder: searchPlaceholder = 'Nhập từ khoá',
    onSearch,
    onChange,
    searchFrontend,
    margin,
    maxLength: searchMaxLength = 200,
    loadingSearch,
  } = popupSearch || {}

  const totallyNoItems = data.length === 0

  const LabelOneItemSelected = data.length === 1 && selected

  let newData = data
  const value = data.find((item) => isEqual(item.value, selected))?.label || ''
  let noItems = true

  if (searchFrontend && search) {
    newData = data.map((item) => {
      const value1 = formatCompare2(item.label)
      const value2 = formatCompare2(search)

      const hide =
        !value1.includes(value2) || item.hide || (hideSelected ? item.value === selected : false)
      if (!hide) noItems = false
      return { ...item, hide }
    })
  } else if (!searchFrontend && search) {
    noItems = false
  } else noItems = false

  if (sort) {
    let first: any[] = []
    if (noSortFirst) {
      first = newData.slice(0, 1)
      newData = newData.slice(1)
    }
    newData = newData.sort((a, b) => {
      const A = formatCompare(a.label)
      const B = formatCompare(b.label)
      return A < B ? -1 : 1
    })
    newData = [...first, ...newData]
  }

  // Open the menu
  const handleOpen = () => {
    setOpen(true)
  }

  // Close the menu
  const handleClose = (hasValue?: boolean) => {
    touched.current = true
    setOpen(false)
    if (!hasValue) validateForm?.()
  }

  // Search when click item, enter or after 1 second
  const handleSearch = (newValue?: string) => {
    if (!newValue) newValue = search || ''

    searchFrontend ? onSearch?.(newValue) : onSearch?.('')
  }

  // When click item, set selected item, set value in textbox and close menu
  const handleClickItem = (item?: SelectType) => {
    setSelected?.(item?.value || '', item)
    searchFrontend && handleSearch(item?.value)
    handleClose(true)
  }

  useEffect(() => {
    if (open && search) {
      setSearch('')
    }
  }, [open])

  useEffect(() => {
    onChange?.(search)

    if (!popupSearch) return

    if (timeout === 0) {
      handleSearch?.(value)
      return
    }

    // If user not typing after 700ms => search
    timeoutSearch.current =
      !searchFrontend && search
        ? setTimeout(() => onSearch?.(search), timeout || 700)
        : setTimeout(handleSearch, timeout || 700)

    return () => clearTimeout(timeoutSearch.current)
  }, [search])

  const renderInputSearch = () => {
    // if (!popupSearch || totallyNoItems) return null
    if (!popupSearch) return null

    return (
      <div className="SelectPaper-search">
        <Input
          autoFocus
          placeholder={searchPlaceholder}
          icon="search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
          maxLength={searchMaxLength}
        />
      </div>
    )
  }

  useEffect(() => {
    if (errorEmpty) touched.current = true
  }, [errorEmpty])

  const errorText =
    (checkbox
      ? checkboxSelected.length === 0 || checkboxSelected.length === minSelected
      : validateHasValue
      ? true
      : !value) &&
    !open &&
    touched.current
      ? error
      : undefined

  if (view) return <div style={{ marginTop: 8, fontWeight: 500 }}>{value}</div>

  const errorTextField = !checkbox && errorText

  const isCheckAll = data.length === checkboxSelected.length + 1

  // When check all => all item will be selected
  const handleCheckAll = () => {
    if (isCheckAll) checkboxOnChange([])
    else
      checkboxOnChange(
        data
          .filter((item) => item.value !== ALL)
          .map(({ value, label }) => ({ id: value, [checkboxName]: label }))
      )
  }

  const trimLabel = (text: string) => {
    if (text.length > cutText) {
      return text.slice(0, cutText) + '...'
    }
  }

  return (
    <div
      className={readOnly ? 'Select-readOnly' : ''}
      style={{ width: fullWidth ? '100%' : undefined, ...style }}
    >
      <TextField
        {...rest}
        ref={anchorRef}
        onClick={disabled ? undefined : handleOpen}
        value={value}
        disabled={disabled}
        size="small"
        fullWidth={fullWidth}
        placeholder={placeholder}
        helperText={errorTextField}
        error={!!errorTextField}
        InputProps={{
          readOnly,
          endAdornment: (
            <InputAdornment position="end" style={{ opacity: disabled ? 0.5 : 1 }}>
              <svg
                className={`Select-arrow ${open && 'focus'}`}
                viewBox="0 0 24 24"
                width={24}
                height={24}
              >
                <path d="M7 10l5 5 5-5z"></path>
              </svg>
            </InputAdornment>
          ),
        }}
      />
      {!!checkbox && (
        <>
          <div
            className={`AdminFormControl scroll${errorText ? ' invalid' : ''}`}
            style={{
              marginTop: 12,
              height: 144,
              marginBottom: 0,
              ...checkboxAreaStyle,
            }}
          >
            {checkboxSelected.map((item) => {
              const { id } = item
              const name = item[checkboxName]

              return (
                <Chip
                  sx={{ maxWidth: '100%' }}
                  key={id}
                  label={name}
                  onDelete={() => checkboxOnChange(checkboxSelected.filter((i) => i.id !== id))}
                />
              )
            })}
          </div>
          {!!errorText && (
            <div style={{ marginLeft: checkboxAreaStyle?.marginLeft }}>
              <FormHelperText error style={{ marginTop: 8, marginBottom: 10 }}>
                {errorText}
              </FormHelperText>
            </div>
          )}
        </>
      )}

      <Popover
        anchorOrigin={{
          vertical: popupSearch ? 'top' : 'bottom',
          // vertical: popupSearch && !totallyNoItems ? 'top' : 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: anchorRef.current?.getBoundingClientRect().width || 0,
            marginTop: margin ? 7 : undefined,
          },
        }}
        disableAutoFocus
        open={open}
        onClose={() => handleClose()}
        anchorEl={anchorRef.current}
      >
        {renderInputSearch()}
        {!!(removable && selected) && (
          <MenuItem className="Select-item remove" onClick={() => handleClickItem()}>
            <div>Bỏ chọn</div>
          </MenuItem>
        )}
        {(noItems || totallyNoItems) && !loadingSearch && (
          <div
            className="Select-item"
            style={{
              padding: totallyNoItems ? '8px 16px' : '0 16px',
              height: totallyNoItems ? 36 : 28,
              color: 'var(--cl-gray)',
            }}
          >
            {totallyNoItems ? labelTotallyNoItems : labelNoItems}
          </div>
        )}
        {LabelOneItemSelected && !loadingSearch && showLabelOneItemSelected && !search && (
          <div
            className="Select-item"
            style={{
              padding: '0 16px',
              height: 28,
              color: 'var(--cl-gray)',
            }}
          >
            Không có giá trị
          </div>
        )}
        <SimpleBar className="SelectPaper-paper" style={{ maxHeight }} autoHide={false}>
          {loadingSearch ? (
            <Loading />
          ) : (
            <>
              {!!checkbox
                ? newData.map(({ label, value, hide }) => (
                    <div
                      className={`Select-item${noWhireSpace ? ' noWhireSpace' : ''}`}
                      key={value}
                      style={{
                        display:
                          (hideSelected && value === selected) || hide
                            ? 'none'
                            : noWhireSpace
                            ? 'flex'
                            : undefined,
                        alignItems: noWhireSpace ? 'center' : undefined,
                        height: '100%',
                      }}
                    >
                      <Checkbox
                        onChange={() =>
                          value === ALL
                            ? handleCheckAll()
                            : checkboxOnChange(
                                checkboxSelected.some((item1) => item1.id === value)
                                  ? checkboxSelected.filter((i) => i.id !== value)
                                  : [
                                      ...(checkboxSelected || []),
                                      { id: value, [checkboxName]: label },
                                    ]
                              )
                        }
                        checked={value === ALL ? isCheckAll : checkboxSelectedIds.includes(value)}
                        style={{ height: 36, width: 36, marginLeft: 8 }}
                      />
                      {label}
                    </div>
                  ))
                : newData.map((item, index) => (
                    <>
                      <MenuItem
                        className={`Select-item${selected === item.value ? ' active' : ''}${
                          highlight ? ' highlight' : ''
                        }`}
                        key={item.value + index}
                        disabled={item.disabled}
                        onClick={() => handleClickItem(item)}
                        style={{
                          display:
                            (hideSelected && item.value === selected) || item.hide
                              ? 'none'
                              : undefined,
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {item.label}
                        </div>
                      </MenuItem>
                    </>
                  ))}
            </>
          )}

          {showLoadmore && !loadingSearch && !!data.length && (
            <MenuItem>
              <div className="d-f jc-c w-100">
                <Waypoint
                  onEnter={() => {
                    onIncreasePage?.()
                  }}
                >
                  <CircularProgress size={20} color="inherit" />
                </Waypoint>
              </div>
            </MenuItem>
          )}
        </SimpleBar>
      </Popover>
    </div>
  )
}

export default Select
