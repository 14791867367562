/* eslint-disable */
import { Paper, Popper as MuiPopper } from '@mui/material'
import React from 'react'

interface Props {
  show: boolean
  anchorEl: HTMLDivElement | null
  top: number
  placeBottom?: boolean
}

const Popper: React.FC<Props> = ({ show, anchorEl, top, children, placeBottom }) => {
  return (
    <MuiPopper
      open={show}
      anchorEl={anchorEl}
      placement={placeBottom ? 'bottom' : 'top'}
      modifiers={[
        {
          name: 'flip',
          enabled: false,
        },
        {
          name: 'preventOverflow',
          enabled: false,
        },
        {
          name: 'offset',
          options: {
            offset: [0, top],
          },
        },
      ]}
      disablePortal={true}
      // transition
      style={{ zIndex: 10, pointerEvents: 'none', userSelect: 'none' }}
    >
      {/* {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={300}> */}
      <Paper className="Chart-popper__paper">
        <div className="Chart-popper__content">{children}</div>
        <div className="Chart-popper__arrow" />
      </Paper>
      {/* </Fade>
      )} */}
    </MuiPopper>
  )
}

export default Popper
