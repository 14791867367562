/* eslint-disable */
import { Collapse } from '@mui/material'
import React, { useState } from 'react'
import { QuestionType } from 'types'
import './index.scss'

interface Props extends QuestionType {}

const QuestionItem: React.FC<Props> = ({ question, answer }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className="QuestionItem">
      <div className={`QuestionItem-btn${open ? ' active' : ''}`} onClick={() => setOpen(!open)}>
        <span className="QuestionItem-question">{question}</span>
        <i className={`icon-arrow-ios-down${open ? ' rotate-180' : ''}`} />
      </div>
      <Collapse in={open} unmountOnExit timeout={{ appear: 100, enter: 400, exit: 400 }}>
        <div className="hr" />
        <div className="QuestionItem-answer">
          {answer?.split('\n').map((item: string, index: number) => (
            <div key={index} style={{ minHeight: 20 }}>
              {item}
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  )
}

export default QuestionItem
