/* eslint-disable */
import React from 'react'
import './index.scss'

interface Props {
  label: string
  required?: boolean
}

const TopLabel: React.FC<Props> = ({ label, required }) => {
  return (
    <div className="TopLabel typo-16-5 neutral-1">
      {label}
      {required && (
        <span>
          {' '}
          (<span style={{ color: 'var(--cl-error)' }}>*</span>)
        </span>
      )}
    </div>
  )
}

export default TopLabel
