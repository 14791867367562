import React from 'react'
import { useHome } from 'store/home'
import Overview from '../overview/Overview'

const HomeOverview: React.FC = () => {
  const { overview } = useHome()

  return <Overview {...overview} />
}

export default HomeOverview
