import { ALLOW_REDIRECT, PREVENT_REDIRECT } from 'store/actionTypes'
import { ActionType } from 'types'

const initialState = true

const confirmRedirectReducer = (state = initialState, action: ActionType) => {
  const { type } = action

  switch (type) {
    case ALLOW_REDIRECT:
      return true

    case PREVENT_REDIRECT:
      return false

    default:
      return state
  }
}

export default confirmRedirectReducer
