import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useToasts } from 'store/toasts'
import Toast from './Toast'

const ToastContainter: React.FC = () => {
  const toasts = useSelector((state: RootState) => state.toasts)
  const { removeToast } = useToasts()

  // useEffect(() => {
  //   setTimeout(
  //     () =>
  //       createToast({
  //         message: {
  //           title: 'Đổi mật khẩu thành công',
  //           content: 'Vui lòng sử dụng mật khẩu mới để đăng nhập vào hệ thống',
  //         },
  //         duration: 10000,
  //       }),
  //     1000
  //   )
  // }, [])

  return (
    <div className="toast-container">
      {toasts.map((toast) => (
        <Toast key={toast.key} onClose={() => removeToast(toast.key)} {...toast} />
      ))}
    </div>
  )
}

export default ToastContainter
