"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.textDict = exports.remove = exports.register = exports.list = exports.htmlDict = exports.has = exports.get = exports.add = void 0;

var _register = _interopRequireDefault(require("../util/register.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const validate = (name, dict) => {
  if (typeof name !== 'string') {
    throw new TypeError(`Invalid dict name, expected string, got ${typeof name}`);
  } else if (typeof dict !== 'object') {
    throw new TypeError(`Invalid dict, expected object, got ${typeof dict}`);
  }

  for (const entryName in dict) {
    const entry = dict[entryName];

    if (!Array.isArray(entry) || entry.some(part => typeof part !== 'string')) {
      throw new TypeError(`Invalid dict entry "${entryName}", expected array of strings`);
    }
  }
};

const register = new _register.default({
  html: {
    bibliographyContainer: ['<div class="csl-bib-body">', '</div>'],
    entry: ['<div class="csl-entry">', '</div>'],
    list: ['<ul style="list-style-type:none">', '</ul>'],
    listItem: ['<li>', '</li>']
  },
  text: {
    bibliographyContainer: ['', '\n'],
    entry: ['', '\n'],
    list: ['\n', ''],
    listItem: ['\t', '\n']
  }
});
exports.register = register;

const add = (name, dict) => {
  validate(name, dict);
  register.set(name, dict);
};

exports.add = add;

const remove = name => {
  register.remove(name);
};

exports.remove = remove;

const has = name => {
  return register.has(name);
};

exports.has = has;

const list = () => {
  return register.list();
};

exports.list = list;

const get = name => {
  if (!register.has(name)) {
    throw new Error(`Dict "${name}" unavailable`);
  }

  return register.get(name);
};

exports.get = get;
const htmlDict = {
  wr_start: '<div class="csl-bib-body">',
  wr_end: '</div>',
  en_start: '<div class="csl-entry">',
  en_end: '</div>',
  ul_start: '<ul style="list-style-type:none">',
  ul_end: '</ul>',
  li_start: '<li>',
  li_end: '</li>'
};
exports.htmlDict = htmlDict;
const textDict = {
  wr_start: '',
  wr_end: '\n',
  en_start: '',
  en_end: '\n',
  ul_start: '\n',
  ul_end: '',
  li_start: '\t',
  li_end: '\n'
};
exports.textDict = textDict;