import { mappingStudentInfoRoutes, ROUTES } from 'configs/constants'
import React from 'react'
import { useIsMobile } from 'store/mobile'
import { formatDate } from 'utils'
import Link from '../links/Link'
import './index.scss'

interface Props {
  id?: string
  created_date?: any
  title: string
  content_to_summary?: string
  image_url?: string
  type?: string
}

const StudentInfoItem: React.FC<Props> = ({
  id,
  created_date,
  title,
  content_to_summary,
  image_url,
  type = '',
}) => {
  const isMobile = useIsMobile()

  return (
    <Link
      to={`/${ROUTES.ROUTE_STUDENTS}/${mappingStudentInfoRoutes[type]}/${id}`}
      className="StudentInfoItem"
    >
      <div className="banner background-image" style={{ backgroundImage: `url("${image_url}")` }} />
      <div className="body">
        <div className="label-icon-wrapper neutral-2">
          <i className="icon-calendar1" />
          {formatDate(created_date)}
        </div>
        <p className={`title typo-20-6 max-line${isMobile ? '' : '-1'}`}>{title}</p>
        <p className={`content typo-16-4 typo-16-14 max-line${isMobile ? '-1' : ''}`}>
          {content_to_summary}
        </p>
      </div>
    </Link>
  )
}

export default StudentInfoItem
