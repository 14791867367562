import { HIDE_MODAL_AUTH, SHOW_MODAL_AUTH } from '../actionTypes'

export const showModalAuth = (route: string) => ({
  type: SHOW_MODAL_AUTH,
  payload: route,
})

export const hideModalAuth = () => ({
  type: HIDE_MODAL_AUTH,
})
