import { DialogContent } from '@mui/material'
import Scrollbar from '../scrollbar/Scrollbar'
import React, { CSSProperties } from 'react'

interface Props {
  style?: CSSProperties
}

const ModalContentScroll: React.FC<Props> = ({ children, style = {} }) => (
  <Scrollbar
    style={{ maxHeight: 'calc(98vh - 200px)', marginRight: -15, paddingRight: 15, ...style }}
  >
    <DialogContent>{children}</DialogContent>
  </Scrollbar>
)

export default ModalContentScroll
