import { UPDATE_BREADCRUMB } from 'store/actionTypes'
import { DispatchType, MenuItem } from 'types'

/**
 * Update breadcrumb
 *
 * @param breadcrumb breadcrumb
 */
export const updateBreadcrumb =
  (breadcrumb?: MenuItem[], banner?: any, noMargin?: boolean) => (dispatch: DispatchType) => {
    dispatch({ type: UPDATE_BREADCRUMB, payload: { breadcrumb, banner, noMargin } })
  }
