/* eslint-disable */
import { NavLink } from 'components/links'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'store/confirmRedirect'
import { SubMenuType } from 'types'
import './index.scss'
import { isActive } from './utils'

const MenuItem: React.FC<SubMenuType> = ({ title, to, subs, level }) => {
  const history = useHistory()

  const [show, setShow] = useState(false)

  const active = isActive(to, subs)

  const Wrapper = useCallback(
    (props) => {
      if (level)
        return (
          <div
            {...props}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (props.to) history.push(props.to)
            }}
          />
        )

      return (
        <NavLink
          {...props}
          onClick={(e) => {
            if (!props.to) e.preventDefault()
          }}
        />
      )
    },
    [level]
  )

  return (
    <Wrapper
      to={to || ''}
      className={`MenuItem${level ? ' isSubMenu' : active ? ' MenuItem-active' : ''}`}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <div style={{ textTransform: level ? undefined : 'uppercase' }}>{title}</div>
      {!!subs && !!subs.length && (
        <>
          <ul
            className={`MenuItem-item${show ? ' show' : ''}${
              level && level >= 1 ? ' isSubMenu' : ''
            }`}
          >
            {subs.map((item, index) => (
              <MenuItem key={index} {...item} level={(level || 0) + 1} />
            ))}
          </ul>
          {!!level && <i className="icon-arrow-ios-right" />}
        </>
      )}
    </Wrapper>
  )
}

export default MenuItem
