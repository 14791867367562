/* eslint-disable */
import React, { useMemo, useRef, useState } from 'react'
import Popper from './Popper'
import './index.scss'
import { useIsMobile } from 'store/mobile'

interface Props {
  name: string
  keys: {
    key: string
    label: string
  }[]
  mainKey: string
  data: { [key: string]: any }[]
  style?: any
}

const columnColors = [
  'primary',
  'semantic-1',
  'chart-0',
  'semantic-4',
  'chart-1',
  'chart-2',
  'chart-3',
  'chart-4',
  'chart-5',
  'chart-6',
  'chart-7',
  'chart-8',
  'chart-9',
  'chart-10',
  'chart-11',
  'chart-12',
  'chart-13',
  'chart-14',
  'chart-15',
  'chart-16',
  'chart-17',
  'chart-18',
  'chart-19',
  'chart-20',
  'chart-21',
  'chart-22',
  'chart-23',
  'chart-24',
  'chart-25',
  'chart-26',
  'chart-27',
  'chart-28',
  'chart-29',
  'chart-30',
  'chart-31',
  'chart-32',
  'chart-33',
  'chart-34',
  'chart-35',
  'chart-36',
  'chart-37',
  'chart-38',
  'chart-39',
  'chart-40',
  'chart-41',
  'chart-42',
  'chart-43',
  'chart-44',
  'chart-45',
  'chart-46',
  'chart-47',
  'chart-48',
  'chart-49',
  'chart-50',
  'chart-51',
  'chart-52',
  'chart-53',
]

const INTERVAL_OFFSET = 5
const MIN_INTERVAL_COUNT = 4

type PopperType = {
  show: boolean
  anchorEl: HTMLDivElement | null
  top: number
  index?: number
  points: any
  key?: string
}

const Chart: React.FC<Props> = ({ name, keys, mainKey, data, style }) => {
  const [popper, setPopper] = useState<PopperType>({
    show: false,
    anchorEl: null,
    top: 0,
    index: 0,
    points: null,
  })
  const [poppers, setPoppers] = useState<PopperType[]>([])

  const columns = useRef<{ [key: string]: any }[]>(data.map(() => ({})))

  const isMobile = useIsMobile()

  const intervalHeight = useMemo(() => (isMobile ? 44 : 60), [isMobile])

  const { show, anchorEl, top, index, points: popperPoints } = popper

  const handleClosePopper = () => setPopper({ ...popper, show: false })
  const handleClosePoppers = () => setPoppers((prev) => prev.map((i) => ({ ...i, show: false })))

  const nrBars = useMemo(() => {
    let sum = 0
    data.forEach((item) => {
      let newI = Object.values(item).filter((i) => i > 0)
      sum += Object.keys(newI).length
    })
    return sum
  }, [data])

  const { subKeys, mapColor, smallChart, columnSmall } = useMemo(() => {
    const mapColor: any = {}
    let index = 0

    // Các cột sub là toàn bộ cột trừ đi cột main
    const subKeys = keys.filter(({ key }) => {
      const isSub = key !== mainKey
      if (isSub) {
        mapColor[key] = `var(--cl-${columnColors[index]})`
        index += 1
      }
      return isSub
    })

    const smallChart = subKeys.length < 0 // Điều kiện để chú thích ngang hàng với title
    const columnSmall = !smallChart

    return { subKeys, mapColor, smallChart, columnSmall }
  }, [keys, mainKey])

  const { maxPoint, points, height } = useMemo(() => {
    // Dùng reduce để lấy điểm cao nhất trong data

    let maxPointOfData = 0
    data?.forEach((dataItem) =>
      Object.keys(dataItem).forEach((keyItem) => {
        if (keyItem !== mainKey) {
          if (dataItem[keyItem] > maxPointOfData) maxPointOfData = dataItem[keyItem]
        }
      })
    )

    // Từ đó sẽ tính được thang đo có bao nhiêu dòng (30 điểm max => 6 khoảng,
    // 25 max => 5 khoảng ..., ít nhất 4 khoảng)
    let intervalCount = Math.max(Math.ceil(maxPointOfData / INTERVAL_OFFSET), MIN_INTERVAL_COUNT)
    if (intervalCount % 2 == 0 && nrBars > 30) {
      intervalCount = intervalCount + 1
    }
    const maxPointOfChart = intervalCount * INTERVAL_OFFSET

    // Danh sách các điểm (0, 5, 10, 15 ...)
    const points = Array.from({ length: intervalCount + 1 }).map(
      (_, index) => index * INTERVAL_OFFSET
    )
    // Chiều cao của đồ thị (số khoảng * chiều cao 1 khoảng)
    const height = intervalCount * intervalHeight

    return { maxPoint: maxPointOfChart, points, height }
  }, [subKeys, data, intervalHeight])

  const glossary = (
    <div className="Chart-glossary d-f fw-w jc-c">
      {subKeys
        .filter(({ key }) => data.some((j) => j[key]))
        .map(({ key, label }) => (
          <div key={key} className="Chart-glossary__item d-f typo-16-5 neutral-1 ai-c">
            <div className="Chart-glossary__block " style={{ backgroundColor: mapColor[key] }} />
            <div className="b-w" style={{ maxWidth: '100%' }}>
              {label}
            </div>
          </div>
        ))}
    </div>
  )

  const renderLines = (
    <div className="Chart-lines w-100 d-f fd-cr jc-sb" style={{ height }}>
      {points.map((item) => (
        <div key={item}>
          <div className="neutral-2">{item}</div>
        </div>
      ))}
    </div>
  )

  const renderColumnWrapper = (item: any, i: number) => (
    <div
      className={`Chart-columnWrapper d-f ai-e${smallChart ? ' c-p' : ''}`}
      key={`${i}-${item[mainKey]}`}
      style={{ opacity: show && index !== i ? 0.5 : undefined }}
      onMouseOver={
        smallChart
          ? (e) => {
              const max = subKeys.reduce((a, b) => Math.max(item[b.key], a), 0)
              const ratio = 1 - max / maxPoint

              setPopper({
                show: true,
                anchorEl: e.currentTarget,
                top: -e.currentTarget.scrollHeight * ratio + (isMobile ? 32 : 36),
                index: i,
                points: item,
              })
            }
          : undefined
      }
      onMouseOut={smallChart ? handleClosePopper : undefined}
    >
      {subKeys.map(({ key }) => {
        const value = item[key]
        if (!value) return null

        const height = Math.floor((value / maxPoint) * 10000) / 100 + '%'

        return (
          <div
            className={`Chart-column${columnSmall ? ' small' : ''}${smallChart ? '' : ' c-p'}`}
            key={`${i}-${key}`}
            style={{
              height,
              backgroundColor: mapColor[key],
              opacity: poppers.some((i) => i.show) && key !== poppers[0].key ? 0.5 : 1,
            }}
            ref={(ref) => (columns.current[i][key] = ref)}
            onMouseOver={
              !smallChart
                ? () => {
                    setPoppers((prev) => {
                      const poppers = data.map((it, id) => {
                        const points = it[key] || 0
                        const show = !!points
                        const anchorEl = columns.current[id][key] || prev[id]?.anchorEl || null

                        const top = points ? -anchorEl?.clientHeight - (isMobile ? 64 : 84) : 0
                        return { show, anchorEl, top, points, key }
                      })

                      return poppers
                    })
                  }
                : undefined
            }
            onMouseOut={!smallChart ? handleClosePoppers : undefined}
          />
        )
      })}
      {smallChart && <div className="Chart-columnWrapper__block" />}
      <div className="Chart-columnWrapper__label neutral-1">{item[mainKey]}</div>
    </div>
  )

  return (
    <div
      className={`${smallChart ? 'Chart-small' : ''}${isMobile ? ' Chart-mobile' : ''}`}
      style={style}
    >
      {smallChart ? (
        <Popper show={show} anchorEl={anchorEl} top={top}>
          {subKeys.map(({ key }) => (
            <div key={key} className="Chart-popper__glossary d-f ai-c typo-14-5 neutral-1">
              <div style={{ backgroundColor: mapColor[key] }} />
              {(popperPoints?.[key] || 0).toFixed(2)} điểm
            </div>
          ))}
        </Popper>
      ) : (
        <>
          {data.map((_, index) => {
            const popper: any = poppers[index] || { show: false, anchorEl: null, top: 0 }
            return (
              <Popper {...popper} key={index} placeBottom>
                <div className="Chart-popper__glossary d-f ai-c typo-14-5 neutral-1">
                  <div style={{ backgroundColor: mapColor[popper.key] }} />
                  {(popper.points || 0).toFixed(2)} điểm
                </div>
              </Popper>
            )
          })}
        </>
      )}
      <div className="d-f jc-sb ai-c">
        <div className="title typo-24-6 primary">{name}</div>
        {smallChart && glossary}
      </div>
      <div>
        {isMobile && !smallChart ? (
          data.map((item, index) => {
            const countColumn = Object.values(item).filter((i) => i > 0).length - 1

            return (
              <div key={index} className="Chart-wrapper w-100">
                <div className="w-100 h-100">
                  {renderLines}

                  <div
                    className={`Chart-content-main ${isMobile ? 'mobile' : ''} ${
                      nrBars > 30 ? '' : 'hideScroll'
                    }`}
                  >
                    <div
                      className={`Chart-content w-100 h-100 d-f ${
                        isMobile && countColumn > 11 ? 'jc-sb' : 'jc-se'
                      }`}
                    >
                      {renderColumnWrapper(item, index)}
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className="Chart-wrapper w-100">
            <div className="w-100 h-100">
              {renderLines}
              <div className={`Chart-content-main ${nrBars > 30 ? '' : 'hideScroll'}`}>
                <div
                  className={`Chart-content w-100 h-100 d-f ${
                    nrBars > 30 ? 'jc-sb' : 'jc-se hideScroll'
                  } `}
                >
                  {data.map(renderColumnWrapper)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!smallChart && glossary}
    </div>
  )
}

export default Chart
