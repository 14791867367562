import { ALL, mappingScientificPaperGroup } from './constants'
import { upperFirst } from 'lodash'
import { ScientificPaperType, SelectType } from 'types'

export const checkIsProceedings = (type: string) => ['proceedings'].includes(type.toLowerCase())
// , 'conference', 'inproceedings'

export const listTypesLess: SelectType[] = [
  'article',
  // 'booklet',
  // 'conference',
  // 'inbook',
  // 'incollection',
  'book',
  'conference',
  'inproceedings',
  // 'manual',
  'mastersthesis',
  // 'misc',
  'phdthesis',
  'proceedings',
  'techreport',
  // 'unpublished',
].map((key) => ({
  value: key,
  label: upperFirst(key),
}))

export const listTypesLessUser: SelectType[] = [
  'article',
  'inproceedings',
  'book',
  'mastersthesis',
  'phdthesis',
  'techreport',
  'conference',
  'proceedings',
].map((key) => ({
  value: key,
  label: upperFirst(key),
}))

export const listScientificPaperGroup: SelectType[] = Object.keys(mappingScientificPaperGroup).map(
  (key) => ({
    value: Number(key),
    label: mappingScientificPaperGroup[key as unknown as keyof typeof mappingScientificPaperGroup],
  })
)

const firtOfListScientificPaperGroup = listScientificPaperGroup.slice(0, 1)

const newListScientificPaperGroup = listScientificPaperGroup
  .slice(1)
  .sort((a, b) => a.label.localeCompare(b.label))

export const listScientificPaperGroupSort = [
  ...newListScientificPaperGroup,
  ...firtOfListScientificPaperGroup,
]

export const listTypes = [{ value: ALL, label: 'Loại' }].concat(listTypesLess)

export const defaultInitialValues: ScientificPaperType = {
  id: '',
  ENTRYTYPE: 'article',
  title: '',
  authors: [],
}

const alwaysShowFields = ['type', 'title', 'year', 'month', 'note']

const mappingFields: any = {
  article: ['journal', 'volume', 'book_number', 'page', 'language'],
  inproceedings: [
    'main_editor',
    'booktitle',
    'publisher',
    'book_number',
    'page',
    // 'editor',
    'language',
    'series',
    'institution',
    'address',
  ],
  conference: [
    'main_editor',
    'booktitle',
    'publisher',
    'volume',
    'page',
    // 'editor',
    'series',
    'institution',
    'address',
  ],
  book: ['publisher', 'volume', 'book_number', 'edition', 'language', 'series', 'address'],
  booklet: ['howpublished', 'address'],
  proceedings: ['main_editor', 'publisher', 'volume', 'series', 'institution', 'address'],
  inbook: [
    'publisher',
    'chapter',
    'volume',
    'book_number',
    'page',
    'edition',
    'language',
    'series',
    'address',
    'book_type',
  ],
  incollection: [
    'booktitle',
    'publisher',
    'chapter',
    'volume',
    'book_number',
    'page',
    'editor',
    'edition',
    'language',
    'series',
    'institution',
    'address',
    'book_type',
  ],
  manual: ['edition', 'institution', 'address'],
  mastersthesis: ['school', 'address', 'book_type'],
  phdthesis: ['school', 'address', 'book_type'],
  techreport: ['school', 'book_number', 'address', 'book_type'],
  misc: ['howpublished', 'page'],
  unpublished: [],
}

Object.keys(mappingFields).forEach((item) => {
  mappingFields[item] = [...alwaysShowFields, ...mappingFields[item]]
  if (!checkIsProceedings(item)) mappingFields[item] = ['main_author', ...mappingFields[item]]
  else mappingFields[item] = ['main_editor', ...mappingFields[item]]
})

const fields = [
  'type',
  'title',
  'main_author',
  'main_editor',
  'journal',
  'booktitle',
  'school',
  'publisher',
  // 'howpublished',
  'year',
  'month',
  // 'chapter',
  'volume',
  'book_number',
  'page',
  'editor',
  'edition',
  'language',
  'series',
  'institution',
  'address',
  'book_type',
  'note',
]

export const getShownFields = (type: string) => {
  if (!type) return []

  const arr = mappingFields[type]

  return fields.filter((item) => arr.includes(item))
}

export const defaultModal: {
  show: boolean
  type: string
  initialValues: ScientificPaperType
} = { show: false, type: 'create', initialValues: defaultInitialValues }

const fullWidth = true
const halfWidthMobile = true

export const mappingFieldUser: {
  [key: string]: {
    id: string
    fullWidth?: boolean
    rows?: number
    maxLength?: number
    halfWidthMobile?: boolean
  }[]
} = {
  article: [
    { id: 'journal', fullWidth, maxLength: 500 },
    { id: 'month', halfWidthMobile },
    { id: 'year', halfWidthMobile },
    { id: 'volume', maxLength: 10 },
    { id: 'book_number', maxLength: 10 },
    { id: 'page' },
    { id: 'language' },
  ],
  inproceedings: [
    { id: 'booktitle', maxLength: 200 },
    { id: 'publisher', maxLength: 200 },
    { id: 'month', halfWidthMobile },
    { id: 'year', halfWidthMobile },
    { id: 'volume', maxLength: 10 },
    { id: 'book_number', maxLength: 10 },
    { id: 'page' },
    { id: 'editor', maxLength: 250 },
    { id: 'language' },
    { id: 'series' },
    { id: 'institution', maxLength: 200 },
    { id: 'address', maxLength: 200 },
  ],
  book: [
    { id: 'publisher', fullWidth, maxLength: 200 },
    { id: 'month', halfWidthMobile },
    { id: 'year', halfWidthMobile },
    { id: 'volume', maxLength: 10 },
    { id: 'book_number', maxLength: 10 },
    { id: 'edition' },
    { id: 'language' },
    { id: 'series' },
    { id: 'address', maxLength: 200 },
  ],
  mastersthesis: [
    { id: 'school', fullWidth, maxLength: 200 },
    { id: 'month', halfWidthMobile },
    { id: 'year', halfWidthMobile },
    { id: 'address', maxLength: 200 },
    { id: 'book_type' },
  ],
  phdthesis: [
    { id: 'school', fullWidth, maxLength: 200 },
    { id: 'month' },
    { id: 'year' },
    { id: 'address', maxLength: 200 },
    { id: 'book_type' },
  ],
  techreport: [
    { id: 'school', fullWidth, maxLength: 200 },
    { id: 'month', halfWidthMobile },
    { id: 'year', halfWidthMobile },
    { id: 'book_number', maxLength: 10 },
    { id: 'address', maxLength: 200 },
    { id: 'book_type', fullWidth },
  ],
  conference: [
    { id: 'booktitle', maxLength: 200 },
    { id: 'publisher', maxLength: 200 },
    { id: 'month', halfWidthMobile },
    { id: 'year', halfWidthMobile },
    { id: 'volume', maxLength: 10 },
    { id: 'page' },
    { id: 'editor', maxLength: 250 },
    { id: 'series' },
    { id: 'institution', maxLength: 200 },
    { id: 'address', maxLength: 200 },
  ],
  proceedings: [
    { id: 'publisher', fullWidth, maxLength: 200 },
    { id: 'month', halfWidthMobile },
    { id: 'year', halfWidthMobile },
    { id: 'volume', maxLength: 10 },
    { id: 'series' },
    { id: 'institution', maxLength: 200 },
    { id: 'address', maxLength: 200 },
  ],
}

for (const key in mappingFieldUser) {
  mappingFieldUser[key].push({ id: 'note', fullWidth, rows: 3, maxLength: 4000 })
}

export const entryFieldsSubmit: { [key: string]: string[] } = {
  article: [
    'ENTRYTYPE',
    'author',
    'bibtex',
    'journal',
    'language',
    'month',
    'note',
    'number',
    'title',
    'volume',
    'year',
    'group',
    'main_research_ids',
  ],
  inproceedings: [
    'ENTRYTYPE',
    'address',
    'author',
    'bibtex',
    'booktitle',
    'editor',
    'institution',
    'language',
    'month',
    'note',
    'number',
    'organization',
    'publisher',
    'series',
    'title',
    'volume',
    'year',
    'group',
    'main_research_ids',
  ],
  book: [
    'ENTRYTYPE',
    'address',
    'author',
    'bibtex',
    'edition',
    'language',
    'month',
    'note',
    'number',
    'publisher',
    'series',
    'title',
    'volume',
    'year',
    'group',
    'main_research_ids',
  ],
  mastersthesis: [
    'ENTRYTYPE',
    'address',
    'author',
    'bibtex',
    'month',
    'note',
    'school',
    'title',
    'type',
    'year',
    'group',
    'main_research_ids',
  ],
  phdthesis: [
    'ENTRYTYPE',
    'address',
    'author',
    'bibtex',
    'month',
    'note',
    'school',
    'title',
    'type',
    'year',
    'group',
    'main_research_ids',
  ],
  techreport: [
    'ENTRYTYPE',
    'address',
    'author',
    'bibtex',
    'institution',
    'month',
    'note',
    'number',
    'school',
    'title',
    'type',
    'year',
    'group',
    'main_research_ids',
  ],
  conference: [
    'ENTRYTYPE',
    'address',
    'author',
    'bibtex',
    'booktitle',
    'editor',
    'institution',
    'month',
    'note',
    'publisher',
    'series',
    'title',
    'volume',
    'year',
    'group',
    'main_research_ids',
  ],
  proceedings: [
    'ENTRYTYPE',
    'address',
    'author',
    'bibtex',
    'institution',
    'month',
    'note',
    'publisher',
    'series',
    'title',
    'volume',
    'year',
    'group',
    'main_research_ids',
  ],
}
