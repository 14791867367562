import React from 'react'
import { ScientificPaperType } from 'types'
import ScientificPapers from '../scientificPapers/ScientificPapers'

interface Props {
  scientific_papers?: ScientificPaperType[]
}

const DepartmentScientificPapers: React.FC<Props> = ({ scientific_papers }) => {
  return <ScientificPapers scientificPapers={scientific_papers} />
}

export default DepartmentScientificPapers
