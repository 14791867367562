"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.remove = exports.list = exports.has = exports.get = exports.add = void 0;
const configs = {};

const add = (ref, config) => {
  configs[ref] = config;
};

exports.add = add;

const get = ref => configs[ref];

exports.get = get;

const has = ref => Object.prototype.hasOwnProperty.call(configs, ref);

exports.has = has;

const remove = ref => {
  delete configs[ref];
};

exports.remove = remove;

const list = () => Object.keys(configs);

exports.list = list;