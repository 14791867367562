import { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AppReducer, RootState } from 'types'

import { getMenuApi } from './actions'

export default function useApp() {
  const dispatch = useDispatch()
  const appReducer: AppReducer = useSelector((state: RootState) => state.app, shallowEqual)

  return useMemo(
    () => ({
      ...appReducer,
      getMenuApi: () => dispatch(getMenuApi()),
    }),
    [dispatch, appReducer]
  )
}
