/* eslint-disable */
import { Chip } from '@mui/material'
import { Props } from 'components/admin/select/Select'
import Select from 'components/user/select/Select'
import { apiUrls } from 'configs/apis'
import { MAX_PAGE_SIZE } from 'configs/constants'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useApis } from 'services/api'
import { SelectType } from 'types'
import TopLabel from '../topLabel/TopLabel'
import './index.scss'

interface PropsType extends Props {
  reload?: boolean
}

const SelectMainResearch: React.FC<PropsType> = forwardRef(({ reload, ...rest }, ref) => {
  const page = useRef(1)
  const [mainResearch, setMainResearch] = useState<SelectType[]>([])
  const { apiGet } = useApis()

  useImperativeHandle(ref, () => ({
    callMainResearch() {
      getMainResearch()
    },
  }))

  const getMainResearch = useCallback(() => {
    apiGet(
      apiUrls.adminMainResearch(),
      { page_size: MAX_PAGE_SIZE, page: page.current, sort: 'name', order_by: 'asc' },
      ({ status, data }) => {
        if (status) {
          const dataMainResearch = data.items.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))
          setMainResearch(dataMainResearch)
        }
      }
    )
  }, [page])

  useEffect(() => {
    page.current = 1
    getMainResearch()
  }, [getMainResearch])

  const { checkbox } = rest

  const handleDelete = (idDelete: string) => {
    const newChecked = checkbox?.selected.filter((item) => item.id !== idDelete) || []
    checkbox?.onChange(newChecked)
  }

  // if (mainResearch?.length === 0) return null
  return (
    <div className="select_main_research">
      <TopLabel label="Các hướng nghiên cứu chính" />
      <Select
        fullWidth
        border
        data={mainResearch}
        placeholder="Các hướng nghiên cứu chính"
        popupSearch={{
          searchFrontend: true,
          labelTotallyNoItems: 'Không có kết quả',
          maxLength: 50,
        }}
        {...rest}
      />
      <div className="checked_zone">
        {checkbox?.selected.map((item) => (
          <div className="checked_item" key={item.id}>
            <Chip
              variant="outlined"
              onDelete={() => handleDelete(item.id)}
              label={item.name?.slice(0, 37) + (item.name?.length > 37 ? '...' : '')}
            />
          </div>
        ))}
      </div>
    </div>
  )
})
export default SelectMainResearch
