/* eslint-disable */
import Modal from '../../modal/Modal'
import React, { useEffect, useState } from 'react'
import { DialogActions } from '@mui/material'
import Button from '../button/Button'
import * as Yup from 'yup'
import { useApis } from 'services/api'
import { Form, FormikProvider, useFormik } from 'formik'
import TopLabel from '../topLabel/TopLabel'
import Input from '../input/Input'
import { validatePhone, validateTypingEmail, validateTypingName, validateTypingPhone } from 'utils'
import { useTranslation } from 'react-i18next'
import ModalButtonCancel from '../../modal/ModalButtonCancel'
import { emailSchema } from 'configs/schemas'
import { apiUrls } from 'configs/apis'
import Scrollbar from '../../scrollbar/Scrollbar'

const validationSchema = Yup.object().shape({
  full_name: Yup.string().trim().required('Họ và tên không được để trống.'),
  email: emailSchema,
  phone: Yup.string().matches(validatePhone(), 'Số điện thoại không hợp lệ.'),
  title: Yup.string().trim().required('Tiêu đề không được để trống.'),
  content: Yup.string().trim().required('Nội dung không được để trống.'),
})

interface Props {
  show: boolean
  onClose: () => void
}

const ModalCooperate: React.FC<Props> = ({ show, onClose }) => {
  const { t } = useTranslation()
  const { apiPost } = useApis()

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const formik = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      title: '',
      content: '',
      phone: '',
    },
    enableReinitialize: true,
    validationSchema,
    // validateOnChange: false,
    onSubmit: ({ email, full_name, content, phone, title }) => {
      setLoading(true)
      full_name = full_name.trim()
      content = content.trim()
      phone = phone.trim()
      title = title.trim()

      apiPost(apiUrls.contactInfo(), { email, full_name, content, phone, title }, ({ status }) => {
        setLoading(false)

        if (status) onClose()
      })
    },
  })

  const {
    getFieldProps,
    handleChange,
    validateForm,
    dirty,
    resetForm,
    handleSubmit,
    errors,
    touched,
  } = formik

  useEffect(() => {
    if (!show) return

    setSubmitted(false)
    resetForm()
    setTimeout(validateForm, 10)
  }, [show])

  return (
    <Modal
      className="ModalCreateUpdate ModalCooperate"
      show={show}
      size="sm"
      title="Liên hệ"
      close={onClose}
      dirty={dirty}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="ModalCooperate-note neutral-2">
            Xin vui lòng điền form dưới đây. Chúng tôi sẽ liên hệ lại ngay cho bạn khi nhận được
            thông tin.
          </div>
          <Scrollbar
            style={{ maxHeight: 'calc(94vh - 270px)', marginRight: -15, paddingRight: 15 }}
          >
            <div className="ModalCreateUpdate-mb-3">
              <TopLabel label={t('full_name')} required />
              <Input
                placeholder={t('full_name')}
                {...getFieldProps('full_name')}
                maxLength={50}
                onChange={(e) => {
                  if (validateTypingName(e)) handleChange(e)
                }}
                error={errors.full_name}
                errorEmpty={submitted || touched.full_name}
              />
            </div>
            <div className="ModalCreateUpdate-mb-3">
              <TopLabel label={t('email')} required />
              <Input
                placeholder={t('email')}
                {...getFieldProps('email')}
                maxLength={50}
                onChange={(e) => {
                  if (validateTypingEmail(e)) handleChange(e)
                }}
                error={t(errors.email as any)}
                errorEmpty={submitted || touched.email}
              />
            </div>
            <div className="ModalCreateUpdate-mb-3">
              <TopLabel label={t('phone')} />
              <Input
                placeholder={t('phone')}
                {...getFieldProps('phone')}
                error={errors.phone}
                onChange={(e) => {
                  if (e.target.value === '' || validateTypingPhone(e)) handleChange(e)
                }}
                maxLength={20}
              />
            </div>
            <div className="ModalCreateUpdate-mb-3">
              <TopLabel label={t('title')} required />
              <Input
                placeholder={t('title')}
                {...getFieldProps('title')}
                maxLength={100}
                error={errors.title}
                errorEmpty={submitted || touched.title}
              />
            </div>
            <div className="mb-3">
              <TopLabel label={t('content')} required />
              <Input
                placeholder={t('content')}
                {...getFieldProps('content')}
                maxLength={500}
                multiline
                rows={5}
                error={errors.content}
                errorEmpty={submitted || touched.content}
              />
            </div>
          </Scrollbar>
          <DialogActions>
            <ModalButtonCancel onClick={onClose}>Hủy</ModalButtonCancel>
            <Button type="submit" onClick={() => setSubmitted(true)} loading={loading}>
              Gửi
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Modal>
  )
}

export default ModalCooperate
