import { apiUrls } from 'configs/apis'
import { MAX_ATTACHMENT_SIZE } from 'configs/constants'
import { useMemo } from 'react'
import { useApis } from 'services/api'
import { useModalConfirm } from 'store/modalConfirm'

export const useUploadFile = (hasCloseButton?: boolean) => {
  const { apiPost } = useApis()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()

  const showPopupCantUpload = (maxSizeLabel?: string) =>
    showModalConfirm({
      title: 'Thông báo',
      content: `Không thể upload file trên ${maxSizeLabel || '50 MB'}.`,
      confirm: { action: hideModalConfirm, text: 'Đã hiểu' },
      close: hasCloseButton ? hideModalConfirm : undefined,
    })

  return useMemo(
    () => ({
      showPopupCantUpload,
      canUpload: (fileSize: number, maxSize?: number, maxSizeLabel?: string) => {
        if (fileSize > (maxSize || MAX_ATTACHMENT_SIZE)) {
          showPopupCantUpload(maxSizeLabel)
          return false
        }
        return true
      },
      upload: (
        file: any,
        prefix: string,
        onSuccess?: (url: string, id?: string, fileSize?: number) => void,
        onError?: (error: string, id?: string) => void,
        identify?: string,
        id?: string
      ) => {
        apiPost(apiUrls.upload(), { upload: { prefix, file } }, ({ status, data, text }) => {
          if (status) {
            onSuccess?.(data.file_url, identify)
          } else onError?.(text, id)
        })
      },
    }),
    [apiPost]
  )
}
