"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAsync = exports.set = exports.reset = exports.addAsync = exports.add = void 0;

var _index = require("../plugins/input/index.js");

var _fetchId = _interopRequireDefault(require("../util/fetchId.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const add = function (data, options = {}, log = false) {
  if (options === true || log === true) {
    this.save();
  }

  this.data.push(...(0, _index.chain)(data, options));
  this.data.filter(entry => !Object.prototype.hasOwnProperty.call(entry, 'id')).forEach(entry => {
    entry.id = (0, _fetchId.default)(this.getIds(), 'temp_id_');
  });
  return this;
};

exports.add = add;

const addAsync = async function (data, options = {}, log = false) {
  if (options === true || log === true) {
    this.save();
  }

  this.data.push(...(await (0, _index.chainAsync)(data, options)));
  this.data.filter(entry => !Object.prototype.hasOwnProperty.call(entry, 'id')).forEach(entry => {
    entry.id = (0, _fetchId.default)(this.getIds(), 'temp_id_');
  });
  return this;
};

exports.addAsync = addAsync;

const set = function (data, options = {}, log = false) {
  if (options === true || log === true) {
    this.save();
  }

  this.data = [];
  return typeof options !== 'boolean' ? this.add(data, options) : this.add(data);
};

exports.set = set;

const setAsync = async function (data, options = {}, log = false) {
  if (options === true || log === true) {
    this.save();
  }

  this.data = [];
  return typeof options !== 'boolean' ? this.addAsync(data, options) : this.addAsync(data);
};

exports.setAsync = setAsync;

const reset = function (log) {
  if (log) {
    this.save();
  }

  this.data = [];
  this._options = {};
  return this;
};

exports.reset = reset;