import { CircularProgress } from '@mui/material'
import React, { CSSProperties } from 'react'
import './index.scss'

interface Props {
  className?: string
  small?: boolean
  fullScreen?: boolean
  style?: CSSProperties
  overlay?: boolean
}

const Loading: React.FC<Props> = ({ className, fullScreen, style, small }) => {
  return (
    <div
      style={style}
      className={`Loading-container${small ? ' small' : ''} ${fullScreen && 'full'} ${className}`}
    >
      <CircularProgress size={small ? 34 : 50} color="primary" />
    </div>
  )
}

export default Loading
