"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.remove = exports.list = exports.has = exports.get = exports.add = void 0;

var _parser = require("./parser.js");

var _type = require("./type.js");

var _data = require("./data.js");

const formats = {};

const add = (format, parsers) => {
  const formatParser = new _parser.FormatParser(format, parsers);
  formatParser.validate();
  const index = formats[format] || (formats[format] = {});

  if (formatParser.typeParser) {
    (0, _type.addTypeParser)(format, formatParser.typeParser);
    index.type = true;
  }

  if (formatParser.dataParser) {
    (0, _data.addDataParser)(format, formatParser.dataParser);
    index.data = true;
  }

  if (formatParser.asyncDataParser) {
    (0, _data.addDataParser)(format, formatParser.asyncDataParser);
    index.asyncData = true;
  }

  if (parsers.outputs) {
    index.outputs = parsers.outputs;
  }
};

exports.add = add;

const get = format => {
  return formats[format];
};

exports.get = get;

const remove = format => {
  const index = formats[format];

  if (!index) {
    return;
  }

  if (index.type) {
    (0, _type.removeTypeParser)(format);
  }

  if (index.data) {
    (0, _data.removeDataParser)(format);
  }

  if (index.asyncData) {
    (0, _data.removeDataParser)(format, true);
  }

  delete formats[format];
};

exports.remove = remove;

const has = format => format in formats;

exports.has = has;

const list = () => Object.keys(formats);

exports.list = list;