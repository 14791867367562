/* eslint-disable */
import { mappingAcademicRank, mappingDegree, mappingDegreeLevel } from 'configs/constants'
import { SelectType, UserType } from 'types'
import { formatCompare } from 'utils'

export const ROLE_PARTY_COMMITTEES = {
  ALL_ROLES: '0',
  SECRETARY_PARTY_COMMITTEE: '1',
  DEPUTY_SECRETARY_PARTY_COMMITTEE: '2',
  PARTY_MEMBER: '3',
  PARTY_CELL_SECRETARY: '4',
  DEPUTY_PARTY_BRANCH: '5',
  BRANCH_COMMISSIONER: '6',
  PARTY_COMMISSIONER_MEMBER: '7',
  NOTHING: '8',
}

export const REGENCIES = {
  ALL_REGENCIES: '0',
  TEACHER: '3',
  HEAD_OF_DEPARTMENT: '1',
  VICE_HEAD_OF_DEPARTMENT: '4',
  HEAD_OF_FACULTY: '2',
  VICE_HEAD_OF_FACULTY: '5',
  HEAD_OF_LABORATORY: '10',
  HEAD_OF_RESEARCH_TEAM: '8',
  HEAD_OF_STRONG_RESEARCH_TEAM: '9',
  DOCUMENTER: '11',
  CONTENT_MANAGER: '6',
  CAPTAIN_CENTER: '12',
  DEPUTY_CAPTAIN_CENTER: '13',
  GENERAL_ASSISTANT: '7',
  HEAD: '14',
  DEPUTY_HEAD: '15',
  CENTER_MANAGER: '16',
  ASSISTANT_CENTER_MANAGER: '17',
  DEPUTY_DEAN: '18',
  INSTITUTE_DIRECTOR: '19',
}

export const STATUSES = {
  ALL_STATUSES: '-1',
  INACTIVE: '0',
  ACTIVE: '1',
}

const {
  ALL_ROLES,
  SECRETARY_PARTY_COMMITTEE,
  DEPUTY_SECRETARY_PARTY_COMMITTEE,
  PARTY_MEMBER,
  PARTY_CELL_SECRETARY,
  DEPUTY_PARTY_BRANCH,
  BRANCH_COMMISSIONER,
  PARTY_COMMISSIONER_MEMBER,
  NOTHING,
} = ROLE_PARTY_COMMITTEES

export const mappingRolePartyCommittee: any = {
  [ALL_ROLES]: 'Chức vụ Đảng',
  [SECRETARY_PARTY_COMMITTEE]: 'Bí thư Đảng ủy',
  [DEPUTY_SECRETARY_PARTY_COMMITTEE]: 'Phó bí thư Đảng ủy',
  [PARTY_MEMBER]: 'Đảng ủy viên',
  [PARTY_CELL_SECRETARY]: 'Bí thư chi bộ',
  [DEPUTY_PARTY_BRANCH]: 'Phó bí thư chi bộ',
  [BRANCH_COMMISSIONER]: 'Chi ủy viên',
  [PARTY_COMMISSIONER_MEMBER]: 'Đảng viên',
  [NOTHING]: 'Không có',
}

const {
  ALL_REGENCIES,
  HEAD_OF_DEPARTMENT,
  HEAD_OF_FACULTY,
  TEACHER,
  VICE_HEAD_OF_DEPARTMENT,
  VICE_HEAD_OF_FACULTY,
  CONTENT_MANAGER,
  HEAD_OF_RESEARCH_TEAM,
  HEAD_OF_STRONG_RESEARCH_TEAM,
  HEAD_OF_LABORATORY,
  DOCUMENTER,
  CAPTAIN_CENTER,
  DEPUTY_CAPTAIN_CENTER,
  GENERAL_ASSISTANT,
  HEAD,
  DEPUTY_HEAD,
  CENTER_MANAGER,
  ASSISTANT_CENTER_MANAGER,
  DEPUTY_DEAN,
  INSTITUTE_DIRECTOR,
} = REGENCIES

export const mappingRegency: any = {
  [ALL_REGENCIES]: 'Chức vụ chính quyền',
  [HEAD_OF_DEPARTMENT]: 'Chủ nhiệm bộ môn',
  [HEAD_OF_FACULTY]: 'Chủ nhiệm khoa',
  [TEACHER]: 'Giáo viên',
  [VICE_HEAD_OF_DEPARTMENT]: 'Phó Chủ nhiệm bộ môn',
  [VICE_HEAD_OF_FACULTY]: 'Phó chủ nhiệm khoa',
  [CONTENT_MANAGER]: 'Quản trị nội dung',
  [HEAD_OF_RESEARCH_TEAM]: 'Trưởng nhóm nghiên cứu',
  [HEAD_OF_STRONG_RESEARCH_TEAM]: 'Trưởng nhóm nghiên cứu mạnh',
  [HEAD_OF_LABORATORY]: 'Trưởng phòng thí nghiệm',
  [DOCUMENTER]: 'Văn thư',
  [CAPTAIN_CENTER]: 'Chỉ huy trưởng trung tâm',
  [DEPUTY_CAPTAIN_CENTER]: 'Phó chỉ huy trưởng trung tâm',
  [GENERAL_ASSISTANT]: 'Trợ lý tổng hợp',
  [HEAD]: 'Trưởng phòng',
  [DEPUTY_HEAD]: 'Phó trưởng phòng',
  [CENTER_MANAGER]: 'Trưởng trung tâm',
  [ASSISTANT_CENTER_MANAGER]: 'Phó trưởng trung tâm',
  [DEPUTY_DEAN]: 'Phó Viện trưởng',
  [INSTITUTE_DIRECTOR]: 'Viện trưởng',
}
export const mappingRegencyForExport: any = {
  [ALL_REGENCIES]: 'Chức vụ chính quyền',
  [HEAD_OF_DEPARTMENT]: 'Chủ nhiệm BM',
  [HEAD_OF_FACULTY]: 'Chủ nhiệm khoa',
  [TEACHER]: 'Giáo viên',
  [VICE_HEAD_OF_DEPARTMENT]: 'Phó Chủ nhiệm BM',
  [VICE_HEAD_OF_FACULTY]: 'PCN khoa',
  [CONTENT_MANAGER]: 'Quản trị nội dung',
  [HEAD_OF_RESEARCH_TEAM]: 'Trưởng nhóm NC',
  [HEAD_OF_STRONG_RESEARCH_TEAM]: 'Trưởng nhóm NCM',
  [HEAD_OF_LABORATORY]: 'Trưởng PTN',
  [DOCUMENTER]: 'Văn thư',
  [CAPTAIN_CENTER]: 'Chỉ huy trưởng TT',
  [DEPUTY_CAPTAIN_CENTER]: 'PCH trưởng TT',
  [GENERAL_ASSISTANT]: 'Trợ lý tổng hợp',
  [HEAD]: 'Trưởng phòng',
  [DEPUTY_HEAD]: 'Phó trưởng phòng',
  [CENTER_MANAGER]: 'Trưởng trung tâm',
  [ASSISTANT_CENTER_MANAGER]: 'Phó trưởng TT',
  [DEPUTY_DEAN]: 'Phó Viện trưởng',
  [INSTITUTE_DIRECTOR]: 'Viện trưởng',
}

const { ALL_STATUSES, INACTIVE, ACTIVE } = STATUSES

export const mappingStatus: any = {
  [ALL_STATUSES]: 'Trạng thái',
  [INACTIVE]: 'Chưa kích hoạt',
  [ACTIVE]: 'Kích hoạt',
}

export const listRegencies: SelectType[] = [
  ALL_REGENCIES,
  HEAD_OF_DEPARTMENT,
  HEAD_OF_FACULTY,
  TEACHER,
  VICE_HEAD_OF_DEPARTMENT,
  VICE_HEAD_OF_FACULTY,
  CONTENT_MANAGER,
  HEAD_OF_RESEARCH_TEAM,
  HEAD_OF_STRONG_RESEARCH_TEAM,
  HEAD_OF_LABORATORY,
  DOCUMENTER,
  CAPTAIN_CENTER,
  DEPUTY_CAPTAIN_CENTER,
  GENERAL_ASSISTANT,
  HEAD,
  DEPUTY_HEAD,
  CENTER_MANAGER,
  ASSISTANT_CENTER_MANAGER,
  DEPUTY_DEAN,
  INSTITUTE_DIRECTOR,
].map((item) => ({
  value: item,
  label: mappingRegency[item],
}))

export const MALE_GENDER = 1
export const FEMALE_GENDER = 2

export const genderList = [
  { label: 'Nam', value: 1 },
  { label: 'Nữ', value: 2 },
]

export const listStatuses: SelectType[] = [ALL_STATUSES, ACTIVE, INACTIVE].map((item) => ({
  value: item,
  label: mappingStatus[item],
}))

// export const listAcademicRanks: SelectType[] = Object.keys(mappingAcademicRank).map((item) => ({
//   value: item,
//   label: mappingAcademicRank[item],
// }))

export const listAcademicRanks: SelectType[] = [
  { label: 'Giảng viên', value: '3' },
  { label: 'Giảng viên chính', value: '4' },
  { label: 'Giảng viên cao cấp', value: '5' },
  { label: 'Giáo sư', value: '1' },
  { label: 'Phó giáo sư', value: '2' },
]

export const listDegrees: SelectType[] = Object.keys(mappingDegree).map((item) => ({
  value: item,
  label: mappingDegree[item],
}))

export const listDegreesLevel: SelectType[] = Object.keys(mappingDegreeLevel).map((item) => ({
  value: item,
  label: mappingDegreeLevel[item],
}))

export const listRegenciesLess = listRegencies.slice(1).sort((a, b) => {
  const A = formatCompare(a.label)
  const B = formatCompare(b.label)
  return A < B ? -1 : 1
})

export const listStatusesLess = listStatuses.slice(1)

export const listRoles: SelectType[] = [
  ALL_ROLES,
  SECRETARY_PARTY_COMMITTEE,
  DEPUTY_SECRETARY_PARTY_COMMITTEE,
  PARTY_MEMBER,
  PARTY_CELL_SECRETARY,
  DEPUTY_PARTY_BRANCH,
  BRANCH_COMMISSIONER,
  PARTY_COMMISSIONER_MEMBER,
  NOTHING,
].map((item) => ({
  value: item,
  label: mappingRolePartyCommittee[item],
}))

export const listRolesLess = listRoles.slice(1, -1)

export const defaultInitialValues: UserType = {
  id: '',
  avatar_url: '',
  full_name: '',
  email: '',
  academic_rank: '',
  degree: '',
  department_id: '',
  group_id: '',
  regency: '',
  status: ACTIVE,
  role_party_committee: '',
  gender: 1,
  decisioned_date_department: null,
  decisioned_date_regency: null,
}

export const defaultModal: {
  show: boolean
  type: string
  initialValues: UserType
} = { show: false, type: 'create', initialValues: defaultInitialValues }
