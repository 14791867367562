import { CircularProgress } from '@mui/material'
import { AdminContext } from 'configs/constants'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Title from '../title'

export const createDetailComponent: ({
  type,
  Component,
  apiUrl,
}: {
  type?: 'add' | 'edit' | 'view'
  Component?: any
  apiUrl: any
  child?: {
    title: string
    titleField: string
  }
  fieldName?: string
}) => React.FC = ({ type, Component, apiUrl, child, fieldName }) => {
  const Returned = () => {
    const { callApi } = useContext(AdminContext)
    const { id } = useParams() || ({} as any)
    const history = useHistory()
    const notAdd = type !== 'add'

    const [state, setState] = useState<{ loading: boolean; error: boolean; data: any }>({
      loading: notAdd,
      error: false,
      data: null,
    })

    useEffect(() => {
      notAdd &&
        callApi({
          method: 'get',
          url: apiUrl(id),
          popupError: () => history.goBack(),
          callback: ({ status, data }) => {
            setState({ loading: false, error: !status, data })
          },
        })
    }, [])

    const { loading, error, data } = state

    if (loading)
      return (
        <div className="w100 d-f ai-c jc-c" style={{ height: '60vh' }}>
          <CircularProgress size={40} color="primary" />
        </div>
      )
    if (error) return null

    const params = {
      [fieldName || 'id']: id,
    }

    return (
      <>
        {!!child && <Title title={`${child.title} ${data[child.titleField]}`} back />}
        {!!Component && <Component {...params} view={type === 'view'} d={data} />}
      </>
    )
  }
  return Returned
}

export const createDetailComponents = (
  Component: any,
  apiUrl: any,
  location: string,
  title: string,
  permission?: any,
  customComponents?: {
    type: string
    component: any
    title: string
  }[]
) => {
  return [
    ...(customComponents?.map((item) => {
      return {
        path: `${location}/${item.type}`,
        component: createDetailComponent({ type: 'add', Component: item.component, apiUrl }),
        title: item.title,
        back: true,
        clearStateOnRefresh: false,
        permission,
      }
    }) || []),
    {
      path: `${location}/add`,
      component: createDetailComponent({ type: 'add', Component, apiUrl }),
      title: `Tạo ${title}`,
      back: true,
      clearStateOnRefresh: false,
      permission,
    },
    {
      path: `${location}/:id/edit`,
      component: createDetailComponent({ type: 'edit', Component, apiUrl }),
      title: `Sửa ${title}`,
      back: true,
      clearStateOnRefresh: false,
      permission,
    },
    {
      path: `${location}/:id`,
      component: createDetailComponent({ type: 'view', Component, apiUrl }),
      title: `Xem ${title}`,
      back: true,
      clearStateOnRefresh: false,
      permission,
    },
  ]
}

export const createChildrenComponents = (
  apiUrl: any,
  titleField: string,
  children: {
    Component?: any
    title: string
    titleFull: string
    type: string
    fieldName?: string
  }[],
  location: string,
  permission?: any
) => {
  return children.map(({ Component, title, titleFull, type, fieldName }) => {
    return {
      path: `${location}/:id/${type}`,
      component: createDetailComponent({
        Component,
        apiUrl,
        child: { title, titleField },
        fieldName,
      }),
      title: titleFull,
      hideTitle: true,
      permission,
    }
  })
}
