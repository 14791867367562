/* eslint-disable */
import { Container } from '@mui/material'
import React from 'react'
import Title from '../title/Title'
import Scrollbar from 'components/scrollbar/Scrollbar'
import EditorViewer from 'components/editorViewer/EditorViewer'

interface Props {
  activity?: string
}

const DepartmentCooperation: React.FC<Props> = ({ activity }) => {
  if (!activity) return null

  return (
    <div className="DepartmentCooperation">
      <Container>
        <Title>Hợp tác trong nước và quốc tế</Title>
      </Container>
      <div className="background">
        <Container className="container d-f">
          <div className="cooperationImage" />
          <div className="content">
            <div>
              <Scrollbar style={{ maxHeight: 312 }}>
                <EditorViewer content={activity} />
              </Scrollbar>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default DepartmentCooperation
