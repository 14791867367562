import MaxLine from 'components/maxLine/MaxLine'
import React from 'react'
import { LaboratoryType } from 'types'
import './index.scss'

const LaboratoryItem: React.FC<LaboratoryType> = ({ name, description }) => {
  return (
    <div className="LaboratoryItem">
      <MaxLine className="name typo-20-6 typo-20-16" numberOfLines={1} text={name} />
      <div className="line-below" />
      <MaxLine className="description typo-16-4 mt-3" numberOfLines={4} text={description} />
    </div>
  )
}

export default LaboratoryItem
