import { Container } from '@mui/material'
import React from 'react'
import Slider from 'react-slick'
import { DepartmentChildType } from 'types'
import ButtonArrow from '../button/ButtonArrow'
import DepartmentChildItem from '../departmentChild/DepartmentChildItem'
import Title from '../title/Title'

const Arrow = <ButtonArrow extraClass="DepartmentSubjects-arrow" big outline />

const settings = {
  dots: false,
  infinite: false,
  speed: 800,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  autoplay: false,
  responsive: [
    {
      breakpoint: 860,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        variableWidth: false,
      },
    },
  ],
}

interface Props {
  department_children?: DepartmentChildType[]
  department_name?: string
}

const DepartmentChilds: React.FC<Props> = ({ department_children, department_name }) => {
  if (!department_children?.length) return null

  return (
    <div className="DepartmentChilds">
      <Container>
        <Title>{`Đơn vị thuộc ${department_name}`}</Title>
      </Container>
      <div className="banner background-image cover">
        <Container>
          <Slider
            className="DepartmentChilds-slider"
            prevArrow={Arrow}
            nextArrow={Arrow}
            {...settings}
          >
            {department_children.map((item) => (
              <DepartmentChildItem key={item.id} {...item} />
            ))}
          </Slider>
        </Container>
      </div>
    </div>
  )
}

export default DepartmentChilds
