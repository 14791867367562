import React from 'react'
import Btn from '../button/Button'

const MAX_PAGE = 5

interface Props {
  count: number
  page: number
  onChange: (newPage: number) => void
  disabled?: boolean
  [key: string]: any
}

const Button = ({ children, active, ...rest }: any) => (
  <Btn
    variant={active ? 'contained' : 'text'}
    className="AdminButton AdminPagination-button"
    {...rest}
  >
    {children}
  </Btn>
)

const Pagination: React.FC<Props> = ({ count, page, onChange, disabled }) => {
  if (count <= 1) return null

  const render: any[] = Array.from({ length: count > MAX_PAGE ? MAX_PAGE : count }).map(
    (_, index) => index + 1
  )
  if (count > MAX_PAGE) {
    if (page > 3) {
      if (page >= count - 2) {
        for (let i = 0; i < MAX_PAGE; i += 1) render[i] = count - (MAX_PAGE - 1) + i
      } else {
        for (let i = 0; i < MAX_PAGE; i += 1) render[i] = page - 2 + i
      }
    }
  }

  const handleChange = (newPage: number) => {
    if (page !== newPage) {
      onChange(newPage)
      window.scrollTo(0, 0)
    }
  }

  const showPrev = count > MAX_PAGE && page > 1
  const showNext = count > MAX_PAGE && page < count

  return (
    <div className="AdminPagination">
      {showPrev && (
        <>
          <Button onClick={() => handleChange(1)} disabled={disabled}>
            <i className="fas fa-angle-double-left" />
          </Button>
          <Button onClick={() => handleChange(page - 1)} disabled={disabled}>
            <i className="fas fa-angle-left" />
          </Button>
        </>
      )}
      {render.map((item) => {
        return (
          <Button
            key={item}
            active={page === item}
            onClick={() => handleChange(item)}
            disabled={disabled}
          >
            {item}
          </Button>
        )
      })}
      {showNext && (
        <>
          <Button onClick={() => handleChange(page + 1)} disabled={disabled}>
            <i className="fas fa-angle-right" />
          </Button>
          <Button onClick={() => handleChange(count)} disabled={disabled}>
            <i className="fas fa-angle-double-right" />
          </Button>
        </>
      )}
    </div>
  )
}

export default Pagination
