/* eslint-disable */
import { IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { validateTypingPassword } from 'utils'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  innerRef?: any
  fullWidth?: boolean
  label?: string
  error?: any
  variant?: 'outlined' | 'standard' | 'filled'
  errorFocused?: boolean
  blurWhenEnter?: boolean
  size?: 'small' | 'medium' | any
  topLabel?: string
  errorEmpty?: boolean
  multiline?: boolean
  inputRef?: any
  onRemove?: () => void
  startIcon?: string
  endIcon?: string
  requiredPlaceholder?: string
  [key: string]: any
}

const Input: React.FC<InputProps> = (props) => {
  const [isFocusing, setIsFocusing] = useState(false)

  const {
    fullWidth = true,
    type = 'text',
    name = '',
    label = '',
    error,
    value,
    onFocus,
    onBlur,
    variant = 'outlined',
    errorFocused,
    onKeyDown,
    blurWhenEnter,
    maxLength,
    inputProps,
    size = 'medium',
    topLabel,
    required,
    placeholder,
    disabled,
    className = '',
    errorEmpty,
    view,
    onChange,
    multiline,
    onRemove,
    startIcon,
    endIcon,
    innerRef,
    requiredPlaceholder,
    ...rest
  } = props as any

  const isSmall = size === 'small'
  const isPassword = type === 'password'

  const [passwordShown, setPasswordShown] = useState(!isPassword)

  /**
   * When input is focus, save status focus to this field name
   */
  const handleFocus = useCallback((e: any) => {
    setIsFocusing(true)
    onFocus?.(e)
  }, [])

  /**
   * Reset status focus when blur field
   */
  const handleBlur = useCallback((e: any) => {
    setIsFocusing(false)
    onBlur?.(e)
  }, [])

  // Reset sang trạng thái ẩn mật khẩu khi đổi màn (đổi label)
  useEffect(() => {
    if (isPassword) setPasswordShown(false)
  }, [label])

  const invalid = (errorEmpty ? true : !!value) && (!isFocusing || errorFocused) && !!error

  const adornmentPassword = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={() => (isPassword ? setPasswordShown(!passwordShown) : {})} edge="end">
          <i
            style={
              isSmall || isPassword ? { fontSize: 20, width: 26 } : { fontSize: 24, width: 32 }
            }
            className={`icon-password${value ? ' hasValue' : ''} icon-eye${
              passwordShown ? '-off' : ''
            }`}
          />
        </IconButton>
      </InputAdornment>
    ),
  }

  const helperText = invalid && error !== true ? error : undefined

  const handleChangePassword = (e: any) => {
    if (validateTypingPassword(e)) onChange(e)
  }

  const id = `Input-${name}`

  return (
    <div className={`${className}${fullWidth ? ' w-100' : ''}`}>
      <TextField
        id={id}
        className="Input"
        {...rest}
        ref={innerRef}
        multiline={multiline}
        size={size}
        disabled={disabled}
        inputProps={inputProps || { maxLength: isPassword ? 16 : maxLength }}
        fullWidth={fullWidth}
        label={label || undefined}
        name={name}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={isPassword ? handleChangePassword : onChange}
        placeholder={placeholder || topLabel}
        onKeyDown={(e) => {
          onKeyDown?.(e)
          if (blurWhenEnter && e.key === 'Enter') {
            const target = e.target as any
            setTimeout(() => target.blur(), 100)
            setIsFocusing(false)
          }
        }}
        type={isPassword ? (passwordShown ? 'text' : 'password') : type}
        value={value}
        error={invalid}
        helperText={helperText}
        variant={variant}
        required={required}
        InputProps={
          isPassword
            ? adornmentPassword
            : {
                startAdornment: startIcon ? (
                  <InputAdornment position="start">
                    <span className={startIcon} />
                  </InputAdornment>
                ) : undefined,
                endAdornment:
                  onRemove && value ? (
                    <InputAdornment position="end" style={{ fontSize: 15 }}>
                      <i onClick={onRemove} className="icon-close"></i>
                    </InputAdornment>
                  ) : requiredPlaceholder && !value ? (
                    <label className="requiredPlaceholder" htmlFor={id}>
                      {requiredPlaceholder} (<span>*</span>)
                    </label>
                  ) : undefined,
              }
        }
      />
    </div>
  )
}

export default Input
