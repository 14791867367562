"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeDataParser = exports.listDataParser = exports.hasDataParser = exports.dataAsync = exports.data = exports.addDataParser = void 0;

var _chain = require("./chain.js");

const flatten = array => [].concat(...array);

const parsers = {};
const asyncParsers = {};
const nativeParsers = {
  '@csl/object': input => [input],
  '@csl/list+object': input => input,
  '@else/list+object': input => flatten(input.map(_chain.chain)),
  '@invalid': () => {
    throw new Error('This format is not supported or recognized');
  }
};
const nativeAsyncParsers = {
  '@else/list+object': async input => flatten(await Promise.all(input.map(_chain.chainAsync)))
};

const data = (input, type) => {
  if (typeof parsers[type] === 'function') {
    return parsers[type](input);
  } else if (typeof nativeParsers[type] === 'function') {
    return nativeParsers[type](input);
  } else {
    throw new TypeError(`No synchronous parser found for ${type}`);
  }
};

exports.data = data;

const dataAsync = async (input, type) => {
  if (typeof asyncParsers[type] === 'function') {
    return asyncParsers[type](input);
  } else if (typeof nativeAsyncParsers[type] === 'function') {
    return nativeAsyncParsers[type](input);
  } else if (hasDataParser(type, false)) {
    return data(input, type);
  } else {
    throw new TypeError(`No parser found for ${type}`);
  }
};

exports.dataAsync = dataAsync;

const addDataParser = (format, {
  parser,
  async
}) => {
  if (async) {
    asyncParsers[format] = parser;
  } else {
    parsers[format] = parser;
  }
};

exports.addDataParser = addDataParser;

const hasDataParser = (type, async) => async ? asyncParsers[type] || nativeAsyncParsers[type] : parsers[type] || nativeParsers[type];

exports.hasDataParser = hasDataParser;

const removeDataParser = (type, async) => {
  delete (async ? asyncParsers : parsers)[type];
};

exports.removeDataParser = removeDataParser;

const listDataParser = async => Object.keys(async ? asyncParsers : parsers);

exports.listDataParser = listDataParser;