import MaxLine from 'components/maxLine/MaxLine'
import React from 'react'
import { MissionType } from 'types'
import './index.scss'

const MissionItem: React.FC<MissionType> = ({ image_url, title, content, dark }) => {
  return (
    <div className="MissionItem" style={{ height: '750px !important', overflowX: 'hidden' }}>
      <div
        className="MissionItem-banner background-image cover"
        style={{ backgroundImage: `url("${image_url}")` }}
      >
        <div className="title typo-20-6 typo-20-16 w-100 d-f ai-c jc-c">{title}</div>
      </div>
      <div className={`content typo-16-4${dark ? '' : ' dark'}`}>
        <MaxLine text={content} numberOfLines={9} />
      </div>
    </div>
  )
}

export default MissionItem
