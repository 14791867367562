import React from 'react'
import { Radio as MuiRadio, RadioProps } from '@mui/material'

interface Props extends RadioProps {
  label?: string
}

const Radio: React.FC<Props> = ({ label, className = '', ...rest }) => {
  return (
    <div className={`d-f ai-c ${className}`}>
      <MuiRadio {...rest} />
      {label}
    </div>
  )
}

export default Radio
