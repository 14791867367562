const { REACT_APP_API_URL } = process.env
const API_VERSION = 'v1'

export const SERVER_URL = REACT_APP_API_URL
export const API_URL = `${REACT_APP_API_URL}/api/${API_VERSION}/`

const urlId = (id?: string) => (id ? `/${id}` : '')

export const apiUrls = {
  signIn: () => 'auth/login',
  refresh: () => 'auth/refresh',
  forgotPassword: () => 'auth/forgot',
  resetPassword: () => 'auth/init',
  verifyPassword: () => 'auth/verify',
  profile: () => 'profile',
  changePassword: () => 'profile/password',
  upload: () => 'upload',
  homepage: () => 'homepage',
  menu: () => 'menu',
  menuTab: () => 'manage/users/menu_tab',
  seminar: () => 'seminar',

  adminChangePassword: () => 'manage/users/password',
  adminUsers: (id?: string) => `manage/users${urlId(id)}`,
  adminUserResetPassword: (id: string) => `manage/users/${id}/password/reset`,
  adminUserActivate: (id: string) => `manage/users/${id}/activate`,
  adminUserDeactivate: (id: string) => `manage/users/${id}/deactivate`,
  adminUserImport: () => 'manage/users/import',
  adminNews: (id?: string) => `manage/articles${urlId(id)}`,
  adminPartners: (id?: string) => `manage/partners${urlId(id)}`,
  adminGroups: (id?: string) => `manage/groups${urlId(id)}`,
  adminGroupPermissions: (id?: string) => `manage/groups${urlId(id)}/roles`,
  adminPermissions: (id?: string) => `manage/roles${urlId(id)}`,
  adminPermissionObjects: () => 'manage/roles/permission',
  adminProducts: (id?: string) => `manage/products${urlId(id)}`,
  adminBanners: (id?: string) => `manage/banners${urlId(id)}`,
  adminSetting: () => 'manage/users/setting',
  adminEnrollments: (id?: string) => `manage/enrollments${urlId(id)}`,
  adminIntroductions: (id?: string) => `manage/introductions${urlId(id)}`,
  adminDepartments: (id?: string) => `manage/departments${urlId(id)}`,
  adminDepartmentsMerge: (id?: string) => `manage/departments/merge${urlId(id)}`,
  adminDepartmentsSplit: (id?: string) => `manage/departments/divide${urlId(id)}`,
  adminDepartmentChilds: (id?: string) => `manage/department_children${urlId(id)}`,
  adminStudents: (id?: string) => `manage/students${urlId(id)}`,
  adminTrainingInfo: (id?: string) => `manage/trainings${urlId(id)}`,
  adminResearchGroups: (id?: string) => `manage/research_groups${urlId(id)}`,
  adminQuestions: (id?: string) => `manage/questions${urlId(id)}`,
  adminSubjects: (id?: string) => `manage/subjects${urlId(id)}`,
  adminAboutUs: (id?: string) => `manage/about${urlId(id)}`,
  adminRewards: (id?: string) => `manage/rewards${urlId(id)}`,
  adminResearchInfo: (id?: string) => `manage/researches${urlId(id)}`,
  adminResearchInfoIsRepresent: (id?: string) => `manage/researches/update-represent${urlId(id)}`,
  adminResearchInfoMainResearch: (id: string) => `manage/researches?main_research_id=${id}`,
  adminScientificPapers: (id?: string) => `manage/scientific_papers${urlId(id)}`,
  adminMilitaryBenchmarks: (id?: string) => `manage/benchmark${urlId(id)}`,
  adminMilitaryBenchmarkImport: () => 'manage/benchmark/import',
  adminCivilianBenchmarks: (id?: string) => `manage/benchmark_civilian${urlId(id)}`,
  adminBenchmarks: (id?: string) => `manage/benchmark_civilian${urlId(id)}`,
  adminCivilianBenchmarkImport: () => 'manage/benchmark_civilian/import',
  adminMajors: (id?: string) => `manage/majors${urlId(id)}`,
  adminContactInfo: (id?: string) => `manage/contact_information${urlId(id)}`,
  adminGeneralInfo: (id?: string) => `manage/general_information${urlId(id)}`,
  changePrioriry: () => 'manage/general_information/priority',
  adminTrainingInfoComment: (id?: string) => `manage/trainings/comment${urlId(id)}`,
  adminEnrollmentsComment: (id?: string) => `manage/enrollments/comment${urlId(id)}`,
  adminStatistic: (id?: string) => `manage/statistic${urlId(id)}`,
  adminStatisticProficiency: () => 'manage/statistic/proficiency-of-main-research',
  adminStatisticGetTime: () => 'manage/statistic/get-time',
  adminStatisticGetUser: () => 'manage/statistic/user-statistic',
  adminResearchGetUser: () => 'manage/researches/user-research',
  adminResearchGroupGetUser: () => 'manage/research_groups/user-research-group',
  adminMainResearch: (id?: string) => `manage/main_research${urlId(id)}`,
  adminChangeHistory: () => 'manage/change_history',
  adminSeminar: () => 'manage/seminar',

  news: (id?: string) => `articles${urlId(id)}`,
  enrollments: (id?: string) => `enrollments${urlId(id)}`,
  history: () => 'histories/keywords',
  search: () => 'search',
  questions: (id?: string) => `questions${urlId(id)}`,
  introductionsInformation: () => 'introductions/information',
  introductionUsers: () => 'users',
  introductionUnions: () => 'introductions/unions',
  introductionRewards: () => 'rewards',
  introductions: (id: string) => `introductions/${id}`,
  departments: (id: string) => `departments/${id}`,
  partners: () => 'partners',
  partnerActivities: () => 'partners/articles',
  users: (id: string) => `users/${id}`,
  benchmarks: () => 'benchmark',
  researches: (id?: string) => `researches/public${urlId(id)}`,
  researchesPublic: (id?: string) => `researches/public${urlId(id)}`,
  researchGroups: (id?: string) => `research_groups${urlId(id)}`,
  scientificPapers: (id?: string) => `scientific_papers${urlId(id)}`,
  scientificPapersConfirmDelete: (id?: string) => `scientific_papers/confirm-delete${urlId(id)}`,
  previewScientificPapers: () => 'scientific_papers/preview',
  crawlScientificPapers: () => 'scientific_papers/crawl',
  importScientificPapers: () => 'scientific_papers/import',
  confirmCrawlScientificPapers: () => 'scientific_papers/crawl/confirm',
  confirmJoinScientificPapers: () => 'scientific_papers/confirm',
  researchActivities: (id?: string) => `research_activities${urlId(id)}`,
  studentInfo: (id?: string) => `students${urlId(id)}`,
  products: (id?: string) => `products${urlId(id)}`,
  trainingInfo: (id?: string) => `trainings${urlId(id)}`,
  contactInfo: () => 'user_contact_information',
  comment: (prefix: string) => `${prefix}/comment`,
  mainResearch: () => 'main_research',
  researchInfo: (id?: string) => `researches${urlId(id)}`,

  researchGetUser: () => 'researches/user-research',
}
