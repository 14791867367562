"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typeOf = exports.dataTypeOf = void 0;

const typeOf = thing => {
  switch (thing) {
    case undefined:
      return 'Undefined';

    case null:
      return 'Null';

    default:
      return thing.constructor.name;
  }
};

exports.typeOf = typeOf;

const dataTypeOf = thing => {
  switch (typeof thing) {
    case 'string':
      return 'String';

    case 'object':
      if (Array.isArray(thing)) {
        return 'Array';
      } else if (typeOf(thing) === 'Object') {
        return 'SimpleObject';
      } else if (typeOf(thing) !== 'Null') {
        return 'ComplexObject';
      }

    default:
      return 'Primitive';
  }
};

exports.dataTypeOf = dataTypeOf;