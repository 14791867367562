import React, { useCallback } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import AdminButton from '../admin/button/Button'
import UserButton from '../user/button/Button'

interface Props {
  className?: string
  show: boolean
  onClose?: () => void
  onCloseAttention?: () => void
}

const ModalAttention: React.FC<Props> = ({ className, show, onClose, onCloseAttention }) => {
  const isAdmin = window.location.pathname.includes('/admin')

  const Button = useCallback(
    (props) => (isAdmin ? <AdminButton {...props} /> : <UserButton {...props} />),
    []
  )

  return (
    <Dialog
      className={`Modal ${className}`}
      open={show}
      fullWidth
      maxWidth="xs"
      onBackdropClick={onCloseAttention}
    >
      <DialogTitle>
        Thông báo
        {!isAdmin && <i className="icon-close Modal-close" onClick={onCloseAttention} />}
      </DialogTitle>
      <DialogContent style={{ fontWeight: 500, textAlign: 'center' }}>
        Nếu nhấn <span className="text-hightlight">Thoát</span>, mọi thông tin bạn đã nhập sẽ không
        được lưu lại.
      </DialogContent>
      <DialogActions>
        <Button
          colorType={isAdmin ? undefined : 'default secondary'}
          onClick={() => {
            onCloseAttention?.()
            onClose?.()
          }}
        >
          Thoát
        </Button>
        <Button variant="contained" onClick={onCloseAttention}>
          Tiếp tục
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalAttention
