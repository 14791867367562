/*eslint-disable*/
import { Button, Input, TextField } from '@mui/material'
import { apiUrls } from 'configs/apis'
import { DEFAULT_AVATAR } from 'configs/constants'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'
import { useApis } from 'services/api'
import { useAuth } from 'store/auth'
import { formatDate } from 'utils'
import Title from '../title/Title'
import './CommentCard.scss'

interface Props {
  perfix: string
  comments: any[]
  setComments?: any
  typePostId: any
  handleChangPage?: any
  total: string
}

const CommentCard: React.FC<Props> = ({
  perfix,
  comments,
  setComments,
  typePostId,
  handleChangPage,
  total,
}) => {
  const [content, setContent] = useState<string>('')
  const { avatar_url } = useAuth()

  const { apiPost } = useApis()
  const { id } = useParams() as any

  const postComment = (content: string) => {
    const bodySend: any = { content: content }

    bodySend[typePostId] = id

    apiPost(apiUrls.comment(perfix), bodySend, ({ data, status }) => {
      if (status) {
        setComments((prev: any) => ({ total: prev.total + 1, comment: [data, ...prev.comment] }))
      }
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    postComment(content)
    setContent('')
  }

  const MINUTE = 60
  const HOUR = MINUTE * 60

  const DAY = HOUR * 24

  const formatTimeComment = (timestamp: any) => {
    const now = new Date().getTime()
    const date = new Date(timestamp * 1000)
    const different = (now - date.getTime()) / 1000
    if (different < MINUTE) return 'Vài giây trước'
    if (different < MINUTE * 2) return '1 phút trước'
    if (different < HOUR) return `${Math.floor(different / MINUTE)} phút trước`
    if (different < HOUR * 2) return '1 giờ trước'
    if (different < DAY) return `${Math.floor(different / HOUR)} giờ trước`
    return formatDate(timestamp)
  }

  return (
    <div className="CommentCard">
      <Title>Bình Luận {`(${total})`}</Title>
      <form className="CommentCard-input d-f" onSubmit={handleSubmit}>
        <img className="avatar" src={avatar_url ? avatar_url : DEFAULT_AVATAR} alt="" />
        <div className="input-wrapper">
          <TextField
            className="input"
            placeholder="Nhập bình luận"
            multiline
            rows={3}
            value={content}
            onChange={(e: any) => {
              setContent(e.target.value)
            }}
            variant="outlined"
            inputProps={{ maxLength: 500 }}
            onKeyDown={(e) => {
              if (e.key == 'Enter' && !e.shiftKey) {
                const target = e.target as any
                setTimeout(() => target.blur(), 100)
                handleSubmit(e)
              }
            }}
          />
          <div className="btn">
            <Button size="small" variant="text" className="cancel" onClick={() => setContent('')}>
              Hủy
            </Button>
            <Button
              size="small"
              variant="contained"
              type="submit"
              className="save"
              disabled={!content.trim()}
              onClick={content ? handleSubmit : undefined}
              sx={{ color: '#fff' }}
            >
              Gửi
            </Button>
          </div>
        </div>
      </form>
      {comments?.length > 0 &&
        comments.map((comment: any) => (
          <div key={comment.id} className="CommentCard-field d-f">
            <img
              className="avatar"
              src={
                comment.created_user
                  ? comment.created_user.avatar_url
                    ? comment.created_user.avatar_url
                    : DEFAULT_AVATAR
                  : DEFAULT_AVATAR
              }
              alt=""
            />
            <div className="comment-content">
              <div className="userName">
                {comment.created_user ? comment.created_user.full_name : 'Người dùng ẩn danh'}
              </div>
              <div className="createDate"> {formatTimeComment(comment.created_date)}</div>
              <div className="content">{comment.content}</div>
            </div>
          </div>
        ))}
      <Waypoint onEnter={handleChangPage} />
    </div>
  )
}

export default CommentCard
