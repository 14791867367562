import { Popover } from '@mui/material'
import Scrollbar from '../../scrollbar/Scrollbar'
import React, { useRef, useState } from 'react'
import './index.scss'

interface Props {
  hideRole?: boolean
  members: { full_name: string; email: string; role?: string; color?: string }[]
}

const PopupMembers: React.FC<Props> = ({ hideRole, members }) => {
  const ref = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const count = members.length

  return (
    <div ref={ref}>
      <b style={{ marginLeft: 6 }}>
        {count ? (
          <>
            <Popover
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{
                sx: {
                  mt: 1,
                  width: 380,
                  borderRadius: '4px',
                  padding: 2,
                },
              }}
              open={open}
              onClose={handleClose}
              anchorEl={ref.current}
            >
              <Scrollbar className="mt-1" style={{ maxHeight: 440 }}>
                {members.map((item: any, index) => (
                  <div key={item.id + index} className="PopupMembers-item d-f">
                    <div className="mr-1 fw-5">{index + 1}.</div>
                    <div className="PopupMembers-item__block">
                      <div className="fw-5">
                        {item.full_name}
                        {!hideRole && (
                          <>
                            <span style={{ margin: '0 5px' }}>-</span>
                            <span style={{ color: item.color }}>{item.role || 'Thành viên'}</span>
                          </>
                        )}
                      </div>
                      {item.email && (
                        <div>
                          <i className="fas fa-envelope" style={{ height: 13 }} />
                          {item.email}
                        </div>
                      )}
                      {item.department?.name && (
                        <div>
                          <i className="fas fa-landmark" />
                          {item.department?.name}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </Scrollbar>
            </Popover>
            <span className="a" onClick={handleOpen}>
              {count}
            </span>
          </>
        ) : (
          count || 0
        )}
      </b>
    </div>
  )
}

export default PopupMembers
