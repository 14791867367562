import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material'
import { configureStore } from 'store'
import { theme } from 'configs/theme'
import metadata from './metadata.json'
import { loading } from 'configs/extensions'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import { persistor } from 'store/configureStore'
import './i18n'

import 'assets/scss/index.scss'

const { buildMajor, buildNumber } = metadata
const version = `${buildMajor}.${Number(buildNumber) + 334}`

ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <Suspense fallback={loading}>
        <Provider store={configureStore}>
          <PersistGate loading={null} persistor={persistor}>
            <Router>
              <App />
            </Router>
          </PersistGate>
        </Provider>
      </Suspense>
    </ThemeProvider>
    {process.env.REACT_APP_ENV !== 'prd' && <div className="version">v{version}</div>}
    {/* <div className="version">v{version}</div> */}
  </>,
  document.getElementById('root')
)
