/* eslint-disable */
import { apiUrls } from 'configs/apis'
import { MAX_PAGE_SIZE, ROLES } from 'configs/constants'
import React, { useEffect, useState } from 'react'
import { useApis } from 'services/api'
import { useAuth } from 'store/auth'
import { useIsMobile } from 'store/mobile'
import { PermissionType, ScientificPaperType } from 'types'
import { randomId, selectPublishedByType } from 'utils'
import Scrollbar from '../../scrollbar/Scrollbar'
import ScientificPaperItem from '../scientificPaperItem/ScientificPaperItem'

interface Props {
  scientificPapers: ScientificPaperType[]
  onUpdateItem: (item: ScientificPaperType) => void
  onRemoveItem: (id: string) => void
  showButton: (createdUserId: string) => boolean
  permission: { add: boolean; edit: boolean; del: boolean }
  menuTabLoading: boolean
}

const MyScientificPapersComponent: React.FC<Props> = ({
  scientificPapers,
  onUpdateItem,
  onRemoveItem,
  showButton,
  permission,
  menuTabLoading,
}) => {
  const isMobile = useIsMobile()

  const { edit, del } = permission
  return (
    <div className="MyScientificPapersComponent">
      {/* <div className="banner background-image d-f ai-c jc-c typo-40-7 ta-c">Công bố khoa học</div> */}
      <div className="MyScientificPapersComponent-row header typo-16-6 ta-c">
        <div className="col-left">Tiêu đề</div>
        {!isMobile && <div className="col-center">Tổng trích dẫn</div>}
        <div className="col-right">Thao tác</div>
      </div>
      <Scrollbar style={{ maxHeight: isMobile ? undefined : 1520 }}>
        {scientificPapers.map((item) => {
          const {
            id = '',
            year,
            title,
            volume,
            number,
            pages,
            created_user,
            ENTRYTYPE,
            cited_by,
            link,
          } = item

          let { author } = item

          if (ENTRYTYPE === 'proceedings') author = item.editor || item.author

          const publishedBy = selectPublishedByType(ENTRYTYPE || '', item)

          const vol = `${volume ? volume : ''}${number ? ` (${number})` : ''}`

          const description = [publishedBy, vol, pages].filter((i) => i).join(', ')

          const params = { date: year, title, author, description, cited_by, link }

          return (
            <div key={id} className="MyScientificPapersComponent-item body">
              <div className="MyScientificPapersComponent-row ">
                <div className="col-left">
                  <ScientificPaperItem {...params} />
                </div>
                {!isMobile && (
                  <div className="col-center d-f ai-c jc-c neutral-1 ta-c typo-16-5">
                    {cited_by}
                  </div>
                )}
                <div className="col-right d-f ai-c jc-c neutral-1 ta-c typo-16-5">
                  {showButton(created_user?.id || '') && (
                    <>
                      {edit && !menuTabLoading && (
                        <i
                          className="icon-edit1"
                          onClick={() => {
                            let author = item.author
                            if (item.ENTRYTYPE === 'proceedings') {
                              author = item.editor || item.author
                            }
                            onUpdateItem({
                              ...item,
                              authors: author?.split(',').map((i) => ({
                                id: randomId(),
                                name: i.trim(),
                              })),
                            })
                          }}
                        />
                      )}
                      {del && !menuTabLoading && (
                        <i className="icon-trash1" onClick={() => onRemoveItem(id)} />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </Scrollbar>
    </div>
  )
}

export default MyScientificPapersComponent
