import { MODAL_AUTH_ROUTES } from 'configs/constants'
import { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ModalAuthReducer, RootState } from 'types'

import { hideModalAuth, showModalAuth } from './actions'

const { SIGN_IN, FORGOT_PASSWORD, RESET_PASSWORD, CHANGE_PASSWORD } = MODAL_AUTH_ROUTES

export default function useModalAuth() {
  const dispatch = useDispatch()
  const modalAuthReducer: ModalAuthReducer = useSelector(
    (state: RootState) => state.modalAuth,
    shallowEqual
  )

  return useMemo(
    () => ({
      ...modalAuthReducer,
      showModalSignIn: () => dispatch(showModalAuth(SIGN_IN)),
      showModalForgotPassword: () => dispatch(showModalAuth(FORGOT_PASSWORD)),
      showModalResetPassword: () => dispatch(showModalAuth(RESET_PASSWORD)),
      showModalChangePassword: () => dispatch(showModalAuth(CHANGE_PASSWORD)),
      hideModalAuth: () => dispatch(hideModalAuth()),
    }),
    [dispatch, modalAuthReducer]
  )
}
