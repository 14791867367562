import React, { useEffect, useLayoutEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import {
  ModalConfirmContainer,
  ToastContainer,
  ScrollToTop,
  SessionContainer,
  Updater,
} from 'components'

import { getUserLS, removeUserLS } from 'utils'
import { loading } from 'configs/extensions'
import { useDispatch } from 'react-redux'
import { updateAuth } from 'store/auth/actions'
import PrivateRoute from 'privateRoute/PrivateRoute'
import { ROUTES } from 'configs/constants'
import AnalysticWrapper from 'analystic'

// Routes
const AdminPage = React.lazy(() => import('pages/AdminPage'))
const UserPage = React.lazy(() => import('pages/UserPage'))
const PreviewScientificPapers = React.lazy(
  () => import('pages/user/previewScientificPapers/PreviewScientificPapers')
)

const clearLSLocations = ['sign-in', 'reset-password']

const App: React.FC = () => {
  const [initializing, setInitializing] = useState(true)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const history = useHistory()

  // Đăng xuất khi nhập các đường dẫn sign-in, reset-password ...
  useLayoutEffect(() => {
    if (clearLSLocations.some((item) => pathname?.includes(item))) {
      removeUserLS()
    }
  }, [pathname])

  useEffect(() => {
    const userInfo = getUserLS()
    const { access_token, isAdmin, force_change_password } = userInfo
    if (access_token) {
      dispatch(updateAuth(userInfo))
      if (isAdmin && !force_change_password && !pathname.includes('/admin')) history.push('/admin')
    }
    setInitializing(false)
  }, [])

  if (initializing) return loading

  return (
    <div className="App">
      <Router>
        <AnalysticWrapper />
        <Updater />
        <ToastContainer />
        <ModalConfirmContainer />
        <SessionContainer />
        <ScrollToTop>
          <Switch>
            <PrivateRoute
              path={`/${ROUTES.ROUTE_PREVIEW_SCIENTIFIC_PAPERS}`}
              component={PreviewScientificPapers}
            />
            <Route path="/admin" component={AdminPage} />
            <Route path="/" component={UserPage} />
            <Redirect to="/" />
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  )
}

export default App
