import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth } from 'store/auth'
import { resolutionMobile } from 'store/mobile/actions'

const Updater: React.FC = () => {
  const dispatch = useDispatch()
  const { isAuth, signOut } = useAuth()

  /**
   * Check local storage every 2s to determine
   * whether the user is sign out from system in an other tab
   */
  useEffect(() => {
    if (!isAuth) return

    const interval = setInterval(() => {
      const data = localStorage.getItem('user')
      if (!data) signOut()
    }, 2000)

    return () => clearInterval(interval)
  }, [dispatch, isAuth])

  // Update isMobile mỗi khi kích thước màn hình thay đổi
  useEffect(() => {
    const handleResize = () => dispatch(resolutionMobile(window.innerWidth <= 860))

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return null
}

export default Updater
