import { MaxLine } from 'components'
import React from 'react'
import { DepartmentChildType } from 'types'
import './index.scss'

const DepartmentChildItem: React.FC<DepartmentChildType> = ({ title, regency }) => {
  return (
    <div className="DepartmentChildItem">
      <div className="slider-card">
        <i className="icon-department" />
        <div className="line-below" />
        <MaxLine className="typo-24-6" text={title} numberOfLines={1} />
        <MaxLine className="regency" text={regency} numberOfLines={1} />
      </div>
    </div>
  )
}

export default DepartmentChildItem
