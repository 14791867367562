/* eslint-disable */
import { BREADCRUMBS, PARTNER_TYPES, TITLES } from 'configs/constants'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useApis } from 'services/api'
import { useBreadcrumb } from 'store/breadcrumb'
import { ItemsType, PartnerType } from 'types'
import { useRefresh } from 'utils'
import Title from '../title/Title'
import banner from 'assets/images/breadcrumbs/partners.jpg'
import { apiUrls } from 'configs/apis'
import Loading from '../../loading/Loading'
import NoItems from '../noItems/NoItems'
import Partners from './Partners'
import OfferCooperate from '../offerCooperate/OfferCooperate'
import MoreNews from '../moreNews/MoreNews'

const { ENTERPRISE, ACADEMIC } = PARTNER_TYPES
const { TITLE_ENTERPRISE_PARTNERS, TITLE_ACADEMIC_PARTNERS } = TITLES

const mappingTitle = {
  [ENTERPRISE]: TITLE_ENTERPRISE_PARTNERS,
  [ACADEMIC]: TITLE_ACADEMIC_PARTNERS,
}

interface Props {
  type: number
}

const { BREADCRUMB_ENTERPRISE_PARTNERS, BREADCRUMB_ACADEMIC_PARTNERS } = BREADCRUMBS

const PartnersWrapper: React.FC<Props> = ({ type }) => {
  const refresh = useRefresh()
  const { showBreadcrumb } = useBreadcrumb()
  const { apiGet } = useApis()

  const [data, setData] = useState<ItemsType<PartnerType>>({
    loading: true,
    items: [],
    total: 0,
  })

  useLayoutEffect(() => {
    showBreadcrumb(
      [type === ENTERPRISE ? BREADCRUMB_ENTERPRISE_PARTNERS : BREADCRUMB_ACADEMIC_PARTNERS],
      banner
    )
  }, [type])

  // Get tất cả items từ server
  const getData = useCallback(() => {
    setData({ ...data, loading: true, items: [] })

    apiGet(
      apiUrls.partners(),
      {
        sort: 'created_date',
        order_by: 'desc',
        type_cooperate_block: type,
      },
      ({ status, data }) => {
        if (status)
          setData({
            ...data,
            loading: false,
          })
      }
    )
  }, [])

  const { loading, items } = data
  const length = items.length
  const title = mappingTitle[type]

  // Mỗi khi refresh trang sẽ đưa page về 1 và get lại danh sách items
  useEffect(() => {
    getData()
  }, [refresh])

  return (
    <div className="PartnersWrapper">
      <Title noPadding>{title}</Title>
      {length === 0 ? (
        loading ? (
          <Loading />
        ) : (
          <NoItems
            title="Không có đơn vị hợp tác nào"
            content="Bạn có thể truy cập vào Trang chủ hoặc sử dụng chức năng tìm kiếm để tìm kiếm"
          />
        )
      ) : (
        <>
          <Partners partners={items} />
          <div className="gap" />
          <OfferCooperate />
          <MoreNews />
        </>
      )}
    </div>
  )
}

export default PartnersWrapper
