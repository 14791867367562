/* eslint-disable*/
import { createContext } from 'react'
import { AdminContextType, SelectType } from 'types'
import defaultAvatar from 'assets/images/default_avatar.png'

export const LS_HISTORY_SEARCH = 'historySearch'
export const LS_SHOWN_COLUMNS = 'shownColumns'
export const LS_LANG = 'lang'
export const LANGUAGES = {
  VI: 'vi',
  EN: 'en',
}

export const GG_ANALYSTICS_URL =
  'https://console.firebase.google.com/u/0/project/fit-prd/analytics/'

export const DEFAULT_AVATAR = defaultAvatar

export const DEFAULT_PAGE_SIZE = 15
export const MAX_PAGE_SIZE = 256
export const USER_PAGE_SIZE = 12

export const SUCCESS = 'success'
export const ERROR = 'error'

export const ALL = 'all'

export const today = new Date()
export const minDate1998 = new Date('01/01/1998')
export const minDate2022 = new Date('01/01/2022')
export const minDate2023 = new Date('01/01/2023')
export const minDate2010 = new Date('01/01/2010')
export const maxDateAfterYear = new Date('12/31/2022')
maxDateAfterYear.setFullYear(today.getFullYear() + 1)

export const FORMAT_DATE = 'DD/MM/YYYY'

export const MAX_ATTACHMENT_SIZE = 52428800
export const MAX_ATTACHMENT_FILES = 10
export const MAX_FILENAME_LENGTH = 500

export const BANNER_COLORS = {
  BLACK: 'black',
  WHITE: 'white',
  PRIMARY: 'primary',
  SEMANTIC: 'semantic',
}

const { BLACK, WHITE, PRIMARY, SEMANTIC } = BANNER_COLORS

export const textColors = {
  [WHITE]: {
    picker: { backgroundColor: '#ffffff', color: '#222929' },
    text: { title: '#ffffff', content: '#e6e6e6' },
  },
  [BLACK]: {
    picker: { backgroundColor: '#222929', color: '#ffffff' },
    text: { title: '#222929', content: '#222929' },
  },
}

export const buttonColors = {
  [PRIMARY]: {
    backgroundColor: '#242056',
    color: '#ffffff',
  },
  [SEMANTIC]: {
    backgroundColor: '#FFCC32',
    color: '#ffffff',
  },
  [WHITE]: {
    backgroundColor: '#ffffff',
    color: '#242056',
  },
}

export const defaultBannerPosition = {
  width: 782,
  right: false,
  textColor: WHITE,
  buttonColor: SEMANTIC,
  textOffset: 40,
  buttonOffset: 50,
}

const defaultAdminContextValue = {
  loading: false,
  startLoading: () => {},
  stopLoading: () => {},
  callApi: () => {},
  groups: [],
  getGroups: () => {},
  listGroups: [],
  mappingGroup: {},
  permissions: [],
  getPermissions: () => {},
  listPermissions: [],
  mappingPermission: {},
  myPermissions: [],
  hasPermission: () => ({}),
  submitGoBack: () => {},
  accessLevels: [],
  manageAllDepartments: false,
}

export const AdminContext = createContext<AdminContextType>(defaultAdminContextValue)

export const ROUTES = {
  ROUTE_BANNERS: 'banners',
  ROUTE_SEMINAR: 'seminar',
  ROUTE_NEWS: 'news',
  ROUTE_INTRODUCTIONS: 'introductions',
  ROUTE_ABOUT_US: 'about-us',
  ROUTE_REWARDS: 'rewards',
  ROUTE_TRAINING_INFO: 'training-info',
  ROUTE_RESEARCH_INFO: 'research-info',
  ROUTE_MY_RESEARCH_INFO: 'my-research-info',
  ROUTE_RESEARCH_GROUPS: 'research-groups',
  ROUTE_RESEARCH_MAIN: 'research-main',
  ROUTE_SCIENTIFIC_PAPERS: 'scientific-papers',
  ROUTE_ALL_SCIENTIFIC_PAPERS: 'all-scientific-papers',
  ROUTE_ENROLLMENTS: 'enrollments',
  ROUTE_QUESTIONS: 'questions',
  ROUTE_STUDENTS: 'students',
  ROUTE_PARTNERS: 'partners',
  ROUTE_PRODUCTS: 'products',
  ROUTE_SUBJECTS: 'subjects',
  ROUTE_DEPARTMENTS: 'departments',
  ROUTE_GROUPS: 'groups',
  ROUTE_PERMISSIONS: 'permissions',
  ROUTE_USERS: 'users',
  ROUTE_BENCHMARKS: 'benchmarks',
  ROUTE_UNIVERSITY_BENCHMARKS: 'university-benchmarks',
  ROUTE_MASTER_BENCHMARKS: 'master-benchmarks',
  ROUTE_DOCTOR_BENCHMARKS: 'doctor-benchmarks',
  ROUTE_MAJORS: 'majors',
  ROUTE_CHANGE_PASSWORD: 'change-password',
  ROUTE_SEARCH: 'search',
  ROUTE_PARTNER_ACTIVITIES: 'partner-activities',
  ROUTE_ENTERPRISE_PARTNERS: 'enterprise-partners',
  ROUTE_ACADEMIC_PARTNERS: 'academic-partners',
  ROUTE_PROFILE: 'profile',
  ROUTE_RESEARCH_ACTIVITIES: 'research-activities',
  ROUTE_PREVIEW_SCIENTIFIC_PAPERS: 'preview-scientific-papers',
  ROUTE_CONTACT_INFORMATION: 'contact-info',
  ROUTE_STATISTIC: 'statistic',
  ROUTE_STATISTIC_USER_INTERACTION: 'user-interaction',
  ROUTE_STATISTIC_USER_NUMBER: 'user-number',
  ROUTE_STATISTIC_UNIT_PROGRESS: 'unit-progress',
  ROUTE_STATISTIC_RESEARCH_NUMBER: 'research-number',
  ROUTE_STATISTIC_RESEARCH_ACTIVITIES: 'research-activities',
  ROUTE_STATISTIC_RESEARCH_PUBLISH: 'research-publish',
  ROUTE_STATISTIC_POST_NUMBER: 'post-number',
  ROUTE_GENERAL_INFO: 'general-info',
  ROUTE_HISTORY_CHANGE: 'history-change',
  ROUTE_PROFICIENCY_LAB: 'proficiency-lab',
}

export const ROLES = {
  ROLE_SADMIN: 'xxx',
  ROLE_BANNERS: 'banner',
  ROLE_SEMINAR: 'hoi-thao',
  ROLE_NEWS: 'tin-tuc-va-su-kien',
  ROLE_INTRODUCTIONS: 'co-cau-to-chuc',
  ROLE_ABOUT_US: 'noi-ve-chung-toi',
  ROLE_REWARDS: 'giai-thuong-va-danh-hieu',
  ROLE_TRAINING_INFO: 'dao-tao',
  ROLE_RESEARCH_INFO: 'nghien-cuu',
  ROLE_MY_RESEARCH_INFO: 'quan-ly-thong-tin-nghien-cuu',
  ROLE_RESEARCH_GROUPS: 'nhom-nghien-cuu',
  ROLE_SCIENTIFIC_PAPERS: 'cong-bo-khoa-hoc',
  ROLE_MANAGEMENT_SCIENTIFIC_PAPERS: 'quan-ly-cong-bo-khoa-hoc',
  ROLE_ENROLLMENTS: 'thong-tin-tuyen-sinh',
  ROLE_QUESTIONS: 'cau-hoi-tuyen-sinh',
  ROLE_STUDENTS: 'sinh-vien',
  ROLE_PARTNERS: 'don-vi-hop-tac',
  ROLE_PRODUCTS: 'san-pham',
  ROLE_SUBJECTS: 'nhom-mon-hoc',
  ROLE_DEPARTMENTS: 'don-vi',
  ROLE_GROUPS: 'permissionadminmanagegroups',
  ROLE_PERMISSIONS: 'permissionadminmanagepermissions',
  ROLE_USERS: 'nguoi-dung',
  ROLE_UNIVERSITY_BENCHMARKS: 'diem-chuan',
  ROLE_MASTER_BENCHMARKS: 'diem-chuan',
  ROLE_DOCTOR_BENCHMARKS: 'diem-chuan',
  ROLE_MAJORS: 'diem-chuan',
  ROLE_CHANGE_PASSWORD: '',
  ROLE_STATISTIC: 'thong-ke',
  ROLE_GENERAL_INFO: 'thong-tin-chung',
  ROLE_CONTACT_INFORMATION: 'thong-tin-de-nghi-hop-tac',
  ROLE_RESEARCH_MAIN: 'huong-nghien-cuu',
  ROLE_HISTORY: 'lich-su-thay-doi-du-lieu',
}

const {
  ROUTE_BANNERS,
  ROUTE_SEMINAR,
  ROUTE_NEWS,
  ROUTE_INTRODUCTIONS,
  ROUTE_ABOUT_US,
  ROUTE_REWARDS,
  ROUTE_TRAINING_INFO,
  ROUTE_RESEARCH_INFO,
  ROUTE_MY_RESEARCH_INFO,
  ROUTE_RESEARCH_GROUPS,
  ROUTE_SCIENTIFIC_PAPERS,
  ROUTE_ENROLLMENTS,
  ROUTE_QUESTIONS,
  ROUTE_STUDENTS,
  ROUTE_PARTNERS,
  ROUTE_PRODUCTS,
  ROUTE_SUBJECTS,
  ROUTE_DEPARTMENTS,
  ROUTE_GROUPS,
  ROUTE_PERMISSIONS,
  ROUTE_USERS,
  ROUTE_MAJORS,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_UNIVERSITY_BENCHMARKS,
  ROUTE_MASTER_BENCHMARKS,
  ROUTE_DOCTOR_BENCHMARKS,
  ROUTE_SEARCH,
  ROUTE_PARTNER_ACTIVITIES,
  ROUTE_ENTERPRISE_PARTNERS,
  ROUTE_ACADEMIC_PARTNERS,
  ROUTE_BENCHMARKS,
  ROUTE_RESEARCH_ACTIVITIES,
  ROUTE_STATISTIC,
  ROUTE_GENERAL_INFO,
  ROUTE_CONTACT_INFORMATION,
  ROUTE_STATISTIC_USER_INTERACTION,
  ROUTE_STATISTIC_USER_NUMBER,
  ROUTE_STATISTIC_UNIT_PROGRESS,
  ROUTE_STATISTIC_RESEARCH_NUMBER,
  ROUTE_STATISTIC_RESEARCH_ACTIVITIES,
  ROUTE_STATISTIC_RESEARCH_PUBLISH,
  ROUTE_STATISTIC_POST_NUMBER,
  ROUTE_PROFICIENCY_LAB,
  ROUTE_RESEARCH_MAIN,
  ROUTE_HISTORY_CHANGE,
  ROUTE_ALL_SCIENTIFIC_PAPERS,
} = ROUTES

const {
  ROLE_SADMIN,
  ROLE_BANNERS,
  ROLE_SEMINAR,
  ROLE_NEWS,
  ROLE_INTRODUCTIONS,
  ROLE_ABOUT_US,
  ROLE_REWARDS,
  ROLE_TRAINING_INFO,
  ROLE_RESEARCH_INFO,
  ROLE_RESEARCH_GROUPS,
  ROLE_RESEARCH_MAIN,
  ROLE_SCIENTIFIC_PAPERS,
  ROLE_ENROLLMENTS,
  ROLE_QUESTIONS,
  ROLE_STUDENTS,
  ROLE_PARTNERS,
  ROLE_PRODUCTS,
  ROLE_SUBJECTS,
  ROLE_DEPARTMENTS,
  ROLE_GROUPS,
  ROLE_PERMISSIONS,
  ROLE_USERS,
  ROLE_UNIVERSITY_BENCHMARKS,
  ROLE_MASTER_BENCHMARKS,
  ROLE_DOCTOR_BENCHMARKS,
  ROLE_CHANGE_PASSWORD,
  ROLE_STATISTIC,
  ROLE_GENERAL_INFO,
  ROLE_MAJORS,
  ROLE_CONTACT_INFORMATION,
  ROLE_HISTORY,
} = ROLES

// Check first permission admin has, and redirect to corresponding location
export const priorityRoutes = [
  // { permission: ROLE_SADMIN, path: ROUTE_USERS },
  // { permission: ROLE_STATISTIC, path: ROUTE_STATISTIC_USER_INTERACTION },
  // { permission: ROLE_STATISTIC, path: ROUTE_STATISTIC },
  { permission: ROLE_STATISTIC, path: ROUTE_STATISTIC_USER_INTERACTION },
  { permission: ROLE_STATISTIC, path: ROUTE_STATISTIC_USER_NUMBER },
  { permission: ROLE_STATISTIC, path: ROUTE_STATISTIC_UNIT_PROGRESS },
  { permission: ROLE_STATISTIC, path: ROUTE_STATISTIC_RESEARCH_NUMBER },
  { permission: ROLE_STATISTIC, path: ROUTE_STATISTIC_RESEARCH_ACTIVITIES },
  { permission: ROLE_STATISTIC, path: ROUTE_STATISTIC_RESEARCH_PUBLISH },
  { permission: ROLE_STATISTIC, path: ROUTE_STATISTIC_POST_NUMBER },
  { permission: ROLE_STATISTIC, path: ROUTE_PROFICIENCY_LAB },
  { permission: ROLE_BANNERS, path: ROUTE_BANNERS },
  { permission: ROLE_SEMINAR, path: ROUTE_SEMINAR },
  { permission: ROLE_NEWS, path: ROUTE_NEWS },
  { permission: ROLE_ABOUT_US, path: ROUTE_ABOUT_US },
  { permission: ROLE_TRAINING_INFO, path: ROUTE_TRAINING_INFO },
  { permission: ROLE_RESEARCH_INFO, path: ROUTE_RESEARCH_INFO },
  { permission: ROLE_SCIENTIFIC_PAPERS, path: ROUTE_SCIENTIFIC_PAPERS },
  { permission: ROLE_STUDENTS, path: ROUTE_STUDENTS },
  { permission: ROLE_PARTNERS, path: ROUTE_PARTNERS },
  { permission: ROLE_PRODUCTS, path: ROUTE_PRODUCTS },
  { permission: ROLE_SUBJECTS, path: ROUTE_SUBJECTS },
  { permission: ROLE_GENERAL_INFO, path: ROUTE_GENERAL_INFO },
  { permission: ROLE_INTRODUCTIONS, path: ROUTE_INTRODUCTIONS },
  { permission: ROLE_REWARDS, path: ROUTE_REWARDS },
  { permission: ROLE_ENROLLMENTS, path: ROUTE_ENROLLMENTS },
  { permission: ROLE_QUESTIONS, path: ROUTE_QUESTIONS },
  { permission: ROLE_UNIVERSITY_BENCHMARKS, path: ROUTE_UNIVERSITY_BENCHMARKS },
  { permission: ROLE_MASTER_BENCHMARKS, path: ROUTE_MASTER_BENCHMARKS },
  { permission: ROLE_DOCTOR_BENCHMARKS, path: ROUTE_DOCTOR_BENCHMARKS },
  { permission: ROLE_MAJORS, path: ROUTE_MAJORS },
  { permission: ROLE_RESEARCH_GROUPS, path: ROUTE_RESEARCH_GROUPS },
  { permission: ROLE_DEPARTMENTS, path: ROUTE_DEPARTMENTS },
  { permission: ROLE_GROUPS, path: ROUTE_GROUPS },
  { permission: ROLE_PERMISSIONS, path: ROUTE_PERMISSIONS },
  { permission: ROLE_CONTACT_INFORMATION, path: ROUTE_CONTACT_INFORMATION },
  { permission: ROLE_USERS, path: ROUTE_USERS },
  { permission: ROLE_RESEARCH_MAIN, path: ROUTE_RESEARCH_MAIN },
  { permission: ROLE_HISTORY, path: ROUTE_HISTORY_CHANGE },
  { permission: ROLE_CHANGE_PASSWORD, path: ROUTE_CHANGE_PASSWORD },
]

// Mỗi màn cần ít nhất một quyền nào đó để có thể truy cập
export const POLICIES = {
  POLICY_BANNERS: [ROLE_BANNERS],
  POLICY_SEMINAR: [ROLE_SEMINAR],
  POLICY_NEWS: [ROLE_NEWS],
  POLICY_INTRODUCTIONS: [ROLE_INTRODUCTIONS],
  POLICY_ABOUT_US: [ROLE_ABOUT_US],
  POLICY_REWARDS: [ROLE_REWARDS],
  POLICY_TRAINING_INFO: [ROLE_TRAINING_INFO],
  POLICY_RESEARCH_INFO: [ROLE_RESEARCH_INFO],
  POLICY_RESEARCH_GROUPS: [ROLE_RESEARCH_GROUPS],
  POLICY_RESEARCH_MAIN: [ROLE_RESEARCH_MAIN],
  POLICY_SCIENTIFIC_PAPERS: [ROLE_SCIENTIFIC_PAPERS],
  POLICY_ENROLLMENTS: [ROLE_ENROLLMENTS],
  POLICY_QUESTIONS: [ROLE_QUESTIONS],
  POLICY_STUDENTS: [ROLE_STUDENTS],
  POLICY_PARTNERS: [ROLE_PARTNERS],
  POLICY_PRODUCTS: [ROLE_PRODUCTS],
  POLICY_SUBJECTS: [ROLE_SUBJECTS],
  POLICY_DEPARTMENTS: [ROLE_DEPARTMENTS],
  POLICY_GROUPS: [ROLE_GROUPS],
  POLICY_PERMISSIONS: [ROLE_PERMISSIONS],
  // POLICY_MILITARY_BENCHMARKS: [ROLE_MILITARY_BENCHMARKS],
  POLICY_UNIVERSITY_BENCHMARKS: [ROLE_UNIVERSITY_BENCHMARKS],
  POLICY_DOCTOR_BENCHMARKS: [ROLE_DOCTOR_BENCHMARKS],
  POLICY_MASTER_BENCHMARKS: [ROLE_MASTER_BENCHMARKS],
  POLICY_MAJORS: [ROLE_MAJORS],
  POLICY_USERS: [ROLE_USERS],
  POLICY_STATISTIC: [ROLE_STATISTIC],
  POLICY_GENERAL_INFO: [ROLE_GENERAL_INFO],
  POLICY_CONTACT_INFORMATION: [ROLE_CONTACT_INFORMATION],
  POLICY_HISTORY: [ROLE_HISTORY],
}

// update học hàm
export const ACADEMIC_RANKS = {
  LECTURER_SUPER: '5',
  LECTURER_MAIN: '4',
  LECTURER: '3',
  ASSOCIATE_PROFESSOR: '2',
  PROFESSOR: '1',
}

export const DEGREES = {
  BACHELOR: '1',
  ENGINEER: '2',
  MASTER: '3',
  DOCTOR: 4,
  DOCTOR_OF_SCIENCE: '5',
}

export const DEGREES_LEVEL = {
  PARTY_CELL_SECRETARY: 1,
  SECRETARY_PARTY_COMMITTEE: 2,
  CAPTAIN_CENTER: 3,
  BRANCH_COMMISSIONER: 4,
  HEAD_OF_DEPARTMENT: 5,
  PARTY_COMMISSIONER_MEMBER: 6,
  LECTURER: 7,
  DEPUTY_PARTY_BRANCH: 8,
  DEPUTY_SECRETARY_PARTY_COMMITTEE: 9,
  DEPUTY_CAPTAIN_CENTER: 10,
  DEPUTY_HEAD_OF_DEPARTMENT: 11,
  ASSISTANT: 12,
  HEAD: 13,
  LABORATORY_HEAD: 14,
  CLERICAL_ASSISTANT: 15,
  DEPUTY_DEAN: 16,
  DEAN: 17,
}

export const SCIENTIFIC_PAPER_GROUP = {
  INTERNATIONAL_JOURNAL_ARTICLE: 1,
  ISI_JOURNAL_ARTICLE: 2,
  SCI_JOURNAL_ARTICLE: 3,
  SCIE_JOURNAL_ARTICLE: 4,
  CAMPUS_CONFERENCE: 5,
  INTERNATIONAL_CONFERENCE: 6,
  NATIONAL_CONFERENCE: 7,
  SUBJECT_JOURNAL: 8,
  CAMPUS_JOURNAL: 9,
  OTHER_TYPES: 0,
}

const {
  INTERNATIONAL_JOURNAL_ARTICLE,
  ISI_JOURNAL_ARTICLE,
  SCI_JOURNAL_ARTICLE,
  SCIE_JOURNAL_ARTICLE,
  CAMPUS_CONFERENCE,
  INTERNATIONAL_CONFERENCE,
  NATIONAL_CONFERENCE,
  SUBJECT_JOURNAL,
  CAMPUS_JOURNAL,
  OTHER_TYPES,
} = SCIENTIFIC_PAPER_GROUP

export const mappingScientificPaperGroup = {
  [INTERNATIONAL_JOURNAL_ARTICLE]: 'Bài báo quốc tế',
  [ISI_JOURNAL_ARTICLE]: 'Bài báo ISI',
  [SCI_JOURNAL_ARTICLE]: 'Bài báo SCI',
  [SCIE_JOURNAL_ARTICLE]: 'Bài báo SCIE',
  [CAMPUS_CONFERENCE]: 'Hội thảo cấp trường',
  [INTERNATIONAL_CONFERENCE]: 'Hội thảo quốc tế',
  [NATIONAL_CONFERENCE]: 'Hội thảo trong nước cấp ngành',
  [SUBJECT_JOURNAL]: 'Tạp chí cấp ngành',
  [CAMPUS_JOURNAL]: 'Tạp chí trong nước cấp trường',
  [OTHER_TYPES]: 'Các dạng khác',
}

const { ASSOCIATE_PROFESSOR, PROFESSOR, LECTURER_SUPER, LECTURER_MAIN, LECTURER } = ACADEMIC_RANKS

export const mappingAcademicRank: any = {
  [LECTURER]: 'Giảng viên',
  [LECTURER_MAIN]: 'Giảng viên chính',
  [LECTURER_SUPER]: 'Giảng viên cao cấp',
  [PROFESSOR]: 'Giáo sư',
  [ASSOCIATE_PROFESSOR]: 'Phó giáo sư',
}

const { BACHELOR, ENGINEER, MASTER, DOCTOR, DOCTOR_OF_SCIENCE } = DEGREES

export const mappingDegree: any = {
  [BACHELOR]: 'Cử nhân',
  [ENGINEER]: 'Kỹ sư',
  [MASTER]: 'Thạc sĩ',
  [DOCTOR]: 'Tiến sĩ',
  [DOCTOR_OF_SCIENCE]: 'Tiến sĩ khoa học',
}

const {
  PARTY_CELL_SECRETARY,
  SECRETARY_PARTY_COMMITTEE,
  CAPTAIN_CENTER,
  BRANCH_COMMISSIONER,
  HEAD_OF_DEPARTMENT,
  PARTY_COMMISSIONER_MEMBER,
  LECTURER: LECTURER_D,
  DEPUTY_PARTY_BRANCH,
  DEPUTY_SECRETARY_PARTY_COMMITTEE,
  DEPUTY_CAPTAIN_CENTER,
  DEPUTY_HEAD_OF_DEPARTMENT,
  ASSISTANT,
  HEAD,
  LABORATORY_HEAD,
  CLERICAL_ASSISTANT,
  DEPUTY_DEAN,
  DEAN,
} = DEGREES_LEVEL

export const mappingDegreeLevel: any = {
  [DEAN]: 'Viện trưởng',
  [DEPUTY_DEAN]: 'Viện phó',
  [HEAD_OF_DEPARTMENT]: 'Chủ nhiệm Bộ môn',
  [DEPUTY_HEAD_OF_DEPARTMENT]: 'Phó Chủ nhiệm Bộ môn',
  [CAPTAIN_CENTER]: 'Chỉ huy trưởng trung tâm',
  [DEPUTY_CAPTAIN_CENTER]: 'Phó chỉ huy trưởng trung tâm',
  [LABORATORY_HEAD]: 'Trưởng phòng thí nghiệm',
  [SECRETARY_PARTY_COMMITTEE]: 'Bí thư Đảng uỷ',
  [PARTY_CELL_SECRETARY]: 'Bí thư Chi bộ',
  [DEPUTY_PARTY_BRANCH]: 'Phó bí thư Chi bộ',
  [DEPUTY_SECRETARY_PARTY_COMMITTEE]: 'Phó Bí thư Đảng uỷ',
  [PARTY_COMMISSIONER_MEMBER]: 'Đảng uỷ viên',
  [BRANCH_COMMISSIONER]: 'Chi uỷ viên',
  [HEAD]: 'Trưởng phòng',
  [ASSISTANT]: 'Trợ lý',
  [CLERICAL_ASSISTANT]: 'Văn thư',
  [LECTURER_D]: 'Giảng viên',
}

export const mappingAcademicRankShort: any = {
  [LECTURER_SUPER]: 'GVCC',
  [LECTURER_MAIN]: 'GVC',
  [LECTURER]: 'GV',
  [ASSOCIATE_PROFESSOR]: 'PGS',
  [PROFESSOR]: 'GS',
}

export const mappingDegreeShort: any = {
  [ENGINEER]: 'Kỹ sư',
  [MASTER]: 'Th.S',
  [DOCTOR]: 'TS',
  [DOCTOR_OF_SCIENCE]: 'TSKH',
}

export const TABLE_MIN_WIDTH = 950

// News
export const NEWS_TYPES = {
  ALL_TYPES: '-1',
  COOPERATIVE_ACTIVITY: '0',
  ACTIVITY_NEWS: '1',
  SCIENTIFIC_NEWS: '2',
  HOT_NEWS: '3',
  COOPERATIVE_NEWS: '4',
}

const {
  ALL_TYPES,
  COOPERATIVE_ACTIVITY,
  ACTIVITY_NEWS,
  SCIENTIFIC_NEWS,
  HOT_NEWS,
  COOPERATIVE_NEWS,
} = NEWS_TYPES

export const mappingNewsType: any = {
  [ALL_TYPES]: 'Loại bài viết',
  [COOPERATIVE_ACTIVITY]: 'Hoạt động hợp tác',
  [ACTIVITY_NEWS]: 'Tin hoạt động',
  [SCIENTIFIC_NEWS]: 'Tin khoa học',
  [HOT_NEWS]: 'Tin nổi bật',
  [COOPERATIVE_NEWS]: 'Tin tức và sự kiện hợp tác',
}

export const mappingNewsTypeUser = { ...mappingNewsType }
mappingNewsTypeUser[COOPERATIVE_NEWS] = 'TT&SK hợp tác'

export const mappingNewsColor: any = {
  [HOT_NEWS]: { color: 'var(--cl-semantic-3)', backgroundColor: 'var(--cl-accent-3)' },
  [SCIENTIFIC_NEWS]: { color: 'var(--cl-semantic-1)', backgroundColor: 'var(--cl-accent-2)' },
  [ACTIVITY_NEWS]: { color: 'var(--cl-primary)', backgroundColor: '#D2F8F3' },
  [COOPERATIVE_ACTIVITY]: { color: 'var(--cl-semantic-1)', backgroundColor: 'var(--cl-accent-2)' },
  [COOPERATIVE_NEWS]: { color: 'var(--cl-semantic-1)', backgroundColor: 'var(--cl-accent-2)' },
}

export const listNewsTypes: SelectType[] = [{ value: ALL_TYPES, label: 'Tất cả' }].concat(
  [HOT_NEWS, ACTIVITY_NEWS, SCIENTIFIC_NEWS].map((key) => ({
    value: key,
    label: mappingNewsType[key],
  }))
)

// Partners

export const PARTNER_TYPES = {
  ALL_TYPES: 0,
  ENTERPRISE: 1,
  ACADEMIC: 2,
}

const { ALL_TYPES: ALL_PARTNER_TYPES, ACADEMIC, ENTERPRISE } = PARTNER_TYPES

export const mappingPartnerType: any = {
  [ALL_PARTNER_TYPES]: 'Khối hợp tác',
  [ACADEMIC]: 'Khối hàn lâm',
  [ENTERPRISE]: 'Khối doanh nghiệp',
}

// Researches
export const RESEARCH_TYPES = {
  ALL: '0',
  NATIONAL_LEVEL: '2',
  MINISTRY_LEVEL: '1',
  COOPERATION: '3',
  COOPERATION_INTERNATIONAL: '4',
  NAFOSTED: '5',
}

const {
  ALL: ALL_RESEARCHES,
  NATIONAL_LEVEL,
  MINISTRY_LEVEL,
  COOPERATION,
  COOPERATION_INTERNATIONAL,
  NAFOSTED,
} = RESEARCH_TYPES

export const mappingResearchType: any = {
  [ALL_RESEARCHES]: 'Tất cả các loại',
  [NATIONAL_LEVEL]: 'Đề tài cấp quốc gia',
  [MINISTRY_LEVEL]: 'Đề tài cấp bộ',
  [COOPERATION]: 'Đề tài hợp tác nghiên cứu theo nghị định thư',
  [COOPERATION_INTERNATIONAL]: 'Đề tài hợp tác quốc tế',
  [NAFOSTED]: 'Nhiệm vụ nghiên cứu cơ bản Quỹ Nafosted',
}

// Student info
export const STUDENT_INFO_TYPES = {
  ALL: '-1',
  // FORMS: '7',
  TYPICAL_ALUMNI: '0',
  SCIENTIFIC_RESEARCH_ACTIVITIES: '1',
  EXTRACURRICULAR_ACTIVITIES: '2',
  // IMPORTANT_ANNOUNCEMENTS: '4',
  // INTERNSHIP_INFORMATION: '5',
  // JOB_INFORMATION: '6',
  ACTIVITY_INFORMATION: '3',
}

const {
  ALL: ALL_STUDENT_INFO,
  // FORMS,
  TYPICAL_ALUMNI,
  SCIENTIFIC_RESEARCH_ACTIVITIES,
  EXTRACURRICULAR_ACTIVITIES,
  // IMPORTANT_ANNOUNCEMENTS,
  // INTERNSHIP_INFORMATION,
  // JOB_INFORMATION,
  ACTIVITY_INFORMATION,
} = STUDENT_INFO_TYPES

export const mappingStudentInfoType: any = {
  [ALL_STUDENT_INFO]: 'Loại bài',
  // [FORMS]: 'Các biểu mẫu',
  [TYPICAL_ALUMNI]: 'Cựu học viên - sinh viên',
  [SCIENTIFIC_RESEARCH_ACTIVITIES]: 'Hoạt động nghiên cứu khoa học',
  [EXTRACURRICULAR_ACTIVITIES]: 'Hoạt động ngoại khóa',
  // [IMPORTANT_ANNOUNCEMENTS]: 'Thông báo quan trọng',
  // [INTERNSHIP_INFORMATION]: 'Thông tin thực tập',
  // [JOB_INFORMATION]: 'Thông tin việc làm',
  [ACTIVITY_INFORMATION]: 'Thông tin hoạt động',
}

export const mappingStudentInfoRoutes = {
  // [IMPORTANT_ANNOUNCEMENTS]: 'important-announcements',
  // [FORMS]: 'forms',
  // [JOB_INFORMATION]: 'job-information',
  // [INTERNSHIP_INFORMATION]: 'internship-information',
  [SCIENTIFIC_RESEARCH_ACTIVITIES]: 'scientific-research-activities',
  [EXTRACURRICULAR_ACTIVITIES]: 'extracurricular-activities',
  [TYPICAL_ALUMNI]: 'typical-alumni',
  [ACTIVITY_INFORMATION]: 'activity-infor',
}

export const STUDENT_INFO = [
  // IMPORTANT_ANNOUNCEMENTS,
  // FORMS,
  // JOB_INFORMATION,
  // INTERNSHIP_INFORMATION,
  SCIENTIFIC_RESEARCH_ACTIVITIES,
  EXTRACURRICULAR_ACTIVITIES,
  TYPICAL_ALUMNI,
  ACTIVITY_INFORMATION,
]

export const ROLE_RESEARCH_TYPE = {
  SECRETARY_USER: '2',
  MANAGER_USER: '3',
  MEMBER_RESEARCH: '1',
}

const { SECRETARY_USER, MANAGER_USER, MEMBER_RESEARCH } = ROLE_RESEARCH_TYPE

export const mappingRoleReseach = {
  [SECRETARY_USER]: 'Thư ký',
  [MANAGER_USER]: 'Chủ nhiệm đề tài',
  [MEMBER_RESEARCH]: 'Thành viên',
}

// Training info
export const TRAINING_INFO_TYPES = {
  ALL: '0',
  UNIVERSITY: '1',
  MASTER: '2',
  DOCTOR: '3',
  SHORT_TERM_IT: '4',
  SHORT_TERM_CN: '5',
  SHORT_TERM_SI: '6',
  SHORT_TERM: '100',
}

const {
  UNIVERSITY,
  MASTER: TRAINING_MASTER,
  DOCTOR: TRAINING_DOCTOR,
  SHORT_TERM_IT,
  SHORT_TERM_CN,
  SHORT_TERM_SI,
  ALL: ALL_TRAINING,
} = TRAINING_INFO_TYPES

export const mappingTrainingInfoTitle: any = {
  [ALL_TRAINING]: 'Đào tạo',
  [UNIVERSITY]: 'Đào tạo Đại học',
  [TRAINING_MASTER]: 'Đào tạo Thạc sĩ',
  [TRAINING_DOCTOR]: 'Đào tạo Tiến sĩ',
  [SHORT_TERM_IT]: 'Đào tạo ngắn hạn - Chứng chỉ CNTT',
  [SHORT_TERM_CN]: 'Đào tạo ngắn hạn - Chứng chỉ mạng máy tính',
  [SHORT_TERM_SI]: 'Đào tạo ngắn hạn - Chứng chỉ an toàn thông tin',
}

export const mappingTrainingInfoRoutes = {
  [ALL_TRAINING]: 'training',
  [UNIVERSITY]: 'university',
  [TRAINING_MASTER]: 'master',
  [TRAINING_DOCTOR]: 'doctor',
  [SHORT_TERM_IT]: 'information-technology',
  [SHORT_TERM_CN]: 'computer-network',
  [SHORT_TERM_SI]: 'safety-information',
}

export const TITLES = {
  TITLE_SEARCH: 'Tìm kiếm',
  TITLE_NEWS: 'Tin tức - Sự kiện',
  TITLE_ENROLLMENTS: 'Thông tin tuyển sinh',
  TITLE_QUESTIONS: 'Hỏi đáp về tuyển sinh',
  TITLE_PARTNER_ACTIVITIES: 'Hoạt động hợp tác',
  TITLE_INTRODUCTIONS: 'Giới thiệu',
  TITLE_INTRODUCTIONS_GENERAL: 'Thông tin chung',
  TITLE_INTRODUCTIONS_COMMITTEE: 'Đảng ủy Viện',
  TITLE_INTRODUCTIONS_UNIONS: 'Cơ cấu tổ chức',
  TITLE_INTRODUCTIONS_REWARDS: 'Các giải thưởng & Danh hiệu của viện',
  TITLE_DEPARTMENTS: 'Đơn vị trực thuộc',
  TITLE_ENTERPRISE_PARTNERS: 'Hợp tác khối doanh nghiệp',
  TITLE_ACADEMIC_PARTNERS: 'Hợp tác khối hàn lâm',
  TITLE_USERS: 'Thông tin cá nhân',
  TITLE_BENCHMARKS: 'Điểm chuẩn qua từng năm',
  TITLE_RESEARCH_INFO: 'Đề tài - Dự án',
  TITLE_RESEARCH_GROUPS: 'Nhóm nghiên cứu',
  TITLE_SCIENTIFIC_PAPERS: 'Công bố khoa học',
  TITLE_MY_RESEARCH_INFO: 'Đề tài nghiên cứu',
  TITLE_RESEARCH_ACTIVITIES: 'Hoạt động nghiên cứu khoa học',
  TITLE_PRODUCTS: 'Sản phẩm tiêu biểu',
  TITLE_STUDENTS: 'Sinh Viên',
}

const {
  TITLE_SEARCH,
  TITLE_NEWS,
  TITLE_ENROLLMENTS,
  TITLE_QUESTIONS,
  TITLE_PARTNER_ACTIVITIES,
  TITLE_INTRODUCTIONS,
  TITLE_INTRODUCTIONS_GENERAL,
  TITLE_INTRODUCTIONS_COMMITTEE,
  TITLE_INTRODUCTIONS_UNIONS,
  TITLE_DEPARTMENTS,
  TITLE_ENTERPRISE_PARTNERS,
  TITLE_ACADEMIC_PARTNERS,
  TITLE_BENCHMARKS,
  TITLE_RESEARCH_INFO,
  TITLE_RESEARCH_GROUPS,
  TITLE_SCIENTIFIC_PAPERS,
  TITLE_RESEARCH_ACTIVITIES,
  TITLE_PRODUCTS,
  TITLE_MY_RESEARCH_INFO,
} = TITLES

export const BREADCRUMBS = {
  BREADCRUMB_SEARCH: { name: TITLE_SEARCH, path: `/${ROUTE_SEARCH}` },
  BREADCRUMB_NEWS: { name: TITLE_NEWS, path: `/${ROUTE_NEWS}` },
  BREADCRUMB_ENROLLMENTS: { name: TITLE_ENROLLMENTS, path: `/${ROUTE_ENROLLMENTS}` },
  BREADCRUMB_QUESTIONS: { name: TITLE_QUESTIONS, path: `/${ROUTE_QUESTIONS}` },
  BREADCRUMB_PARTNER_ACTIVITIES: {
    name: TITLE_PARTNER_ACTIVITIES,
    path: `/${ROUTE_PARTNER_ACTIVITIES}`,
  },
  BREADCRUMB_INTRODUCTIONS_GENERAL: {
    name: TITLE_INTRODUCTIONS,
    path: `/${ROUTE_INTRODUCTIONS}`,
    extraName: TITLE_INTRODUCTIONS_GENERAL,
  },
  BREADCRUMB_INTRODUCTIONS_COMMITTEE: {
    name: TITLE_INTRODUCTIONS,
    path: `/${ROUTE_INTRODUCTIONS}`,
    extraName: TITLE_INTRODUCTIONS_COMMITTEE,
  },
  BREADCRUMB_INTRODUCTIONS_UNIONS: {
    name: TITLE_INTRODUCTIONS,
    path: `/${ROUTE_INTRODUCTIONS}`,
    extraName: TITLE_INTRODUCTIONS_UNIONS,
  },
  BREADCRUMB_INTRODUCTIONS_REWARDS: {
    name: TITLE_INTRODUCTIONS,
    path: `/${ROUTE_INTRODUCTIONS}`,
    extraName: 'Các giải thưởng và danh hiệu của Viện',
  },
  BREADCRUMB_DEPARTMENTS: {
    name: TITLE_DEPARTMENTS,
    path: `/${ROUTE_DEPARTMENTS}`,
  },
  BREADCRUMB_INTRODUCTION_DETAIL: {
    name: TITLE_INTRODUCTIONS_UNIONS,
    path: { pathname: `/${ROUTE_INTRODUCTIONS}`, state: 'unions' },
  },
  BREADCRUMB_ENTERPRISE_PARTNERS: {
    name: TITLE_ENTERPRISE_PARTNERS,
    path: `/${ROUTE_ENTERPRISE_PARTNERS}`,
  },
  BREADCRUMB_ACADEMIC_PARTNERS: {
    name: TITLE_ACADEMIC_PARTNERS,
    path: `/${ROUTE_ACADEMIC_PARTNERS}`,
  },
  BREADCRUMB_BENCHMARKS: {
    name: TITLE_BENCHMARKS,
    path: `/${ROUTE_BENCHMARKS}`,
  },
  BREADCRUMB_RESEARCH_INFO: {
    name: TITLE_RESEARCH_INFO,
    path: `/${ROUTE_RESEARCH_INFO}`,
  },
  BREADCRUMB_MY_RESEARCH_INFO: {
    name: TITLE_MY_RESEARCH_INFO,
    path: `/${ROUTE_MY_RESEARCH_INFO}`,
  },
  BREADCRUMB_RESEARCH_GROUPS: {
    name: TITLE_RESEARCH_GROUPS,
    path: `/${ROUTE_RESEARCH_GROUPS}`,
  },
  BREADCRUMB_SCIENTIFIC_PAPERS: {
    name: TITLE_SCIENTIFIC_PAPERS,
    path: `/${ROUTE_SCIENTIFIC_PAPERS}`,
  },
  BREADCRUMB_ALL_SCIENTIFIC_PAPERS: {
    name: TITLE_SCIENTIFIC_PAPERS,
    path: `/${ROUTE_ALL_SCIENTIFIC_PAPERS}`,
  },
  BREADCRUMB_RESEARCH_ACTIVITIES: {
    name: TITLE_RESEARCH_ACTIVITIES,
    path: `/${ROUTE_RESEARCH_ACTIVITIES}`,
    extraNameMobile: 'HĐNC khoa học',
  },
  BREADCRUMB_PRODUCTS: {
    name: TITLE_PRODUCTS,
    path: `/${ROUTE_PRODUCTS}`,
  },
}

export const MODAL_AUTH_ROUTES = {
  SIGN_IN: 'SIGN_IN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
}
