/* eslint-disable */
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import Button from '../button/Button'
import * as Yup from 'yup'
import Modal from '../../modal/Modal'
import { DialogActions } from '@mui/material'
import ModalButtonCancel from '../../modal/ModalButtonCancel'
import Input from '../input/Input'
import { validateTypingPreventSpace } from 'utils'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'
import { useModalConfirm } from 'store/modalConfirm'

const validationSchema = Yup.object().shape({
  link: Yup.string().required('Link scholar không được để trống.'),
})

interface Props {
  linkScholar: string
  onClose?: any
}

const CrawlScientificPapers: React.FC<Props> = ({ linkScholar, onClose }) => {
  const { apiPost, apiPut } = useApis()
  const { showModalConfirm, hideModalConfirm } = useModalConfirm()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [clearInput, setClearInput] = useState(true)

  const handleClose = () => setShow(false)

  const formik = useFormik({
    initialValues: {
      link: linkScholar,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: ({ link }) => {
      setLoading(true)

      apiPost(apiUrls.crawlScientificPapers(), { link }, ({ id, status, text }) => {
        setLoading(false)

        const confirmCrawl = id === '195'
        if (status || confirmCrawl) handleClose()

        showModalConfirm({
          content: text,
          cancel: confirmCrawl ? { text: 'Hủy', action: hideModalConfirm } : undefined,
          confirm: confirmCrawl
            ? {
                action: () => {
                  hideModalConfirm()
                  apiPut(apiUrls.confirmCrawlScientificPapers(), { link }, ({ status, text }) => {
                    if (status) onClose?.()
                    showModalConfirm({
                      content: text,
                      confirm: {
                        action: () => {
                          hideModalConfirm()
                        },
                      },
                    })
                  })
                },
                text: 'Đồng ý',
              }
            : {
                action: () => {
                  hideModalConfirm()

                  if (id === '193') {
                    handleClose()
                    onClose?.()
                    resetForm()
                  }
                },
              },
          close: () => {
            if (id === '193') {
              handleClose()
              onClose?.()
              resetForm()
            }
            hideModalConfirm()
          },
        })
      })
    },
  })

  const {
    handleSubmit,
    dirty,
    getFieldProps,
    errors,
    handleChange,
    isSubmitting,
    touched,
    setFieldValue,
    resetForm,
  } = formik

  useEffect(() => {
    if (show && linkScholar) {
      setFieldValue('link', linkScholar)
    }
  }, [show, linkScholar])

  return (
    <>
      <Modal
        className="CrawlScientificPapers"
        show={show}
        size="xs"
        close={handleClose}
        title="Crawl từ Scholar"
        dirty={dirty}
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Input
              className="mb-3"
              requiredPlaceholder="Link scholar"
              {...getFieldProps('link')}
              error={errors.link}
              maxLength={500}
              onChange={(e) => {
                if (e.target.value === '' || validateTypingPreventSpace(e)) handleChange(e)
              }}
              errorEmpty={isSubmitting || touched.link}
              disabled={loading}
            />
            <DialogActions>
              <ModalButtonCancel onClick={handleClose}>Hủy</ModalButtonCancel>
              <Button loading={loading} type="submit">
                Lưu
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Modal>
      <Button colorType="default secondary" icon="link" size="small" onClick={() => setShow(true)}>
        <span className="text">Crawl từ Scholar</span>
      </Button>
    </>
  )
}

export default CrawlScientificPapers
