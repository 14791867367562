import Title from '../title/Title'
import React from 'react'
import { useHome } from 'store/home'
import TrainingItem from '../trainingItem/TrainingItem'
import { Container } from '@mui/material'
import { useIsMobile } from 'store/mobile'
import Link from '../links/Link'
// import { ROUTES } from 'configs/constants'
import { createTrainingLink } from 'utils'
import { TRAINING_INFO_TYPES } from 'configs/constants'
const { ALL } = TRAINING_INFO_TYPES

const HomeTraining: React.FC = () => {
  const { trainings } = useHome()
  const isMobile = useIsMobile()

  if (!trainings.length) return null

  const viewMore = trainings.length > 3

  return (
    <Container>
      <div className="HomeTraining">
        <Title>Đào tạo</Title>
        <div className="HomeTraining-row">
          {trainings.slice(0, 3).map((item) => (
            <div key={item.id} className="col">
              <TrainingItem {...item} />
            </div>
          ))}
        </div>
      </div>
      {viewMore && (
        <div className={`text-center${isMobile ? '' : ' mt-2'}`}>
          <Link className="typo-18-6 underline" to={createTrainingLink(ALL)} color="neutral-2">
            Xem thêm
          </Link>
        </div>
      )}
    </Container>
  )
}

export default HomeTraining
