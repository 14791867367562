"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sort = void 0;

var _label = require("../plugin-common/output/label.js");

var _name = require("@citation-js/name");

const getComparisonValue = function (obj, prop, label = prop === 'label') {
  let value = label ? (0, _label.getLabel)(obj) : obj[prop];

  switch (prop) {
    case 'author':
    case 'editor':
      return value.map(name => name.literal || name.family || (0, _name.format)(name));

    case 'accessed':
    case 'issued':
      return value['date-parts'][0];

    case 'page':
      return value.split('-').map(num => parseInt(num));

    case 'edition':
    case 'issue':
    case 'volume':
      value = parseInt(value);
      return !isNaN(value) ? value : -Infinity;

    default:
      return value || -Infinity;
  }
};

const compareProp = function (entryA, entryB, prop, flip = /^!/.test(prop)) {
  prop = prop.replace(/^!/, '');
  const a = getComparisonValue(entryA, prop);
  const b = getComparisonValue(entryB, prop);
  return (flip ? -1 : 1) * (a > b ? 1 : a < b ? -1 : 0);
};

const getSortCallback = function (...props) {
  return (a, b) => {
    const keys = props.slice();
    let output = 0;

    while (!output && keys.length) {
      output = compareProp(a, b, keys.shift());
    }

    return output;
  };
};

const sort = function (method = [], log) {
  if (log) {
    this.save();
  }

  this.data.sort(typeof method === 'function' ? method : getSortCallback(...method, 'label'));
  return this;
};

exports.sort = sort;