import { useMemo } from 'react'

import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useHistory as useHistoryBase } from 'react-router'
import { RootState } from 'store'
import { useModalConfirm } from 'store/modalConfirm'
import { allowRedirect, preventRedirect } from './actions'

export default function useHistory() {
  const { showModalConfirmRedirect } = useModalConfirm()
  const dispatch = useDispatch()
  const allow = useSelector((state: RootState) => state.confirmRedirect, shallowEqual)
  const historyBase = useHistoryBase()

  /**
   * If not allow redirect => show popup
   * @param callback
   * @returns can redirect or not
   */
  const goTo = (callback: any) => {
    if (!allow) {
      showModalConfirmRedirect(() => {
        dispatch(allowRedirect())
        callback()
      })
    } else callback()
  }

  const push = (
    location: string | { pathname?: string; search?: string; state?: any },
    state?: any
  ) => goTo(() => historyBase.push(location as any, state))

  const goBack = () => goTo(() => historyBase.goBack())

  return useMemo(
    () => ({
      allow,
      goTo,
      push,
      goBack,
      allowRedirect: () => dispatch(allowRedirect()),
      preventRedirect: () => dispatch(preventRedirect()),
    }),
    [historyBase, allow]
  )
}
