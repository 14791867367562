import { UPDATE_APP } from 'store/actionTypes'
import { ActionType, AppReducer } from 'types'

const initialState: AppReducer = {
  departments: [],
  loading: true,
}

const appReducer = (state = initialState, action: ActionType) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_APP:
      return { ...state, ...(payload || {}), loading: false }

    default:
      return state
  }
}

export default appReducer
