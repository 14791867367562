import { Button as MuiButton, ButtonProps, CircularProgress } from '@mui/material'
import React, { MutableRefObject } from 'react'
import './index.scss'

interface Props extends ButtonProps {
  innerRef?: MutableRefObject<any>
  loading?: boolean
  icon?: string
  fas?: boolean
  colorType?:
    | 'default'
    | 'icon'
    | 'top'
    | 'seconday'
    | 'cta'
    | 'cta primary'
    | 'cta white'
    | 'default secondary'
    | 'default secondary big-radius'
    | 'none'
    | 'default semantic'
  adminButton?: boolean
}

const Button: React.FC<Props> = ({
  innerRef,
  loading,
  children,
  disabled,
  size,
  colorType = 'default',
  className = '',
  icon,
  adminButton = false,
  fas,
  ...rest
}) => {
  const isSmall = size === 'small'

  const newChildren = (
    <>
      {children}
      {icon && (
        <i
          className={`Button-icon ${fas ? `fas ${icon}` : `icon-${icon}`} ${
            children ? '' : ' no-ml'
          }`}
        />
      )}
    </>
  )

  return (
    <MuiButton
      {...rest}
      className={
        !adminButton ? `Button Button-${colorType} ${className}${isSmall ? ' small' : ''}` : ''
      }
      ref={innerRef}
      disabled={loading || disabled}
      size={size}
    >
      <div className="border" />
      {loading ? (
        <div className="d-f jc-c ai-c" style={{ position: 'relative' }}>
          <div className="d-f" style={{ visibility: 'hidden' }}>
            {newChildren}
          </div>
          <CircularProgress
            style={{ position: 'absolute' }}
            size={isSmall ? 18 : 24}
            color="inherit"
          />
        </div>
      ) : (
        newChildren
      )}
      {colorType?.includes('cta') && <i className="icon-chevron-right-big" />}
    </MuiButton>
  )
}

export default Button
