import ApiRequest from 'services/api'
import { apiUrls } from 'configs/apis'
import { DispatchType } from 'types'
import { UPDATE_HOME } from '../actionTypes'

/**
 * Get homepage, chứa banners, about, news ...
 */
export const getHomePageApi = () => (dispatch: DispatchType) => {
  return ApiRequest.get(apiUrls.homepage(), {}, ({ status, data }) => {
    if (status) dispatch({ type: UPDATE_HOME, payload: data })
  })
}
