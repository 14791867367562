/* eslint-disable */
import axios, { Method, AxiosRequestConfig } from 'axios'
import { apiUrls, API_URL } from '../configs/apis'
import { configureStore } from 'store'
import { CLEAR_AUTH, CREATE_TOAST, UPDATE_AUTH } from 'store/actionTypes'
import { CallbackType } from 'types'
import { trimFileName, updateUserLS } from 'utils'
import { ERROR, SUCCESS } from 'configs/constants'

axios.defaults.baseURL = API_URL

const refresh = async (callbackError: any) => {
  let msg = ''
  const refreshToken = configureStore.getState().auth.refresh_token
  if (refreshToken) {
    const headers = { Authorization: `Bearer ${refreshToken}` }

    try {
      const response = await axios.post(apiUrls.refresh(), {}, { headers })
      const { data: responseData }: any = response
      const { message, data } = responseData
      if (message?.status === SUCCESS) {
        updateUserLS(data)
        configureStore.dispatch({
          type: UPDATE_AUTH,
          payload: data,
        })
        return true
      }
    } catch (e: any) {
      msg = e?.response?.data.message.text || 'Phiên đăng nhập hết hạn.'
    }
  }
  callbackError()
  // If cannot refresh => sign out
  configureStore.dispatch({ type: CLEAR_AUTH, payload: msg })
  return false
}

/**
 * Config request common
 *
 * @param {String} method Request method
 * @param {String} url Request URL
 * @param {Object} data Request params
 * @param {boolean} hideToast hideToast
 */
const request = async (
  method: Method,
  url: string,
  data: any = {},
  callback: CallbackType = () => {},
  hideToast: boolean = false,
  isRefresh?: boolean
) => {
  const { download } = data || {}
  let filename: string
  if (download) {
    filename = data.filename
    delete data.filename
  }

  // config params
  const auth = configureStore.getState().auth
  const accessToken = auth.access_token

  const headers = { Authorization: accessToken ? `Bearer ${accessToken}` : '' }

  const defaultParams = { headers, method, url, responseType: download ? 'blob' : undefined }

  const paramConfigs: AxiosRequestConfig | any =
    method === 'get' ? { ...defaultParams, params: data } : { ...defaultParams, data: data }

  const { upload } = data

  if (upload) {
    if (!upload.notHasPrefix) paramConfigs.url += `?prefix=${upload.prefix}`
    const formData = new FormData()
    formData.append('file', upload.file, upload.file.name)
    paramConfigs.data = formData
  }

  return new Promise<any>((resolve, reject) => {
    axios(paramConfigs)
      .then((res) => {
        if (download) {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          const fileName = res.headers['content-disposition']
            .split('attachment; filename=')[1]
            .replaceAll('"', '')
          link.href = url
          link.setAttribute('download', fileName || 'file.xlsx')
          document.body.appendChild(link)
          link.click()
          window.URL.revokeObjectURL(url)
          document.body.removeChild(link)

          return
        }
        let { data = {} as any } = res
        const { code = 500, message = {} as any } = data
        const { id = '', text = '', show = false, duration = 5 } = message
        const status = (message.status || ERROR).toLowerCase()
        data = {
          code,
          id,
          status: status === SUCCESS || status === true,
          text,
          data: data.data,
        }
        if (show && !hideToast)
          configureStore.dispatch({
            type: CREATE_TOAST,
            payload: { duration: (duration || 5) * 1000, type: status, message: { content: text } },
          })

        resolve(data)
        callback(data)
      })
      .catch(async (error) => {
        reject(error)

        const { response = {} } = error || {}

        const { status } = response

        const callbackError = () =>
          callback({
            code: 500,
            id: '500',
            status: false,
            text: 'Có lỗi xảy ra. Vui lòng thử lại.',
            data: null,
            error,
          })

        const callbackErrorRefresh = () =>
          callback({
            code: status,
            id: status,
            status: false,
            text: `Phiên đăng nhập hết hạn.`,
            data: null,
            error,
          })

        if (status === 403 || status === 401 || status === 422) {
          if (!isRefresh) {
            const res = await refresh(callbackErrorRefresh)
            if (res) {
              request(method, url, data, callback, hideToast, true)
            }
          }
        } else {
          callbackError()
        }
      })
  })
}

/**
 * Request process callback with method GET
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} callback callback
 */
const apiGet = (url = '', params = {}, callback?: CallbackType) => {
  return request('get', url, params, callback)
}

/**
 * Request process callback with method POST
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} callback callback
 */
const apiPost = (url = '', params = {}, callback?: CallbackType) => {
  return request('post', url, params, callback)
}

/**
 * Request process callback with method PUT
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} callback callback
 */
const apiPut = (url = '', params = {}, callback?: CallbackType) => {
  return request('put', url, params, callback)
}

/**
 * Request process callback with method DELETE
 *
 * @param {String} url Request URL
 * @param {Object} params Request params
 * @param {Function} callback callback
 */
const apiDelete = (url = '', params = {}, callback?: CallbackType) => {
  return request('delete', url, params, callback)
}

export const useApis = () => ({
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
  requestApi: request,
})

export default { get: apiGet, post: apiPost, put: apiPut, delete: apiDelete, request }
