/* eslint-disable */
import { Button as MuiButton, Collapse, Drawer } from '@mui/material'
import React, { useState } from 'react'
import { matchPath } from 'react-router-dom'
import Button from '../button/Button'
import Link from '../links/Link'
import { menu, routes } from './constants'
import MenuItem from '../menuItem/MenuItemCollapse'
import { useModalAuth } from 'store/modalAuth'
import { DEFAULT_AVATAR, TRAINING_INFO_TYPES } from 'configs/constants'
import { createTrainingLink } from 'utils'
const { ALL } = TRAINING_INFO_TYPES

interface Props {
  isAuth: boolean
  avatar_url?: string
  open?: boolean
  onClose?: any
  menuUserItems: any[]
  seminar?: string
  handleRiderect?: () => void
}

const Sidebar: React.FC<Props> = ({
  isAuth,
  avatar_url,
  onClose,
  menuUserItems,
  seminar,
  handleRiderect,
  ...rest
}) => {
  const { showModalSignIn } = useModalAuth()

  const [show, setShow] = useState(false)

  const isActive = (path: string) => !!matchPath(window.location.pathname, { path, exact: false })

  return (
    <Drawer anchor="right" {...rest} onBackdropClick={onClose} keepMounted={true}>
      <div className="UserSidebar" style={{ width: 292 }}>
        <div className="UserSidebar-header d-f jc-sb ai-c">
          <i onClick={onClose} className="icon-arrow-ios-left c-p neutral-1" />
          {isAuth ? (
            <MuiButton
              className={`Header-avatar${show ? ' open' : ''}`}
              onClick={() => setShow(!show)}
            >
              <div className="Header-avatar__border" />
              <img alt="" src={avatar_url || DEFAULT_AVATAR} />
            </MuiButton>
          ) : (
            <Button
              className="Header-signIn"
              colorType="top"
              onClick={() => {
                onClose()
                showModalSignIn()
              }}
            >
              Đăng nhập
            </Button>
          )}
          <Button
            style={{ marginLeft: 8 }}
            className="Header-changePage"
            colorType="default secondary"
            onClick={handleRiderect}
          >
            Trang Quản trị <i style={{ margin: 0 }} className="icon-arrow-ios-right" />
          </Button>
        </div>
        {isAuth && (
          <Collapse in={show} timeout={500}>
            {menuUserItems.map(({ to, title, onClick }, index) => (
              <div key={index} className="UserSidebar-label" style={{ marginRight: -4 }}>
                <Link
                  to={to || '/'}
                  semantic={isActive(to)}
                  onClick={(e) => {
                    if (!to) e.preventDefault()
                    onClose()
                    onClick?.()
                  }}
                >
                  {title}
                </Link>
              </div>
            ))}
            <div className="UserSidebar-hr" />
          </Collapse>
        )}
        {seminar && (
          <span className="animate-charcter" onClick={() => window.open(seminar)}>
            Hội thảo
          </span>
        )}
        {routes.map(({ path, title }) => (
          <div key={path} className="UserSidebar-label">
            <Link to={path} semantic={isActive(path)} onClick={onClose}>
              {title}
            </Link>
          </div>
        ))}
        <div className="UserSidebar-hr" />

        {menu.map(({ title, to, subs }, index) => (
          <div key={index}>
            <MenuItem to={to} title={title} subs={subs} onClose={onClose} />
          </div>
        ))}
      </div>
    </Drawer>
  )
}

export default Sidebar
