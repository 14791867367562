import { MaxLine } from 'components'
import React from 'react'
import { SubjectType } from 'types'
import './index.scss'

const SubjectItem: React.FC<SubjectType> = ({ name }) => {
  return (
    <div className="SubjectItem">
      <div className="slider-card">
        <i className="icon-open-book" />
        <div className="line-below" />
        <MaxLine className="typo-24-6" text={name} numberOfLines={1} />
      </div>
    </div>
  )
}

export default SubjectItem
