import { ALLOW_REDIRECT, PREVENT_REDIRECT } from 'store/actionTypes'
import { DispatchType } from 'types'

export const allowRedirect = () => (dispatch: DispatchType) => {
  dispatch({ type: ALLOW_REDIRECT })
}

export const preventRedirect = () => (dispatch: DispatchType) => {
  dispatch({ type: PREVENT_REDIRECT })
}
