import { Container } from '@mui/material'
import Scrollbar from '../../scrollbar/Scrollbar'
import React from 'react'
import { useIsMobile } from 'store/mobile'
import { ScientificPaperType } from 'types'
import ScientificPaperItem from '../scientificPaperItem/ScientificPaperItem'
import Title from '../title/Title'
import { bibtexToJson } from 'utils'

interface Props {
  title?: string
  banner?: any
  scientificPapers?: ScientificPaperType[]
  maxHeight?: number
  maxLineMobile?: number
  isFull?: boolean
}

const ScientificPapers: React.FC<Props> = ({
  title = 'Công bố khoa học',
  // banner,
  scientificPapers,
  maxHeight = 1520,
  maxLineMobile,
  isFull = false,
}) => {
  const isMobile = useIsMobile()

  if (!scientificPapers?.length) return null

  const newData = scientificPapers.map((i: any) => {
    const bibtex = bibtexToJson(i?.bibtex)
    return {
      ...i,
      ...bibtex,
      id: i.id,
      author: bibtex.newAuthors,
      book_number: bibtex.book_number || i.number,
      book_type: i.type,
    }
  })

  const newScientificPapers = newData?.map((item) => {
    const {
      year,
      title,
      journal,
      booktitle,
      publisher,
      howpublished,
      institution,
      school,
      organization,
      volume,
      number,
      pages,
      ENTRYTYPE,
      cited_by,
      link,
    } = item

    let { author } = item

    if (ENTRYTYPE === 'proceedings') author = item.editor || item.author

    const publishedBy =
      school || journal || booktitle || howpublished || organization || publisher || institution
    const vol = `${volume ? volume : ''}${number ? ` (${number})` : ''}`

    const description = [publishedBy, vol, pages].filter((i) => i).join(', ')

    return { date: year, title, author, description, cited_by, link }
  })
  const rederContent = (
    <div className="MyScientificPapersComponent">
      <Title>{title}</Title>
      {/* <div
        style={banner ? { backgroundImage: `url("${banner}")` } : undefined}
        className="banner background-image cover d-f ai-c jc-c typo-40-7 ta-c"
      >
        {title}
      </div> */}
      {!isMobile && (
        <div className="MyScientificPapersComponent-row header typo-16-6 ta-c">
          <div className="col-left">Tiêu đề</div>
          <div className="col-right">Tổng trích dẫn</div>
        </div>
      )}
      <Scrollbar style={{ maxHeight: isMobile ? undefined : maxHeight }}>
        {newScientificPapers.map((item, index) => (
          <div key={index} className="MyScientificPapersComponent-item body">
            <div className="MyScientificPapersComponent-row ">
              <div className={isMobile ? '' : 'col-left'}>
                <ScientificPaperItem key={index} maxLineMobile={maxLineMobile} {...item} />
              </div>
              {!isMobile && (
                <div className="col-right d-f ai-c jc-c neutral-1 ta-c typo-16-5">
                  {item.cited_by}
                </div>
              )}
            </div>
          </div>
        ))}
      </Scrollbar>
    </div>
  )

  return <>{isFull ? rederContent : <Container>{rederContent}</Container>}</>
}

export default ScientificPapers
