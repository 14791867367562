import { Container } from '@mui/material'
import React from 'react'
import { OverviewType } from 'types'
import './index.scss'

const plusSymbol = <p className="typo-25-8 plus">+</p>

const Overview: React.FC<OverviewType> = ({
  department,
  research_group,
  scientific_paper,
  research_information,
  reward,
  noBackground,
}) => {
  if (!department && !research_group && !scientific_paper && !research_information && !reward)
    return null

  const data = [
    {
      icon: 'department',
      count: department,
      label: (
        <>
          Bộ môn
          <br />
          &amp; Trung tâm
        </>
      ),
    },
    {
      icon: 'research-group1',
      count: research_group,
      label: (
        <>
          Nhóm nghiên
          <br />
          cứu
        </>
      ),
    },
    {
      icon: 'scientific-paper',
      count: scientific_paper > 1000 ? <>1000{plusSymbol}</> : scientific_paper,
      label: (
        <>
          Công bố
          <br />
          khoa học
        </>
      ),
    },
    {
      icon: 'research-info',
      count: research_information > 100 ? <>100{plusSymbol}</> : research_information,
      label: (
        <>
          Đề tài,
          <br />
          dự án
        </>
      ),
    },
    {
      icon: 'reward',
      count: reward > 50 ? <>50{plusSymbol}</> : reward,
      label: (
        <>
          Giải thưởng
          <br />
          &amp; danh hiệu
        </>
      ),
    },
  ]

  return (
    <div className={`Overview${noBackground ? ' mt-0' : ''}`}>
      {!noBackground && <div className="background" />}
      <Container>
        <div className="Overview-row">
          {data.map(({ icon, label, count }) => (
            <div key={icon} className="col">
              <div>
                <div className="icon d-f ai-c jc-c">
                  <i className={`icon-${icon}`} />
                </div>
                <div className="typo-64-7 count d-f ai-fs">{count}</div>
                <p className="typo-24-6-5 label">{label}</p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Overview
