import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'

import { Container } from '@mui/material'
import { useBreadcrumb } from 'store/breadcrumb'
import { MenuItem } from 'types'

import './index.scss'
import { NavLink } from 'components'
import { useIsMobile } from 'store/mobile'

interface Props {
  className?: string
}

const Breadcrumb: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { breadcrumb, banner, noMargin } = useBreadcrumb()
  const isMobile = useIsMobile()

  const { className = '' } = props

  if (isNil(breadcrumb)) return null

  const arrow = (
    <div className="Breadcrumb-arrow">
      <span className="icon-arrow-ios-right" />
    </div>
  )

  const breadcrumbList = (
    <ul className={`Breadcrumb-list${banner ? ' hasBanner' : ''}`}>
      <li>
        <NavLink className={isEmpty(breadcrumb) ? 'Breadcrumb-defaultName' : ''} to="/">
          {t('hostname')}
        </NavLink>
        {!isEmpty(breadcrumb) && arrow}
      </li>
      {breadcrumb.map((item: MenuItem, index: number) => (
        <li key={index}>
          <NavLink to={item.path}>
            {t(
              (isMobile && item.extraNameMobile ? item.extraNameMobile : item.extraName) ||
                item.name
            )}
          </NavLink>
          {index < breadcrumb.length - 1 && arrow}
        </li>
      ))}
    </ul>
  )

  if (banner)
    return (
      <div
        className={`Breadcrumb-banner${noMargin ? ' noMargin' : ''}`}
        style={{ backgroundImage: `url("${banner}")` }}
      >
        <Container className="d-f fd-c ai-c">
          <div className="Breadcrumb-title typo-70-7">{breadcrumb[breadcrumb.length - 1].name}</div>
          {breadcrumbList}
          <div className="line-below" />
        </Container>
      </div>
    )

  return (
    <div className={`Breadcrumb-area ${className}`}>
      <Container className="Breadcrumb-container">{breadcrumbList}</Container>
    </div>
  )
}

export default Breadcrumb
