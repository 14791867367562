import { removeVietnameseTones } from './common'

export const validateEmail = () => /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
export const validatePassword = () =>
  /(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9!@#$%^&*()\\-_=+{}|?>.<,:;]{8,16}/
export const validateName = () => /^([A-Za-z ']+\s?)*$/
export const validatePhone = () => /^([+1-9]{2}|[0-9])[0-9]{0,19}$/

export const validateTypingPhone = (e: any) => /^[+0-9][0-9]*$/.test(e.target.value)
export const validateTypingNumber = (e: any) => /^[0-9]*$/.test(e.target.value)
export const validateTypingNumberD = (e: any) => /^[1-9][0-9.]*$/.test(e.target.value)

export const validateTypingEmail = (e: any) => {
  const value = e.target.value
  if (value === '') return true
  if (value.trim() === '') return false
  if (value.includes(' ')) return false
  // if (/^[A-Z0-9a-z._%+-@-]*$/.test(value)) return true
  return true
}

export const validateAscii = (e: string) => {
  const value = e.toString()
  const valueAlphabet = removeVietnameseTones(value || '')
  /* eslint-disable-next-line */
  return /^[\x00-\x7F]*$/.test(valueAlphabet)
}
export const validateEmoji = (e: string) => {
  const emojiRegex =
    /* eslint-disable-next-line */
    /[\uD800-\uDBFF][\uDC00-\uDFFF]|\u2700-\u27BF|\u24C2-\u1F251|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDC00-\uDFFF]|\uD83D[\uDE00-\uDE4F]/g
  /* eslint-disable-next-line */
  return !emojiRegex.test(e)
}

export const validateTypingName = (e: any) => {
  const value = e.target.value
  if (value === '') return true
  if (value.trim() === '') return false
  if (value.includes('  ')) return false
  if (
    // eslint-disable-next-line no-useless-escape
    /[0-9`~!@#$%^&*(),.?'":;{}+=|<>_\-\\\/\[\]]/.test(value)
  )
    return false
  return true
}

export const validateTypingNameWithNumber = (e: any) => {
  const value = e.target.value
  if (value === '') return true
  if (value.trim() === '') return false
  if (value.includes('  ')) return false
  if (
    // eslint-disable-next-line no-useless-escape
    /[`~!@#$%^&*(),.?'":;{}+=|<>_\-\\\/\[\]]/.test(value)
  )
    return false
  return true
}
export const validateTypingNameWithNumberNoSpace = (e: any) => {
  const value = e.target.value
  if (value === '') return true
  if (value.trim() === '') return false
  if (value.includes(' ')) return false
  if (
    // eslint-disable-next-line no-useless-escape
    /[`~!@#$%^&*(),.?'":;{}+=|<>_\-\\\/\[\]]/.test(value)
  )
    return false
  return true
}

export const validateTypingNameDash = (e: any) => {
  const value = e.target.value
  if (value === '') return true
  if (value.trim() === '') return false
  if (value.includes('  ')) return false
  if (
    // eslint-disable-next-line no-useless-escape
    /[0-9`~!@#$%^&*(),.?'":;{}+=|<>_\\\/\[\]]/.test(value)
  )
    return false
  return true
}

export const validateTypingPassword = (e: any) => {
  const value = e.target.value
  if (value === '') return true
  if (
    // eslint-disable-next-line no-useless-escape
    /^[a-zA-Z0-9`~!@#$%^&*(),.?'":;{}+=|<>_\-\\\/\[\]]{1,16}$/.test(value)
  )
    return true
  return false
}

export const validateTypingPreventSpace = (e: any) => {
  const value = e.target.value
  if (value === '') return true
  if (value.includes(' ')) return false
  return true
}

export const validateTypingPreventDoubleSpace = (e: any) => {
  const value = e.target.value
  if (value === '') return true
  if (value[0] === ' ') return false
  if (value.includes('  ')) return false
  return true
}
export const validateTypingScientificPaper = (e: any) => {
  const value = e.target.value
  if (value === '') return true
  if (value[0] === ' ') return false
  if (value.includes('  ')) return false
  if (value.includes('  ')) return false
  if (/[{}]/.test(value)) return false
  return true
}

export const validateTypingYear = (e: any) => {
  if (e.target.value === '') return true

  return /^[1-9][0-9]*$/.test(e.target.value)
}

export const validateTypingNumberDecimal = (e: any) => {
  const value = e.target.value

  if (value === '') return true
  if (value === '.') return false
  const splits = value.split('.')
  if (splits.length > 2) return false

  if (value.includes('.') && splits[splits.length - 1]?.length >= 3) return false
  return validateTypingNumberD(e)
}
