import React, { useEffect, useState } from 'react'
import Link from '../links/Link'
import MaxLine from '../../maxLine/MaxLine'
import { createFileName } from 'utils'
import './index.scss'
import { useApis } from 'services/api'
import { apiUrls } from 'configs/apis'

interface Props {
  attached_files: string[]
  style?: any
  isEnrollments?: boolean
}

const AttachedFile: React.FC<Props> = ({ attached_files, style, isEnrollments }) => {
  const { apiGet } = useApis()
  const [files, setFile] = useState([])

  const getFileName = () => {
    apiGet(
      apiUrls.upload(),
      {
        attached_files: attached_files,
      },
      ({ data, status }) => {
        if (status) {
          const output: any = []

          attached_files.forEach((element) => {
            const elementFind = data.find((item: any) => item.attached_file === element)

            if (elementFind) {
              const { attached_file, file_name } = elementFind

              output.push({ attached_file, file_name, trimFile: false })
            } else {
              output.push({ attached_file: element, file_name: element, trimFile: true })
            }
          })
          setFile(output)
        }
      }
    )
  }
  useEffect(() => {
    getFileName()
  }, [JSON.stringify(attached_files)])
  if (!attached_files || attached_files?.length <= 0) return null
  return (
    <div className="ScientificPaperAttachedFile" style={style}>
      <div
        className={'attachedFile-title typo-14-12-5 neutral-2 d-f ai-c'}
        style={{ lineHeight: '24px' }}
      >
        <i
          className="icon-attach attach-icon"
          style={{
            // fontSize: 24,
            marginRight: isEnrollments ? 12 : 4,
          }}
        ></i>
        File đính kèm
      </div>
      <div className="mt-1 pb-2" style={{ marginLeft: isEnrollments ? 36 : 28 }}>
        {files?.map((item: any) => (
          <Link
            key={item?.attached_file}
            className="title typo-14-12-5"
            color="system-link"
            hoverUnderline
            to={{ pathname: item.attached_file || '' }}
            target="_blank"
            style={{
              paddingBottom: 12,
              display: 'block',
              width: 'fit-content',
              lineHeight: '24px',
            }}
          >
            <MaxLine
              text={item?.trimFile ? createFileName(item?.file_name) : item?.file_name}
              numberOfLines={1}
            />
          </Link>
        ))}
      </div>
    </div>
  )
}

export default AttachedFile
