import { MODAL_AUTH_ROUTES } from 'configs/constants'
import { HIDE_MODAL_AUTH, SHOW_MODAL_AUTH } from 'store/actionTypes'
import { ActionType, ModalAuthReducer } from 'types'

const initialState: ModalAuthReducer = {
  show: false,
  route: MODAL_AUTH_ROUTES.SIGN_IN,
}

const modalAuthReducer = (state = initialState, action: ActionType) => {
  const { type, payload } = action

  switch (type) {
    case SHOW_MODAL_AUTH:
      return { show: true, route: payload }

    case HIDE_MODAL_AUTH:
      return { ...state, show: false }

    default:
      return state
  }
}

export default modalAuthReducer
