import { Container } from '@mui/material'
import EditorViewer from 'components/editorViewer/EditorViewer'
import React from 'react'

interface Props {
  contact_info?: string
}

const DepartmentContact: React.FC<Props> = ({ contact_info }) => {
  if (!contact_info) return null

  return (
    <div className="DepartmentContact mt-80">
      <Container>
        <div className="title typo-20-6 typo-20-16 primary">Thông tin liên hệ</div>
        <EditorViewer content={contact_info} />
      </Container>
    </div>
  )
}

export default DepartmentContact
