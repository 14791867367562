/* eslint-disable */
import React, { useState } from 'react'
import Button from '../button/Button'
import './index.scss'
import ModalCooperate from './ModalCooperate'

interface Props {
  noMarginTop?: boolean
}

const OfferCooperate: React.FC<Props> = ({ noMarginTop }) => {
  const [show, setShow] = useState(false)

  return (
    <div className={`OfferCooperate${noMarginTop ? ' noMarginTop' : ''}`}>
      <ModalCooperate show={show} onClose={() => setShow(false)} />
      <div className="header typo-36-6 typo-36-20">Đề nghị hợp tác</div>
      <div className="body">
        <div className="content typo-20-5">
          Liên hệ ngay để nhận tư vấn hoàn toàn miễn phí từ đội ngũ tuyển sinh nhiệt huyết, tận tâm
        </div>
        <Button colorType="default secondary big-radius" onClick={() => setShow(true)}>
          Liên hệ ngay
        </Button>
      </div>
    </div>
  )
}

export default OfferCooperate
