/* eslint-disable */
import React from 'react'
import Link from '../links/Link'
import './index.scss'
import productFullWidth from 'assets/images/product_fullwidth.jpg'
import Button from '../button/Button'
import { useHistory } from 'store/confirmRedirect'
import { useIsMobile } from 'store/mobile'

interface Props {
  id?: string
  image_url: string
  title: string
  content_to_summary?: string
  fullWidth?: boolean
}

const ProductItem: React.FC<Props> = ({ id, image_url, title, content_to_summary, fullWidth }) => {
  const history = useHistory()
  const isMobile = useIsMobile()

  const to = `/products`

  if (fullWidth)
    return (
      <div
        className="ProductItem-full background-image"
        style={{ backgroundImage: `url("${productFullWidth}")` }}
      >
        <div>
          <div className="title max-line-1 typo-36-6">{title}</div>
          <div className="content max-line">{content_to_summary}</div>
          <Button
            colorType="cta"
            onClick={() =>
              history.push(to, {
                id,
                show: true,
              })
            }
          >
            XEM CHI TIẾT
          </Button>
        </div>
      </div>
    )

  return (
    <Link
      to={{
        pathname: to,
        state: {
          id,
          show: true,
        },
      }}
      className="ProductItem-notFull"
    >
      <div style={{ backgroundImage: `url("${image_url}")` }} className="background-image"></div>
      <div className="line-below" />
      <div className={`title max-line${isMobile ? '-1' : ''} typo-24-6`}>{title}</div>
      <div className={`content max-line${isMobile ? '' : '-3'} typo-16-4`}>
        {content_to_summary}
      </div>
    </Link>
  )
}

export default ProductItem
