/* eslint-disable */
import { Button, Container } from '@mui/material'
import React from 'react'
import UserLogo from 'assets/images/user_logo.png'
import {
  GG_ANALYSTICS_URL,
  mappingStudentInfoRoutes,
  mappingStudentInfoType,
  ROUTES,
  STUDENT_INFO,
  today,
  TRAINING_INFO_TYPES,
} from 'configs/constants'
import { useApp } from 'store/app'
import { Link } from 'components/user'
import { useIsMobile } from 'store/mobile'
import { createTrainingLink } from 'utils'
import iconAnalytics from 'assets/images/ic_analytics.svg'

const { ROUTE_DEPARTMENTS, ROUTE_STUDENTS } = ROUTES

const { UNIVERSITY, MASTER, DOCTOR, SHORT_TERM_IT, SHORT_TERM_CN, SHORT_TERM_SI } =
  TRAINING_INFO_TYPES

const trainings = [
  { title: 'Đào tạo Đại học', path: createTrainingLink(UNIVERSITY) },
  { title: 'Đào tạo Thạc sĩ', path: createTrainingLink(MASTER) },
  { title: 'Đào tạo Tiến sĩ', path: createTrainingLink(DOCTOR) },
  { title: 'Chứng chỉ CNTT', path: createTrainingLink(SHORT_TERM_IT) },
  { title: 'Chứng chỉ mạng máy tính', path: createTrainingLink(SHORT_TERM_CN) },
  { title: 'Chứng chỉ an toàn thông tin', path: createTrainingLink(SHORT_TERM_SI) },
]

const students = STUDENT_INFO.map((i) => ({
  title: mappingStudentInfoType[i] as string,
  path: `/${ROUTE_STUDENTS}/${mappingStudentInfoRoutes[i]}`,
}))

const Footer: React.FC = () => {
  const { departments } = useApp()
  const isMobile = useIsMobile()

  const footerData = [
    {
      title: 'ĐƠN VỊ TRỰC THUỘC',
      data: departments.map((item) => ({
        title: item.name,
        path: { pathname: `/${ROUTE_DEPARTMENTS}`, state: item.id } as any,
      })),
    },
    {
      title: 'CHƯƠNG TRÌNH ĐÀO TẠO',
      data: trainings,
    },
    {
      title: 'HỌC VIÊN - SINH VIÊN',
      data: students,
    },
  ]

  /**
   * Handle back to top
   */
  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div className="Footer">
      <Container>
        <div className="logo d-f ai-c">
          <img alt="" src={UserLogo} width={'51px'} />
          VIỆN CÔNG NGHỆ THÔNG TIN VÀ TRUYỀN THÔNG
        </div>
        <div className="row">
          <div className="col-3">
            <div className="name">VIỆN CÔNG NGHỆ THÔNG TIN VÀ TT HỌC VIỆN KỸ THUẬT QUÂN SỰ</div>
            <div className="title">Địa chỉ:</div>
            <div className="content">
              236 Hoàng Quốc Việt,
              <br />
              Cầu Giấy, Hà Nội
            </div>
            <div className="title">Email:</div>
            <div className="content">inict@mta.edu.vn</div>
            <div className="title">Điện thoại:</div>
            <div className="content">069.515.329 - 069.515.333</div>
          </div>
          {!isMobile &&
            footerData.map(({ title, data }) => (
              <div key={title} className="col-3">
                <div>
                  <div className="col-title">{title}</div>
                  <div className="line-below small my-2" />
                  {data.map(({ title, path }) => (
                    <div className="col-content" key={title}>
                      <Link color="neutral-3" key={title} to={path}>
                        {title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
        <div className="hr" />
        <div className="Footer-end">
          <span className="copyright typo-14-4">
            © Bản quyền thuộc về Viện Công nghệ thông tin và Truyền thông - HVKTQS,{' '}
            {today.getFullYear()}
          </span>
          <div className="icons d-f">
            <Link hoverAccent to="#">
              <i className="icon-facebook" />
            </Link>
            <Link hoverAccent to="#">
              <i className="icon-twitter" />
            </Link>
            <Link hoverAccent to="#">
              <i className="icon-google" />
            </Link>
          </div>
        </div>
      </Container>

      <div className="Footer-analiytics" onClick={() => window.open(GG_ANALYSTICS_URL)}>
        <img src={iconAnalytics} alt="ic_analytics" width={24} />
        <span>Google Analytics</span>
      </div>
      <Button className="Footer-goToTop" onClick={handleBackToTop}>
        <i className="icon-arrow-to-top" />
      </Button>
    </div>
  )
}

export default Footer
