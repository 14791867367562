/* eslint-disable */
import { Popover } from '@mui/material'
import React, { useRef, useState } from 'react'
import SimpleBar from 'simplebar-react'
import { randomId } from 'utils'
import Input from '../input/Input'
import useHistorySearch from './useHistorySearch'

const Search: React.FC = () => {
  const anchorRef = useRef<HTMLInputElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [value, setValue] = useState('')
  const [open, setOpen] = useState(false)

  const { historySearch, handleSearch, handleRemoveHistory } = useHistorySearch()

  const handleClose = () => setOpen(false)

  /**
   * Handle search
   *
   * @param keyword
   */
  const handleSearchInput = (keyword: string) => {
    handleClose()
    handleSearch(keyword)
  }

  const handleSubmitSearch = (e: React.FormEvent) => {
    e.preventDefault()
    if (!value.trim()) return
    handleSearchInput(value)
  }

  return (
    <form onSubmit={handleSubmitSearch}>
      <Input
        className="Header-search"
        innerRef={anchorRef}
        inputRef={inputRef}
        name={randomId()}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        fullWidth={false}
        placeholder="Tìm kiếm"
        startIcon="icon-search"
        onRemove={() => setValue('')}
        maxLength={200}
        onClick={() => setOpen(true)}
        size="small"
        blurWhenEnter
      />
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: anchorRef.current?.getBoundingClientRect().width || 0,
          },
        }}
        disableAutoFocus
        open={open && !!historySearch.length && !value}
        onClose={() => handleClose()}
        anchorEl={anchorRef.current}
      >
        <SimpleBar autoHide={false} className="Search-paper">
          {historySearch.map((item) => (
            <div key={item} className="HistorySearch-item" onClick={() => handleSearchInput(item)}>
              <div className="d-f ai-c">
                <i className="icon-history" />
                <span>{item}</span>
              </div>
              <i className="icon-close" onClick={(e) => handleRemoveHistory(e, item)} />
            </div>
          ))}
        </SimpleBar>
      </Popover>
    </form>
  )
}

export default Search
