/*eslint-disable*/
import { Avatar, Button, TableCell, TableRow } from '@mui/material'
import MaxLine from '../../maxLine/MaxLine'
import moment from 'moment'
import React from 'react'
import { RowType, TableFieldsType } from 'types'
import { isFunction } from 'lodash'
import Checkbox from '../checkbox/Checkbox'
import { DEFAULT_AVATAR } from 'configs/constants'

interface Props {
  fields: TableFieldsType
  row: RowType
  onClick?: (item: RowType) => void
  onMiddleClick?: (item: RowType) => void
  checkbox?: {
    checked: boolean
    onClick: () => void
  }
  dnd?: {
    innerRef?: any
    dragHandleProps?: any
    draggableProps?: any
    isDragging?: boolean
  }
  dndDisable?: boolean
}

const Row: React.FC<Props> = ({
  row,
  fields,
  onClick,
  onMiddleClick,
  checkbox,
  dnd,
  dndDisable,
}) => {
  const { innerRef, dragHandleProps = {}, draggableProps = {}, isDragging } = dnd || {}

  const rowStyle = dnd ? { ...draggableProps.style, display: isDragging ? 'table' : '' } : {}

  return (
    <TableRow
      {...draggableProps}
      style={rowStyle}
      ref={innerRef}
      className={onClick ? 'TableRow-clickable' : ''}
      onClick={() => onClick?.(row)}
      onMouseDown={(e: any) => {
        if (onMiddleClick && e.button === 1) onMiddleClick(row)
      }}
    >
      {!!checkbox && (
        <TableCell style={{ width: '4%' }}>
          <Checkbox checked={checkbox.checked} onClick={checkbox.onClick} />
        </TableCell>
      )}
      {!!dnd && (
        <TableCell style={{ width: '5%', textAlign: 'center' }} {...dragHandleProps}>
          <i
            className="fas fa-arrows-alt-v"
            style={{
              color: dndDisable ? '#999' : '#727779',
              fontSize: 20,
              cursor: dndDisable ? 'default' : 'pointer',
            }}
          />
        </TableCell>
      )}
      {Object.keys(fields).map((cell: string, index: number) => {
        const columnName = cell
        const field = fields[columnName]
        const newColumnName = fields[columnName].key || columnName
        const newContent = row[newColumnName]

        const { style = {}, bodyStyle = {} } = field

        if (field.actions) field.type = 'actions'

        const checked = field.checked
        const checkedContent = isFunction(checked) ? checked(row) : checked || newContent

        let newRenderContent
        switch (field.type) {
          case 'date':
            newRenderContent = !newContent ? '-' : moment(newContent * 1000).format('DD/MM/YYYY')
            break

          case 'date-hour':
            newRenderContent = !newContent ? '' : moment(newContent * 1000).format('DD/MM/YYYY HH:mm')
            break

          case 'date-empty':
            newRenderContent = !newContent ? '' : moment(newContent * 1000).format('DD/MM/YYYY')
            break

          case 'avatar':
            newRenderContent = (
              <Avatar alt="" src={newContent || DEFAULT_AVATAR} className="Row-avatar" />
            )
            break

          case 'image':
            newRenderContent = <img alt="" src={newContent} className="Row-image" />
            break

          case 'user':
            newRenderContent = <div title={newContent?.email}>{newContent?.full_name || ''}</div>
            break

          case 'check':
            newRenderContent = checkedContent ? (
              <i
                style={{ marginLeft: 20, fontSize: 22, color: 'var(--cl-primary)' }}
                className="fas fa-check-circle"
              />
            ) : null // far fa-circle'
            break

          case 'content':
            newRenderContent = row[`${newColumnName}_to_summary`]
            break

          case 'dash':
            newRenderContent = newContent || '-'
            break

          case 'actions':
            newRenderContent = (
              <div className="Row-actions">
                {field.actions?.map((i, index) => {
                  let hidden = i.hidden
                  let icon = i.icon
                  let title: any = i.title
                  if (isFunction(hidden)) hidden = hidden(row)
                  if (isFunction(icon)) icon = icon(row)
                  if (isFunction(title)) title = title(row)

                  return (
                    <Button
                      style={{ visibility: hidden ? 'hidden' : 'visible' }}
                      disabled={i.disabled}
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation()
                        i.action(row)
                      }}
                      title={title}
                    >
                      <i className={`fas fa-${icon}`} />
                    </Button>
                  )
                })}
              </div>
            )
            break

          default:
            if (field.renderContent) newRenderContent = field.renderContent(row)
            else newRenderContent = newContent
            break
        }

        return (
          <TableCell key={index} style={{ ...style, ...bodyStyle }}>
            {field.disableMaxLine ? (
              newRenderContent
            ) : (
              <MaxLine
                hideTooltip={field.type === 'content'}
                title={field.title ? field.title(row) : field.defaultTitle ? newContent : undefined}
                text={newRenderContent}
                numberOfLines={field.maxLine}
              />
            )}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default Row
