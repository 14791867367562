import moment from 'moment'

/**
 * Format timestamp to DD/MM/YYYY
 * @param text
 * @param keyword
 * @returns
 */
export const formatDate = (timestamp?: number) => {
  return moment((timestamp || 0) * 1000).format('DD/MM/YYYY')
}
